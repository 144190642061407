import React from 'react'

const IcCycleNext = (props) => {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.66" cx="20" cy="20" r="19.5455" fill="white" stroke="#939393" strokeWidth="0.909091"/>
            <path d="M24.7959 21.3379L18.255 28.6055C17.8137 29.0959 17 28.7837 17 28.1239C17 27.9518 17.0617 27.7854 17.1738 27.6548L22.6304 21.3033C23.2743 20.5537 23.2743 19.4463 22.6304 18.6967L17.1738 12.3452C17.0617 12.2146 17 12.0482 17 11.8761C17 11.2163 17.8137 10.9041 18.255 11.3945L24.7959 18.6621C25.4804 19.4227 25.4804 20.5773 24.7959 21.3379Z" fill="#939393"/>
        </svg>

    )
}

export default IcCycleNext
