export const HOME = 'home'
export const FACEPAY_MAGIC = 'facepay_magic'
export const FACEPAY_BY_BANK = 'facepay_by_bank'
export const TUTORIAL = 'tutorial'
export const SUPPORT = 'support'
export const COMMUNICATION = 'communication'
export const PARTNER = 'partner'
export const POLICY = 'policy'
export const TERMS_OF_USE = "terms-of-use"
export const SCOPE_APPL = 'scope-of-application'
export const INFOMATION_COLLETED = 'infomation-colleted'
export const FACEPAY_PURPOSES = 'facepay-purposes'
export const SHARE_AND_DISCLOSE = 'share-and-disclose'
export const CHOOSE_THE_RIGHT_PRIVATE = 'choose-the-right-private'
export const ACCESS_AND_CUSTOMIZE= "access-and-customize"
export const PRIVACY_POLICY_CHANGES = 'privacy-policy-changes'
export const INFOMATION_SECURITY = 'information-security'
export const OTHER_REGULATIONS = 'other-regulations'

export const FOUNDER = 'founder'
export const WEE_DIGITAL = 'wee_digital'
export const RECRUITMENT = 'recruitment'
export const FACEPAY_PAYMENT_STORES = 'facepay_payment_stores'

export const ACCOUNT_MANAGEMENT = 'account-management'
export const BASIC_INFOMATION = 'basic-infomation'
export const PAYMENT_AT_COUNTER = 'payment-at-counter'
export const RESOLVE_COMPLAINT = 'resolve-complaint'
export const SIGN_UP_ACCOUNT = 'sign-up-account'
export const FAQ_FACEPAY_MAGIC = 'faq_facepay_magic'

export const DOWNLOAD = "download"
export const LINK = "link"
export const PAYMENT = "payment"

export const FACEPAY = "facepay"
export const TPBANK = "tp_bank"
export const OCB = "ocb"
export const VTB = "vietinbank"
export const SP = "smartpay"
export const EXIM = "eximbank"
