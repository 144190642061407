import styled from 'styled-components'

const WrapPage = styled.div`
    .wrap-page{
        .page{
            margin: 0 auto;
            padding: 0 24px;
            box-sizing: border-box;
            &.page-one{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                position: relative;
                min-height: 788px;
                height: 100vh;
                background: #F7FCFF;
                .lottie{
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 100%;
                    svg{
                        height: 450px !important;
                    }  
                }
                .wrap-text{
                    font-family: "SVN-Bebas Neue";
                    font-style: normal;
                    padding-top: 125px;
                    .title{                       
                        font-weight: bold;
                        font-size: 74px;
                        line-height: 72px;
                        text-align: center;
                        letter-spacing: -1px;
                        text-transform: uppercase;
                        color: #191919;
                        z-index: 1;
                        display: flex;
                        flex-direction: column;
                        &.blue{
                            color: #1279DA;                        
                        }
                    }
                    .desc{
                        font-family: "SVN-Gilroy";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 21px;
                        color: #304659;
                        text-align: center;
                        z-index: 1;
                        padding: 24px 18px 0 18px;
                        margin: 0 auto;
                    }
                }      
            }
            &.page-three{
                min-height: 788px;
                height: fit-content;
                padding: 40px 24px;
                background: #F7FCFF;
                .content{
                    padding-bottom: 21px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 80px;
                        height: 80px;
                    }
                    .desc{
                        font-family: "SVN-Gilroy";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 130%;
                        color: #939393;
                        text-align: center;
                        &.bold{
                            font-weight: bold;
                            font-size: 22px;
                            line-height: 26px;
                            padding: 20px 0 9px 0;
                            color: #191919;
                        }
                    }
                }
            }        
        }
    }
    .page-two{
        /* min-height: 788px; */
        height: fit-content;
        .wrap-title{     
            padding: 56px 15px 0 15px;
            .symbol{
                font-family: "SVN-Bebas Neue";
                font-style: normal;
                font-weight: bold;
                font-size: 77px;
                line-height: 74px;
                letter-spacing: -1px;
                text-transform: uppercase;
                color: #C8C8C8;
            }
            .title{
                font-family: "SVN-Bebas Neue";
                font-style: normal;
                font-weight: bold;
                font-size: 77px;
                line-height: 74px;
                letter-spacing: -1px;
                text-transform: uppercase;
                color: #191919;
                &.two{
                    padding: 0 0 0 20px;
                }
                &.blue{
                    color: #1279DA; 
                    padding: 0 0 0 20px;                   
                }
            }
        }
        .wrap-desc{
            padding-bottom: 70px;
            .desc{
                font-family: "SVN-Gilroy";
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 21px;
                color: #191919;
                padding: 20px 24px 0 24px;
                &:first-of-type{
                    padding: 40px 24px 0 24px;
                }
            }
        }
    }
    .page-four{
        /* min-height: 788px; */
        height: fit-content;
        padding: 66px 24px 8px 24px;
        .title-page{
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 39px;
            text-align: center;
            color: #191919;
            &.blue{
                color: #1279DA;
            }
        }
        .wrap-content{
            padding-top: 66px;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            display: flex;
            flex-direction: column;
            .content{
                padding-bottom: 32px;
                text-decoration: none;
                .title{               
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 22px;
                    color: #191919;
                }
                .date{
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 130%;
                    color: #191919;
                    padding: 10px 0;
                    span{
                        color: #1279DA;
                    }
                }
                .desc{
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 130%;
                    color: #939393;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    padding-bottom: 12px;
                }
                .view{
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 130%;
                    color: #1279DA;
                    /* cursor: pointer; */
                    /* text-decoration: none; */
                    /* padding-top: 12px; */
                }
            }
        }
    }

    @media only screen and (min-width: 1024px){     
        .wrap-page{
            width: 100vw;
            background: #F7FCFF;
            .page{
                max-width: 1200px;
                &.page-one{
                    .lottie{
                        width: unset;
                        svg{
                            height: 490px !important;
                        }  
                    }
                    .wrap-text{
                        padding-top: 165px;
                        .title{
                            font-size: 102px;
                            line-height: 102px; 
                            flex-direction: row;
                            &.blue{
                                padding-left: 5px;
                            }                           
                        }
                        .desc{
                            font-size: 16px;
                            line-height: 23px;
                            width: 418px;
                            padding: 22px 0 0 0;
                        }
                    }
                }
                &.page-three{
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 30px;
                    align-items: center;
                    min-height: unset;
                    height: fit-content;
                    padding: 74px 0 95px 0;
                    .content{
                        padding-bottom: 0;
                        align-items: flex-start;
                        .desc{
                            font-size: 13px;
                            line-height: 16px;
                            text-align: unset;
                            &.bold{
                                font-size: 22px;
                                line-height: 26px;
                                padding: 25px 0 18px 0;
                            }
                        }
                    }                    
                }              
            }
        }
        .page-two{
            max-width: 1200px;
            margin: 0 auto;            
            padding: 161px 0 192px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .wrap-title{
                padding: 0;
                .symbol{
                    font-size: 102px;
                    line-height: 102px;
                }
                .title{
                    font-size: 102px;
                    line-height: 102px;
                    &.two{
                        padding: 0 0 0 25px;
                    }
                    &.blue{
                        padding: 0 0 0 25px;
                    }
                }
            }
            .wrap-desc{
                width: 458px;
                padding-bottom: 0;
                .desc{
                    font-size: 16px;
                    line-height: 23px;
                    padding: 0 0 20px 0;
                    &:first-of-type{
                        padding: 0 0 20px 0;
                    }
                    &:last-of-type{
                        padding: 0 0 0 0;
                    }
                }
            }
            .image-pagetwo{
                position: unset                
            }
        }
        .page-four{
            max-width: 1200px;
            margin: 0 auto;
            padding: 55px 0 60px 0;
            .title-page{
                font-size: 50px;
                line-height: 52px;
            }
            .wrap-content{
                padding-top: 82px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);                
                grid-gap: 40px 110px;
                .content{
                    padding-bottom: unset;
                    .title{
                        font-size: 21px;
                        line-height: 24px;
                    }
                    .date{
                        padding: 14px 0;
                    }                    
                }
            }
        }    
    }

`
export { WrapPage }