import * as types from './types'

export const initialState = {
    result: {

    },
    code: "",
}

const formRegister = (state = initialState, action) => {
    switch (action.type) {

        case types.FORM_REGISTER: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.FORM_REGISTER_ERR: {
            return {
                ...state,
                ...action.payload
            }
        }

        default:
            return state
    }
}

export default formRegister