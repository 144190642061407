import React from 'react'
import { withNamespaces } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';

//constant
import * as pathNames from '../../../assets/constant/pathNames'

//data
import { AccountManagementData } from '../../../assets/data/AccountManagementData';

//component
import QAndAList from '../../Control/QAndAList';

const AccountManagement = ({ t }) => {

    const { subSupportType } = useParams()
    const { url } = useRouteMatch()

    return (
        <QAndAList
            urlSupportPage={url.replace(`\/${subSupportType}`, "")}
            defaultActiveTab={subSupportType}
            title={t('account_management.title')}
            tab={t('account_management.tab', { returnObjects: true })}
            objDatas={AccountManagementData}
            path={pathNames.ACCOUNT_MANAGEMENT}
        />
    )
}

export default withNamespaces()(AccountManagement)
