import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next';

//style
import { WrapPage } from './index.styles'

//video
import christan_video from '../../assets/media/christian_video.mov'

//component
import PageSix from '../Home/PageSix';
import IconUnmute from '../../assets/images/IconUnmute';
import IconMute from '../../assets/images/IconMute';


const Founder = ({ t }) => {

    const [isMute, setIsMute] = useState(true)

    const toogleIsMuteVideo = () => {
        setIsMute(!isMute)
    }

    return (
        <WrapPage>
            <div className="wrap-page">
                <div className="page-home">
                    <div className="content">
                        <p className="title">{t('founder.title')}</p>
                        <p className="name">
                            <span className="name blue">{t('founder.chris')}</span> {t('founder.nguyen')}
                        </p>
                        <p className="desc">{t('founder.desc')}</p>

                    </div>
                    <div className='video-container'>
                        <video playsInline muted={isMute} autoPlay loop >
                            <source src={christan_video} type="video/mp4" />
                        </video>
                        {
                            isMute ?
                                <IconUnmute className='icon-mute' onClick={() => toogleIsMuteVideo()} />
                                :
                                <IconMute className='icon-mute' onClick={() => toogleIsMuteVideo()} />
                        }
                    </div>
                </div>
            </div>
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(Founder)
