import React from 'react'

const IcCyclePrevious = (props) => {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.66" cx="20" cy="20" r="19.5455" transform="matrix(-1 0 0 1 40 0)" fill="white" stroke="#939393" strokeWidth="0.909091"/>
            <path d="M15.2041 21.3379L21.745 28.6055C22.1863 29.0959 23 28.7837 23 28.1239C23 27.9518 22.9383 27.7854 22.8262 27.6548L17.3696 21.3033C16.7257 20.5537 16.7257 19.4463 17.3696 18.6967L22.8262 12.3452C22.9383 12.2146 23 12.0482 23 11.8761C23 11.2163 22.1863 10.9041 21.745 11.3945L15.2041 18.6621C14.5196 19.4227 14.5196 20.5773 15.2041 21.3379Z" fill="#939393"/>
        </svg>

    )
}

export default IcCyclePrevious
