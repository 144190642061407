import React, { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

// styles
import { WrapCommunicationPost } from './index.styles';

// constant
import { COMMUNICATION } from '../../assets/constant/pathNames';

// data
import { CommunicationData } from '../../assets/data/CommunicationData';

// component
import IcArrowLeft from '../../assets/images/IcArrowLeft';
import PageSix from '../Home/PageSix';

//image
import ic_facebook from '../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

const CommunicationPost = ({ t }) => {

    const { postKey } = useParams()

    const [postDetails, setPostDetails] = useState(null);

    useEffect(() => {
        const postDetails = CommunicationData[postKey]
        if (!postDetails) return;
        setPostDetails(postDetails)
    }, [postKey]);

    if (!postDetails) return null

    const { title, date, desc, contents, otherPosts } = postDetails

    return (
        <WrapCommunicationPost>
            <div className="page page-first">
                <Link to={`/${COMMUNICATION}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <div className="title">{t(title)}</div>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t(date)}</div>
                </div> */}
                <div className="desc">{t(desc)}</div>
                <div className="wrap-content">
                    {contents.map((item, index) => {
                        const content = t(item, { returnObjects: true })
                        if (typeof (content) === 'string') {
                            return <div key={index} className="content" dangerouslySetInnerHTML={{ __html: content }} />
                        }
                        const { src, alt, title_img } = postDetails[content.link]
                        return (
                            <figure key={index}>
                                <img src={src} alt={alt} />
                                <figcaption>{t(title_img)}</figcaption>
                            </figure>
                        )
                    })}
                </div>
            </div >
            <div className="wrap-page">
                <div className="page page-other-post">
                    <div className="title-other-post">{t('communication.other_post')}</div>
                    <div className="wrap-post">
                        {otherPosts.map(postKey => {
                            const post = CommunicationData[postKey]
                            const { image, title, link } = post
                            return (
                                <div className="mini-post" key={postKey}>
                                    <img src={image} alt="post" />
                                    <div>
                                        <div className="title-post">{t(title)}</div>
                                        <Link className="more" to={link}>{t('common.xem_them')}</Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <PageSix />
        </WrapCommunicationPost >
    )
}

export default withNamespaces()(CommunicationPost)
