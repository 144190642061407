import React from 'react'
import { withNamespaces } from 'react-i18next'

// image
import IcMailWhite from '../../../assets/images/IcMailWhite'
import IcPhoneWhite from '../../../assets/images/IcPhoneWhite'

// styles
import { WrapContactPage } from './index.styles'

const ContactPage = ({ t }) => {
    return (
        <WrapContactPage>
            <div className="contact-page">
                <div className="title-page"><span>{t('support.page_three.title.text1')}</span> {t('support.page_three.title.text2')}</div>
                <div className="list-contact-method">
                    <div className="request-support">
                        <div>
                            <div className="contact-title">{t('support.page_three.gui_yeu_cau.title')}</div>
                            <div className="contact-details">{t('support.page_three.gui_yeu_cau.details')}</div>
                        </div>
                        <a className="link-email" href="mailto: cs@facepay.vn">
                            <button><IcMailWhite />
                                <p>{t('support.page_three.gui_yeu_cau.btn_title')}</p>
                            </button>
                        </a>
                    </div>
                    <div className="hotline">
                        <div>
                            <div className="contact-title">{t('support.page_three.hotline.title')}</div>
                            <div className="contact-details">{t('support.page_three.hotline.details')}</div>
                        </div>
                        <a className="link-phone" href="tel: 02037101010">
                            <button><IcPhoneWhite />
                                <p>{t('support.page_three.hotline.btn_title')}</p>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </WrapContactPage>
    )
}

export default withNamespaces()(ContactPage)
