import styled from 'styled-components'
import bg_home from '../../../assets/images/bg_home.png'
// import bg_home_mobile from '../../../assets/images/bg_home_mobile.png'

const Bound = styled.div`
    background-image: url(${bg_home});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0 0 29px;
    height: 100vh;
    min-height: 788px;
    overflow: hidden;
    .page-wrapper{ 
        padding-top: 100px;
        position: relative;
        height: calc(100% - 100px);
        .page-one-content-container{
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 2;
            width: 100%;
            top: 220px;
            left: 0;
            .title-page{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 34px;
                line-height: 40px;
                color: #000000;            
                padding-bottom: 5px;
                height: 223px;
                margin: 0 0 18px 0;
                .title-page-wrap{
                    display: flex;
                    flex-direction: column;
                }
                &.black{
                    color: rgba(25, 25, 25, 1);
                }
                .no-cash-animation{
                    position: absolute;
                    left: -20px;
                    width: 345px;
                    height: 223px;
                }
            }
            .content-page{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 21px;
                color: #FFFFFF;   
                max-width: 264px;
                .black{
                    color: rgba(25, 25, 25, 1);
                    margin-left: 4px;
                }
                
            }
            .badge-wrap{
                display: flex;
                justify-content: center;
                margin: 39px auto 0;
                .ic-badge{
                    padding-right: 10px;
                    cursor: pointer;                    
                    &.google-play{
                        width: 161px;
                        height: 48px;
                    }
                    &.app-store{
                        width: 141px;
                        height: 48px;
                    }
                }
            }
            .btn-view-details{
                width: 248px;
                height: 39px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 24px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 38px;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                color: #1279DA;
                cursor: pointer;
                margin: 36px auto 0;
            }
        }
        .img-model{           
            display: none;
        }   
        .img-model-mobile{
            position: absolute;
            bottom: 0;
            /* right: 0; */
            left: -80px
        }    
    }   

    @media only screen and (min-width: 630px) and (max-width: 770px){
        .page-wrapper{
            .lottie{
                width: 720px !important;
                height: 753px !important;
                right: unset !important;
                left: 180px !important;
            }
            .mask-group{
                visibility: visible;
                right: unset !important;
                left: 140px;
            }
        }  
    }
    
    @media only screen and (min-width: 630px){   
        display: flex;
        justify-content: center;
        padding: 0; 
        .page-wrapper{
            padding: 100px 32px 0 32px;
            display: flex;
            flex-direction: column;
            max-width: 1200px;
            width: 100%;
            .page-one-content-container{
                display: flex;
                flex-direction: column;
                position: unset;
                margin-top: 60px;
                .title-page{
                    height: 350px;
                    font-size: 50px;
                    line-height: 57px;
                    margin: 50px 0 25px;
                    .title-page-wrap{
                        display: inline-block;
                        .frist{
                            padding-right: 10px;
                        }
                    }
                    &.blue{
                        flex-direction: column;
                    }
                    .no-cash-animation{
                        left: -5px;
                        width: 540px;
                        height: 350px;
                    }
                }
                .content-page{                    
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    color: #FFFFFF;
                    max-width: 498px;                
                }
                .badge-wrap{
                    display: flex;
                    justify-content: unset;
                    margin: 52px 0 0 0;
                    .ic-badge{
                        padding-right: 20px;
                        cursor: pointer;                    
                        &.google-play{
                            width: 188px;
                            height: 55px;
                        }
                        &.app-store{
                            width: 167px;
                            height: 55px;
                        }
                    }
                }
                .btn-view-details{
                    justify-content: unset;
                    margin: 34px 0 0 64px;
                }
            }
            .img-model{
                display: block;
                position: absolute;
                right: -100px; 
                bottom: 0;
            }
            .img-model-mobile{
                display: none ;
            }
        }        
    }
`

const OverlayFullScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    max-width: 1280px;
    height: 900px;
    background: ${props => props.notUseOverlay ? 'transparent' : '#22222280'};
    z-index: 6;

    .wrap-inner-screen {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export { Bound, OverlayFullScreen }