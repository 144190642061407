import styled from 'styled-components'

const WrapBurgerBtn = styled.div`
    width: 25px;
    height: 25px;
    /* padding-bottom: 10px; */
    margin-bottom: 18px;
    &:hover {
        cursor: pointer;
       
    }

    .inner-burger-btn {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        

        &.expand {
            .top-bun {
                width: 25px;
                transform: 
                    translateX(-50%)
                    translateY(8px)
                    rotateZ(-135deg);
            }
            .patty {
                display: none;
            }
            .bottom-bun {
                width: 25px;
                transform: 
                    translateX(-50%)
                    translateY(-7px)
                    rotateZ(135deg);
            }
        }

        & > * {
            box-sizing: border-box;
            width: 18px;
            border: 1px solid ${props => props.color ?? '#304659'};
            position: absolute;
            display: table;
            border-radius: 20px;
            
        }
    
        .top-bun {
            top: 4px;
            left: 50%;
            transform: translateX(-50%);
            transition: transform 0.2s ease-in-out, width 0.2s ease-in-out;
        }
    
        .patty {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: transform 0.2s ease-in-out, width 0.2s ease-in-out;
        }
    
        .bottom-bun {
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
            transition: transform 0.2s ease-in-out, width 0.2s ease-in-out;
        }
    }

`;

export {
    WrapBurgerBtn
}