import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

//constant
import { POLICY } from '../../../assets/constant/pathNames';


const ChooseTheRightPrivate = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${POLICY}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('choose_the_right_private.title_page')}</p>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t('choose_the_right_private.date_page')}</div>
                </div> */}
                <div className="content-page">
                    <p className="content">Chúng tôi cung cấp cho bạn các lựa chọn sau để chia sẽ thông tin với và nhận thông tin từ chúng tôi, bao gồm:</p>
                    <p className="note">a) Các quyền trên thiết bị;</p>
                    <p className="note">b) Ngừng nhận các tin tức về chương trình khuyến mãi; và</p>
                    <p className="note">c) Ngừng nhận các thông báo trong ứng dụng.</p>
                    <p className="title-large">1. Các quyền trên thiết bị</p>
                    <p className="content">Phần lớn các hệ điều hành di động (iOS, Android, vv) sẽ không cung cấp dữ liệu nếu chưa có sự đồng ý của người dùng. Những hệ điều hành này có các cách thức phan quyền khác nhau để nhận sự đồng ý của người dùng. Hệ điều hành iOS sẽ thông báo đến người dùng khi fpa lần đầu tiên yêu cầu quyền truy cập vào một số loại dữ liệu và cho phép người dùng lựa chọn đồng ý hay từ chối sự truy cập này. Hệ điều hành Android sẽ thông báo cho người dùng về các quyền được yêu cầu bởi fpa trước khi người dùng sử dụng fpa, và việc người dùng chấp nhận sử dụng fpa được hiểu rằng người dùng đã đồng ý đến các quyền được yêu cầu này.</p>
                    <p className="title-large">2. Ngừng nhận các tin tức về chương trình khuyến mãi</p>
                    <p className="content">Bạn có thể chọn ngừn nhận cái email về chương trình khuyến mãi từ chúng tôi. Xin lưu ý rằng nếu bạn chọn lựa chọn này, bạn sẽ vẫn nhận các email không liên quan đến các chương trình khuyến mãi, ví dụ như các thông tin về bảo mật liên quan đến Tài khoản Facepay của bạn và các cảnh báo về những lượt đăng nhập vào Tài khoản Facepay của bạn trên các thiết bị khác.</p>
                    <p className="title-large">3. Ngừng nhận các thông báo trong ứng dụng</p>
                    <p className="content">Bạn có thể lựa chọn ngừng nhận các thông báo trong ứng dụng bằng cách tùy chỉnh trong phần “Cài đặt” trong fpa, và có thể mở lại tính năng này bất kỳ lúc nào.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(ChooseTheRightPrivate)
