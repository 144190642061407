import styled from 'styled-components'
const Bound = styled.div`
    height: fit-content;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .page-wrapper{
        display: flex;
        flex-direction: column;
        padding: 0 32px 50px;
        width: calc(100% - calc(32px + (173 - 32) * ((100vw - 375px) / (768 - 375))) - calc(32px + (150 - 32) * ((100vw - 375px) / (768 - 375))));
        .introduce{
            padding-top: 35px;
            .text-bold{
                font-family: "SVN-Gilroy";
                font-weight: bold;
                font-size: 13px;
                line-height: 130%;
                color: #191919;
                text-transform: uppercase;
            }
            .wrapper{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                grid-gap: 10px;
                grid-template-rows: 25px;
                align-items: center;
                               
            }
            .text{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 21px;
                color: #939393;
                padding-top: 10px;
                cursor: pointer;
                display: block;
                text-decoration: none;
            }
            .icon-wrap{
                display: flex;
                align-items: center;
                padding-top: 12px;
                .icon{
                    padding-right: 12px;
                    cursor: pointer;
                }
            }
        
        }
    }
    .bg_image{
        display: flex;
        flex-direction: column;
        padding: 0 32px;
        .text-wrap{            
            .text{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 30px;
                color: #000000;
                padding: 62px 0 27px 0;
                width: 252px            
            }
            .badge-wrap{
                display: flex;
                .icon-badge{
                    padding-right: 10px;
                    cursor: pointer;
                }
            }
        }
        img{
            visibility: hidden;
        }
    }
    .footer-wrap{
        height: 89px;
        width: 100%;
        background: #1279DA;
        .footer{            
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            /* height: calc(100% - 35px);
            padding: 21px 0 14px 0; */ 
            height: 89px;           
            .contact{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 21px;
                .contact-wrap{
                    display: flex;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 18px;
                    color: #FFFFFF;
                    &.frist{
                        padding: 0 10px 0 0;
                    }
                    a{
                        text-decoration: none;
                        color: #FFFFFF;
                        padding-left: 3px;
                    }
                }            
            }
            .license{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 130%;
                color: rgba(255, 255, 255, 0.65);
            }
        }        
    }
    @media only screen and (min-width: 768px){        
        background: #FFFFFF;
        .page-wrapper{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-row-gap: 20px;
            padding-top: 46px;
            padding-bottom: 40px;
            padding-left: calc(32px + (173 - 32) * ((100vw - 768px) / (1440 - 768)));
            padding-right: calc(32px + (150 - 32) * ((100vw - 768px) / (1440 - 768)));
            margin: 0 auto;
            width: calc(100% - calc(32px + (173 - 32) * ((100vw - 768px) / (1440 - 768))) - calc(32px + (150 - 32) * ((100vw - 768px) / (1440 - 768))));
            .introduce{
                padding-top: 0;
                .text-bold{
                    font-size: 14px;
                    line-height: 23px;
                }
                .wrapper{
                    display: flex;
                    flex-direction: column;
                    align-items: unset;
                }
                .text{
                    font-size: 16px;
                    line-height: 23px;
                    padding-top: 13px
                }
            }
            .social{
                grid-column: 1 / span 4;
            }
        }
        .bg_image{
            padding: 0;
            position: relative;
            .text-wrap{
                z-index: 1;
                max-width: 1200px;
                margin: 0 auto;
                padding-right: 869px;     
                .text{
                    width: 271px;
                    font-size: 26px;
                    line-height: 31px;
                    padding: 54px 0 47px 0;
                }
                .badge-wrap{
                    padding-bottom: 68px
                }
            }
            img{
                visibility: unset;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .footer-wrap{
            z-index: 1;
            height: 60px;
            .footer{                
                flex-direction: row;
                justify-content: space-between;
                max-width: 1200px;
                margin: 0 auto;
                height: 60px;
                .contact{
                    padding: 0;
                    justify-content: flex-start;
                    .contact-wrap{
                        &.frist{
                            padding: 0 38px 0 0;
                        }
                    }
                }
                .license{
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    color: #FFFFFF;
                }
            }
        }
    }
    @media only screen and (min-width: 1200px){        
        .page-wrapper{
            grid-template-columns: repeat(5, 1fr);
            .social{
                grid-column: unset;
            }
        }
    }

`
export { Bound }