import styled from 'styled-components'
//image
import bg_weedigital from '../../assets/images/bg_weedigital_page.png'

const WrapPage = styled.div`
    .wrap-page-home{     
        background-image: url(${bg_weedigital});
        background-size: cover;
        background-repeat:no-repeat;
        min-height: 788px;
        height: 100vh;
        width: 100vw;
        position: relative;      
        .page-home{
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
           .title{
                font-family: 'SVN-Bebas Neue';
                font-style: normal;
                font-weight: bold;
                font-size: 74px;
                line-height: 72px;
                text-transform: uppercase;
                color: #FFFFFF;                
                display: flex;
                justify-content: center;
                &.blue{
                    color: #1279DA;
                    padding-right: 5px;
                }                
            }
            .desc{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                text-transform: uppercase;
                color: #C8C8C8;
                display: flex;
                width: 297px;
                padding-top: 27px;
            }
        }        
    }
    .page-one{
        padding: 42px 24px 67px 24px;
        min-height: 788px;
        height: fit-content;
        .content-left{
            .title{
                font-family: "SVN-Bebas Neue";
                font-style: normal;
                font-weight: bold;
                font-size: 74px;
                line-height: 72px;
                letter-spacing: -1px;
                text-transform: uppercase;
                color: #191919;
                &.blue{
                    color: #1279DA;
                   
                }
            }
        }
        .content-right{
            padding-top: 83px;
            font-family: "SVN-Gilroy";
            font-style: normal;
            .desc{                    
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: #191919;
                padding-bottom: 20px;
            }
            .desc-bold{
                padding-top: 16px;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #191919;
                &.blue{
                    color: #1279DA;
                }
            }
        }
    }
    .wrap-page-two{
        background: #F7FCFF;        
        .page-two{
            padding: 40px 24px 32px 24px;
            min-height: 788px;
            height: fit-content;
            .content{                
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: "SVN-Gilroy";
                font-style: normal;
                padding-bottom: 21px;
                img{
                    height: 80px;
                    width: 80px;
                }
                .title{         
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 22px;
                    color: #191919;
                    padding: 20px 0 9px 0;
                    text-align: center;
                    &.special{
                        width: 227px;
                    }
                }
                .desc{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 130%;
                    color: #939393;
                    text-align: center;
                }
            }
        }
    }
    .page-three{
        /* min-height: 788px; */
        height: fit-content;
        padding: 82px 24px 53px 24px;
        .content-left{
            .title{
                font-family: "SVN-Bebas Neue";
                font-style: normal;
                font-weight: bold;
                font-size: 74px;
                line-height: 72px;
                letter-spacing: -1px;
                text-transform: uppercase;
                color: #191919;
                &.blue{
                    color: #1279DA;
                }
            }
            .desc{
                font-family: "SVN-Gilroy";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                color: #939393;
                padding-top: 25px;
            }
        }
        .content-right{
            display: flex;
            flex-direction: column;
            .image-container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 36px 0;
                .image-wrap{
                    display: flex;
                    align-items: center;
                    position: relative;
                    min-height: 152px;
                    flex-grow: 1;
                    overflow: hidden;
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    ::-webkit-scrollbar-thumb {
                        display: none;
                    }
                    .image{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        flex-shrink: 0;
                        transition: transform 0.3s ease-in-out;
                        background: #FFFFFF;
                        &.previous {
                            transform: translateX(-100%);
                            z-index: 2;
                        }
                        &.active{
                            transform: translateX(0);
                            z-index: 3;
                        }
                        &.next {
                            transform: translateX(100%);
                            z-index: 1;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .icon-previous-wrap{
                    flex-shrink: 0;
                    height: 40px;
                    width: 40px;
                    .icon-previous{
                        flex-shrink: 0;
                        width: 40px;
                        height: 40px;
                        z-index: 3;
                        cursor: pointer;
                    }                
                }
                .icon-next-wrap{
                    flex-shrink: 0;
                    height: 40px;
                    width: 40px;
                    .icon-next{
                        flex-shrink: 0;
                        width: 40px;
                        height: 40px;
                        z-index: 3;
                        cursor: pointer;
                    }
                }
            }
            .step-container{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                user-select: none;
                .cycle{
                    height: 7px;
                    width:7px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    position: relative;
                    margin: 0 10px 0 0;
                    &:last-of-type{
                        margin: 0;
                    }
                    &.wrapper{
                        background: rgba(18, 121, 218, 0.2);                 
                    }
                    .step{
                        height: 7px;
                        width: 7px;
                        background: #C8C8C8;
                        border-radius: 50%;
                        margin: 0 10px 0 0;
                        &.active{
                            background: #1279DA;
                        }
                    }
                }
            }
        }
    }


    @media only screen and (min-width: 768px){
        .wrap-page-home{  
            .page-home{
                .title{
                    font-size: 102px;
                    line-height: 102px;
                    letter-spacing: -1px;
                    &.blue{
                        padding-right: 10px;
                    }     
                }
                .desc{
                    font-size: 21px;
                    line-height: 24px;
                    width: unset;
                    padding-top: 20px;
                }
            }
        }
        .page-one{
            max-width: 1200px;
            margin: 0 auto;
            padding: 0;
            display: grid;
            grid-template-columns: 562px 1fr;
            grid-column-gap: 117px;
            align-items: center;
            .content-left{
                .title{
                    font-size: 102px;
                    line-height: 102px;
                }
            }
            .content-right{
                padding-top: 0;
                .desc{
                    font-size: 18px;
                    line-height: 23px;
                }
                .desc-bold{
                    font-size: 21px;
                    line-height: 24px;
                }
            }
        }
        .wrap-page-two{
            .page-two{
                max-width: 1200px;
                margin: 0 auto;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 30px;
                align-items: center;
                min-height: unset;
                height: fit-content;
                padding: 74px 0 95px 0;
                .content{
                    padding-bottom: 0;
                    align-items: flex-start;
                    display: grid;
                    grid-template-rows: 80px 50px 36px;
                    grid-row-gap: 20px;
                    .title{
                        font-size: 21px;
                        line-height: 24px;
                        padding: 0;
                        text-align: unset;
                        &.two{
                            width: 150px;
                        }
                    }
                    .desc{
                        font-size: 14px;
                        line-height: 130%;
                        text-align: unset;
                    }
                }
            }
        }
        .page-three{
            min-height: 788px;
            max-width: 1200px;
            margin: 0 auto;
            padding: 0;
            align-items: center;
            display: grid;
            grid-template-columns: 1fr 672px;
            grid-column-gap: 71px;
            .content-left{
                .title{
                    font-size: 102px;
                    line-height: 102px;
                }
                .desc{
                    font-size: 18px;
                    line-height: 23px;
                }
            }
            .content-right{
                flex-direction: row;
                .image-container{
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin: unset;
                    .image-wrap{                        
                        display: grid;
                        grid-template-columns: repeat(3, 210px);
                        grid-template-rows: repeat(3, 118px);
                        grid-gap: 21px;
                        align-items: unset;
                        position: unset;
                        min-height: unset;
                        .image{
                            position: unset;
                            width: 100%;
                            height: 100%;
                            text-align: unset;
                            flex-shrink: unset;
                            transition: unset;
                            background: #FFFFFF;
                            &.previous {
                                transform: unset;
                                z-index: 1;
                            }
                            &.active{
                                transform: unset;
                                z-index: 1;
                            }
                            &.next {
                                transform: unset;
                                z-index: 1;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    .icon-previous-wrap{
                        display: none
                    }
                    .icon-next-wrap{
                        display: none;
                    }
                }
                .step-container{
                    display: none;
                }
            }
        }
    }
`
export { WrapPage }