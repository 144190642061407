import React from 'react'
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

//constant
import * as pathNames from '../../../assets/constant/pathNames'

//data
import { PaymentAtCounterData } from '../../../assets/data/PaymentAtCounterData';

//component
import QAndAList from '../../Control/QAndAList';

const PaymentAtCounter = ({ t }) => {
    return (
        <QAndAList
            title={t('payment_at_counter.title')}
            objDatas={PaymentAtCounterData}
            path={pathNames.PAYMENT_AT_COUNTER}
        />
    )
}

export default withNamespaces()(PaymentAtCounter)
