import React from 'react'

const IcArrowRightBlue = (props) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.58997 16.58L13.17 12L8.58997 7.41L9.99997 6L16 12L9.99997 18L8.58997 16.58Z" fill="#1279DA" />
        </svg>
    )
}

export default IcArrowRightBlue
