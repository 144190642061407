import React from 'react'

const IcPhoneWhite = (props) => {
    return (
        <svg {...props} width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3334 14.6V17.1C18.3344 17.3321 18.2868 17.5618 18.1939 17.7745C18.1009 17.9871 17.9645 18.178 17.7935 18.3349C17.6225 18.4918 17.4206 18.6113 17.2007 18.6856C16.9809 18.76 16.7479 18.7876 16.5168 18.7667C13.9525 18.4881 11.4893 17.6118 9.32511 16.2084C7.31163 14.9289 5.60455 13.2218 4.32511 11.2084C2.91676 9.03438 2.04031 6.5592 1.76677 3.98337C1.74595 3.75293 1.77334 3.52067 1.84719 3.30139C1.92105 3.08211 2.03975 2.88061 2.19575 2.70972C2.35174 2.53883 2.54161 2.4023 2.75327 2.30881C2.96492 2.21532 3.19372 2.16692 3.42511 2.16671H5.92511C6.32953 2.16273 6.7216 2.30594 7.02824 2.56965C7.33488 2.83336 7.53517 3.19958 7.59177 3.60004C7.69729 4.4001 7.89298 5.18565 8.17511 5.94171C8.28723 6.23998 8.31149 6.56414 8.24503 6.87577C8.17857 7.18741 8.02416 7.47347 7.80011 7.70004L6.74177 8.75837C7.92807 10.8447 9.65549 12.5721 11.7418 13.7584L12.8001 12.7C13.0267 12.476 13.3127 12.3216 13.6244 12.2551C13.936 12.1887 14.2602 12.2129 14.5584 12.325C15.3145 12.6072 16.1001 12.8029 16.9001 12.9084C17.3049 12.9655 17.6746 13.1694 17.9389 13.4813C18.2032 13.7932 18.3436 14.1914 18.3334 14.6Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcPhoneWhite
