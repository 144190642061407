import logo_vinpearl from '../images/WeeDigital/logo_vinpearl.png'
import logo_vinhomes from '../images/WeeDigital/logo_vinhomes.png'
import logo_vibbank from '../images/WeeDigital/logo_vibbank.png'
import logo_vinmart from '../images/WeeDigital/logo_vinmart.png'
import logo_sungroup from '../images/WeeDigital/logo.sungroup.png'
import logo_vietinbank from '../images/WeeDigital/logo_vietinbank.png'
import logo_vpbank from '../images/WeeDigital/logo_vpbank.png'
import logo_agribank from '../images/WeeDigital/logo_agribank.png'
import logo_ocbbank from '../images/WeeDigital/logo_ocbbank.png'
import logo_fecredit from '../images/WeeDigital/logo_fecredit.png'
import logo_tpbank from '../images/WeeDigital/logo_tpbank.png'
import logo_security from '../images/WeeDigital/logo_security.png'
import logo_novaland from '../images/WeeDigital/logo_novaland.png'
import logo_citygym from '../images/WeeDigital/logo_citygym.png'
import logo_smartpay from '../images/WeeDigital/logo_smartpay.png'
import logo_payme from '../images/WeeDigital/logo_payme.png'
import logo_eurowindow from '../images/WeeDigital/logo_eurowindow.png'
import logo_gs25 from '../images/WeeDigital/logo_gs25.png'

export const LogoPartnerData = [
    {
        step: 1,
        image: logo_vinpearl,
    },
    {
        step: 2,
        image: logo_vinhomes,
    },
    {
        step: 3,
        image: logo_vinmart,
    },
    {
        step: 4  ,
        image: logo_sungroup,
    },
    {
        step: 5,
        image: logo_vibbank,
    },
    {
        step: 6,
        image: logo_vietinbank,
    },
    {
        step: 7,
        image: logo_agribank,
    },
    {
        step: 8,
        image: logo_ocbbank,
    },
    {
        step: 9,
        image: logo_vpbank,
    },
    {
        step: 10,
        image: logo_fecredit,
    },   
    {
        step: 11,
        image: logo_tpbank,
    },
    {
        step: 12,
        image: logo_security,
    },
    {
        step: 13,
        image: logo_novaland,
    },
    {
        step: 14,
        image: logo_citygym,
    },
    {
        step: 15,
        image: logo_smartpay,
    },
    {
        step: 16,
        image: logo_payme,
    },
    {
        step: 17,
        image: logo_eurowindow,
    },
    {
        step: 18,
        image: logo_gs25,
    },
]