import React from 'react'

const IcYoutube = (props) => {
    return (
        <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 32C7.15789 32 0 24.8421 0 16C0 7.15789 7.15789 0 16 0C24.8421 0 32 7.15789 32 16C32.0842 24.8421 24.8421 32 16 32Z" fill="url(#ic_youtube)" />
            <path d="M24.5438 14.0373C24.5438 11.9582 22.7576 10.2407 20.5954 10.2407H10.3481C8.18587 10.2407 6.39966 11.9582 6.39966 14.0373V18.6475C6.39966 20.7266 8.18587 22.4441 10.3481 22.4441H20.5954C22.7576 22.4441 24.5438 20.7266 24.5438 18.6475V14.0373ZM18.5271 16.6588L13.9206 18.8283C13.7325 18.9187 13.1685 18.8283 13.1685 18.5571V14.1277C13.1685 13.9469 13.8266 13.7662 13.9206 13.9469L18.3391 16.2068C18.5271 16.2972 18.7151 16.5684 18.5271 16.6588Z" fill="white" />
            <defs>
                <linearGradient id="ic_youtube" x1="32.0007" y1="2.85557e-07" x2="31.3878" y2="38.3185" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFFF" />
                    <stop offset="0.651042" stopColor="#005BEC" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default IcYoutube
