import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../Home/PageSix'
import ContactPage from '../Support/ContactPage'

//image
import IcArrowLeft from '../../assets/images/IcArrowLeft'
import ic_facebook from '../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

//constant
import { SUPPORT } from '../../assets/constant/pathNames';

const TermsOfUse = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${SUPPORT}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('terms_of_use.title_page')}</p>                
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <p className="date-page">{t('terms_of_use.date_page')}</p>
                </div> */}
                <p className="desc-page">{t('terms_of_use.desc_01')}</p>
                <p className="desc-page">{t('terms_of_use.desc_02')}</p>
                <p className="desc-page">
                    {t('terms_of_use.desc_03')} <span><a href="mailto: cs@facepay.vn">{t('terms_of_use.email')}</a></span>
                </p>
                <div className="content-page">
                    <p className="title-large">1. ĐỊNH NGHĨA</p>
                    <p className="content">Trong Điều Khoản Chung này, các từ và thuật ngữ sẽ có nghĩa dưới đây, trừ khi ngữ cảnh có yêu cầu khác:</p>
                    <p className="wrapper">
                        <span className="title">1.1. Wee Digital:</span> là Công ty Trách Nhiệm Hữu Hạn Wee Digital, một công ty được thành lập hợp lệ và hoạt động theo pháp luật của nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam, có Giấy Chứng Nhận Đăng Ký Doanh Nghiệp Số 0315128395 cấp lần đầu ngày 25/06/2018.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.2. Facepay:</span> là nền tảng xác thực giao dịch thanh toán dựa trên công nghệ nhận dạng khuôn mặt, do công ty Wee Digital sáng tạo, phát triển và vận hành, được định nghĩa đầy đủ ở điều 3.1 dưới đây.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.3. Ứng dụng Facepay:</span> là ứng dụng trên nền tảng di động do Wee Digital phát triển và vận hành để cung cấp dịch vụ xác thực giao dịch thanh toán.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.4. Tài khoản Facepay:</span> là tài khoản định danh cho một cá nhân hoặc tổ chức nhất định có giao dịch thông qua dịch vụ của Facepay.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.5. Người Sử Dụng:</span> là các khách hàng có nhu cầu mở tài khoản và sử dụng nền tảng Facepay.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.6. Người Bán Hàng:</span> Cửa hàng hoặc nhà cung cấp bán hàng hóa hoặc dịch vụ cho Người Sử Dụng. Người Bán Hàng đã cài đặt và tích hợp hệ thống xác thực giao dịch thanh toán Facepay tại các điểm bán hàng của họ.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.7. Bên Thứ Ba:</span> là các đối tác xử lý giao dịch thanh toán trên nền tảng Facepay, bao gồm Công ty cổ phần thanh toán quốc gia NAPAS, các ngân hàng, cổng thanh toán, ví điện tử kết nối với Facepay.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.8. Ngân Hàng Của Người Sử Dụng:</span> là tổ chức tài chính đã phát hành thẻ ngân hàng, thẻ tín dụng, mở tài khoản của Người Sử Dụng, và là nơi chứa nguồn tiền liên quan đến giao dịch để Người Sử Dụng thanh toán cho hàng hóa dịch vụ đã mua. Ngân hàng của Người Sử Dụng phải là ngân hàng đã kết nối với Facepay.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.9. Ngân Hàng Của Người Bán Hàng:</span> là ngân hàng nơi Người Bán Hàng lập tài khoản đích cho khoản thanh toán được yêu cầu từ tài khoản ngân hàng của Người Sử Dụng, khi Người Sử Dụng thực hiện mua hàng hóa dịch vụ qua Facepay đặt tại điểm bán hàng.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.9. Ngân Hàng Của Người Bán Hàng:</span> là ngân hàng nơi Người Bán Hàng lập tài khoản đích cho khoản thanh toán được yêu cầu từ tài khoản ngân hàng của Người Sử Dụng, khi Người Sử Dụng thực hiện mua hàng hóa dịch vụ qua Facepay đặt tại điểm bán hàng.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.10. Dịch Vụ:</span> bao gồm nhưng không giới hạn các dịch vụ được Người Sử Dụng thực hiện trên Facepay như thanh toán giao dịch trực tuyến, thanh toán tại các điểm bán hàng…
                    </p>
                    <p className="wrapper">
                        <span className="title">1.11. Giao Dịch: </span> là bất kỳ giao dịch nào của Người Sử Dụng liên quan đến việc sử dụng Facepay.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.12. Điểm Giao Dịch: </span> là các điểm cửa hàng, điểm bán hàng trực tuyến và ngoại tuyến đã cài đặt và tích hợp hệ thống, thiết bị xác thực giao dịch thanh toán Facepay.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.13. Hạn Mức Giao Dịch: </span> là các hạn mức tối đa Người Sử Dụng có thể thực hiện giao dịch trong một giao dịch, một ngày, một tháng. Hạn mức này có thể được điều chỉnh theo chính sách của Facepay, của các ngân hàng phát hành thẻ, của Bên Thứ Ba, và luôn đảm bảo tuân thủ theo các Quy Định Pháp Luật.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.14. Dịch Vụ Khách Hàng: </span> là dịch vụ chăm sóc khách hàng của Facepay.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.15. Quy Định Pháp Luật: </span> bao gồm toàn bộ các quy định pháp luật của nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam như Luật, Bộ luật, Pháp lệnh, Nghị định, Thông tư, quy chuẩn, quy tắc, quyết định hành chính của cơ quan nhà nước có thẩm quyền và các quy định có hiệu lực pháp luật khác tại từng thời điểm.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.16. Ngày Làm Việc: </span> là các ngày từ Thứ Hai đến Thứ Sáu, không bao gồm ngày nghỉ, lễ, Tết theo Quy Định Pháp Luật.
                    </p>
                    <p className="wrapper">
                        <span className="title">1.17. Đơn Vị Tiền Tệ: </span> quy định sử dụng cho tài khoản, giao dịch trên Facepay là Đồng Việt Nam (VND).
                    </p>
                    <p className="title-large">2. CÁC QUY TẮC CHUNG</p>
                    <p className="wrapper">
                        <span className="title">2.1.</span> Bằng việc tải về, truy cập ứng dụng Facepay, Người Sử Dụng xác nhận đã hiểu rõ Điều Khoản Chung và hoàn toàn đồng ý với từng phần cũng như toàn bộ Điều Khoản Chung được quy định tại đây, cũng như bất kỳ các điều chỉnh liên quan.
                    </p>
                    <p className="wrapper">
                        <span className="title">2.2.</span> Bằng việc truy cập, tải về và sử dụng ứng dụng Facepay, Người Sử Dụng thừa nhận và đồng ý rằng đã chấp thuận với các phương thức, yêu cầu, chính sách được quy định trong Điều Khoản Chung, và rằng Người Sử Dụng theo đây đồng ý cho Wee Digital thu thập, sử dụng, tiết lộ, xử lý dữ liệu cá nhân của Người Sử Dụng như được mô tả trong Điều Khoản Chung này.
                    </p>
                    <p className="wrapper">
                        <span className="title">2.3.</span> Người Sử Dụng sẽ được xem là đương nhiên chấp nhận và chịu sự ràng buộc của Điều Khoản Chung này và việc Người Sử Dụng dùng một phần hoặc toàn bộ nền tảng Facepay được xem là giữa Người Sử Dụng và Wee Digital đã ký kết và thực hiện một Hợp đồng dịch vụ.
                    </p>
                    <p className="wrapper">
                        <span className="title">2.4.</span> Wee Digital có quyền thay đổi Điều Khoản Chung hoặc bất kỳ tính năng nào của Facepay, vào bất kỳ thời điểm nào, thay đổi đó sẽ có hiệu lực ngay lập tức sau khi công bố.
                    </p>
                    <p className="wrapper">
                        <span className="title">2.5.</span> Người Sử Dụng đồng ý đánh giá Điều Khoản Chung định kỳ để đảm bảo rằng Người Sử Dụng đã được cập nhật đối với bất kỳ thay đổi nào của Điều Khoản Chung. Việc tiếp tục sử dụng Facepay sẽ được xem là Người Sử Dụng chấp nhận hoàn toàn Điều Khoản Chung đã được thay đổi.
                    </p>
                    <p className="wrapper">
                        <span className="title">2.6.</span> Người Sử Dụng đảm bảo rằng Người Sử Dụng đã hiểu rõ các hướng dẫn và quy trình sử dụng Facepay và những thay đổi, bổ sung (nếu có) của Wee Digital đối với Facepay.
                    </p>
                    <p className="title-large">3. SỬ DỤNG FACEPAY</p>
                    <p className="title">3.1. Định nghĩa </p>
                    <p className="wrapper">3.1.1. Nền tảng Facepay bao gồm các phần mềm hệ thống Facepay, ứng dụng Facepay cài đặt trên thiết bị di động của Người Sử Dụng, các thiết bị nhận thanh toán (Facepay Payment Terminal) đặt tại Điểm Giao Dịch, giúp Người Sử Dụng thực hiện xác thực giao dịch thanh toán cho Người Bán Hàng, thông qua công nghệ nhận dạng khuôn mặt.</p>
                    <p className="wrapper">3.1.2. Facepay chỉ là nền tảng công nghệ xác thực giao dịch thanh toán và trong khi hoạt động, Facepay không có nghĩa vụ sở hữu các khoản tiền của Người Bán Hàng và (hoặc) của Người Sử Dụng. Không có sự chuyển tiền thực tế từ Người Sử Dụng sang Facepay và (hoặc) từ Facepay đến Người Bán Hàng trong thời gian giao dịch mua bán.</p>
                    <p className="wrapper">3.1.3. Các giao dịch thanh toán tại Điểm Giao Dịch được thực hiện trực tiếp giữa Người Sử Dụng, Người Bán Hàng và Bên Thứ Ba. Facepay xác thực các giao dịch đó bằng công nghệ nhận dạng khuôn mặt trên thiết bị Facepay Payment Terminal đặt tại Điểm Giao Dịch.</p>
                    <p className="wrapper">3.1.4. Facepay là một nhà thầu độc lập và không phải là đại lý hoặc tổ chức được ủy thác của bạn, tức Người Sử Dụng. Tài khoản Facepay của bạn không phải là tài khoản ngân hàng.</p>
                    <p className="wrapper">3.1.5. Facepay không phải là ngân hàng phát hành, người bán hàng, người xử lý giao dịch thanh toán hoặc tổ chức lưu ký. Bạn đang thực hiện các giao dịch với Bên Thứ Ba bằng Facepay là giữa bạn và Bên Thứ Ba đó, chứ không phải với Facepay. Facepay là nền tảng xác thực giao dịch thanh toán, không phải là một bên tham gia vào các giao dịch của bạn tại các Điểm Giao Dịch của Người Bán Hàng.</p>
                    <p className="wrapper">3.1.6. Người Sử Dụng Facepay có thể mua sắm và thanh toán hàng hóa và dịch vụ bằng cách sử dụng công nghệ nhận dạng khuôn mặt chỉ tại Điểm Giao Dịch đã cài đặt và tích hợp hệ thống, thiết bị xác thực thanh toán Facepay.</p>
                    <p className="wrapper">3.1.7. Người Sử Dụng phải là cá nhân từ 18 tuổi trở lên để tham gia thỏa thuận này.</p>
                    <p className="wrapper">3.1.8. Mỗi Người Sử Dụng chỉ được mở duy nhất 01 (một) Tài Khoản Facepay.</p>
                    <p className="wrapper">3.1.9. Người chưa đăng ký sử dụng Facepay không được phép sử dụng các dịch vụ, tính năng trên Facepay và bất kỳ nỗ lực nào để làm như vậy có thể kích hoạt các thủ tục pháp lý chống lại những người đó.</p>
                    <p className="wrapper">3.1.10. Facepay chỉ cung cấp dịch vụ trên lãnh thổ Việt Nam.</p>
                    <p className="title">3.2. Đăng ký và sử dụng Facepay</p>
                    <p className="wrapper">3.2.1. Để sử dụng Facepay, trước hết Người Sử Dụng cần tải ứng dụng Facepay, mở Tài Khoản Facepay theo các hướng dẫn. </p>
                    <p className="wrapper">3.2.2. Người Sử Dụng đồng ý cung cấp cho Wee Digital các thông tin đầy đủ, cập nhật và chính xác liên quan đến Người Sử Dụng mà Wee Digital sẽ yêu cầu vào từng thời điểm để sử dụng Facepay.</p>
                    <p className="wrapper">Người Sử Dụng đồng ý thông báo ngay cho Wee Digital bất kỳ thay đổi nào về thông tin Tài Khoản Facepay và các thông tin đã cung cấp cho Wee Digital. Người Sử Dụng tuyên bố và bảo đảm rằng các thông tin cung cấp cho Wee Digital là trung thực và chính xác và chịu trách nhiệm đối với các thông tin đã cung cấp.</p>
                    <p className="wrapper">3.2.3. Theo yêu cầu của Wee Digital, Người Sử Dụng sẽ cung cấp cho Wee Digital các thông tin liên quan đến việc sử dụng Facepay mà Wee Digital có thể yêu cầu một cách hợp lý cho các mục đích sau đây:</p>
                    <p className="note">(i) Trợ giúp Wee Digital tuân thủ các nghĩa vụ của mình theo Quy Định Pháp Luật.</p>
                    <p className="note">(ii) Báo cáo các cơ quan hữu quan hoặc các cơ quan chính phủ về việc tuân thủ những nghĩa vụ đó.</p>
                    <p className="note">(iii) Đánh giá việc Người Sử Dụng đã tuân thủ, đang tuân thủ và có thể tiếp tục tuân thủ tất cả các nghĩa vụ của mình theo Điều Khoản Chung này hay không.</p>
                    <p className="wrapper">3.2.4. Trừ khi pháp luật có quy định khác, Người Sử Dụng buộc phải hoàn thành việc liên kết Tài Khoản Facepay với tài khoản thanh toán hoặc thẻ ngân hàng của Người Sử Dụng mở tại Ngân Hàng Của Người Sử Dụng để sử dụng Facepay.</p>
                    <p className="wrapper">3.2.5. Người Sử Dụng cam kết không sử dụng Facepay cho bất kỳ mục đích hoặc liên quan đến bất kỳ hành động vi phạm các Quy Định Pháp Luật, bao gồm, nhưng không giới hạn, các luật và quy định liên quan đến phòng, chống rửa tiền, chống tài trợ khủng bố.</p>
                    <p className="wrapper">3.2.6. Người Sử Dụng xác nhận rằng Người Sử Dụng có đầy đủ năng lực hành vi, quyền hạn hoặc thẩm quyền để sử dụng Facepay.</p>
                    <p className="wrapper">3.2.7. Người Sử Dụng chịu trách nhiệm quản lý tài khoản, mã PIN, các thông tin liên quan đến tài khoản, biện pháp xác thực, thông tin thiết bị… của mình.</p>
                    <p className="wrapper">Nếu các thông tin trên của Người Sử Dụng bị mất hoặc bị lấy cắp hoặc bị tiết lộ một cách bất hợp pháp, thì Người Sử Dụng phải thay đổi thông tin tài khoản bằng cách sử dụng các công cụ được cài đặt sẵn trong Ứng Dụng Facepay hoặc thông báo ngay cho Wee Digital thông qua Dịch Vụ Khách Hàng để tạm ngừng Tài Khoản Facepay.</p>
                    <p className="wrapper">Người Sử Dụng sẽ hoàn toàn chịu trách nhiệm về bất kỳ và tất cả yêu cầu Giao Dịch đã xảy ra trước khi Wee Digital nhận được thông báo đó.</p>
                    <p className="wrapper">3.2.8. Khi sử dụng Facepay, Người Sử Dụng chịu trách nhiệm về bất kỳ và tất cả hành động cũng như sai sót của mình trong việc vận hành ứng dụng Facepay và (hoặc) thực hiện Giao Dịch. Nếu bất kỳ một sai sót hay sự cố nào xảy ra, Người Sử Dụng phải liên hệ ngay với Dịch Vụ Khách Hàng để được hướng dẫn. Wee Digital sẽ nỗ lực hết sức để tư vấn và trợ giúp Người Sử Dụng.</p>
                    <p className="wrapper">3.2.9. Trong trường hợp có sự cố hoặc nếu một Giao Dịch không được thực hiện theo yêu cầu của Người Sử Dụng, Người Sử Dụng thông báo ngay cho Wee Digital về sự cố đó và Wee Digital sẽ nỗ lực hết sức để tư vấn và trợ giúp Người Sử Dụng.</p>
                    <p className="wrapper">3.2.10. Cũng có khả năng Tài Khoản Facepay không được chấp thuận (đăng ký không thành công). Điều này có thể xảy ra vì một số lý do: không đủ dữ liệu, gian lận, lỗi người dùng, lỗi hệ thống, v.v... Trong trường hợp đó, đại diện Dịch Vụ Khách Hàng của chúng tôi sẽ liên hệ với Người Sử Dụng để trợ giúp.</p>
                    <p className="wrapper">3.2.11. Người Sử Dụng có trách nhiệm cập nhật phần cứng hoặc thiết bị cần thiết để truy cập và sử dụng Facepay, cũng như mọi bản cập nhật của Facepay. Facepay không đảm bảo rằng bất kỳ phần nào của Facepay sẽ hoạt động trên bất kỳ phần cứng hoặc thiết bị cụ thể nào. Ngoài ra, việc sử dụng Facepay có thể bị ảnh hưởng bởi các lỗi và sự cố Internet.</p>
                    <p className="title">3.3. Hồ Sơ Mở Tài Khoản Facepay:</p>
                    <p className="content">Là các giấy tờ, thông tin của cá nhân phải cung cấp theo quy định của pháp luật và yêu cầu của Wee Digital, bao gồm nhưng không giới hạn:</p>
                    <p className="wrapper">3.3.1. Họ và tên; ngày, tháng, năm sinh; quốc tịch; giới tính</p>
                    <p className="wrapper">3.3.2. Số điện thoại; địa chỉ email </p>
                    <p className="wrapper">3.3.3. Số căn cước công dân / chứng minh nhân dân / hộ chiếu còn thời hạn;</p>
                    <p className="wrapper">3.3.4. Các thông tin sinh trắc học như khuôn mặt và vân tay của Người Sử Dụng.</p>
                    <p className="wrapper">3.3.5. Facepay chưa chấp nhận việc đăng ký mở và sử dụng Tài Khoản Facepay dưới tư cách một tổ chức, đơn vị, tập hợp đông người.</p>
                    <p className="wrapper">3.3.6. Facepay chưa chấp nhận việc đăng ký mở và sử dụng Tài Khoản Facepay thông qua người giám hộ, người đại diện theo pháp luật.</p>
                    <p className="wrapper">3.3.7. Hồ Sơ Mở Tài Khoản Facepay sẽ được cập nhật theo quy định của pháp luật và yêu cầu phù hợp của Wee Digital theo từng thời điểm và được đăng tải trên ứng dụng Facepay.</p>
                    <p className="title">3.4. Phí sử dụng Facepay</p>
                    <p className="wrapper">3.4.1. Wee Digital có quyền áp dụng phí sử dụng Facepay. Người Sử Dụng:</p>
                    <p className="note">(i) Đồng ý sẽ chịu trách nhiệm thanh toán đầy đủ và đúng hạn mọi khoản phí dịch vụ và các lệ phí khác liên quan đến bất kỳ Giao Dịch nào hoặc việc sử dụng Facepay mà Wee Digital tính phí.</p>
                    <p className="note">(ii) Đồng ý cho phép Wee Digital khấu trừ bất kỳ khoản phí, lệ phí hay khoản tiền khác mà Ngưởi Sử Dụng phải trả cho Wee Digital từ tài khoản ngân hàng liên kết Facepay của Người Sử Dụng tại Ngân Hàng Của Người Sử Dụng.</p>
                    <p className="note">(iii) Wee Digital có quyền điều chỉnh, thay đổi biểu phí tùy từng thời điểm theo quyết định riêng của mình.</p>
                    <p className="wrapper">3.4.2. Mức phí sử dụng Facepay:</p>
                    <p className="note">(i) Wee Digital miễn phí sử dụng Facepay cho Người Sử Dụng ba (03) tháng đầu tiên kể từ khi Người Sử Dụng bắt đầu đăng ký mở tài khoản Facepay. Từ ngày 1 của tháng thứ tư, Wee Digital bắt đầu thu phí sử dụng Facepay. Ví dụ: Người Sử Dụng đăng ký mở tài khoản Facepay vào ngày 17-2-2021 thì đến ngày 1-5-2021, Wee Digital sẽ thu phí sử dụng Facepay từ Người Sử Dụng.</p>
                    <p className="note">(ii) Wee Digital có quyền hủy bỏ chính sách miễn phí sử dụng trên tùy thời điểm, theo quyết định riêng của mình. Những thay đổi sẽ được cập nhật tại Điều Khoản Chung này.</p>
                    <p className="note">(iii) Mức phí Wee Digital quy định hiện tại cho mỗi tài khoản Facepay là 10.000 VND (mười nghìn đồng Việt Nam) mỗi tháng, không giới hạn số lượng Giao Dịch mà Người Sử Dụng thực hiện trong tháng đó.</p>
                    <p className="wrapper">3.4.3. Cách thức tính phí:</p>
                    <p className="note">(i) Bắt đầu từ 0 giờ ngày mùng 1 (lịch dương) đầu mỗi tháng trở đi, khi Người Sử Dụng thực hiện Giao Dịch đầu tiên trong tháng thì Wee Digital sẽ tự động thực hiện việc thu phí sử dụng Facepay của Người Sử Dụng. Phí này sẽ chuyển thẳng từ tài khoản ngân hàng liên kết của Người Sử Dụng đến tài khoản của Wee Digital.</p>
                    <p className="note">(ii) Nếu trong tháng (theo lịch dương, từ 0 giờ ngày mùng 1 cho đến 24 giờ ngày cuối cùng của tháng), Người Sử Dụng không thực hiện bất kỳ Giao Dịch nào, thì Người Sử Dụng sẽ không bị thu phí hàng tháng.</p>
                    <p className="title-large">3.5. Ngừng sử dụng Facepay</p>
                    <p className="title">3.5.1. Wee Digital ngừng cung cấp Facepay:</p>
                    <p className="wrapper">3.5.1.1. Người Sử Dụng đồng ý, xác nhận và chấp thuận rằng bất kỳ tính năng, dịch vụ nào của Facepay có thể được Wee Digital hủy bỏ vì bất kỳ lý do nào mà Wee Digital thấy là phù hợp và cần thiết, vào bất kỳ thời điểm nào, mà không cần thông báo trước cho Người Sử Dụng. </p>
                    <p className="wrapper">Người Sử Dụng cũng đồng ý rằng bất kỳ lý do hủy bỏ nào mà Wee Digital đưa ra sẽ được Người Sử Dụng xem là hợp lý. Sau khi hủy bỏ, bất kỳ tính năng, dịch vụ nào của Facepay có thể được cung cấp lại bởi Wee Digital trên cơ sở thấy là phù hợp.</p>
                    <p className="wrapper">3.5.1.2. Người Sử Dụng đồng ý, xác nhận và chấp thuận rằng vào mọi thời điểm Wee Digital có quyền ngừng hoặc chấm dứt Tài Khoản Facepay của Người Sử Dụng, hoặc khả năng tiếp cận và sử dụng bất kỳ phần nào của Facepay, vì bất kỳ lý do nào mà Wee Digital thấy là phù hợp và cần thiết, bao gồm, nhưng không giới hạn trường hợp Người Sử Dụng vi phạm bất kỳ quy định nào của Điều Khoản Chung này hoặc làm trái hoặc vi phạm bất kỳ quy định, luật hiện hành liên quan đến việc sử dụng Facepay. Người Sử Dụng cũng đồng ý rằng bất kỳ lý do hủy bỏ nào do Wee Digital đưa ra sẽ được Người Sử Dụng xem là hợp lý.</p>
                    <p className="wrapper">3.5.1.3. Nếu Người Sử Dụng vi phạm bất kỳ quy định nào của Điều Khoản Chung này hoặc làm trái hoặc vi phạm bất kỳ quy định, Quy Định Pháp Luật liên quan đến việc sử dụng Facepay, Wee Digital có quyền ngừng cung cấp quyền sử dụng, thông báo cho cơ quan nhà nước có thẩm quyền và (hoặc) các cá nhân, tổ chức liên quan về việc làm trái hoặc vi phạm, theo cách thức phù hợp. Sau khi hủy bỏ hoặc chấm dứt việc sử dụng Facepay (hoặc bất kỳ phần nào của Facepay):</p>
                    <p className="note">(i) Tất cả các quyền đã được trao cho Người Sử Dụng theo Điều Khoản Chung này sẽ chấm dứt ngay lập tức;</p>
                    <p className="note">(ii) Người Sử Dụng phải thanh toán ngay cho Wee Digital mọi khoản phí và lệ phí chưa trả đến hạn và còn nợ Wee Digital (nếu có).</p>
                    <p className="title">3.5.2. Người Sử Dụng ngừng sử dụng Facepay:</p>
                    <p className="content">Người Sử Dụng có thể ngừng việc sử dụng Facepay của mình căn cứ theo Điều Khoản Chung này vào bất kỳ thời điểm nào bằng cách làm theo hướng dẫn trong mục “Những câu hỏi thường gặp” trên website facepay.vn, hoặc liên hệ với Dịch Vụ Khách Hàng để được hướng dẫn.</p>
                    <p className="wrapper">Sau khi Người Sử Dụng ngừng sử dụng Facepay:</p>
                    <p className="note">(i) Tất cả các quyền đã được trao cho Người Sử Dụng theo Điều Khoản Chung này liên quan đến Facepay sẽ ngừng ngay lập tức;</p>
                    <p className="note">(ii) Người Sử Dụng phải thanh toán ngay cho Wee Digital mọi khoản phí và lệ phí chưa trả đến hạn và còn nợ Wee Digital liên quan đến Facepay (nếu có).</p>
                    <p className="title-large">4. QUYỀN SỞ HỮU TRÍ TUỆ</p>
                    <p className="wrapper"><span className="title">4.1.</span> Tất cả các nhãn hiệu hàng hóa, logo, nhãn hiệu dịch vụ và tất cả các quyền sở hữu trí tuệ khác thuộc bất kỳ loại nào (cho dù đã được đăng ký hay chưa), và tất cả các nội dung thông tin, thiết kế, tài liệu, đồ họa, phần mềm, hình ảnh, video, âm nhạc, âm thanh, phức hợp phần mềm, mã nguồn và phần mềm cơ bản liên quan đến Facepay (gọi chung là “Quyền Sở Hữu Trí Tuệ”) là tài sản và luôn luôn là tài sản của Wee Digital. Tất cả các Quyền Sở Hữu Trí Tuệ được bảo hộ bởi Quy Định Pháp Luật về bản quyền và các công ước quốc tế. Tất cả các quyền đều được bảo lưu.</p>
                    <p className="wrapper"><span className="title">4.2.</span> Ngoại trừ được cho phép rõ ràng trong Điều Khoản Chung này, Người Sử Dụng không được sử dụng, biên tập, công bố, mô phỏng, dịch, thực hiện các sản phẩm phái sinh từ, phân phát hoặc bằng cách khác sử dụng, tái sử dụng, sao chép, sửa đổi, hoặc công bố Quyền Sở Hữu Trí Tuệ theo bất kỳ cách thức nào mà không có sự chấp thuận trước bằng văn bản của Wee Digital. Người Sử Dụng không được trợ giúp hoặc tạo điều kiện cho bất kỳ bên thứ ba nào sử dụng Quyền Sở Hữu Trí Tuệ theo bất kỳ cách thức nào mà cấu thành một vi phạm về sở hữu trí tuệ và (hoặc) đối với các quyền liên quan khác của Wee Digital.</p>
                    <p className="title-large">5. BỒI HOÀN</p>
                    <p className="content">Người Sử Dụng đồng ý bồi hoàn cho Wee Digital và các bên liên quan của Wee Digital và đảm bảo cho họ không bị thiệt hại bởi mọi tổn thất, khiếu nại, yêu cầu, khiếu kiện, thủ tục tố tụng, chi phí (bao gồm, nhưng không giới hạn, các chi phí pháp lý) và các trách nhiệm có thể phải gánh chịu hoặc đưa ra đối với Wee Digital và (hoặc) các nhân viên, cán bộ… của Wee Digital phát sinh từ hoặc liên quan đến:</p>
                    <p className="note">(i) Việc Người Sử Dụng sử dụng Facepay (hoặc bất kỳ phần nào của Facepay);</p>
                    <p className="note">(ii) Việc Người Sử Dụng vi phạm Điều Khoản Chung này.</p>
                    <p className="title-large">6. TIẾT LỘ THÔNG TIN</p>
                    <p className="wrapper"><span className="title">6.1.</span> Người Sử Dụng đồng ý rằng Wee Digital có thể thu thập, lưu trữ, sử dụng và xử lý các thông tin về Hồ Sơ Mở Tài Khoản Facepay cũng như các thông tin khác từ Người Sử Dụng hoặc các bên thứ ba để phục vụ cho mục đích nhận biết khách hàng và xác thực theo Quy Định Pháp Luật. Wee Digital cũng có thể thu thập, lưu trữ, sử dụng và xử lý thông tin cá nhân của Người Sử Dụng cho mục đích nghiên cứu và phân tích hoạt động nhằm cải tiến tính năng, dịch vụ.</p>
                    <p className="wrapper"><span className="title">6.2.</span> Khi Người Sử Dụng đăng ký một Tài Khoản Facepay, Người Sử Dụng hiểu và đồng ý cấp quyền cho Wee Digital thu thập, lưu giữ, sử dụng và xử lý thông tin thông qua việc truy cập vào các ứng dụng sau trên thiết bị di động theo Điều Khoản Chung này. </p>
                    <p className="wrapper"><span className="title">6.3.</span> Người Sử Dụng chấp thuận, ủy quyền không hủy ngang và vô điều kiện cho Wee Digital tiết lộ hoặc công bố các thông tin liên quan đến Người Sử Dụng hoặc các giao dịch của Người Sử Dụng với các cá nhân hoặc tổ chức mà Wee Digital có thể được yêu cầu tiết lộ theo bất kỳ Quy Định Pháp Luật hoặc quy định nào áp dụng đối với Wee Digital hoặc căn cứ theo bất kỳ yêu cầu hoặc lệnh nào của bất kỳ cơ quan nhà nước có thẩm quyền nào hoặc lệnh của tòa án.</p>
                    <p className="wrapper"><span className="title">6.4.</span> Người Sử Dụng đồng ý với Chính Sách Bảo Mật của Wee Digital được quy định <span style={{ fontWeight: "bold", cursor: "pointer" }}><a>tại đây.</a></span></p>
                    <p className="title-large">7. GIỚI HẠN TRÁCH NHIỆM</p>
                    <p className="wrapper"><span className="title">7.1.</span> Trong mọi trường hợp, Wee Digital (bao gồm cả các nhân viên, điểm giao dịch hoặc các bên liên kết với Wee Digital) sẽ không chịu trách nhiệm đối với Người Sử Dụng về bất kỳ tổn thất, thiệt hại, trách nhiệm và chi phí nào theo bất kỳ nguyên nhân hành động nào gây ra bởi việc sử dụng, hoặc không có khả năng sử dụng Facepay trừ khi Wee Digital (bao gồm cả các nhân viên, điểm giao dịch hoặc các bên liên kết với Wee Digital) có lỗi trong việc để xảy ra tổn thất, thiệt hại.</p>
                    <p className="wrapper"><span className="title">7.2.</span> Tuy nhiên, trong trường hợp Wee Digital (bao gồm cả các nhân viên, điểm giao dịch hoặc các bên liên kết với Wee Digital) phải chịu trách nhiệm về các tổn thất hoặc thiệt hại theo quy định nêu trên đây, thì Người Sử Dụng đồng ý rằng toàn bộ trách nhiệm của Wee Digital (bao gồm cả các nhân viên, điểm giao dịch hoặc các bên liên kết với Wee Digital) sẽ được giới hạn ở số tiền thực tế của các thiệt hại trực tiếp phải gánh chịu bởi Người Sử Dụng và trong bất kỳ trường hợp nào sẽ không vượt quá tổng số tiền được xác thực giao dịch thanh toán thông qua Facepay.</p>
                    <p className="wrapper"><span className="title">7.3.</span> Trong mọi trường hợp, Wee Digital sẽ không chịu trách nhiệm về bất kỳ thiệt hại gián tiếp, đặc biệt, do hệ quả hoặc sự kiện ngẫu nhiên nào phát sinh hoặc bắt nguồn từ việc sử dụng, hoặc không có khả năng sử dụng Facepay.</p>
                    <p className="wrapper"><span className="title">7.4.</span> Trong phạm vi mà Quy Định Pháp Luật cho phép, Người Sử Dụng đồng ý rằng Wee Digital (bao gồm cả các nhân viên, điểm giao dịch hoặc các bên liên kết với Wee Digital) sẽ không chịu trách nhiệm về bất kỳ tổn thất, thiệt hại, trách nhiệm và (hoặc) chi phí nào mà Người Sử Dụng phải gánh chịu do việc Người Sử Dụng hoặc một bên thứ ba khác truy cập trái phép vào máy chủ, giao diện, website của Facepay và ứng dụng Facepay. Thiết bị và (hoặc) dữ liệu của Người Sử Dụng dù là vô tình hoặc bằng cách thức không hợp pháp hoặc không được phép như xâm nhập trái phép hoặc các lý do khác nằm ngoài tầm kiểm soát của Wee Digital (ví dụ như vi rút máy tính).</p>
                    <p className="wrapper"><span className="title">7.5.</span> Wee Digital sẽ không chịu trách nhiệm về việc không thực hiện hoặc chậm thực hiện các nghĩa vụ của mình theo Điều Khoản Chung này do các sự kiện bất khả kháng nằm ngoài tầm kiểm soát hợp lý của Wee Digital, bao gồm, nhưng không giới hạn, thiên tai, bão tố, mưa dông, bùng nổ vi rút, các hạn chế của chính phủ, đình công, chiến tranh, hỏng mạng hoặc hỏng mạng viễn thông hoặc các sự kiện bất khả kháng khác theo quy định của pháp luật hoặc được công nhận bởi hai bên.</p>
                    <p className="title-large">8. AN NINH</p>
                    <p className="wrapper"><span className="title">8.1.</span> Người Sử Dụng cam kết sẽ chỉ sử dụng Facepay cho các hoạt động hợp pháp, không thực hiện các giao dịch thanh toán bất hợp pháp, bao gồm nhưng không giới hạn các hành vi đánh bạc, thanh toán các hàng hóa, dịch vụ bất hợp pháp hay rửa tiền, và sẽ thông báo ngay lập tức cho Wee Digital về (các) giao dịch có dấu hiệu sử dụng trái phép Tài Khoản Facepay của Người Sử Dụng.</p>
                    <p className="wrapper"><span className="title">8.2.</span> Người Sử Dụng cam kết không sử dụng, và không cho bất kỳ bên thứ ba nào khác sử dụng Facepay để thực hiện các hành vi xâm nhập trái phép, tấn công hệ thống, phát tán virus và phần mềm độc hại và các hành vi vi phạm Quy Định Pháp Luật hoặc gây gián đoạn, cản trở hoạt động bình thường đối với hệ thống của Wee Digital và các đối tác của Wee Digital, hoặc đối với việc sử dụng Facepay của người khác.</p>
                    <p className="wrapper"><span className="title">8.3.</span> Người Sử Dụng chịu trách nhiệm và miễn trừ toàn bộ trách nhiệm cho Wee Digital về việc sử dụng hoặc hoạt động trên Tài Khoản Facepay của Người Sử Dụng trước pháp luật. Bất kỳ hoạt động gian lận, lừa gạt hoặc hoạt động bất hợp pháp khác có thể là căn cứ cho phép Wee Digital tạm ngừng Tài Khoản Facepay và (hoặc) chấm dứt Dịch Vụ được cung cấp cho Người Sử Dụng, theo toàn quyền quyết định của Wee Digital, và Wee Digital có thể trình báo về hành vi của Người Sử Dụng với các cơ quan nhà nước có thẩm quyền để xem xét xử lý.</p>
                    <p className="title-large">9. THÔNG TIN LIÊN LẠC VÀ THÔNG BÁO</p>
                    <p className="wrapper"><span className="title">9.1.</span> Người Sử Dụng đồng ý rằng Wee Digital hoặc các bên liên kết với Wee Digital có thể gửi tin nhắn cho Người Sử Dụng thông qua số điện thoại hoặc Ứng Dụng Facepay các thông tin cập nhật về Dịch Vụ và các sự kiện được đưa ra hoặc cung cấp bởi Wee Digital hoặc các bên liên kết với Wee Digital. Người Sử Dụng đồng ý rằng các thông báo được gửi qua hình thức tin nhắn nêu tại điều này có giá trị đầy đủ như một thông báo chính thức của Wee Digital đến Người Sử Dụng.</p>
                    <p className="wrapper"><span className="title">9.2.</span> Người Sử Dụng đồng ý rằng Wee Digital không có bất kỳ nghĩa vụ nào trong việc đưa ra thông báo định kỳ cho Người Sử Dụng về chi tiết các Giao Dịch được tiến hành bởi Người Sử Dụng.</p>
                    <p className="wrapper"><span className="title">9.3.</span> Mọi thông báo và tài liệu (nếu có) cần phải được đưa ra bởi Người Sử Dụng theo Điều Khoản Chung này cho Wee Digital sẽ được gửi cho Wee Digital đến địa chỉ trụ sở hoặc thông qua Dịch Vụ Khách Hàng của Facepay.</p>
                    <p className="wrapper"><span className="title">9.4.</span> Tất cả các thông báo và tài liệu (nếu có) cần gửi cho Người Sử Dụng bởi Wee Digital theo Điều Khoản Chung này sẽ được gửi bằng một trong những phương thức sau:</p>
                    <p className="note">(i) Gửi thư thường hoặc thư bảo đảm đến địa chỉ mới nhất của Người Sử Dụng theo Hồ Sơ Mở Tài Khoản Facepay của Người Sử Dụng;</p>
                    <p className="note">(ii) Gửi thư điện tử đến địa chỉ thư điện tử mới nhất của Người Sử Dụng theo ghi chép của Wee Digital;</p>
                    <p className="note">(iii) Công bố thông báo hoặc thông tin liên lạc trên trang website <span><a href="http://facepay.vn/" target="_blank">http://facepay.vn/</a></span></p>
                    <p className="note">(iv) Gửi tin nhắn (SMS) hoặc gọi điện đến số điện thoại mới nhất của Người Sử Dụng theo ghi chép của Wee Digital.</p>
                    <p className="wrapper"><span className="title">9.5.</span> Bất kỳ thông báo hoặc tài liệu hoặc thư từ liên lạc nào được xem là đã được gửi và nhận:</p>
                    <p className="note">(i) Nếu được gửi bằng thư thường hoặc thư bảo đảm, trong vòng ba (3) Ngày Làm Việc kể từ ngày gửi;</p>
                    <p className="note">(ii) Nếu được gửi bằng các hình thức khác được quy định tại điều này, Ngày Làm Việc sau ngày gửi thông báo hoặc tài liệu đó.</p>
                    <p className="title-large">10. XỬ LÝ CÁC GIAO DỊCH CÓ NHẦM LẪN, SỰ CỐ KỸ THUẬT HOẶC DẤU HIỆU VI PHẠM PHÁP LUẬT</p>
                    <p className="wrapper"><span className="title">10.1.</span> Trong trường hợp Wee Digital có cơ sở xác định một giao dịch được thực hiện do nhầm lẫn, do sự cố kỹ thuật hoặc có dấu hiệu vi phạm pháp luật, Wee Digital tùy theo quyết định của riêng mình có quyền thực hiện các biện pháp phòng ngừa, ngăn chặn nhằm giảm thiểu các thiệt hại có thể phát sinh, bao gồm nhưng không giới hạn các biện pháp sau:</p>
                    <p className="note">(i) Tạm ngừng hoạt động Tài Khoản Facepay của Người Sử Dụng; hoặc (và)</p>
                    <p className="note">(ii) Báo cáo với các ngân hàng của người sử dụng và của bên bán hàng khoanh giữ, đóng băng, khấu trừ, thu hồi khoản tiền phát sinh do nhầm lẫn và (hoặc) sự cố kỹ thuật; hoặc (và)</p>
                    <p className="note">(iii) Báo cáo cơ quan nhà nươc có thẩm quyền để được hướng dẫn xử lý đối với giao dịch và (hoặc) khoản tiền phát sinh từ hoặc có liên quan đến nhầm lẫn, sự cố kỹ thuật hoặc vi phạm pháp luật.</p>
                    <p className="wrapper"><span className="title">10.2.</span> Người Sử Dụng đồng ý rằng Wee Digital có quyền thực hiện các biện pháp quy định tại Điều 11.1 nêu trên và sẽ cam kết hợp tác đầy đủ với Wee Digital trong việc xác minh, giải quyết các vấn đề liên quan đến giao dịch có nhầm lẫn, sự cố kỹ thuật hoặc dấu hiệu vi phạm pháp luật.</p>
                    <p className="wrapper"><span className="title">10.3.</span> Trong trường hợp Người Sử Dụng không hợp tác với Wee Digital hoặc có biểu hiện chiếm đoạt, tẩu tán khoản tiền phát sinh từ hoặc liên quan đến nhầm lẫn, sự cố kỹ thuật hoặc vi phạm pháp luật, Wee Digital có quyền duy trì các biện pháp phòng ngừa, ngăn chặn nêu tại Điều 11.1 và yêu cầu cơ quan có thẩm quyền xử lý, bao gồm cả xử lý hình sự trong trường hợp có dấu hiệu tội phạm. Trong mọi trường hợp, Wee Digital sẽ thực hiện theo kết luận, quyết định hoặc phán quyết cuối cùng của cơ quan nhà nước có thẩm quyền.</p>
                    <p className="wrapper"><span className="title">10.4.</span> Trong trường hợp Wee Digital có cơ sở xác định Người Sử Dụng có hành vi lạm dụng các chương trình khuyến mại, chính sách ưu đãi, hỗ trợ người dùng của Wee Digital, thì Wee Digital có quyền tạm ngừng hoặc chấm dứt các chương trình, chính sách ưu đãi, hỗ trợ đó với một, hoặc một số hoặc toàn bộ Người Sử Dụng có liên quan và thực hiện các biện pháp cần thiết để thu hồi các ưu đãi, hỗ trợ và lợi ích kinh tế mà Người Sử Dụng liên quan đã nhận được. Trong trường hợp hành vi lạm dụng có yếu tố vi phạm Quy Định Pháp Luật, Wee Digital sẽ thông báo với cơ quan nhà nước có thẩm quyền để xem xét xử lý.</p>
                    <p className="wrapper"><span className="title">10.5.</span> Trong trường hợp Người Sử Dụng có khiếu nại với Wee Digital về các Dịch Vụ trên Facepay thì các khiếu nại chỉ được chấp thuận là phù hợp khi các giao dịch khiếu nại phát sinh trong vòng 60 ngày kể từ ngày thực hiện giao dịch, trừ trường hợp Người Sử Dụng chứng minh không thể thực hiện việc khiếu nại do các yếu tố bất khả kháng. Sau thời hạn này, Wee Digital có quyền từ chối giải quyết khiếu nại.</p>
                    <p className="content">Các đối tác của Wee Digital đều có quy trình xử lý khiếu nại với thời hạn đã được thiết lập và Wee Digital phải tuân thủ vấn đề này. Vì vậy, Wee Digital có thể mất tối đa đến 180 ngày để xử lý khiếu nại tùy theo tính chất của các khiếu nại và Wee Digital khuyến khích Người Sử Dụng hợp tác và giữ liên lạc với Wee Digital, đồng thời tuân thủ thời gian phản hồi thông tin trong toàn thời gian phối hợp xử lý khiếu nại để Wee Digital có thể thực hiện tốt việc xử lý khiếu nại này.</p>
                    <p className="content">Trong quá trình xử lý khiếu nại, Wee Digital có quyền đề nghị Người Sử Dụng cung cấp các bằng chứng xác minh tính trung thực của khiếu nại, Wee Digital mong nhận được sự hợp tác của Người Sử Dụng để tiến hành xử lý khiếu nại. Các thông tin Người Sử Dụng cung cấp chỉ được trao đổi với mục đích xử lý khiếu nại. </p>
                    <p className="title-large">11. CÁC QUY ĐỊNH KHÁC</p>
                    <p className="wrapper"><span className="title">11.1.</span> Việc Wee Digital không thực hiện hoặc áp dụng bất kỳ quyền hoặc biện pháp nào mà Wee Digital có theo quy định tại Điều Khoản Chung này hoặc theo Quy Định Pháp Luật không bị xem là Wee Digital từ bỏ hoặc hạn chế quyền hoặc biện pháp đó, và Wee Digital bảo lưu quyền thực hiện quyền hoặc biện pháp đó vào bất kỳ thời điểm nào Wee Digital nhận thấy thích hợp.</p>
                    <p className="wrapper"><span className="title">11.2.</span> Trong trường hợp bất kỳ quy định nào của Điều Khoản Chung này được xác định là bất hợp pháp hoặc không thể thực thi bằng cách khác thì Wee Digital sẽ sửa đổi quy định đó, hoặc (theo toàn quyền quyết định của mình) bỏ quy định đó ra khỏi Điều Khoản Chung. Nếu bất kỳ quy định nào của Điều Khoản Chung được xác định là bất hợp pháp hoặc không thể thực thi, việc xác định như vậy sẽ không ảnh hưởng đến các quy định còn lại của Điều Khoản Chung, và Điều Khoản Chung sẽ vẫn có đầy đủ hiệu lực. </p>
                    <p className="wrapper"><span className="title">11.3.</span> Người Sử Dụng xác nhận rằng Wee Digital, theo các luật và quy định hiện hành hoặc sau khi nhận được chỉ thị của các cơ quan hữu quan chính phủ, có thể được yêu cầu thực hiện các hành động mà có thể vi phạm các quy định của Điều Khoản Chung. Về vấn đề này, Người Sử Dụng đồng ý không buộc Wee Digital phải chịu trách nhiệm. </p>
                    <p className="wrapper"><span className="title">11.4.</span> Người Sử Dụng không được chuyển nhượng các quyền của mình theo Điều Khoản Chung này nếu không có sự chấp thuận trước bằng văn bản của Wee Digital. Wee Digital có thể chuyển nhượng các quyền của mình theo Điều Khoản Chung mà không cần có sự chấp thuận trước bằng văn bản của Người Sử Dụng.</p>
                    <p className="wrapper"><span className="title">11.5.</span> Điều Khoản Chung này sẽ có giá trị ràng buộc đối với những người thừa kế, các đại diện cá nhân và đại diện theo pháp luật, các bên kế nhiệm quyền sở hữu và các bên nhận chuyển nhượng được phép về tài sản (nếu có) của Người Sử Dụng.</p>
                    <p className="wrapper"><span className="title">11.6.</span> Bất kỳ tranh chấp hoặc bất đồng nào theo Điều Khoản Chung này trước hết sẽ được giải quyết thông qua thương lượng hòa giải. Nếu không đạt được thỏa thuận thông qua thương lượng hòa giải như vậy, các bên tại đây đồng ý sẽ đưa tranh chấp hoặc bất đồng lên tòa án có thẩm quyền tại Thành Phố Hồ Chí Minh để giải quyết.</p>
                    <p className="wrapper"><span className="title">11.7.</span> Tranh chấp giữa Người Sử Dụng và bên thứ ba: Wee Digital không có bất cứ trách nhiệm liên quan nào mà chỉ đóng vai trò hỗ trợ Người Sử Dụng, cung cấp thông tin cần thiết để Người Sử Dụng và bên thứ ba liên quan để cùng giải quyết. Người Sử Dụng và bên thứ ba phải trực tiếp giải quyết mọi vấn đề liên quan đến giao dịch của Người Sử Dụng và bên thứ ba.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(TermsOfUse)
