import React, { useRef, useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
// import { useHistory, useLocation } from "react-router-dom";
import lottie from 'lottie-web'
import { Link } from 'react-router-dom';


// styles
import { WrapPage } from './index.styles'

//data
import { ConnectData } from '../../assets/data/ConnectData';

//component
import Downloads from './DownLoads';
import Links from './Links';
import Payments from './Payments';
import PageSix from '../Home/PageSix';

//image
import ic_fastpay from '../../assets/images/ic_fastpay.png'
import ic_checkout from '../../assets/images/ic_checkout.png'
import bg_coming_soon from '../../assets/images/bg_coming_soon.png'
import bg_payment_page_mobile from '../../assets/images/bg_payment_page_mobile.png'
import IcArrowRightBlue from '../../assets/images/IcArrowRightBlue/IcArrowRightBlue';
import model_tutorial from '../../assets/images/model_tutorial.png'
import model_tutorial_mobile from '../../assets/images/model_tutorial_mobile.png'
import IcCycleNext from '../../assets/images/IcCycleNext';
import IcCyclePrevious from '../../assets/images/IcCyclePrevious';

//constant
import { BASIC_INFOMATION, FACEPAY_PAYMENT_STORES, SUPPORT } from '../../assets/constant/pathNames';

//data
import { LogoBankData } from '../../assets/data/ListBank';

let interval = null

const Tutorial = ({ t }) => {

    const lstStep = LogoBankData
    const wrapLogoBankRef = useRef(null)
    const wrapAnimation = useRef()
    const keyRef = useRef(lstStep[0].step)

    const [activeReview, setActiveReview] = useState(lstStep[0].step);
    const [idNav, setIdNav] = useState(0);
    const [listLogoBank, setListLogoBank] = useState(LogoBankData)
    
    useEffect(() => {
        _renderUI()
        window.addEventListener("resize", _handleResize)
        return () => {
            window.removeEventListener("resize", _handleResize)
        }
    }, [])

    const _handleResize = (e) => {
        _renderUI()
    }

    const _renderUI = () => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

        switch (wrapLogoBankRef.current.clientWidth) {
            case 1228: {
                let list = listLogoBank.filter((item) => item.type !== 'blank')
                list.splice(32, 0, { type: 'blank' });
                setListLogoBank(list)
            }
                break;
            case 1080: {
                let list = listLogoBank.filter((item) => item.type !== 'blank')
                list.splice(35, 0, { type: 'blank' }, { type: 'blank' });
                setListLogoBank(list)
            }
                break;
            case 928: {
                let list = listLogoBank.filter((item) => item.type !== 'blank')
                list.splice(36, 0, { type: 'blank' }, { type: 'blank' });
                setListLogoBank(list)
            }
                break;
            case 768: {
                let list = listLogoBank.filter((item) => item.type !== 'blank')
                list.splice(35, 0, { type: 'blank' });
                setListLogoBank(list)
            }
                break;
            case 624: {
                let list = listLogoBank.filter((item) => item.type !== 'blank')
                list.splice(36, 0, { type: 'blank' });
                setListLogoBank(list)
            }
                break
            default: {
                let list = listLogoBank.filter((item) => item.type !== 'blank')
                console.log("ping-default");
                setListLogoBank(list)
            }
                break;
        }
    }

    useEffect(() => {
        lottie.loadAnimation({
            container: wrapAnimation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation/introduction.json'
        })
    }, []);

    const _handleChooseStep = (index) => {
        setIdNav(index)
    }

    useEffect(() => {
        interval = setInterval(() => {
            setActiveReviewKey(1)
        }, 2000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    const onClickRedirect = (delta) => {
        if (interval) {
            clearInterval(interval)
            interval = null;
            interval = setInterval(() => {
                setActiveReviewKey(1)
            }, 2000);
        }
        setActiveReviewKey(delta)
    }

    const setActiveReviewKey = (delta) => {
        let newKey = keyRef.current + delta
        if (newKey < 1) newKey = 38
        if (newKey > 38) newKey = 1
        keyRef.current = newKey
        setActiveReview(newKey)
    }

    const imgItem = ({ step, image }, index, classImg) => (
        <div id={`image${step}`} key={index} className={`image ${classImg}`}>
            {
                image && <img src={image} alt="image" />
            }
        </div>
    )

    return (
        <WrapPage>
            <div className="wrap-home-page">
                <div className="home-page">
                    <div className="content-page">
                        <p className="title-page">
                            {t('tutorial.home_page.title_one')}
                        </p>
                        <p className="title-page blue">
                            {t('tutorial.home_page.title_two')}
                        </p>
                        <p className="text-page">{t('tutorial.home_page.text_1')}</p>
                        <p className="text-page bold">"{t('tutorial.home_page.text_2')}"</p>
                    </div>
                    {/* <div className="lottie" ref={wrapAnimation}></div> */}
                    <img className='model' src={model_tutorial} alt="image" />
                    <img className='model-mobile' src={model_tutorial_mobile} alt="image" />

                </div>
            </div>
            <div className="connect-page">
                <div className="title-wrap">
                    <p className="title-connect blue">{t('tutorial.connect_page.ket_noi')}</p>
                    <p className="title-connect">{t('tutorial.connect_page.facepay')}</p>
                </div>
                <div className="content-connect">
                    <div className="wrap-header">
                        {
                            ConnectData.map((item, index) => (
                                <div
                                    key={index}
                                    className={
                                        idNav === index
                                            ? "header active"
                                            : "header"
                                    }
                                    onClick={() => _handleChooseStep(index)}
                                >
                                    <p className="step">{item.step}</p>
                                    <p className={idNav === index ? "label active" : "label"}>
                                        {item.label}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                    <div className="wrap-content">
                        {/* {
                            idNav === 0 ?
                                <Downloads />
                                : idNav === 1 ?
                                    <Links />
                                    :
                                    <Payments />
                        } */}
                        {
                            idNav === 0 ?
                                <Downloads />
                                :
                                <Links />
                        }
                    </div>
                </div>
            </div>
            <div className="connect-page">
                <div className="title-wrap">
                    <p className="title-connect blue">{t('tutorial.experience_page.trai_nghiem')}</p>
                    <p className="title-connect">{t('tutorial.experience_page.facepay')}</p>
                </div>
                <p className="text-page">{t('tutorial.experience_page.desc')}</p>
                <div className="wrap-image">
                    <img className="icon-fastpay" src={ic_fastpay} alt="icon-fastpay" />
                    {/* <img className="icon-checkout" src={ic_checkout} alt="icon-checkout" /> */}
                </div>
            </div>
            <div className="payment-page">
                <div className="bg-payment">
                    <img className="bg-image" src={bg_payment_page_mobile} alt="bg-payment" />
                </div>
                <div className="page-wrapper">
                    <div className="content-text">
                        <div className="title-page">
                            <p >{t('tutorial.payment_page.title1')}</p>
                            <p className="title-page blue">{t('tutorial.payment_page.title2')}</p>
                        </div>
                        <p className="desc-page">{t('tutorial.payment_page.desc')}</p>
                        <div className="content-page">
                            <div className="wrap-step">
                                <div className="cycle"><span className="step">1</span></div>
                                <p>{t('tutorial.payment_page.step1')}</p>
                            </div>
                            <div className="wrap-step">
                                <div className="cycle"><span className="step">2</span></div>
                                <p>{t('tutorial.payment_page.step2')}</p>
                            </div>
                            <div className="wrap-step">
                                <div className="cycle"><span className="step">3</span></div>
                                <p>{t('tutorial.payment_page.step3')}</p>
                            </div>
                        </div>
                        <Link className="note" to={`${FACEPAY_PAYMENT_STORES}`}>
                            <p>{t('tutorial.payment_page.note')}</p>
                            <IcArrowRightBlue />
                        </Link>
                    </div>
                </div>
            </div>
            <div className='wrap-list-bank' ref={wrapLogoBankRef}>
                <div className="title-page">
                    <p className="title-page blue">{t('tutorial.bank.title_blue')}</p>
                    <p>{t('tutorial.bank.title')}</p>
                </div>
                <div className="list-bank" >
                    <div className="image-container" >
                        <div className="icon-previous-wrap">
                            <IcCyclePrevious className="icon-previous" onClick={() => onClickRedirect(-1)} />
                        </div>
                        <div className="image-wrap" >                            
                            {
                                listLogoBank.map((item, index) => {                                   
                                    if (item.type === 'blank') return <div key={index} />

                                    else if (item.step === activeReview)
                                        return imgItem(item, index, 'active')
                                    if (activeReview - 1 > 0 && item.step === activeReview - 1)
                                        return imgItem(item, index, 'previous')
                                    return imgItem(item, index, 'next')
                                })
                            }
                        </div>
                        <div className="icon-next-wrap">
                            <IcCycleNext className="icon-next" onClick={() => onClickRedirect(1)} />
                        </div>
                    </div>
                    <div className="step-container">
                        {
                            lstStep.map((item, index) => (
                                <div
                                    className={activeReview === item.step ? "cycle wrapper" : "cycle"}
                                    key={index}
                                >
                                    <div className={activeReview === item.step ? "step active" : "step"}></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* <div className="coming-soon-page">
                <p className="title-page">{t('tutorial.coming-soon.title')}</p>
                <p className="des-page">{t('tutorial.coming-soon.text1')}</p>
                <p className="des-bold-page">{t('tutorial.coming-soon.text2')}</p>
            </div> */}
            <PageSix />
        </WrapPage>
    )
}

export default withNamespaces()(Tutorial)
