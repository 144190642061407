import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    /* .page-wrapper{
        max-width: 1200px;
    }
    .title-page{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 40px;
        color: #000000;
        display: flex;      
    }
    .content-page{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        padding-top: 38px;
    } */

`;

export default GlobalStyles