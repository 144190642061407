import styled from 'styled-components'

const WrapContactPage = styled.div`
    width: 100vw;
    background: #F7FCFF;
    font-family: 'SVN-Gilroy';    

    .contact-page {
        padding: 0 32px;
        background: #F7FCFF;
        padding-bottom: 72px;

        .title-page {
            /* margin-top: 23px; */
            color: #304659;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 39px;
            text-align: center;
            padding-top: 53px;
            display: flex;
            flex-direction: column;

            span {
                color: #1279DA;
            }
        }

        .list-contact-method {
            & > * {
                .contact-title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                    color: #304659;
                }

                .contact-details {
                    margin-top: 15px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 130%;
                    color: #939393;
                }
                .link-email{
                    text-decoration: none;
                }
                .link-phone{
                    text-decoration: none;
                }
                button {
                    margin-top: 32px;
                    padding: 8px 12px;
                    border: none;
                    outline: none;
                    background: #1279DA;
                    border-radius: 38px;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 21px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    /* width: 139px; */
                    padding: 8px 12px;
                    p{
                        padding-top: 3px
                    }
                    & > *:first-child {
                        margin-right: 6px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            
            .request-support {
                margin-top: 62px;
            }

            .hotline {
                margin-top: 51px;
            }

            
        }
    }

    @media only screen and (min-width: 768px) {
        margin: 30px 0 0 0;
        .contact-page {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 24px;

            .title-page {
                flex-direction: row;
                justify-content: center;
                padding-top: 66px;
                /* margin-top: 64px; */
                font-size: 50px;
                line-height: 52px;
                span{
                    padding-right: 10px;
                }
            }

            .list-contact-method {
                padding: 124px 0 72px 0;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 30px;
                justify-content: space-between;

                & > * {
                    display: flex;
                    align-items: flex-start;
                    .contact-title {
                        font-size: 22px;
                        line-height: 26px;
                    }
                    .contact-details {
                        margin-top: 19px;
                        width: 325px;
                        font-size: 13px;
                        line-height: 16px;
                    }

                    button {
                        margin-top: 0;
                        margin-left: 30px;
                        flex-shrink: 0;
                        font-size: 16px;
                        line-height: 23px;
                        p{
                            padding-top: unset;
                        }
                    }
                }

                .request-support, .hotline {
                    margin-top: 0;
                    flex-direction: column;
                    justify-content: space-between;
                    .link-email{
                        button{
                            margin: 34px 0 0;
                        }
                    }
                    .link-phone{
                        button{
                            margin: 34px 0 0;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1024px) {
        .contact-page {
            .list-contact-method {
                
                .request-support, .hotline {
                    margin-top: 0;
                    flex-direction: row;
                    justify-content: space-between;
                    .link-email{
                        button{
                            margin: 0;
                        }
                    }
                    .link-phone{
                        button{
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
`;

export {
    WrapContactPage
}