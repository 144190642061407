import React from 'react'
import { withNamespaces } from 'react-i18next';

//style
import { WrapComponent } from './index.styles'

//image
import icon_ocb from '../../../assets/images/icon_ocb.png'
import logo_OCB_mobile from '../../../assets/images/LogoOCB/logo_OCB_mobile.png'
import logo_OCB_pc from '../../../assets/images/LogoOCB/logo_OCB_pc.png'

//component
import StepByStepReverse from '../../Control/StepByStepReverse';

//data
import { OCBData } from '../../../assets/data/OCBBankData';

const OcbBank = ({ t, refPage }) => {

    return (
        <WrapComponent ref={refPage}>
            <div className="bank-page-ocb">
                <div className='overlay' />
                <div className="block-left">
                    <StepByStepReverse
                        headLogo={{
                                mobile: logo_OCB_mobile,
                                laptop: logo_OCB_pc
                            }
                        }
                        headSubTitle={t('facePay_by_bank.ocb.title')}
                        defaultActiveStep={OCBData[0].step}
                        lstStep={OCBData}
                    />
                </div>
                <div className="block-right">
                    <img className="icon" src={icon_ocb} alt="icon-ocbbank" />
                </div>
            </div>
        </WrapComponent>
    )
}

export default withNamespaces()(OcbBank)
