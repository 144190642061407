import React from 'react'

const IcSearchWhite = (props) => {
    return (
        <svg {...props} width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.971 14.3741L18.0542 17.4324C18.0676 17.4476 18.075 17.4672 18.075 17.4875C18.075 17.5075 18.0678 17.5268 18.0549 17.5418C18.0479 17.5481 18.0399 17.5531 18.0312 17.5568C18.0211 17.561 18.0102 17.5632 17.9992 17.5632C17.9882 17.5632 17.9773 17.561 17.9671 17.5568C17.957 17.5525 17.9478 17.5463 17.94 17.5385L17.9379 17.5363L14.8712 14.4697L14.3959 13.9944L13.8716 14.4149C12.5249 15.495 10.8156 16.018 9.09509 15.8765C7.37459 15.735 5.7737 14.9397 4.6216 13.6541C3.46949 12.3684 2.85375 10.6903 2.90097 8.96461C2.94819 7.23895 3.65478 5.59696 4.87547 4.37628C6.09615 3.15559 7.73814 2.449 9.46381 2.40177C11.1895 2.35455 12.8676 2.9703 14.1533 4.1224C15.4389 5.27451 16.2342 6.8754 16.3757 8.5959C16.5172 10.3164 15.9942 12.0257 14.9141 13.3724L14.4919 13.8989L14.971 14.3741ZM6.00835 14.6405C7.09097 15.3639 8.36379 15.75 9.66585 15.75C11.4119 15.75 13.0864 15.0564 14.321 13.8218C15.5556 12.5872 16.2492 10.9127 16.2492 9.16666C16.2492 7.8646 15.8631 6.59178 15.1397 5.50916C14.4163 4.42654 13.3881 3.58273 12.1852 3.08446C10.9822 2.58618 9.65855 2.45581 8.38151 2.70983C7.10447 2.96385 5.93143 3.59085 5.01073 4.51154C4.09004 5.43224 3.46304 6.60528 3.20902 7.88232C2.955 9.15936 3.08537 10.483 3.58365 11.686C4.08192 12.8889 4.92573 13.9171 6.00835 14.6405Z" fill="white" stroke="white" strokeWidth="1.5" />
        </svg>
    )
}

export default IcSearchWhite
