import styled from 'styled-components'

//img
import bg_comunication_mobile from '../../assets/images/bg_comunication_mobile.png'
import bg_comunication from '../../assets/images/bg_comunication.png'


const WrapPage = styled.div`
    .wrap-page-one{
        /* background: radial-gradient(76.7% 256.34% at 76.7% 45.62%, #ABDFF9 0%, #8EC2F9 38.86%, #CFB3FE 100%); */
        background-image:url(${bg_comunication_mobile});
        background-size:100%;
        background-repeat:no-repeat;
        background-position:center;
        background-size: cover;  
        .page-one {
            padding: 100px 30px 0 32px;
            height: 100vh;
            min-height: 788px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            box-sizing: border-box;
            .title-page{
                font-family: 'SVN-Bebas Neue';
                font-style: normal;
                font-weight: 700;
                font-size: 72px;
                line-height: 72px;
                letter-spacing: -1px;
                text-transform: uppercase;
                color: #F7FCFF;
                padding: 0 0 5px 0;
                display: flex;
                flex-direction: column;
                text-align: center;
                &.blue{
                    color: #1279DA;
                }
            }
            .btn{
                width: 177px;
                height: 42px;
                background: #FFFFFF;
                border-radius: 20px;
                padding: 8px 17px;
                box-sizing: border-box;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 26px;
                text-align: center;
                color: #1279DA;
                margin: 0 auto 85px;
                text-decoration: none;
                cursor: pointer;
            }
            /* .content-page{
                z-index: 1;
                .title-page{
                    font-family: 'SVN-Bebas Neue';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 72px;
                    line-height: 72px;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    color: #F7FCFF;
                    padding: 0 0 5px 0;
                    display: flex;
                    flex-direction: column;
                    &.blue{
                        color: #1279DA;
                    }
                }
                .text-page{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 21px;
                    color: #191919;
                    padding: 16px 0 0 0;
                    z-index: 9;
                }
            }
            .lottie{
                position: absolute;
                bottom: 0;
                right: 30px;
                width: 343px;
            } */
        }
    }
    .page-two {
        .title{                
            font-weight: bold;
            font-size: 16px;
            line-height: 21px;
            color: #191919;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .date{
            font-weight: normal;
            font-size: 13px;
            line-height: 130%;
            color: #939393;
            margin-top: 12px;
        }
        .desc{
            font-weight: normal;
            font-size: 14px;
            line-height: 130%;
            color: #191919;
            display: none;
        }
        .view{
            font-weight: normal;
            font-size: 13px;
            line-height: 130%;
            color: #1279DA;
            cursor: pointer;
            text-decoration: none;
            display: inline-block;
            margin-top: 12px;
        }

        & > *:nth-child(6n), & > *:nth-child(6n + 1) {
            position: relative;
            display: flex;
            width: 100vw;
            height: 100vw;
            &.none-effect {
                width: auto;
                height: auto;
                &::after {
                    background: transparent;
                }
            }

            &::after {
                content: '';
                background: rgba(0,0,0,0.7);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
            .image{
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
            }
            .content{
                font-family: "SVN-Gilroy";
                font-style: normal;
                position: absolute;
                left: 0px;
                bottom: 15px;
                padding: 0 24px;
                z-index: 1;
                text-decoration: none;
                cursor: pointer;
                .title{
                    color: #ffffff;
                }
                .desc{
                    color: #ffffff;
                }                
            }
        }

        & > *:nth-child(n + 2):not(:nth-child(6n)):not(:nth-child(6n + 1)) {
            display: flex;
            justify-content: space-between;
            width: 100vw;
            height: 50vw;
            .image{
                width: 50%;                
                object-fit: cover;
            }
            .content{
                padding: 32px 16px 0 24px;
                text-decoration: none;
                cursor: pointer;
            }

            &:nth-child(odd) {
                flex-direction: row-reverse;
            }
        } 
    }

    @media only screen and (min-width: 768px){
        .wrap-page-one{       
            background-image:url(${bg_comunication});
            .page-one{
                max-width: 1200px;
                margin: 0 auto;
                padding: 0;
                height: 100vh;
                .title-page{
                    font-size: 102px;
                    line-height: 99px;
                    margin-top: 150px;
                }
                .btn{
                    width: 206px;
                    height: 42px;
                    font-size: 22px;
                    line-height: 26px;
                    margin: 0 auto 61px;
                }
                /* .content-page{
                    .title-page{
                        font-size: 102px;
                        line-height: 99px;
                    }
                    .text-page{
                        font-size: 16px;
                        line-height: 23px;
                        width: 449px;
                        padding: 30px 0 0 0;
                    }
                }
                .lottie{
                    width: 580px;
                    height: 630px;
                    position: unset;
                }  */
            }
        }
        .page-two{
            max-width: 1200px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);

            .title{
                font-size: 18px;
                line-height: 23px;
                -webkit-line-clamp: 2;
            }
            .date{
                font-size: 14px;
                line-height: 130%;
                margin-top: 18px;
            }
            .desc{
                margin-top: 12px;
                font-size: 14px;
                line-height: 130%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .view{
                font-size: 14px;
                line-height: 130%;
            }
            & > *:nth-child(6n), & > *:nth-child(6n + 1) {
                width: auto;
                height: ${props => props.heightPostItem ? `${props.heightPostItem}px` : "auto"};
                .content{
                    bottom: 53px;
                    padding: 0 108px 0 28px;
                }
            }

            & > *:nth-child(6n) {
                grid-row: 3/5;
                grid-column: 2/3;
            }

            & > *:nth-child(6n + 1) {
                grid-row: 5/7;
                grid-column: 1/2;
            }

            & > *:nth-child(12n) {
                grid-row: 8/10;
                grid-column: 2/3;
            }

            & > *:nth-child(12n + 1) {
                grid-row: 1/3;
                grid-column: 1/2;
            }

            & > *:nth-child(n + 2):not(:nth-child(6n)):not(:nth-child(6n + 1)) {
                width: auto;
                height: ${props => props.heightPostItem ? `${props.heightPostItem / 2}px` : "auto"};
                .content{
                    padding: 45px 28px 0 28px;
                }

                &:nth-child(even) {
                }

                &:nth-child(odd) {
                }
            }
        }
    }   
    
`;

export {
    WrapPage
}