import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

//constant
import { POLICY } from '../../../assets/constant/pathNames';

const PrivacyPolicyChanges = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${POLICY}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('privacy_policy_changes.title_page')}</p>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t('privacy_policy_changes.date_page')}</div>
                </div> */}
                <div className="content-page">
                    <p className="content">Chúng tôi có thể thay đổi hoặc cập nhật Chính sách này tại từng thời điểm, và sẽ thông báo đến bạn, bằng các nỗi lực hợp lý về mặt thường mại, những thay đổi chinh thông qua thông báo trong fpa hoặc địa chỉ email đã đăng ký khi bạn mở Tài khoản Facepay.Tuy nhiên, bạn nên kiểm tra thường xuyên Chính sách này để đảm bảo rằng bạn luôn nắm được các thông tin cập nhật nhất với những thay đổi này. Chúng tôi sẽ đưa “Các thay đổi gần nhất” lên đầu của Chính sách này, kèm theo ngày nhiệu lực của những thay đổi.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(PrivacyPolicyChanges)
