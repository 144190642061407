import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';

// image
import IcCard from '../../assets/images/IcCard'
import IcLifeBouy from '../../assets/images/IcLifeBouy'
import IcMail from '../../assets/images/IcMail'
import IcShoppingBag from '../../assets/images/IcShoppingBag'
import IcUser from '../../assets/images/IcUser'
import IcUserPlus from '../../assets/images/IcUserPlus'
import IcArrowRight from '../../assets/images/IcArrowRight'

// styles
import { WrapPage } from './index.styles'

// component
import QAndAItem from './QAndAItem'
import PageSix from '../Home/PageSix'
import ContactPage from './ContactPage'

//constant
import * as pathNames from '../../assets/constant/pathNames'

// data
import QuestionAndAnswerData from '../../assets/data/QuestionAndAnswerData';
import IcMagic from '../../assets/images/IcMagic';

const Support = ({ t }) => {

    const [qAndAActiveKey, setQAndAActiveKey] = useState(null);

    return (
        <WrapPage>
            {/* page one */}
            <div className="page page-one">
                <div className="title">{t('support.page_one.title.text1')} <span>{t('support.page_one.title.text2')}</span> {t('support.page_one.title.text3')}</div>
                <div className="desc">{t('support.page_one.desc')}</div>
                <div className="list-support-method">
                    <div className="support-request">
                        <div>{t('support.page_one.contact.title')}</div>
                        <div className="contact-desc">{t('support.page_one.contact.desc')}</div>
                        <a className="link-email" href="mailto: cs@facepay.vn">
                            <button><IcMail className="ic-mail" />
                                <p>{t('support.page_one.contact.button_text')}</p>
                            </button>
                        </a>
                    </div>
                    <Link to={`/${pathNames.SUPPORT}/${pathNames.BASIC_INFOMATION}`}>
                        <IcCard className="icon" />
                        <div className="support-method-details">
                            <div className="support-title">{t('support.page_one.thong_tin.title')}</div>
                            <div className="support-desc">{t('support.page_one.thong_tin.desc')}</div>
                        </div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link to={`/${pathNames.SUPPORT}/${pathNames.SIGN_UP_ACCOUNT}`}>
                        <IcUserPlus className="icon" />
                        <div className="support-method-details">
                            <div className="support-title">{t('support.page_one.dang_ky.title')}</div>
                            <div className="support-desc">{t('support.page_one.dang_ky.desc')}</div>
                        </div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link to={`/${pathNames.SUPPORT}/${pathNames.ACCOUNT_MANAGEMENT}`}>
                        <IcUser className="icon" />
                        <div className="support-method-details">
                            <div className="support-title">{t('support.page_one.quan_ly.title')}</div>
                            <div className="support-desc">{t('support.page_one.quan_ly.desc')}</div>
                        </div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link to={`/${pathNames.SUPPORT}/${pathNames.PAYMENT_AT_COUNTER}`}>
                        <IcShoppingBag className="icon" />
                        <div className="support-method-details">
                            <div className="support-title">{t('support.page_one.thanh_toan.title')}</div>
                            <div className="support-desc">{t('support.page_one.thanh_toan.desc')}</div>
                        </div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link to={`/${pathNames.SUPPORT}/${pathNames.RESOLVE_COMPLAINT}`}>
                        <IcLifeBouy />
                        <div className="support-method-details">
                            <div className="support-title">{t('support.page_one.giai_quyet.title')}</div>
                            <div className="support-desc">{t('support.page_one.giai_quyet.desc')}</div>
                        </div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    {/* <Link to={`/${pathNames.SUPPORT}/${pathNames.FAQ_FACEPAY_MAGIC}`}>
                        <IcMagic />
                        <div className="support-method-details">
                            <div className="support-title">{t('support.page_one.facepay_magic.title')}</div>
                            <div className="support-desc">{t('support.page_one.facepay_magic.desc')}</div>
                        </div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link> */}
                </div>
            </div>
            {/* page two */}
            <div className="page page-two">
                <div className="title"><span>{t('support.page_two.title.text1')}</span> {t('support.page_two.title.text2')}</div>
                <div className="list-q-and-a">
                    {t('support.page_two.q_and_a', { returnObjects: true }).map(({ key, q, a }) => (
                        <QAndAItem
                            key={key}
                            uniqueKey={key}
                            ques={q}
                            ans={a}
                            link={QuestionAndAnswerData[key]}
                            isActive={key === qAndAActiveKey}
                            setActive={setQAndAActiveKey}
                        />
                    ))}
                </div>
            </div>
            {/* page three */}
            <ContactPage />
            <PageSix />
        </WrapPage>
    )
}

export default withNamespaces()(Support)
