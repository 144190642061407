import React from 'react'
import { withNamespaces } from 'react-i18next';

//style
import { WrapComponent } from './index.styles'

//image
import icon_vtb from '../../../assets/images/icon_vtb.png'
import logo_VTB_mobile from '../../../assets/images/LogoVTB/logo_VTB_mobile.png'
import logo_VTB_pc from '../../../assets/images/LogoVTB/logo_VTB_pc.png'

//component
import StepByStep from '../../Control/StepByStep';

//data
import { VietinBankData } from '../../../assets/data/VietinBankData';

const VietinBank = ({ t, refPage }) => {

    return (
        <WrapComponent ref={refPage}>
            <div className="bank-page-vtb">
                <div className='overlay' />
                <div className="block-left">
                    <img className="icon" src={icon_vtb} alt="icon-vtb" />
                </div>
                <div className="block-right">
                    <StepByStep
                        headLogo={{
                                mobile: logo_VTB_mobile,
                                laptop: logo_VTB_pc
                            }
                        }
                        headSubTitle={t('facePay_by_bank.vtb_bank.title')}
                        defaultActiveStep={VietinBankData[0].step}
                        lstStep={VietinBankData}
                    />
                </div>
            </div>
        </WrapComponent>
    )
}

export default withNamespaces()(VietinBank)
