import styled from 'styled-components'

const WrapFacePageByBank = styled.div`
    .wrap-home-page{
        background: #191919;
        .home-page{       
            height: 100vh;
            min-height: 788px;
            padding: calc(139px + (200 - 139) * ((100vw - 375px) / (768 - 375))) calc(32px + (100 - 32) * ((100vw - 375px) / (768 - 375))) 0;
            display: flex;
            flex-direction: column;
            position: relative;
            box-sizing: border-box;
            .content-page{   
                z-index: 1;
                .title-page{
                    font-family: 'SVN-Bebas Neue';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 74px;
                    line-height: 72px;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    width: 248px;
                    &.blue{
                        color: #1279DA;
                        padding: 10px 0 0 0;
                        width: 285px;
                    }
                }
                .text-page{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 21px;
                    color: #FFFFFF;
                    padding: 23px 0 0 0;
                    width: 90%;
                }
            }            
            .bg_image{
                position: absolute;
                bottom: 0;
                right: 0;
                img{
                    width: 768.6px;
                    height: 566.3px;
                }
            }
        }
    }
    
    @media only screen and (min-width: 540px){
        .wrap-home-page{
            .home-page{
                .content-page{   
                    .title-page{
                        width: unset;
                        &.blue{
                            width: unset;
                        }
                    }
                    .text-page{
                        width: 306px;
                    }
                }   
            }
        }
    }

    @media only screen and (min-width: 768px){
        .wrap-home-page{
            .home-page{
                /* max-width: 1200px; */
                height: 100vh;
                margin: 0 auto;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0 calc(32px + (150 - 32) * ((100vw - 768px) / (1440 - 768)));
                .content-page{
                    .title-page{
                        font-size: 102px;
                        line-height: 102px;
                        display: block;
                        width: 587px;
                        &.blue{
                            padding: 4px 0 0 0;
                            width: 716px;
                        }
                    }
                    .text-page{
                        font-size: 18px;
                        line-height: 23px;
                        width: 444px;
                        padding: 48px 0 0 0;
                    }
                }
                .bg_image{
                    bottom: 0;
                    right: 0;
                    img{
                        width: unset;
                        height: unset;
                    }
                }
            }              
        }
    }

    @media only screen and (min-width: 1440px){
        .wrap-home-page{
            .home-page{
                padding: 150px;
            }              
        }
    }
`;

export {
    WrapFacePageByBank
}