import React from 'react'
import { withNamespaces } from 'react-i18next';

//style
import { WrapComponent } from './index.styles'

//image
import icon_tpbank from '../../../assets/images/icon_tpbank.png'
import logo_tpbank_mobile from '../../../assets/images/LogoTPBank/logo_tpbank_mobile.png'
import logo_tpbank_pc from '../../../assets/images/LogoTPBank/logo_tpbank_pc.png'

//component
import StepByStep from '../../Control/StepByStep'

//data
import { TPBankData } from '../../../assets/data/TPBankData';

const TpBank = ({ t, refPage}) => {
    
    return (
        <WrapComponent ref={refPage}>
            <div className="bank-page-tpbank">
                <div className='overlay' />
                <div className="block-left">
                    <img className="icon" src={icon_tpbank} alt="icon-tpbank" />
                </div>
                <div className="block-right">
                    <StepByStep
                        headLogo={{
                                mobile: logo_tpbank_mobile,
                                laptop: logo_tpbank_pc
                            }
                        }
                        headSubTitle={t('facePay_by_bank.tp_bank.title')}
                        defaultActiveStep={TPBankData[0].step}
                        lstStep={TPBankData}
                    />
                </div>
            </div>
        </WrapComponent>
    )
}

export default withNamespaces()(TpBank)
