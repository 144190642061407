import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

//constant
import { POLICY } from '../../../assets/constant/pathNames';

const ShareAndDisclose = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${POLICY}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('fshare_and_disclose.title_page')}</p>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t('fshare_and_disclose.date_page')}</div>
                </div> */}
                <div className="content-page">
                    <p className="content">Một số tính năng và/ hoặc dịch vụ của Ứng dụng Facepay cần chúng tôi phải chia sẽ một số thông tin với bên thứ ba theo yêu cầu của bạn. Chúng tôi cũng có thể chia sẽ thông tin của bạn với các đối tác bên thứ ba của chúng tôi và/ hoặc công chúng khi bạn đưa nội dung đến một diễn đàn công khai, hoặc bởi lý do về pháp lý hoặc khi có tranh chấp. Ứng dụng Facepay sẽ không tiết lộ thông tin của bạn cho bất kỳ bên thứ ba nào mà không có sự đồng ý của bạn, trừ khi được quy định khác ở trong Chính sách này.</p>
                    <p className="content">Chúng tôi có thể chia sẽ các thông tin thu thập được khi:</p>
                    <p className="title-large">1. Khi bạn yêu cầu</p>
                    <p className="content">Điều này bao gồm việc chia sẽ thông tin của bạn với các đối tác của chúng tôi. Ví dụ, khi bạn yêu cầu truy cập vào hoặc sử dụng bất kỳ dịch vụ nào được cung cấp bởi bên thứ ba vh@ đối tác, chúng tôi có thể chia sẽ thông tin của bạn với các bên thứ ba vh@ đối tác này. Bên thứ ba vh@ đối tác có thể bao gồm các nhà cung cấp ứng dụng di động khác, các websites tích hợp vào IPA của chúng tôi hoặc Dịch vụ, vh@ các ứng dụng di động có API hoặc dịch vụ chúng tôi tích hợp vào fpa, vh@ các đối tác cung cấp các quảng cáo chuyên biệt, chương trình khuyến mãi, nội dung vh@ dịch vụ.</p>
                    <p className="title-large">2. Với cộng đồng khi bạn đưa nội dung lên một diễn đàn công khai</p>
                    <p className="content">Chúng tôi luôn lắng nghe ý kiến của tất cả người dùng, bao gồm thông qua các kên diễn đàn trực tiếp ví dụ như trang fanpage Facebook của chúng tôi. Khi bạn trao đổi với chúng tôi thông qua các kênh này, bất kỳ ai cũng có thể thấy được nội dung bạn đang trao đổi.</p>
                    <p className="title-large">3. Với các nhà cung cấp dịch vụ hoặc đối tác của chúng tôi</p>
                    <p className="content">Chúng tôi có thể các thông tin đến các nhà cung cung dịch vụ của chúng tôi, đơn vị tư vấn, đơn vị truyền thông, đơn vị nghiên cứu vh@ các đối tác khác (“Đối tác”). Ví dụ, chúng tôi có thể cung cấp thông tin cho các đơn vị nhằm hỗ trợ chúng tôi trong việc thực hiện khảo sát và vận hành Dịch vụ vh@ fpa. Thông tin được chia sẽ bao gồm thông tin từ các công cụ ví dụ như cookies, log file vh@ định vị thiết bị. Nhà cung cấp dịch vụ của chúng tôi và đối tác sẽ có thể trung cập vào các thông ở mức độ phù hợp cần thiết để thực hiện dịch vụ cho chúng tôi và đảm bảo tuân thủ các nguyễn tắt bảo mật.</p>
                    <p className="content">Chúng tôi có thể xóa một phần các dữ liệu nào mà người dùng có thể được định danh và chia sẽ dữ liệu với đối tác ở định dạn không định danh. Chúng tôi có thể kết hợp thông tin của bạn với các thông khác để các thông tin được chia sẽ không còn liên kết với bạn và chia sẽ các thông tin này cho Đối tác.</p>
                    <p className="title-large">4. Vì lý do pháp lý hoặc khi có tranh chấp</p>
                    <p className="content">Chúng tôi có thể chia sẽ thông tin của bạn khi có yêu cầu của các cơ quan chức năng theo pháp luật, cụ thể:</p>
                    <p className="note">a) Chúng tôi chia sẽ thông tin của bạn với các cơ quan chức năng, cơ quan chinh phủ vh@ các bên thứ ba khác cần thiết để đảm bảo tính tuân thủ của các Điều khoản chung, Chính sách này vh@ các chính sách liên quan, nhằm bảo vệ quyền vh@ tài sản của Wee Digital, các Đối tác, Người dùng vh@ các cá nhân liên quan khác, hoặc trong trường hợp phát sinh tranh chấp liên quan đến việc sử dụng DỊch vụ vh@ fpa (hoặc một phần trong đó).</p>
                    <p className="note">b) Chúng tôi có thể tiếp cận, bảo vệ, và chia sẽ các thông tin được thu thập nếu chúng tôi có đủ cơ sở để tin rằng các hành động này là cần thiết để: (a) phát hiện, ngăn chặn, và giải quyết gian lận và các hành động phi pháp; (b) bảo vệ người dùng; chúng tôi và các cá nhân liên quan khác bao gồm như một phần của cuộc điều tra; và (c) phong tránh các thiệt hại về tài sản, tính mạng hoặc thương tật. Ví dụ, nếu bạn đang sử dụng thẻ của một người khác, chúng tôi có thể chia sẽ thông tin về chủ thể, bao gồm thông tin về giao dịch; và</p>
                    <p className="note">c) Chúng tôi cũng có thể chia sẽ thông tin củau bạn với các bên khác liên quan đến hoặc trong quá trình đàm phan mua bán sáp nhập, tái cấu trúc, tài chinh.</p>
                    <p className="title-large">5. Khi bạn đồng ý</p>
                    <p className="content">Trư khi có quy định khác trong Chính sách này, chúng tôi có thể chia sẽ thông tin của bạn cho các mục đích khác như đã trao đồi với bạn tại từng thời điểm và nhận được sự đồng ý.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(ShareAndDisclose)
