import React, { useRef, useState, useEffect, useReducer, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from 'react-i18next';
import InputTextControl from '../InputTextControl';
import NumberFormat from 'react-number-format'
//style
import { WrapPage } from './index.styles'
import { formRegister } from '../../../redux/formRegister/action';

const titleErrEmptyData = "Thông tin không được để trống"

const Registration = ({ t }) => {

    const regisPage = useRef()

    const dispatch = useDispatch()

    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorName, setErrorName] = useState('')
    const [errorCompanyName, setErrorCompanyName] = useState('')
    const [errorPhoneNumber, setErrorPhoneNumber] = useState('')
    const [errorEmail, setErrorEmail] = useState('')

    const { code } = useSelector(infos => ({
        code: infos.formRegister.code
    }))

    useEffect(() => {
        if (code === 0) {
            setIsSuccess(true)
            regisPage.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
        } else if (code === 43) {
            setErrorPhoneNumber("Số điện thoại không hợp lệ")
        } else if (code === 44) {
            setErrorEmail("Email không hợp lệ")
        }
    }, [code])


    const [infos, setInfos] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            name: "",
            companyName: "",
            phoneNumber: "",
            email: ""
        }
    )

    const _handleOnChange = useCallback(
        (state) => (e) => {
            const { value } = e.target;
            if (state === "name") {
                setErrorName("")
            }
            if (state === "companyName") {
                setErrorCompanyName("")
            }
            if (state === "phoneNumber") {
                setErrorPhoneNumber("")
            }
            if (state === "email") {
                setErrorEmail("")
            }
            // const newState = value.trimStart().replace(/\s+/g, " ")
            setInfos({ [state]: value })
        },
        [],
    )

    const SubmitFormSuccess = () => {
        // console.log("success")
        setInfos({
            name: "",
            companyName: "",
            phoneNumber: "",
            email: ""
        })
    }

    const SubmitFormError = () => {
        // console.log("error")
    }

    const _handleSentInfoCustomer = () => {

        let isChecked = true

        setErrorName("")
        setErrorCompanyName("")
        setErrorPhoneNumber("")
        setErrorEmail("")

        if (!infos.name.trim() || infos.name.length === 0) {
            setErrorName(titleErrEmptyData)
            isChecked = false
        }
        if (!infos.companyName.trim() || infos.companyName.length === 0) {
            setErrorCompanyName(titleErrEmptyData)
            isChecked = false
        }
        if (!infos.phoneNumber.trim() || infos.phoneNumber.length === 0) {
            setErrorPhoneNumber(titleErrEmptyData)
            isChecked = false
        }
        if (!infos.email.trim() || infos.email.length === 0) {
            setErrorEmail(titleErrEmptyData)
            isChecked = false
        }

        if (isChecked === true) {
            const dataRegister = {
                representativeName: infos.name.trim().replace(/\s+/g, " "),
                businessName: infos.companyName.trim().replace(/\s+/g, " "),
                phoneNumber: infos.phoneNumber.trim(),
                email: infos.email.trim().replace(/\s+/g, " "),
            };
            // console.log(dataRegister);
            dispatch(formRegister(dataRegister, SubmitFormSuccess, SubmitFormError))
        }
    }

    return (
        <WrapPage ref={regisPage}>
            {
                isSuccess === false ?
                    <div className="registratione-page" >
                        <div className="title-page">
                            <span>{t('partner.registration.no')}</span> {t('partner.registration.cash')}, <span>{t('partner.registration.no')}</span> {t('partner.registration.card')}, <span>{t('partner.registration.no')}</span> {t('partner.registration.phone')}
                        </div>
                        <div className="text-wrap">
                            {t('partner.registration.mang_den')}
                        </div>
                        <div className="input-wrap">
                            <p className="title-input">{t('partner.registration.ten_cua_ban')}</p>
                            <InputTextControl
                                placeholder="Tên người liên hệ"
                                value={infos.name}
                                onChange={_handleOnChange("name")}
                                isError={errorName.length === 0 ? false : true}
                            />
                            <p className="error">{errorName}</p>
                        </div>
                        <div className="input-wrap">
                            <p className="title-input">{t('partner.registration.ten_doanh_nghiep')}</p>
                            <InputTextControl
                                className="input-number"
                                placeholder="Tên doanh nghiệp"
                                value={infos.companyName}
                                onChange={_handleOnChange('companyName')}
                                isError={errorCompanyName.length === 0 ? false : true}
                            />
                            <p className="error">{errorCompanyName}</p>
                        </div>
                        <div className="input-wrap">
                            <p className="title-input">{t('partner.registration.so_dien_thoai')}</p>
                            <NumberFormat
                                className={errorPhoneNumber.length === 0 ? 'input-number' : "input-number err"}
                                type={'tel'}
                                placeholder="+84 xxx xxx xxx"
                                // onValueChange={Objvalue => setInfos({ phoneNumber: Objvalue.value })}
                                onChange={_handleOnChange("phoneNumber")}
                                allowNegative={false}
                                allowLeadingZeros={true}
                                value={infos.phoneNumber}
                                isAllowed={values => {
                                    const isValid = !values.value.includes('.')
                                    return isValid
                                }}
                            />
                            <p className="error">{errorPhoneNumber}</p>
                        </div>
                        <div className="input-wrap">
                            <p className="title-input">{t('partner.registration.email')}</p>
                            <InputTextControl
                                placeholder="Email liên hệ của bạn"
                                value={infos.email}
                                onChange={_handleOnChange("email")}
                                isError={errorEmail.length === 0 ? false : true}
                            />
                            <p className="error">{errorEmail}</p>
                        </div>
                        <div className="button-control" onClick={_handleSentInfoCustomer}>
                            {t('partner.registration.buoc_vao')}
                        </div>
                    </div>
                    :
                    <div className="thanks-page">
                        <p className="title">{t('partner.registration.thanks_you')}</p>
                        <p className="content">{t('partner.registration.cam_on_ban')}</p>
                    </div>
            }

        </WrapPage>
    )
}

export default withNamespaces()(Registration)
