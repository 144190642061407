import React from 'react'

const IcTarget = (props) => {
    return (
        <svg {...props} width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.5 24C42.5 34.2173 34.2173 42.5 24 42.5V45.5C35.8741 45.5 45.5 35.8741 45.5 24H42.5ZM24 42.5C13.7827 42.5 5.5 34.2173 5.5 24H2.5C2.5 35.8741 12.1259 45.5 24 45.5V42.5ZM5.5 24C5.5 13.7827 13.7827 5.5 24 5.5V2.5C12.1259 2.5 2.5 12.1259 2.5 24H5.5ZM24 5.5C34.2173 5.5 42.5 13.7827 42.5 24H45.5C45.5 12.1259 35.8741 2.5 24 2.5V5.5ZM26.5 24C26.5 25.3807 25.3807 26.5 24 26.5V29.5C27.0376 29.5 29.5 27.0376 29.5 24H26.5ZM24 26.5C22.6193 26.5 21.5 25.3807 21.5 24H18.5C18.5 27.0376 20.9624 29.5 24 29.5V26.5ZM21.5 24C21.5 22.6193 22.6193 21.5 24 21.5V18.5C20.9624 18.5 18.5 20.9624 18.5 24H21.5ZM24 21.5C25.3807 21.5 26.5 22.6193 26.5 24H29.5C29.5 20.9624 27.0376 18.5 24 18.5V21.5Z" fill="#1279DA" />
            <path d="M34.5 24C34.5 29.799 29.799 34.5 24 34.5V37.5C31.4558 37.5 37.5 31.4558 37.5 24H34.5ZM24 34.5C18.201 34.5 13.5 29.799 13.5 24H10.5C10.5 31.4558 16.5442 37.5 24 37.5V34.5ZM13.5 24C13.5 18.201 18.201 13.5 24 13.5V10.5C16.5442 10.5 10.5 16.5442 10.5 24H13.5ZM24 13.5C29.799 13.5 34.5 18.201 34.5 24H37.5C37.5 16.5442 31.4558 10.5 24 10.5V13.5Z" fill="#191919" />
        </svg>
    )
}

export default IcTarget
