import React from 'react'

const IcFacebook = (props) => {
    return (
        <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 16C32 7.16253 24.8375 0 16 0C7.16253 0 0 7.16253 0 16C0 23.9875 5.85 30.6063 13.5 31.8063V20.625H9.43747V16H13.5V12.475C13.5 8.4656 15.8875 6.25 19.5437 6.25C21.2937 6.25 23.125 6.5625 23.125 6.5625V10.5H21.1063C19.1187 10.5 18.5 11.7344 18.5 13V16H22.9375L22.2281 20.625H18.5V31.8063C26.15 30.6063 32 23.9875 32 16Z" fill="url(#ic_facebook)" />
            <path d="M22.2281 20.625L22.9374 16H18.5V13C18.5 11.7344 19.1187 10.5 21.1062 10.5H23.125V6.5625C23.125 6.5625 21.2937 6.25 19.5437 6.25C15.8875 6.25 13.5 8.4656 13.5 12.475V16H9.43744V20.625H13.5V31.8063C14.3156 31.9344 15.15 32 16 32C16.85 32 17.6844 31.9344 18.5 31.8063V20.625H22.2281Z" fill="white" />
            <defs>
                <linearGradient id="ic_facebook" x1={32} y1="2.83828e-07" x2="31.3944" y2="38.0866" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFFF" />
                    <stop offset="0.651042" stopColor="#005BEC" />
                </linearGradient>
            </defs>
        </svg>


    )
}

export default IcFacebook
