import styled from 'styled-components'

const WrapPage = styled.div`
    --max-width: 1200px;
    padding-top: 123px;
    font-family: 'SVN-Gilroy';
    background-image: -webkit-linear-gradient(top, #F7FCFF, #F7FCFF 21.5%, transparent 21.5%, transparent 100%);

    .page {
        padding: 0 24px;

        .title {
            margin: 23px 15px 0 15px;
            color: #304659;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 39px;
            text-align: center;

            span {
                color: #1279DA;
            }
        }

        &.page-one {
            padding-bottom: 0px;
            /* height: 100vh; */
            min-height: 788px;
            .desc {
                margin: 31px 0 0 0;                
                font-style: normal;
                font-weight: normal;
                color: #304659;
                text-align: center;
                font-size: 15px;
                line-height: 21px;
            }

            .list-support-method {
                margin: 36px 0 0 0;

                & > * {
                    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
                    border-radius: 12px;
                    padding: 21px 8px 19px 20px;
                    /* width: calc(var(--max-width) / 4 - 90px); */
                }

                & > *:not(:first-child) {
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
                    background: #ffffff;
                    cursor: pointer;
                    text-decoration: none;
                    & > * {
                        flex-shrink: 0;
                    }
                    .icon{
                        width: 40px;
                        height: 40px;
                    }
                    .support-method-details {
                        flex-grow: 1;
                        margin-left: 19px;
                        flex-basis: 30%;

                        .support-title {
                            font-style: normal;
                            font-weight: bold;
                            color: #304659;
                            font-size: 15px;
                            line-height: 21px;
                        }

                        .support-desc {
                            font-style: normal;
                            font-weight: normal;
                            color: #939393;
                            line-height: 130%;
                            font-size: 12px;
                            margin-top: 6px
                        }
                    }

                    .ic-arrow-right {
                        margin-left: 19px;
                    }

                }

                .support-request {
                    background: #1279DA;
                    min-height: 170px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    box-sizing: border-box;
                    & > * {
                        flex-shrink: 0;
                    }

                    & > *:first-child {
                        font-style: normal;
                        font-weight: bold;
                        color: #FFFFFF;
                        font-size: 15px;
                        line-height: 21px;
                        width: 100%;
                    }

                    .contact-desc {
                        margin-top: 10px;
                        flex-grow: 1;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 16px;
                        color: #ffffffa3;
                        width: 100%;
                    }
                    .link-email{
                        display: flex;
                        justify-content: center;
                        text-decoration: none;
                        button {
                            border: none;
                            outline: none;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-height: 38px;
                            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
                            border-radius: 38px;
                            background: #ffffff;
                            font-family: 'SVN-Gilroy';
                            font-style: normal;
                            font-weight: bold;
                            color: #1279DA;
                            font-size: 16px;
                            line-height: 22px;
                            width: 148px;
                            p{
                                padding-top: 3px;     
                            }
                            .ic-mail {
                                margin-right: 6px;
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        &.page-two {
            height: 100%;
            padding: 53px 14px 61px 14px;
            box-sizing: border-box;
            .title {
               display: flex;
               flex-direction: column;
            }

            .list-q-and-a {
                margin-top: 49px;

                & > *:not(:first-child) {
                    /* margin-top: 8px; */
                }
            }
        }
    }

    /* @media only screen and (min-width: 645px) {
        .page {
            .title {
                
            }
            
            &.page-one {
                padding-bottom: 50px;
                .desc {
                    
                }
                
                .list-support-method {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 30px;
                    grid-row-gap: 17px;
                    .support-request {
                        .contact-desc {
                            margin-top: 10px;
                        }
                        .link-email{
                            margin-top: 17px;
                        }
                    }
                }
            }
        }
    } */

    @media only screen and (min-width: 645px) {
        padding-top: 85px;
        .page {
            max-width: var(--max-width);
            margin: 0 auto;

            .title {
                margin: unset;
                font-size: 50px;
                line-height: 52px;
            }
            
            &.page-one {
                /* height: 100vh; */
                padding: 30px 24px 50px 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .desc {
                    margin-top: 33px;
                    font-size: 16px;
                    line-height: 23px;
                }
                
                .list-support-method {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    /* grid-template-rows: repeat(2, auto); */
                    grid-column-gap: 30px;
                    grid-row-gap: 17px;
                    margin: 86px 0 0 0;

                    & > * {
                        padding: 24px 25px 21px;
                    }

                    & > *:not(:first-child) {
                        margin-top: 0;
                        display: block;
                        .icon{
                            width: 48px;
                            height: 48px;
                        }
                        .support-method-details {
                            margin-left: 0;
                            .support-title {
                                margin-top: 19px;
                                font-size: 22px;
                                line-height: 26px;
                            }

                            .support-desc {
                                margin-top: 12px;
                                font-size: 13px;
                                line-height: 16px;
                            }

                        }

                        .ic-arrow-right {
                            display: none;
                        }
                    }

                    .support-request {
                        grid-row: 1/-1;
                        padding: 33px 24px 28px;
                        align-items: stretch;

                        & > *:first-child {
                            font-size: 22px;
                            line-height: 26px;
                        }

                        .contact-desc {
                            margin-top: 10px;
                        }
                        .link-email{
                            button {
                                min-height: 40px;
                                font-size: 16px;
                                line-height: 23px;
                                width: 214px;
                                margin-top: 17px;
                                .ic-mail {
                                    margin-right: 6px;
                                }
                            }
                        }
                    }
                }
            }

            &.page-two {
                /* justify-content: unset;
                min-height: unset; */
                padding: 0 0 75px 0;
                max-width: var(--max-width);
                margin: 0 auto;
                .title {
                   flex-direction: row;
                   justify-content: center;
                   span{
                       margin-right: 10px;
                   }
                }

                .list-q-and-a {
                    margin-top: 54px;

                    & > *:not(:first-child) {
                        /* margin-top: 6px; */
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 953px) {
        .page {
            .title {
                
            }
            
            &.page-one {
                padding-bottom: 50px;
                .desc {
                    
                }
                
                .list-support-method {
                    grid-template-columns: repeat(3, 1fr);
                    .support-request{
                        grid-column: 1 / span 1;
                        grid-row: 1 / span 3;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1260px) {
        .page {
            .title {
                
            }
            
            &.page-one {
                padding-bottom: 0;
                .desc {
                    
                }
                
                .list-support-method {
                    grid-template-columns: repeat(4, 1fr);
                    .support-request{
                        grid-column: 1 / span 1;
                        grid-row: 1 / span 2;
                    }
                }
            }
        }
    }
`;

export {
    WrapPage
}