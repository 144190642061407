import styled from 'styled-components'

const WrapPage = styled.div`
    font-family: 'SVN-Gilroy';
    color: #191919;
    .page {
        padding: 0 24px 49px;
        box-sizing: border-box;
        margin: 0 auto;
        &.page-first {
            margin: 142px auto 0;
        }
        .go-back {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 130%;
            color: #1279DA;
            text-decoration: none;
        }
        .title {
            margin-top: 14px;
            font-style: normal;
            font-weight: bold;
            font-size: 38px;
            line-height: 40px;
            color: #191919;
        }
        .wrap-share-date {
            margin-top: 26px;
            display: flex;
            flex-direction: column;
            .wrap-left{
                display: flex;
                /* align-items: center; */
                font-family: "SVN-Gilroy";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: #191919;
                button {
                    height: 20px;
                    border: none;
                    outline: none;
                    background: #395CA5;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 130%;
                    color: #F7FCFF;
                    display: flex;
                    align-items: center;
                    padding: 3px 7px;
                    box-sizing: border-box;
                    .ic-share {
                        height: 100%;
                        border-right: 1px solid #ffffff4d;
                        padding-right: 5px;
                        margin-right: 5px;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .place{
                    font-weight: normal;
                    color: #1279DA;
                    margin: 0 10px
                }
                .date{
                    color: #191919;
                }
            }
            .wrap-right{
                display: flex;
                /* align-items: center; */
                /* margin-top: 20px; */
                .icon-clock{
                    margin-right: 15px;
                }
            }
        }
        .line{
            border: 0.5px solid rgba(147, 147, 147, 0.5);
            margin: 43px 0 0 0;
        }
        .wrap-content {
            margin-top: 40px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            color: #191919;
            .content {                
                &:not(:first-child) {
                    margin-top: 20px;
                }
            }
            .line-dashed{
                border: 0.5px dashed #939393;
                margin: 20px 0 0 0;
            }
            .wrap-mail{
                margin-top: 20px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                color: #191919;                
                .mail{
                    /* padding-left: 5px; */
                    color: #1279DA;
                    text-decoration: none;
                }
            }
        } 
    }

    @media only screen and (min-width: 768px){
        .page {
            max-width: 1200px;
            padding: 0 0 82px 0;
            .wrap-share-date{
                flex-direction: row;
                justify-content: space-between;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 130%;
                color: #191919;
            }
        }
    }
`
export { WrapPage }