import styled from 'styled-components'
import bg_home from '../../assets/images/bg_home.png'

const WrapPage = styled.div`
    display: flex;
    flex-direction: column;
    .background-container{       
        /* background: linear-gradient(163.44deg, #B8E2FF 5.43%, #C6B3FF 96.29%); */
        
        .background-wrap{          
            .background-left{
                min-height: 100vh;
                position: relative;
                background-image: url(${bg_home});
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                .title-page{
                    position: absolute;
                    left: calc(34px + (96 - 34) * ((100vw - 375px) / (768 - 375)));
                    top: 50%;
                    transform: translate(0, -50%);
                }
                .lottie{
                    position: absolute;
                    bottom: -4px;
                    right: 0px;                  
                    img{
                        height: 444px;
                    }
                }
            }
            .background-right{
                background: #ffffff;
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
            }  
        }
    }

    @media only screen and (min-width: 768px){               
        .background-container{   
            height: fit-content;        
            .background-wrap{
                display: grid;
                grid-template-columns: 1fr calc(384px + (690 - 384) * ((100vw - 768px) / (1440 - 768)));
                min-height: 100vh;
                margin: 0 auto;
                .background-left{
                    height: 100%;
                    .title-page{
                        position: absolute;
                        left: calc(34px + (96 - 34) * ((100vw - 768px) / (1440 - 768)));
                    }
                    .lottie{                       
                        img{
                            height: 674px;
                        }
                    }
                }
                .background-right{
                    padding-top: 124px;
                    padding-bottom: 50px;
                    padding-left: calc(32px + (130 - 32) * ((100vw - 768px) / (1440 - 768)));
                    padding-right: calc(32px + (116 - 32) * ((100vw - 768px) / (1440 - 768)));
                    height: calc(100% - 124px - 50px);
                    display: unset;
                }  
            }
        }
    }
    @media only screen and (min-width: 1440px){               
        .background-container{           
            .background-wrap{
                grid-template-columns: 1fr 690px;
                .background-left{
                    .title-page{
                        left: 96px;
                    }
                }
                .background-right{
                    padding-left: 130px;
                    padding-right: 116px;
                }  
            }
        }
    }
`;

export {
    WrapPage
}