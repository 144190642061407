import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'


//constant
import { POLICY } from '../../../assets/constant/pathNames';

const ScopeOfApplication = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${POLICY}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('scope_of_application.title_page')}</p>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t('scope_of_application.date_page')}</div>
                </div> */}
                <div className="content-page">
                    <p className="content">Chính sách này được áp dụng cho tất cả các đối tượng cung cấp thông tin cho Wee Digital thông qua các hình thức gọi điện thoại, ứng dụng di động (bao gồm ứng dụng Facepay), websites, tài khoản mạng xã hội, các phương thức truyền thông và tất cả các kênh phương tiệnkhác.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(ScopeOfApplication)
