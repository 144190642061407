import React from 'react'

const IcGlobe = (props) => {
    return (
        <svg {...props} width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 24L33.4997 24.0312C33.5001 24.0104 33.5001 23.9896 33.4997 23.9688L32 24ZM16 24L14.5003 23.9688C14.4999 23.9896 14.4999 24.0104 14.5003 24.0312L16 24ZM4 25.5H44V22.5H4V25.5ZM22.8925 5.01163C27.6498 10.2199 30.3534 16.9788 30.5003 24.0312L33.4997 23.9688C33.3376 16.1893 30.3553 8.73354 25.1075 2.98837L22.8925 5.01163ZM30.5003 23.9688C30.3534 31.0212 27.6498 37.7801 22.8925 42.9884L25.1075 45.0116C30.3553 39.2665 33.3376 31.8107 33.4997 24.0312L30.5003 23.9688ZM25.1075 42.9884C20.3502 37.7801 17.6466 31.0212 17.4997 23.9688L14.5003 24.0312C14.6624 31.8107 17.6447 39.2665 22.8925 45.0116L25.1075 42.9884ZM17.4997 24.0312C17.6466 16.9788 20.3502 10.2199 25.1075 5.01163L22.8925 2.98837C17.6447 8.73354 14.6624 16.1893 14.5003 23.9688L17.4997 24.0312ZM42.5 24C42.5 34.2173 34.2173 42.5 24 42.5V45.5C35.8741 45.5 45.5 35.8741 45.5 24H42.5ZM24 42.5C13.7827 42.5 5.5 34.2173 5.5 24H2.5C2.5 35.8741 12.1259 45.5 24 45.5V42.5ZM5.5 24C5.5 13.7827 13.7827 5.5 24 5.5V2.5C12.1259 2.5 2.5 12.1259 2.5 24H5.5ZM24 5.5C34.2173 5.5 42.5 13.7827 42.5 24H45.5C45.5 12.1259 35.8741 2.5 24 2.5V5.5Z" fill="#1279DA" />
        </svg>
    )
}

export default IcGlobe
