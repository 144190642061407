import * as pathNames from '../constant/pathNames'

const QuestionAndAnswerData = {
    "1": `/${pathNames.SUPPORT}/${pathNames.BASIC_INFOMATION}/q1`,
    "2": `/${pathNames.SUPPORT}/${pathNames.BASIC_INFOMATION}/q10`,
    "3": `/${pathNames.SUPPORT}/${pathNames.SIGN_UP_ACCOUNT}/${pathNames.FACEPAY}/q1`,
    "4": `/${pathNames.SUPPORT}/${pathNames.BASIC_INFOMATION}/q12`,
    "5": `/${pathNames.SUPPORT}/${pathNames.PAYMENT_AT_COUNTER}/q10`,
    "6": `/${pathNames.SUPPORT}/${pathNames.RESOLVE_COMPLAINT}/${pathNames.FACEPAY}/q1`,
}

export default QuestionAndAnswerData