import styled from 'styled-components'

const WrapHeader = styled.div`
    position: relative;
    z-index: 4;

    .header-desktop {
        display: none;
    }

    .header-mobile {
        position: fixed;
        width: 100vw;
        display: flex;
        flex-direction: column;
        top: ${props => props.isShowHeader ? 0 : '-100vh'};
        background-color: ${props => props.changeBgColor ? '#fff' : 'transparent'};
        transition: background-color 0.3s ease-in-out;
        &.active {
            .wrap-logo {
                background-color: #ffffff;             
            }

            .wrap-menu {
                height: calc(100vh - 85px);
                background-color: #ffffff;
                .inner-menu {
                    visibility: visible;
                    transform: translateY(0);
                }
            }
        }

        .wrap-logo {
            min-height: ${props => props.changeBgColor ? 85 : 100}px;;
            box-sizing: border-box;
            padding: 0 32px 0 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            z-index: 1;
            background-color: rgba(0,0,0,0);
            transition: min-height 0.3s ease-in-out;
        }

        .wrap-menu {
            width: 100%;
            height: 0;
            background-color: rgba(0,0,0,0);
            transition: background-color 0.2s ease-in-out, height 0.2s ease-in-out;

            .inner-menu {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: "SVN-Gilroy";
                font-style: normal;
                font-weight: normal;
                font-size: 32px;
                line-height: 40px;
                flex-grow: 1;
                visibility: hidden;
                transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
                transform: translateY(20px);
                & > a {
                    margin-bottom: 40px;
                    display: block;
                    text-decoration: none;
                    color: #000000;

                    &:active {
                        color: inherit;
                    }

                    &:visited {
                        color: inherit;
                    }
                }
            }
        }
    }
    /* @media only screen and (min-width: 1063px) {


    } */

    @media only screen and (min-width: 1063px) {
        .header-desktop {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 43px 0 43px;
            min-height: ${props => props.changeBgColor ? 85 : 100}px;
            position: fixed;
            width: 100vw;
            box-sizing: border-box;
            top: ${props => props.isShowHeader ? 0 : '-100vh'};
            transition: min-height  0.3s ease-in-out, background-color 0.3s ease-in-out;
            background-color: ${props => props.changeBgColor ? '#fff' : 'transparent'};

            .inner-header-desktop {
                max-width: 1200px;
                width: 100vw;
                display: flex;
                align-items: center;
                justify-content: space-between;
                top: ${props => props.isShowHeader ? 0 : '-100vh'};
                transition: top 0.4s ease-in-out;

                & > img {
                    max-height: 36px;
                }

                .wrap-menu {
                    display: flex;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 24px;
                    color: #000000;
                    position: relative;
                    & > *:not(:first-child) {
                        margin-left: 26px;
                    }

                    & > * {
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    .navigation-routes-link{
                        text-decoration: none;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 24px;
                        color: #939393;
                        /* .home{
                            color: #fff;
                        }                    */
                        &.active {
                            font-weight: bold;
                            font-size: 18px;
                            line-height: 24px;
                            color: #304659;
                            .facepay-bank{
                                color: ${props => props.changeBgColor ? 'unset' : '#fff'};
                            }                            
                        }  
                        .menu-item{
                            position: relative;
                            /* padding: 4px 0; */
                            .line{
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                height: 2px;
                                width: 0%;
                                transition: width  0.3s ease-in-out;
                                background: #1279DA;
                                &.active{
                                    width: 100%;
                                }
                            }
                            &:hover {
                                .facepay-bank{
                                    visibility: visible;
                                }
                            }
                        }                        
                    }
                    .wrap-popup{
                        background: #FFFFFF;
                        border-radius: 5px;
                        position: absolute;
                        top: 25px;
                        left: -5px;
                        visibility: hidden;
                        .item{
                            background: #FFFFFF;
                            box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
                            padding: 12px 23px 12px 12px;
                            font-family: 'SVN-Gilroy';
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 23px;
                            color: #191919;
                            display: flex;
                            align-items: center;
                            :first-of-type{
                                border-radius: 5px 5px 0 0 ;
                            }
                            :last-child{
                                border-radius: 0 0 5px 5px;
                            }
                            .icon{
                                margin-right: 5px;
                                height: 26px;
                            }
                        }
                    }
                }
            }
        }

        .header-mobile {
            display: none;
        }
    }
`;

export {
    WrapHeader
}