import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

//constant
import { POLICY } from '../../../assets/constant/pathNames';


const FacepayPurposes = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${POLICY}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('facepay_purposes.title_page')}</p>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t('facepay_purposes.date_page')}</div>
                </div> */}
                <div className="content-page">
                    <p className="content">Chúng tôi sử dụng các thông tin được thu thập để:</p>
                    <p className="note">a) Cung cấp Dịch vụ, ỨNG DỤNG FACEPAY và/ hoặc bất kỳ tính năng nào đến bạn;</p>
                    <p className="note">b) Nâng cấp tính bảo mật của ỨNG DỤNG FACEPAY và Giao dịch;</p>
                    <p className="note">c) Cung cấp cho bạn các hỗ trợ cần thiết và tiện lợi hơn khi liên lạc đến chúng tôi;</p>
                    <p className="note">d) Nghiên cứu và phát triển Dịch vụ, Ứng dụng Facepay (hoặc một phần nào) và/ hoặc cách thức vận hành của chúng tôi;</p>
                    <p className="note">e) Đưa đến cho bạn những chương trình quảng cáo, khuyến mãi, khảo sát và/ hoặc nội dung, bao gồm các quảng cáo chuyên biệt, và/ hoặc nâng cao trải nghiệm người dùng; và</p>
                    <p className="note">f) Hoàn thành các nghĩa vụ theo quy định của pháp luật.</p>
                    <p className="content">Để tránh nhầm lẫn, chúng tôi không chia sẽ thông tin cá nhân của bạn với bất kỳ bên thứ ba nào khác cho bất kỳ mục đích thường mại ngoại trừ các trường hợp đã nêu trong Chính sách và/ hoặc Điều khoản chung.</p>
                    <p className="title-large">1. Cung cấp Dịch vụ, Ứng dụng Facepay và/ hoặc các tính năng liên quan</p>
                    <p className="content">Chúng tôi sử dụng thông tin thu thập được để cung cấp, cá nhân hóa, duy trì và nâng cấp Dịch vụ, Ứng dụng Facepay và/ hoặc các tính năng, bao gồm:</p>
                    <p className="note">a) Xác thực và xác nhận thanh toán cho Giao dịch của bạn tại điểm mua hàng hóa/ dịch vụ;</p>
                    <p className="note">b) Ghi nhớ thông tin của bạn giúp các lần đăng nhập tiếp theo được dễ dàng hơn khi sử dụng Dịch vụ, Ứng dụng Facepay, và/ hoặc các tính năng;</p>
                    <p className="note">c) Cung cấp các tính năng giúp bạn chia sẽ thông tin với ngườk khác, ví dụ như khi bạn cần đưa đánh giá đến điểm bán hàng, hoặc khi giới thiệu bạn bè sử dụng Dịch vụ và/ hoặc Ứng dụng Facepay</p>
                    <p className="note">d) Cung cấp các tính năng giúp cá nhân hóa Tài khoản Facepay của bạn; và</p>
                    <p className="note">e) Cập nhật Ứng dụng Facepay, sửa lỗi phần mềm, lỗi vận hành, phân tích, kiểm tra và nghiên cứu.</p>
                    <p className="title-large">2. Tính an toàn và Bảo mật</p>
                    <p className="content">Chúng tôi sử dụng dữ liệu và thông tin mà bạn cung cấp cho chúng tôi nhằm giúp duy trì sự an toàn, tính bảo mật và trung thực của Ứng dụng Facepay và/ hoặc Dịch vụ. Ví dụ: Chúng tôi thu thu thập và nắm thông tin liên quan đến Giao dịch từ thiết bị và Tải khoản Facepay của bạn nhằm xác định các hành vi giao dịch nghi ngờ giả mạo. Ngoài ra, chúng tôi sử dụng tên, số điện thoại, số CMND/CCCD của bạn cung cấp giúp chúng tôi so sánh với các thông tin trên thẻ mà bạn muốn liên kết với Tài khoản Facepay của bạn. Điều này giúp đảm bảo các thông tin trên thẻ được sử dụng sẽ trung khớp với thông tin mà bạn đã đăng ký với Tài Khoản Facepay, giúp tránh giả mạo, lừa đảo và các hành vi phạm pháp khác, bảo vệ bạn cũng như các người dùng khác.</p>
                    <p className="title-large">3. Chăm sóc khác hàng</p>
                    <p className="content">Chúng tôi sử dụng các thông tin thu thập được (bao gồm bản ghi âm cuộc gọi tổng đài, sau khi đã thông báo và được bạn chấp nhận) nhằm hỗ trợ bạn khi bạn liên hệ với chúng tôi qua các kênh liên lạc được cung cấp, bao gồm Chăm sóc khách hàng, cụ thể để:</p>
                    <p className="note">a) Chuyển câu hỏi của bạn và/ hoặc yêu cầu của bạn đến đúng bộ phận xử lý;</p>
                    <p className="note">b) Tìm hiểu và giải quyết các câu hỏi và/ hoặc yêu cầu của bạn; và</p>
                    <p className="note">c) Giám sát và nâng cao chất lượng CSKH.</p>
                    <p className="title-large">4. Nghiên cứu và phát triển</p>
                    <p className="content">Chúng tôi có thể sử dụng các thông tin được thu thập cho mục đích kiểm nghiệm, nghiên cứu, phan tích và phát triển sản phẩm. Qua đó, chúng tôi sẽ phát triển và nâng cao mức độ an toàn và bảo mật của Dịch vụ và/ hoặc Ứng dụng Facepay, phát triển các tính năng/ sản phẩm mới. và cung cấp các giải pháp thanh toán mới liên quan đến Dịch vụ và Ứng dụng Facepay.</p>
                    <p className="title-large">5. Liên lạc từ Facepay</p>
                    <p className="content">Chúng tôi có thể sử dụng thông tin thu thập được để gửi đến cho bạn các thông tin về sản phẩm, dịch vụ, chương trình khuyến mãi, nghiên cứu, khảo sát, tin tức, thông tin cập nhật, sự kiển, kết quả trung thưởng, giải thưởng, quảng cáo và các nội dung khác liên quan đến Dịch vụ và Ứng dụng Facepay, cũng như các dịch vụ từ đối tác của chúng tôi.</p>
                    <p className="content">Hiện nay chúng tôi có các phương thức liên lạc với bạn như sau:</p>
                    <p className="note">a) Thông báo trong ứng dụng đến Tài khoản Facepay của bạn;</p>
                    <p className="note">b) Tin nhắn sms hoặc gọi điện thoại đến số điện thoại đăng ký khi mở Tài khoản Facepay hoặc được cập nhật sau đó;</p>
                    <p className="note">c) Email đến địa chỉ email đăng ký khi mở Tài khoản Facepay hoặc được cập nhật sau đó; và</p>
                    <p className="note">d) Các thông báo rộng rãi và/ hoặc thông tin liên lạc trên website <span><a href="http://facepay.vn/">facepay.vn</a></span>, fanpage Facebook của chúng tôi và/ hoặc các mạng xã hội khác.</p>
                    <p className="title-large">6. Các yêu cầu và quy định pháp lý</p>
                    <p className="content">Chúng tôi có thể sử dụng thông tin thu thập được để điều tra, giải quyết các khiếu nại, tranh chấp liên quan đến việc sử dụng Dịch vụ, Ứng dụng Facepay và/ hoặc các tính năng, hoặc khi được yêu cầu và/ hoặc cho phép bởi các cơ quan chức năng theo quy định của pháp luật.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(FacepayPurposes)
