import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction:column;    
    .value-input{
        height: 48px;
        display: flex;
        align-items: center;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        background: #FFFFFF;
        border: 0.5px solid #C8C8C8;
        box-sizing: border-box;
        border-radius: 11px;
        outline: none;
        padding: 0 12px;
        &.err{
            border: 0.5px solid #FF3131;
        }
        ::placeholder{
            color: #C8C8C8;
        }
    }
    @media only screen and (min-width: 768px){
        .value-input{
            font-size: 16px;
            line-height: 23px;
        }
    }

`
export { Bound }