import styled from 'styled-components'
import bg_coming_soon from '../../assets/images/bg_coming_soon.png'
import bg_payment_page from '../../assets/images/bg_payment_page.png'
import bg_tutorial from '../../assets/images/bg_tutorial.png'

const WrapPage = styled.div`
    .wrap-home-page{
        /* background: radial-gradient(75.73% 251.54% at 75.73% 60.92%, #ABDFF9 0%, #8EC2F9 39.74%, #CFB3FE 100%); */
        background-image:url(${bg_tutorial});
        background-size:100%;
        background-repeat:no-repeat;
        background-position:center;
        background-size: cover; 
        .home-page{       
            height: 100vh;
            min-height: 788px;
            padding: 98px 24px 0 24px;
            display: flex;
            /* flex-direction: column-reverse; */
            position: relative;
            box-sizing: border-box ;
            .content-page{
                /* margin-bottom: 150px; */
                margin-top: 28px;
                z-index: 1;
                .title-page{
                    font-family: 'SVN-Bebas Neue';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 72px;
                    line-height: 72px;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    color: #F7FCFF;
                    padding: 0 0 5px 0;
                    display: flex;
                    flex-direction: column;
                    &.blue{
                        color: #1279DA;
                    }
                }
                .text-page{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 21px;
                    color: #F7FCFF;
                    padding: 20px 0 0 0;
                    &.bold{
                        font-weight: bold;
                    }
                }
            }            
            /* .lottie{
                position: absolute;
                top: -50px;
                left: 0;
                height: 750px;
                width: 410px;
            } */           

            .model{           
                display: none;
            }   
            .model-mobile{
                position: absolute;
                bottom: 0;
                right: 0;
            }  
        }
    }
    .connect-page{
        height: fit-content;
        min-height: 788px;
        .title-wrap{
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 39px 18px 30px 18px;
            .title-connect{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                line-height: 39px;
                &.blue{
                    color: #1279DA;
                }
            }
        }
        .text-page{
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            color: #191919;
            padding: 20px 34px 20px 34px;
            text-align: center;
        }
        .wrap-image{
            display: flex;
            justify-content: center;
            .icon-fastpay{               
                width: 340px;
                height: 379.05px;
            }
            .icon-checkout{
                width: 302px;
                height: 335px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
        .content-connect{
            .wrap-header{
                /* width: calc(267px - 46px); */
                width: fit-content;
                height: 40px;
                background: #F3F5F9;
                border-radius: 8px;
                display: flex;
                align-items: center;
                /* justify-content: space-between; */
                margin: 0 auto;
                padding: 0 5px;
                .header{
                    text-decoration: none;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                    color: #939393;
                    width: fit-content;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    padding: 0 10px;
                    box-sizing: border-box;
                    &.active {
                        color: #ffffff;
                        background: #1279DA;
                        border-radius: 6px;
                    }
                    .label{
                        display: none;
                        padding: 0 0 0 5px;
                        &.active{
                            display: unset;
                        }
                    }
                }        
            }
            .wrap-content{
                max-width: 1200px;
                min-height: 700px;
                height: fit-content;
                padding: 30px 0 20px 0;
                align-self: center;
                margin: 0 auto;
                .title-step{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 23px;
                    color: #191919;
                    text-align: center;
                    padding: 0 0 20px 0;                    
                }
            }
        }       
    }
    .coming-soon-page{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(${bg_coming_soon});
        position: relative;
        min-height: 673px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title-page{
            font-family: 'SVN-Bebas Neue';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 72px;
            letter-spacing: -1px;
            text-transform: uppercase;
            color: #FFFFFF;
        }
        .des-page{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
            margin: 36px 0 25px 0;
            width: 342px;
        }
        .des-bold-page{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
        }
    }
    .payment-page{
        min-height: 788px;
        padding: 150px 0 0 0;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column-reverse;        
        .bg-payment{
            position: absolute;
            left: 0;
            top: 50px;
            /* transform: translate(-50%, 0); */
            /* top:30px;
            left: 0; */
            z-index: -1;
            .bg-image{
                width: 396px;
                height: 396px;
            }
        }
        .page-wrapper{
            .content-text{  
                z-index: 1;
                display: flex;
                flex-direction: column;             
                .title-page{
                    font-family: 'SVN-Bebas Neue';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 74px;
                    line-height: 72px;
                    color: #304659;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    padding: 0 0 0 20px;
                    &.blue{
                        color: #1279DA;
                        padding: 0;
                    }
                }
                .desc-page{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 26px;
                    color: #191919;
                    padding: 38px 20px 33px 20px;
                }
                .content-page{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 23px;
                    color: #304659;
                    padding: 0 20px;
                    p{
                        padding: 0 0 0 8px;
                        font-size: 15px;
                        line-height: 21px;
                    }
                    .wrap-step{
                        display: flex;
                        align-items: center;
                        padding-bottom: 15px;
                        .cycle{
                            height: 32px;
                            min-width: 32px;
                            border-radius: 50%;
                            background: rgba(18, 121, 218, 0.2);
                            .step{
                                font-family: 'SVN-Gilroy';
                                font-style: normal;
                                font-weight: bold;
                                font-size: 18px;
                                color: #FFFFFF;
                                height: 24px;
                                width: 24px;
                                background: #1279DA;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                padding-top: 2px;
                                box-sizing: border-box;
                                margin: 0;
                            }
                        }
                    }

                }
                .note{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 130%;
                    display: flex;
                    align-items: center;
                    color: #1279DA;
                    text-decoration: none;
                    padding: 25px 0 31px 20px;
                    width: fit-content;
                    a{
                        color: #1279DA;
                        text-decoration: none;
                    }
                }   
            }
        }
    }
    .wrap-list-bank{
        height: fit-content;
        margin: 0 auto;
        padding: 48px 24px 48px 24px;
        box-sizing: border-box;
        .title-page{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 34px;
            line-height: 37px;
            text-align: center;
            color: #191919;
            &.blue{
                color: #1279DA;
            }
        }
        .list-bank{
            display: flex;
            flex-direction: column;
            .image-container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 36px 0;
                .image-wrap{
                    display: flex;
                    align-items: center;
                    position: relative;
                    min-height: 152px;
                    flex-grow: 1;
                    overflow: hidden;
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    ::-webkit-scrollbar-thumb {
                        display: none;
                    }
                    .image{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        flex-shrink: 0;
                        transition: transform 0.3s ease-in-out;
                        background: #FFFFFF;
                        &.previous {
                            transform: translateX(-100%);
                            z-index: 2;
                        }
                        &.active{
                            transform: translateX(0);
                            z-index: 3;
                        }
                        &.next {
                            transform: translateX(100%);
                            z-index: 1;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .icon-previous-wrap{
                    cursor: pointer;
                    flex-shrink: 0;
                    height: 40px;
                    width: 40px;
                    .icon-previous{
                        flex-shrink: 0;
                        width: 40px;
                        height: 40px;
                        z-index: 3;
                        cursor: pointer;
                    }                
                }
                .icon-next-wrap{
                    cursor: pointer;
                    flex-shrink: 0;
                    height: 40px;
                    width: 40px;
                    .icon-next{
                        flex-shrink: 0;
                        width: 40px;
                        height: 40px;
                        z-index: 3;
                        cursor: pointer;
                    }
                }
            }
            .step-container{               
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                user-select: none;
                .cycle{
                    height: 7px;
                    width:7px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    position: relative;
                    margin: 0 10px 0 0;
                    &:last-of-type{
                        margin: 0;
                    }
                    &.wrapper{
                        background: rgba(18, 121, 218, 0.2);                 
                    }
                    .step{
                        height: 7px;
                        width: 7px;
                        background: #C8C8C8;
                        border-radius: 50%;
                        margin: 0 10px 0 0;
                        &.active{
                            background: #1279DA;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px){
        .wrap-home-page{
            .home-page{
                max-width: 1200px;
                height: 100vh;
                margin: 0 auto;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0;
                .content-page{
                    margin-bottom: unset;
                    .title-page{
                        font-size: 102px;
                        line-height: 99px;
                    }
                    .text-page{
                        font-size: 16px;
                        line-height: 23px;
                        color: #F7FCFF;
                        width: 400px;
                        padding: 30px 0 0 0;
                    }
                }
               
                .model{
                    display: block;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
                .model-mobile{
                    display: none;
                }
            }              
        }
        .connect-page{
            .title-wrap{
                flex-direction: row;
                justify-content: center;
                .title-connect{                   
                    font-size: 50px;
                    line-height: 52px;
                    letter-spacing: -1px;
                    text-align: center;
                    padding: 48px 10px 31px 0;
                }
            }
            .text-page{
                padding: 13px 0 57px 0;
                max-width: 892px;
                margin: 0 auto;
            }
            .wrap-image{               
                display: flex;
                margin: 0 auto;
                max-width: 1200px;
                padding-bottom: 59px;
                .icon-fastpay{                   
                    width: 502px;
                    height: 561px;
                }
                .icon-checkout{
                    width: 444px;
                    height: 495px;
                    position: unset;
                }
            }
            .content-connect{
                display: flex;
                flex-direction: column;
                .wrap-header{
                    height: 40px;
                    border-radius: 10px;                     
                    .header{
                        font-size: 18px;
                        line-height: 19px;
                        width: 264px;
                        height: 30px;
                        padding: 0;
                        &.active {
                            border-radius: 8px;
                        }
                        .label{
                            display: unset;
                        }
                    }
                    .step{
                        display: none;
                    }                  
                }
                .wrap-content{
                    min-height: calc(626px - 50px);
                    height: fit-content;
                    padding: 50px 0 0 0;
                    .title-step{
                        font-size: 22px;
                        line-height: 28px;
                        padding: 0 0 20px 190px;
                        &.link-page{
                            padding: 0 0 20px 140px;
                        }
                    }
                }           
            }
        }
        .coming-soon-page{
            min-height: 654px;
            margin: 0;
            .title-page{
                font-size: 102px;
                line-height: 102px;
            }
            .des-page{
                font-size: 18px;
                line-height: 23px;
                width: 393px;
                margin: 41px 0 25px 0;
            }
            .des-bold-page{
                font-weight: bold;
                font-size: 18px;
                line-height: 23px;
            }
        }
        .wrap-list-bank{
            padding: 48px 24px 48px 24px;
            width: 624px;
            .title-page{                
                font-size: 50px;
                line-height: 52px;                
            }
            .list-bank{
                padding: 68px 0 38px 0;
                .image-container{
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin: unset;
                    .image-wrap{                        
                        display: grid;
                        grid-gap: 40px 36px;
                        width: 624px;
                        grid-template-columns: repeat(4, 120px);
                        justify-content: center;
                        align-items: unset;
                        position: unset;
                        min-height: unset;
                        .image{
                            position: unset;
                            width: 100%;
                            height: 100%;
                            text-align: unset;
                            flex-shrink: unset;
                            transition: unset;
                            background: #FFFFFF;
                            &.previous {
                                transform: unset;
                                z-index: 1;
                            }
                            &.active{
                                transform: unset;
                                z-index: 1;
                            }
                            &.next {
                                transform: unset;
                                z-index: 1;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    .icon-previous-wrap{
                        display: none
                    }
                    .icon-next-wrap{
                        display: none;
                    }
                }
                .step-container{
                    display: none;
                }
            }     
        }
    }
   

    @media only screen and (min-width: 832px) {        
        .wrap-list-bank{
            width: 774px;
            .list-bank{
                .image-container{
                    .image-wrap{
                        grid-template-columns: repeat(5, 120px);
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 984px) {        
        .wrap-list-bank{
            width: 950px;
            .list-bank{
                .image-container{
                    .image-wrap{
                        grid-template-columns: repeat(6, 120px);
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1136px) {       
        .wrap-list-bank{
            width: 1100px;
            .list-bank{
                .image-container{
                    .image-wrap{
                        grid-template-columns: repeat(7, 120px);
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1288px) {
        .payment-page{           
            background-image: url(${bg_payment_page});
            background-size:inherit;
            background-position: 0 0;
            background-repeat:no-repeat;
            padding: 0;
            flex-direction: row-reverse;
            .bg-payment{
                display: none;
            }
            .page-wrapper{
                max-width: 1200px;
                width: 100%;
                margin: 0 auto;
                position: relative;
                min-height: 788px;
                .content-text{
                    width: 515px;
                    position: absolute;
                    top: 89px;
                    right: 0;
                    .title-page{
                        font-size: 102px;
                        line-height: 102px;
                        padding: 0;
                    }
                    .desc-page{
                        font-size: 22px;
                        line-height: 28px;
                        padding: 35px 0 40px 0;
                    }
                    .content-page{
                        font-size: 20px;
                        line-height: 25px;
                        padding: 0;
                        p{
                            padding: 0 0 0 12px;
                            font-size: 16px;
                            line-height: 23px;
                        }
                        .wrap-step{
                            padding-bottom: 20px;
                            .cycle{
                                height: 40px;
                                min-width: 40px;
                                .step{                                    
                                    height: 28px;
                                    width: 28px;
                                }
                            }
                        }
                    }
                    .note{
                        font-size: 14px;
                        line-height: 130%;
                        padding: 18px 0 0 0
                    }
                }
            }        
        }
        .wrap-list-bank{
            width: 1248px;
            .list-bank{
                .image-container{
                    .image-wrap{
                        grid-template-columns: repeat(8, 120px) ;
                    }
                }
            }
        }
    }
`;

export {
    WrapPage
}