import React from 'react'
import { withNamespaces } from 'react-i18next';

//style
import { WrapComponent } from './index.styles'

//image
import icon_smartpay from '../../../assets/images/icon_smartpay.png'
import img_smartpay_cover from '../../../assets/images/LogoSmartPay/img_smartpay_cover.png'
import logo_smartpay_mobile from '../../../assets/images/LogoSmartPay/logo_smartpay_mobile.png'
import logo_smartpay_pc from '../../../assets/images/LogoSmartPay/logo_smartpay_pc.png'

//component
import StepByStepReverse from '../../Control/StepByStepReverse';

//data
import { SmartPayData } from '../../../assets/data/SmartPayData';


const SmartPay = ({ t, refPage }) => {

    return (
        <WrapComponent ref={refPage}>
            <div className="bank-page-smartpay">
                <div className='overlay' />
                <div className="block-left">
                    <StepByStepReverse
                        headLogo={{
                                mobile: logo_smartpay_mobile,
                                laptop: logo_smartpay_pc
                            }
                        }
                        headSubTitle={t('facePay_by_bank.smart_pay.title')}
                        defaultActiveStep={SmartPayData[0].step}
                        lstStep={SmartPayData}
                    />
                </div>
                <div className="block-right">
                    <img className="icon" src={icon_smartpay} alt="icon-smartpay" />
                </div>

            </div>
        </WrapComponent>
    )
}

export default withNamespaces()(SmartPay)
