import { RECRUITMENT } from "../constant/pathNames";

export const RecruitmentData = {
    business_analyst: {
        key: "business_analyst",
        title: "recruitment.page_four.post1.title",
        place: "recruitment.page_four.post1.place",
        date: "recruitment.page_four.post1.date",
        time: "recruitment.page_four.post1.time",
        desc: "recruitment.page_four.post1.desc",
        link: `/${RECRUITMENT}/business_analyst`,
        contents: [
            "recruitment.page_four.post1.content_page.contents.content1",
            "recruitment.page_four.post1.content_page.contents.content2",
            "recruitment.page_four.post1.content_page.contents.content3",
            "recruitment.page_four.post1.content_page.contents.content4",
            "recruitment.page_four.post1.content_page.contents.content5",
            "recruitment.page_four.post1.content_page.contents.content6",
            "recruitment.page_four.post1.content_page.contents.content7",
            "recruitment.page_four.post1.content_page.contents.content8",
            "recruitment.page_four.post1.content_page.contents.content9",
            "recruitment.page_four.post1.content_page.contents.content10",
            "recruitment.page_four.post1.content_page.contents.content11",
            "recruitment.page_four.post1.content_page.contents.content12",
            "recruitment.page_four.post1.content_page.contents.content13",
            "recruitment.page_four.post1.content_page.contents.content14",
            "recruitment.page_four.post1.content_page.contents.content15",
            "recruitment.page_four.post1.content_page.contents.content16",
            "recruitment.page_four.post1.content_page.contents.content17",
            "recruitment.page_four.post1.content_page.contents.content18",
            "recruitment.page_four.post1.content_page.contents.content19",
            "recruitment.page_four.post1.content_page.contents.content20",
            "recruitment.page_four.post1.content_page.contents.content21",
            "recruitment.page_four.post1.content_page.contents.content22",
            "recruitment.page_four.post1.content_page.contents.content23",
            "recruitment.page_four.post1.content_page.contents.content24",
            "recruitment.page_four.post1.content_page.contents.content25",
            "recruitment.page_four.post1.content_page.contents.content26",
            "recruitment.page_four.post1.content_page.contents.content27",
            "recruitment.page_four.post1.content_page.contents.content28",
            "recruitment.page_four.post1.content_page.contents.content29",
        ],
        footer: [
            "recruitment.page_four.post1.content_page.footer.text1",
            "recruitment.page_four.post1.content_page.footer.text2",
            "recruitment.page_four.post1.content_page.footer.text3",
            "recruitment.page_four.post1.content_page.footer.text4",
            "recruitment.page_four.post1.content_page.footer.text5",
        ],
        link_email_tuyendung: {
            href: "mailto: tuyendung@wee.vn"
        }
    },
    scrum_master: {
        key: "scrum_master",
        title: "recruitment.page_four.post2.title",
        place: "recruitment.page_four.post2.place",
        date: "recruitment.page_four.post2.date",
        time: "recruitment.page_four.post2.time",
        desc: "recruitment.page_four.post2.desc",
        link: `/${RECRUITMENT}/scrum_master`,
        contents: [
            "recruitment.page_four.post2.content_page.contents.content1",
            "recruitment.page_four.post2.content_page.contents.content2",
            "recruitment.page_four.post2.content_page.contents.content3",
            "recruitment.page_four.post2.content_page.contents.content4",
            "recruitment.page_four.post2.content_page.contents.content5",
            "recruitment.page_four.post2.content_page.contents.content6",
            "recruitment.page_four.post2.content_page.contents.content7",
            "recruitment.page_four.post2.content_page.contents.content8",
            "recruitment.page_four.post2.content_page.contents.content9",
            "recruitment.page_four.post2.content_page.contents.content10",
            "recruitment.page_four.post2.content_page.contents.content11",
            "recruitment.page_four.post2.content_page.contents.content12",
            "recruitment.page_four.post2.content_page.contents.content13",
            "recruitment.page_four.post2.content_page.contents.content14",
            "recruitment.page_four.post2.content_page.contents.content15",
            "recruitment.page_four.post2.content_page.contents.content16",
            "recruitment.page_four.post2.content_page.contents.content17",
            "recruitment.page_four.post2.content_page.contents.content18",
            "recruitment.page_four.post2.content_page.contents.content19",
            "recruitment.page_four.post2.content_page.contents.content20",
            "recruitment.page_four.post2.content_page.contents.content21",
            "recruitment.page_four.post2.content_page.contents.content22",
        ],
        footer: [
            "recruitment.page_four.post2.content_page.footer.text1",
            "recruitment.page_four.post2.content_page.footer.text2",
            "recruitment.page_four.post2.content_page.footer.text3",
            "recruitment.page_four.post2.content_page.footer.text4",
            "recruitment.page_four.post2.content_page.footer.text5",
        ],
        link_email_tuyendung: {
            href: "mailto: tuyendung@wee.vn"
        }
    },
    tester: {
        key: "tester",
        title: "recruitment.page_four.post3.title",
        place: "recruitment.page_four.post3.place",
        date: "recruitment.page_four.post3.date",
        time: "recruitment.page_four.post3.time",
        desc: "recruitment.page_four.post3.desc",
        link: `/${RECRUITMENT}/tester`,
        contents: [
            "recruitment.page_four.post3.content_page.contents.content1",
            "recruitment.page_four.post3.content_page.contents.content2",
            "recruitment.page_four.post3.content_page.contents.content3",
            "recruitment.page_four.post3.content_page.contents.content4",
            "recruitment.page_four.post3.content_page.contents.content5",
            "recruitment.page_four.post3.content_page.contents.content6",
            "recruitment.page_four.post3.content_page.contents.content7",
            "recruitment.page_four.post3.content_page.contents.content8",
            "recruitment.page_four.post3.content_page.contents.content9",
            "recruitment.page_four.post3.content_page.contents.content10",
            "recruitment.page_four.post3.content_page.contents.content11",
            "recruitment.page_four.post3.content_page.contents.content12",
            "recruitment.page_four.post3.content_page.contents.content13",
            "recruitment.page_four.post3.content_page.contents.content14",
            "recruitment.page_four.post3.content_page.contents.content15",
            "recruitment.page_four.post3.content_page.contents.content16",
            "recruitment.page_four.post3.content_page.contents.content17",
            "recruitment.page_four.post3.content_page.contents.content18",
            "recruitment.page_four.post3.content_page.contents.content19",
            "recruitment.page_four.post3.content_page.contents.content20",
            "recruitment.page_four.post3.content_page.contents.content21",
            "recruitment.page_four.post3.content_page.contents.content22",
        ],
        footer: [
            "recruitment.page_four.post3.content_page.footer.text1",
            "recruitment.page_four.post3.content_page.footer.text2",
            "recruitment.page_four.post3.content_page.footer.text3",
            "recruitment.page_four.post3.content_page.footer.text4",
            "recruitment.page_four.post3.content_page.footer.text5",
        ],
        link_email_tuyendung: {
            href: "mailto: tuyendung@wee.vn"
        }
    },
    ios_mobile_developer: {
        key: "ios_mobile_developer",
        title: "recruitment.page_four.post4.title",
        place: "recruitment.page_four.post4.place",
        date: "recruitment.page_four.post4.date",
        time: "recruitment.page_four.post4.time",
        desc: "recruitment.page_four.post4.desc",
        link: `/${RECRUITMENT}/ios_mobile_developer`,
        contents: [
            "recruitment.page_four.post4.content_page.contents.content1",
            "recruitment.page_four.post4.content_page.contents.content2",
            "recruitment.page_four.post4.content_page.contents.content3",
            "recruitment.page_four.post4.content_page.contents.content4",
            "recruitment.page_four.post4.content_page.contents.content5",
            "recruitment.page_four.post4.content_page.contents.content6",
            "recruitment.page_four.post4.content_page.contents.content7",
            "recruitment.page_four.post4.content_page.contents.content8",
            "recruitment.page_four.post4.content_page.contents.content9",
            "recruitment.page_four.post4.content_page.contents.content10",
            "recruitment.page_four.post4.content_page.contents.content11",
            "recruitment.page_four.post4.content_page.contents.content12",
            "recruitment.page_four.post4.content_page.contents.content13",
            "recruitment.page_four.post4.content_page.contents.content14",
            "recruitment.page_four.post4.content_page.contents.content15",
            "recruitment.page_four.post4.content_page.contents.content16",
            "recruitment.page_four.post4.content_page.contents.content17",
            "recruitment.page_four.post4.content_page.contents.content18",
            "recruitment.page_four.post4.content_page.contents.content19",
            "recruitment.page_four.post4.content_page.contents.content20",
            "recruitment.page_four.post4.content_page.contents.content21",
            "recruitment.page_four.post4.content_page.contents.content22",
            "recruitment.page_four.post4.content_page.contents.content23",
            "recruitment.page_four.post4.content_page.contents.content24",
        ],
        footer: [
            "recruitment.page_four.post4.content_page.footer.text1",
            "recruitment.page_four.post4.content_page.footer.text2",
            "recruitment.page_four.post4.content_page.footer.text3",
            "recruitment.page_four.post4.content_page.footer.text4",
            "recruitment.page_four.post4.content_page.footer.text5",
        ],
        link_email_tuyendung: {
            href: "mailto: tuyendung@wee.vn"
        }
    },
    digital_marketing: {
        key: "digital_marketing",
        title: "recruitment.page_four.post5.title",
        place: "recruitment.page_four.post5.place",
        date: "recruitment.page_four.post5.date",
        time: "recruitment.page_four.post5.time",
        desc: "recruitment.page_four.post5.desc",
        link: `/${RECRUITMENT}/digital_marketing`,
        contents: [
            "recruitment.page_four.post5.content_page.contents.content1",
            "recruitment.page_four.post5.content_page.contents.content2",
            "recruitment.page_four.post5.content_page.contents.content3",
            "recruitment.page_four.post5.content_page.contents.content4",
            "recruitment.page_four.post5.content_page.contents.content5",
            "recruitment.page_four.post5.content_page.contents.content6",
            "recruitment.page_four.post5.content_page.contents.content7",
            "recruitment.page_four.post5.content_page.contents.content8",
            "recruitment.page_four.post5.content_page.contents.content9",
            "recruitment.page_four.post5.content_page.contents.content10",
            "recruitment.page_four.post5.content_page.contents.content11",
            "recruitment.page_four.post5.content_page.contents.content12",
            "recruitment.page_four.post5.content_page.contents.content13",
            "recruitment.page_four.post5.content_page.contents.content14",
            "recruitment.page_four.post5.content_page.contents.content15",
            "recruitment.page_four.post5.content_page.contents.content16",
            "recruitment.page_four.post5.content_page.contents.content17",
            "recruitment.page_four.post5.content_page.contents.content18",
            "recruitment.page_four.post5.content_page.contents.content19",
            "recruitment.page_four.post5.content_page.contents.content20",
            "recruitment.page_four.post5.content_page.contents.content21",
            "recruitment.page_four.post5.content_page.contents.content22",
        ],
        footer: [
            "recruitment.page_four.post5.content_page.footer.text1",
            "recruitment.page_four.post5.content_page.footer.text2",
            "recruitment.page_four.post5.content_page.footer.text3",
            "recruitment.page_four.post5.content_page.footer.text4",
            "recruitment.page_four.post5.content_page.footer.text5",
        ],
        link_email_tuyendung: {
            href: "mailto: tuyendung@wee.vn"
        }
    },
}