import img_01 from '../images/TPBank/B01.png'
import img_02 from '../images/TPBank/B02.png'

export const TPBankData = [
    {
        step: 1,
        image: img_01,
        text: "facePay_by_bank.tp_bank.dowload.0"
    },
    {
        step: 2,
        image: img_02,
        text: "facePay_by_bank.tp_bank.dowload.1"
    },    
]