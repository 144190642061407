import React from 'react'
import { withNamespaces } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';

//constant
import * as pathNames from '../../../assets/constant/pathNames'

//data
import { FacePayMagicFAQData } from '../../../assets/data/FacePayMagicFAQData';

//component
import QAndAList from '../../Control/QAndAList';

const FacePayMagicFAQ = ({ t }) => {
    const { subSupportType } = useParams()
    const { url } = useRouteMatch()

    return (
        <QAndAList
            urlSupportPage={url.replace(`\/${subSupportType}`, "")}
            defaultActiveTab={subSupportType}
            title={t('facepay_magic.title')}
            tab={t('facepay_magic.tab', { returnObjects: true })}
            objDatas={FacePayMagicFAQData}
            path={pathNames.FAQ_FACEPAY_MAGIC}
        />
    )
}

export default withNamespaces()(FacePayMagicFAQ)
