import React from 'react'

const IcDocument = (props) => {
    return (
        <svg {...props} width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 15.6C6 12.2397 6 10.5595 6.65396 9.27606C7.2292 8.14708 8.14708 7.2292 9.27606 6.65396C10.5595 6 12.2397 6 15.6 6H32.4C35.7603 6 37.4405 6 38.7239 6.65396C39.8529 7.2292 40.7708 8.14708 41.346 9.27606C42 10.5595 42 12.2397 42 15.6V32.4C42 35.7603 42 37.4405 41.346 38.7239C40.7708 39.8529 39.8529 40.7708 38.7239 41.346C37.4405 42 35.7603 42 32.4 42H15.6C12.2397 42 10.5595 42 9.27606 41.346C8.14708 40.7708 7.2292 39.8529 6.65396 38.7239C6 37.4405 6 35.7603 6 32.4V15.6Z" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.5 32C22.5 32.8284 23.1716 33.5 24 33.5C24.8284 33.5 25.5 32.8284 25.5 32H22.5ZM25.5 24C25.5 23.1716 24.8284 22.5 24 22.5C23.1716 22.5 22.5 23.1716 22.5 24H25.5ZM24 14.5C23.1716 14.5 22.5 15.1716 22.5 16C22.5 16.8284 23.1716 17.5 24 17.5V14.5ZM24.02 17.5C24.8484 17.5 25.52 16.8284 25.52 16C25.52 15.1716 24.8484 14.5 24.02 14.5V17.5ZM25.5 32V24H22.5V32H25.5ZM24 17.5H24.02V14.5H24V17.5Z" fill="#191919" />
        </svg>
    )
}

export default IcDocument
