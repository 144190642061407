import styled from 'styled-components'

const WrapPage = styled.div`
    /* min-height: 788px; */
    .registratione-page{
        padding: 48px 24px 50px 24px;
        .title-page{       
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: bold;
            font-size: 34px;
            line-height: 37px;
            color: #000000;
            span{
                color: #1279DA;
            }
            
        }
        .text-wrap{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
            color: #000000;
            padding: 20px 0 39px 0;
        }
        .input-wrap{
            display: flex;
            flex-direction: column;
            margin: 0 0 14px 0;
            .title-input{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 130%;
                color: #304659;
                margin: 0 0 5px 0;
            }
            .input-number{
                height: 48px;
                display: flex;
                align-items: center;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 21px;
                background: #FFFFFF;
                border: 0.5px solid #C8C8C8;
                box-sizing: border-box;
                border-radius: 11px;
                outline: none;
                padding: 0 12px;
                &.err{
                    border: 0.5px solid #FF3131;
                }
                ::placeholder{
                    color: #C8C8C8;
                }
            }
            .error{
                font-family: "SVN-Gilroy";
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 130%;
                color: #FF3131;
                height: 17px;
                padding: 3px 0 0 12px;
            } 
        }
        .button-control{
            height: 46px;
            background: #1279DA;
            border-radius: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 21px;
            color: #FFFFFF;
            cursor: pointer;
            margin: 25px 0 0 0;
        }
    }
    .thanks-page{
        padding: 200px 24px 0 24px;
        min-height: 788px;
        box-sizing: border-box;
        .title{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: bold;
            font-size: 50px;
            line-height: 52px;
            text-align: center;
            letter-spacing: -1px;
            padding: 0 0 65px 0;
        }
        .content{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 23px;
            text-align: center;
            color: #000000;
        }
    }    

    @media only screen and (min-width: 768px){
        .registratione-page{
            padding: 0;
            .title-page{
                font-size: calc(34px + (50 - 34) * ((100vw - 768px) / (1440 - 768)));
                line-height: 52px;
                letter-spacing: -1px;
            }
            .text-wrap{
                font-size: 16px;
                line-height: 23px;
                padding: 26px 0 45px 0;
            }
            .input-wrap{
                margin: 0 0 10px 0;
                .title-input{
                    font-size: 13px;
                    line-height: 16px;
                }
                .input-number{
                    font-size: 16px;
                    line-height: 23px;
                }
                .error{
                    font-size:  13px;
                    line-height: 16px;
                    height: 18px;
                    padding: 4px 0 0 16px;
                } 
            }
            .button-control{
                font-size: 16px;
                line-height: 23px;
                margin: 20px 0 0 0;
            }
        }
        .thanks-page{    
            padding: 100px 0 0 0;       
            .title{                
                font-size: 50px;
                line-height: 52px;
                padding: 108px 0 65px 0;
            }
            .content{
                font-size: 16px;
                line-height: 23px;
            }
        }    
    }
`
export { WrapPage }