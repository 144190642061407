
export const ResolveComplaintData = {
    facepay: {
        q1: {
            key: "q1",
            title: "resolve_complaint.facepay.q_01.title",
            date: "resolve_complaint.facepay.q_01.date",
            link_email: {
                href: "mailto: cs@facepay.vn"
            },
            link_phone_number: {
                href: "tel: 02037101010"
            },
            desc: [
                "resolve_complaint.facepay.q_01.desc.desc1",
                "resolve_complaint.facepay.q_01.desc.desc2",
                "resolve_complaint.facepay.q_01.desc.desc3"
            ],
        },
        q2: {
            key: "q2",
            title: "resolve_complaint.facepay.q_02.title",
            date: "resolve_complaint.facepay.q_02.date",
            desc: [
                "resolve_complaint.facepay.q_02.desc.desc1",
                "resolve_complaint.facepay.q_02.desc.desc2",
                "resolve_complaint.facepay.q_02.desc.desc3",
                "resolve_complaint.facepay.q_02.desc.desc4",
                "resolve_complaint.facepay.q_02.desc.desc5",
                "resolve_complaint.facepay.q_02.desc.desc6"
            ],
        },
    },
    tp_bank: {
        q3: {
            key: "q3",
            title: "resolve_complaint.tp_bank.q_03.title",
            date: "resolve_complaint.tp_bank.q_03.date",
            link_email: {
                href: "mailto: dichvu_khachhang@tpb.com.vn"
            },
            link_phone_number1: {
                href: "tel: 1900585885"
            },
            link_phone_number2: {
                href: "tel: 19006036"
            },
            desc: [
                "resolve_complaint.tp_bank.q_03.desc.desc1",
                "resolve_complaint.tp_bank.q_03.desc.desc2",
                "resolve_complaint.tp_bank.q_03.desc.desc3"
            ],
        }
    },
    ocb: {
        q4: {
            key: "q4",
            title: "resolve_complaint.ocb.q_04.title",
            date: "resolve_complaint.ocb.q_04.date",
            link_email: {
                href: "mailto: dvkh@ocb.com.vn"
            },
            link_phone_number: {
                href: "tel: 18006678"
            },
            desc: [
                "resolve_complaint.ocb.q_04.desc.desc1",
                "resolve_complaint.ocb.q_04.desc.desc2",
                "resolve_complaint.ocb.q_04.desc.desc3"
            ],
        }
    },
    vietinbank: {
        q5: {
            key: "q5",
            title: "resolve_complaint.vietinbank.q_05.title",
            date: "resolve_complaint.vietinbank.q_05.date",
            link_email: {
                href: "mailto: contact@vietinbank.vn"
            },
            link_phone_number_1: {
                href: "tel: 1900558868"
            },
            link_phone_number_2: {
                href: "tel: (+84)2439418868"
            },
            desc: [
                "resolve_complaint.vietinbank.q_05.desc.desc1",
                "resolve_complaint.vietinbank.q_05.desc.desc2",
                "resolve_complaint.vietinbank.q_05.desc.desc3"
            ],
        }
    },    
    smartpay: {
        q6: {
            key: "q6",
            title: "resolve_complaint.smartpay.q_06.title",
            date: "resolve_complaint.smartpay.q_06.date",
            link_email: {
                href: "mailto: hotro@paysmart.com.vn"
            },
            link_phone_number: {
                href: "tel: 19002064"
            },
            desc: [
                "resolve_complaint.smartpay.q_06.desc.desc1",
                "resolve_complaint.smartpay.q_06.desc.desc2",
                "resolve_complaint.smartpay.q_06.desc.desc3"
            ],
        }
    }
}