import React, { useState, useLayoutEffect, useMemo } from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom'

// constant
import { SUPPORT } from '../../../assets/constant/pathNames'

// component
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

// styles
import { WrapQAndAList } from './index.styles'

const QAndAList = ({ t, urlSupportPage, defaultActiveTab, title, tab, objDatas, path }) => {
    
    const [activeTab, setActiveTab] = useState(defaultActiveTab || (tab ? tab[0].tag : null))

    const objDataQ = useMemo(() => !!activeTab ? objDatas[activeTab] : objDatas, [activeTab])

    return (
        <WrapQAndAList>
            <div className="page-one">
                <Link to={`/${SUPPORT}`} className="come-back">
                    <IcArrowLeft /> {t('common.quay_lai')}
                </Link>
                <div className="wrap-title-page">
                    <div className="title-page">{title}</div>
                    {
                        !!tab &&
                        <div className="wrap-tab">
                            {
                                tab.map(({ title, tag }) => (
                                    <Link to={`${urlSupportPage}/${tag}`} key={tag}>
                                        <div 
                                            className={activeTab === tag ? "tab active" : "tab"} 
                                            onClick={() => setActiveTab(tag)}>
                                            {title}
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    }
                </div>
                <div className="content-page">
                    {
                        Object.values(objDataQ).map((item, i) => {
                            const row = t(item.desc[0], { returnObjects: true })
                            return (
                                <Link key={i} className="content-item" to={`/${SUPPORT}/${path}${activeTab ? `/${activeTab}` : ''}/${item.key}`}>
                                    <p className="title">{t(item.title)}</p>
                                    <div className="desc">
                                        {
                                            row.map((rowItem, k) => {
                                                if (typeof (rowItem) === "string")
                                                    return (
                                                        <React.Fragment key={k}>
                                                            {rowItem}
                                                        </React.Fragment>
                                                    )
                                                return <React.Fragment key={k}>
                                                    <span className="text">
                                                        {rowItem.text}
                                                    </span>
                                                </React.Fragment>
                                            })
                                        }
                                    </div>
                                    <p className="view" >{t('common.xem_them')}</p>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
            <ContactPage />
            <PageSix />
        </WrapQAndAList>
    )
}

export default withNamespaces()(QAndAList)
