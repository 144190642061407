import React, { useState, useRef, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import lottie from 'lottie-web'

//style
import * as styles from './index.styles'

//image
import model_home from '../../../assets/images/model_home.png'
import model_home_mobile from '../../../assets/images/model_home_mobile.png'
import GooglePlayBadgeBlack from '../../../assets/images/GooglePlayBadgeBlack';
import AppStoreBadgeBlack from '../../../assets/images/AppStoreBadgeBlack';
//component
import Popup50K from '../../Control/Popup50K';
import Portal from '../../Control/Portal';

const PageOne = ({ t }) => {

    const wrapNocashAnimation = useRef()

    const [isShowPopup, setIsShowPopup] = useState(false)

    useEffect(() => {
        let temp = lottie.loadAnimation({
            container: wrapNocashAnimation.current,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: '/animation/no_cash.json'
        })
        setTimeout(() => {
            if (temp) {
                temp.play()
            }
        }, 800);
    }, []);

    return (
        <styles.Bound>
            <div className="page-wrapper">
                <div className='page-one-content-container'>
                    <div className="title-page">
                        <div ref={wrapNocashAnimation} className="no-cash-animation"></div>
                    </div>
                    <div className="content-page">
                        {t('home.page_one.text_1')}
                        <span className="black">{t('home.page_one.text_2')}</span>
                    </div>
                    <div className="badge-wrap">
                        <a href="https://play.google.com/store/apps/details?id=vn.wee.facepay" target="_blank">
                            <GooglePlayBadgeBlack className="ic-badge google-play" />
                        </a>
                        <a href="https://apps.apple.com/vn/app/facepay/id1529422054" target="_blank">
                            <AppStoreBadgeBlack className="ic-badge app-store" />
                        </a>
                    </div>
                    {/* <div className='btn-view-details' onClick={() => setIsShowPopup(true)}>Facepay tặng bạn mới 50k</div> */}
                </div>
                <img className="img-model" src={model_home} alt="model" />
                <img className="img-model-mobile" src={model_home_mobile} alt="model" />
                {
                    isShowPopup &&
                    <Portal>
                        <Popup50K setIsShowPopup={setIsShowPopup} />
                    </Portal>
                }
            </div>
        </styles.Bound>
    )
}

export default withNamespaces()(PageOne)
