import React, { useRef } from 'react'
//css
import { WrapPopup } from './index.styles'
import { OverlayFullScreen } from '../../Home/PageOne/index.styles'

//img
import popup_50K from '../../../assets/images/popup_50K.png'
import IcClose from '../../../assets/images/IcClose'

const Popup50K = ({ setIsShowPopup }) => {

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopup>
                    <IcClose className="ic_close" onClick={() => setIsShowPopup(false)} />
                    <img className='image-popup' src={popup_50K} alt="popup" />
                </WrapPopup>
            </div>
        </OverlayFullScreen>
    )
}

export default Popup50K