import styled from 'styled-components'

const WrapQAndAList = styled.div`
    padding-top: 123px;
    box-sizing: border-box;

    .page-one{
        min-height: 788px;
        height: fit-content;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        /* padding: 129px 0 0 0; */
        box-sizing: border-box;
        .come-back{        
            font-weight: 500;
            font-size: 13px;
            color: #1279DA;
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 0 24px;
        }
        .wrap-title-page{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 0 45px 0;
            .title-page{
                font-weight: bold;
                font-size: 36px;
                line-height: 39px;
                color: #304659;
                padding: 0 24px;
            }
            .wrap-tab{
                display: flex;
                overflow-y: scroll;
                padding: 25px 0 0 24px;
                & > * {
                    text-decoration: none;
                }                
                .tab{
                    width: 103px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #F7F7F7;
                    border-radius: 12px;
                    font-family: "SVN-Gilroy";
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 21px;
                    box-sizing: border-box;
                    color: #C8C8C8;
                    cursor: pointer;
                    text-align: center;
                    
                    &.active{                        
                        background: #1279DA;
                        border: 0.1px solid #1279DA;
                        box-sizing: border-box;
                        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
                        color: #FFFFFF;
                    }
                }
                & > *:not(:first-child) {
                    margin-left: 10px;
                }
            }
        }
        .content-page{
            display: flex;
            flex-direction: column;
            padding: 0 24px;
            .content-item{
                padding-bottom: 28px;
                text-decoration: none;
                .title{
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 23px;
                    color: #191919;
                    padding-bottom: 14px;
                }
                .desc{
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 130%;
                    color: #939393;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    max-width: 350px; 
                }
                .text{
                    padding: 0 5px;
                    font-family: "SVN-Gilroy";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 130%;
                    color: #939393;   
                }
                .view{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 130%;
                    color: #1279DA;
                    cursor: pointer;
                    padding-top: 14px;
                    display: block;
                    text-decoration: none;
                }
            }
        }
    }

    @media only screen and (min-width: 768px){    
        padding-top: 142px;
        .page-one{
            max-width: 1200px;
            margin: 0 auto;
            /* padding: 142px 0 0 0; */
            /* height: 100vh; */
            .come-back{
                font-size: 14px;
                line-height: 130%;
                padding: 0;
            }            
            .wrap-title-page{
                padding: 20px 0 45px 0;
                .title-page{
                    font-size: 50px;
                    line-height: 52px;
                    letter-spacing: -1px;
                    margin-bottom: unset;
                    padding: 0;      
                }
                .wrap-tab{
                    overflow-y: unset ;
                    padding: 25px 0 0 0;
                    .tab{
                        width: fit-content;
                        height: 46px;
                        width: 144px;
                        font-size: 18px;
                        line-height: 23px;                       
                    }
                    & > *:not(:first-child) {
                        margin-left: 14px;
                    }
                }
            }
            .content-page{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;
                padding: 0;
                .content-item{
                    padding-bottom: 33px;
                    .title{
                        
                    }
                    .desc{
                        max-width: 550px;
                    }
                }
            }
        }
    }
`;

export {
    WrapQAndAList
}