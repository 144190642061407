import { useEffect } from "react"

export const useTargetActiveElement = (listRef, elementActiveRef , status) => {

    useEffect(() => {

        if(!status) return
        if(!elementActiveRef.current) return

        const posChild = elementActiveRef.current.getBoundingClientRect().top
        const posParent = listRef.current.getBoundingClientRect().top

        listRef.current.scrollTo({
            left: 0,
            top: (posChild) - posParent,
            behavior: "smooth",
            // block: "start"
        })

    }, [status])
}

