import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

//constant
import { POLICY } from '../../../assets/constant/pathNames';

const AccessAndCustomize = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${POLICY}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('access_and_customize.title_page')}</p>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t('access_and_customize.date_page')}</div>
                </div> */}
                <div className="content-page">
                    <p className="wrapper"><span className="title">1. </span>Bạn có thể truy cập, điều chỉnh hoặc cập nhật các thông tin bạn đã cung cấp cho chúng tôi bằng Tài khoản Facepay của bạn. </p>
                    <p className="wrapper"><span className="title">2. </span>Nếu bạn mong muốn chúng tôi xóa hoàn toàn các thông tin của bạn, bạn cần phải đóng tài khoản Facepay theo như Điều khoản chung.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(AccessAndCustomize)
