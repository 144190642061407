import img_01 from '../images/OCBBank/B01.png'
import img_02 from '../images/OCBBank/B02.png'

export const OCBData = [
    {
        step: 1,
        image: img_01,
        text: "facePay_by_bank.ocb.dowload.0"
    },
    {
        step: 2,
        image: img_02,
        text: "facePay_by_bank.ocb.dowload.1"
    },
]