import styled from 'styled-components'
const Bound = styled.div`
    height: fit-content;
    min-height: 788px;
    padding: 99px 21px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-text{
        .title-page{
            font-family: 'SVN-Bebas Neue';
            font-style: normal;
            font-weight: bold;
            font-size: 72px;
            line-height: 72px;
            color: #304659;
            letter-spacing: -1px;
            text-transform: uppercase;
            &.blue{
                color: #1279DA;
            }
        }
        .des-page{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: #191919;
            width: 264px;
            padding: 35px 0 25px 0;
        }
        .content-page{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
            color: #191919;
            width: 322px;
            .bold{
                padding: 0 5px;
                font-weight: bold;
            }
        }  
        .tutorial{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 130%;
            color: #1279DA;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 21px 0 0 0;
            text-decoration: none;
            width: fit-content;
        }
    }
    .lottie-wrap{
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        align-items: center;
        .lottie{            
            width: 320px;
            height: 320px;
            svg{
                height: 364px !important;
            }         
        }
    }

    @media only screen and (min-width: 440px) and (max-width: 509px){
        .content-text{
            .title-page{
                min-width: 400px;
            }
        }
    }

    @media only screen and (min-width: 510px) and (max-width: 829px){
        .content-text{
            .title-page{
                min-width: 400px;
            }
        }
        .lottie-wrap{
            /* padding-top: 50px; */
            .lottie{
                svg{
                    height: 500px !important;
                }  
            }
        }
    }

    @media only screen and (min-width: 830px){
        display: flex;
        flex-direction: row;
        max-width: 1200px;
        padding: 0;
        margin: 0 auto;
        .content-text{
            /* padding: 0 0 0 50px; */
            width: 600px;
            .title-page{
                font-size: 102px;
                line-height: 99px;
            }
            .des-page{
                font-size: 22px;
                line-height: 26px;
                width: unset;
                padding: 38px 0 38px 0;
            }
            .content-page{
                font-size: 16px;
                line-height: 23px;
                width: 500px;
            }
            .tutorial{
                padding: 38px 0 0 0;
            }
        }
        .lottie-wrap{
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            .lottie{
                width: 500px;
                height: 500px;
                svg{
                    height: 500px !important;
                }  
            }
        }
    }    
`
export { Bound }