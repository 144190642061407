import React from 'react'

const IcLindin = (props) => {
    return (
        <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 32C7.15789 32 0 24.8421 0 16C0 7.15789 7.15789 0 16 0C24.8421 0 32 7.15789 32 16C32 24.8421 24.8421 32 16 32Z" fill="url(#ic_lindin)" />
            <path d="M11.9582 12.3787H8.67395V23.0734H11.9582V12.3787Z" fill="white" />
            <path d="M10.3582 10.9474C11.453 10.9474 12.2951 10.1053 12.2951 9.01057C12.2951 7.91583 11.453 7.07373 10.3582 7.07373C9.26349 7.07373 8.42139 8.00004 8.42139 9.01057C8.42139 10.1053 9.26349 10.9474 10.3582 10.9474Z" fill="white" />
            <path d="M17.3473 17.5159C17.3473 16.0001 18.021 15.0738 19.3684 15.0738C20.5473 15.0738 21.1368 15.9159 21.1368 17.5159C21.1368 19.0317 21.1368 23.158 21.1368 23.158H24.421C24.421 23.158 24.421 19.2843 24.421 16.337C24.421 13.4738 22.821 12.0422 20.5473 12.0422C18.2737 12.0422 17.3473 13.8107 17.3473 13.8107V12.3791H14.1473V23.0738H17.3473C17.3473 23.0738 17.3473 19.1159 17.3473 17.5159Z" fill="white" />
            <defs>
                <linearGradient id="ic_lindin" x1={32} y1="2.85557e-07" x2="31.387" y2="38.3185" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFFF" />
                    <stop offset="0.651042" stopColor="#005BEC" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default IcLindin
