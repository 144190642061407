import React from 'react'
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

//style
import { WrapPage } from './index.styles'

//image
import IcArrowRight from '../../assets/images/IcArrowRight';
import IcGlobe from '../../assets/images/IcGlobe';
import IcSearch from '../../assets/images/IcSearch';
import IcTarget from '../../assets/images/IcTarget';
import IcShare from '../../assets/images/IcShare';
import IcDocument from '../../assets/images/IcDocument';
import IcShield from '../../assets/images/IcShield';
import IcOctagon from '../../assets/images/IcOctagon';

//component
import ContactPage from '../Support/ContactPage';
import PageSix from '../Home/PageSix';

// constant
import { ACCESS_AND_CUSTOMIZE, CHOOSE_THE_RIGHT_PRIVATE, FACEPAY_PURPOSES, INFOMATION_COLLETED, INFOMATION_SECURITY, OTHER_REGULATIONS, POLICY, PRIVACY_POLICY_CHANGES, SCOPE_APPL, SHARE_AND_DISCLOSE, TERMS_OF_USE } from '../../assets/constant/pathNames';
import IcSetting from '../../assets/images/IcSetting';
import IcPolicyUser from '../../assets/images/IcPolicyUser';

const Policy = ({ t }) => {
    return (
        <WrapPage>
            {/* page one */}
            <div className="page-one">
                <div className="title"><span>{t('policy.title.text1')}</span> {t('policy.title.text2')}</div>
                <div className="desc">{t('policy.desc')}</div>
                <div className="desc">
                    {t('policy.desc_2')} <span><Link to={`/${TERMS_OF_USE}`}>{t('policy.desc_3')}</Link></span>
                </div>
                <div className="list-policy-method">
                    <Link className="wrap-method" to={`/${POLICY}/${SCOPE_APPL}`}>
                        <IcGlobe className="icon" />
                        <div className="policy-title">{t('policy.pham_vi')}</div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link className="wrap-method" to={`/${POLICY}/${INFOMATION_COLLETED}`}>
                        <IcSearch className="icon" />
                        <div className="policy-title">{t('policy.cac_thong_tin')}</div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link className="wrap-method" to={`/${POLICY}/${FACEPAY_PURPOSES}`}>
                        <IcTarget className="icon" />
                        <div className="policy-title">{t('policy.muc_dich')}</div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link className="wrap-method" to={`/${POLICY}/${SHARE_AND_DISCLOSE}`}>
                        <IcPolicyUser className="icon" />
                        <div className="policy-title">{t('policy.chia_se')}</div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link className="wrap-method" to={`/${POLICY}/${CHOOSE_THE_RIGHT_PRIVATE}`}>
                        <IcShare className="icon" />
                        <div className="policy-title">{t('policy.lua_chon')}</div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link className="wrap-method" to={`/${POLICY}/${ACCESS_AND_CUSTOMIZE}`}>
                        <IcSetting className="icon" />
                        <div className="policy-title">{t('policy.truy_cap')}</div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link className="wrap-method" to={`/${POLICY}/${PRIVACY_POLICY_CHANGES}`}>
                        <IcDocument className="icon" />
                        <div className="policy-title">{t('policy.cac_thay_doi')}</div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link className="wrap-method" to={`/${POLICY}/${INFOMATION_SECURITY}`}>
                        <IcShield className="icon" />
                        <div className="policy-title">{t('policy.cac_bien_phap')}</div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                    <Link className="wrap-method" to={`/${POLICY}/${OTHER_REGULATIONS}`}>
                        <IcOctagon className="icon" />
                        <div className="policy-title">{t('policy.cac_qui_dinh')}</div>
                        <IcArrowRight className="ic-arrow-right" />
                    </Link>
                </div>
            </div>
            <div className="page-two">
                <ContactPage />
            </div>
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(Policy)
