import React from 'react'

const IcCard = (props) => {
    return (
        <svg {...props} width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 20H46" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42 8H6C3.79086 8 2 9.79086 2 12V36C2 38.2091 3.79086 40 6 40H42C44.2091 40 46 38.2091 46 36V12C46 9.79086 44.2091 8 42 8Z" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 30H20" stroke="#191919" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCard
