import React from 'react'
import { withNamespaces } from 'react-i18next';

//style
import { WrapComponent } from './index.styles'

//image
import icon_eximbank from '../../../assets/images/ic_eximbank.png'
import logo_eximbank_mobile from '../../../assets/images/LogoEXIMBANK/logo_eximbank_mobile.png'
import logo_eximbank_pc from '../../../assets/images/LogoEXIMBANK/logo_eximbank_pc.png'

//component
import StepByStep from '../../Control/StepByStep';

//data
import { EximBankData } from '../../../assets/data/EximBankData';


const EximBank = ({ t, refPage }) => {

    return (
        <WrapComponent ref={refPage}>
            <div className="bank-page-eximbank">
                <div className='overlay' />
                <div className="block-left">
                    <img className="icon" src={icon_eximbank} alt="icon-eximbank" />
                </div>
                <div className="block-right">
                    <StepByStep
                        headLogo={{
                                mobile: logo_eximbank_mobile,
                                laptop: logo_eximbank_pc
                            }
                        }
                        headTitle={t('facePay_by_bank.exim_bank.title')}
                        defaultActiveStep={EximBankData[0].step}
                        lstStep={EximBankData}
                        isDisableStep={true}
                    />
                </div>
            </div>
        </WrapComponent>
    )
}

export default withNamespaces()(EximBank)
