import img_01 from '../images/Link/01.png'
import img_02 from '../images/Link/02.png'
import img_03 from '../images/Link/03.png'
import img_04 from '../images/Link/04.png'
import img_05 from '../images/Link/05.png'
import img_06 from '../images/Link/06.png'
import img_07 from '../images/Link/07.png'

export const ExperienceLinkData = [
    {
        step: 1,
        image: img_01,
        text: "tutorial.connect_page.link.0"
    },
    {
        step: 2,
        image: img_02,
        text: "tutorial.connect_page.link.1"
    },
    {
        step: 3,
        image: img_03,
        text: "tutorial.connect_page.link.2"
    },
    {
        step: 4,
        image: img_04,
        text: "tutorial.connect_page.link.3"
    },
    {
        step: 5,
        image: img_05,
        text: "tutorial.connect_page.link.4"
    },
    {
        step: 6,
        image: img_06,
        text: "tutorial.connect_page.link.5"
    },
    {
        step: 7,
        image: img_07,
        text: "tutorial.connect_page.link.6"
    },
]