import React from 'react'

export default function IconMute(props) {
    return (
        <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1666 9.16663L9.99996 12.5H6.66663V17.5H9.99996L14.1666 20.8333V9.16663Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.8916 9.10828C22.4539 10.671 23.3315 12.7902 23.3315 14.9999C23.3315 17.2096 22.4539 19.3289 20.8916 20.8916M17.95 12.0499C18.7311 12.8313 19.1699 13.8909 19.1699 14.9958C19.1699 16.1006 18.7311 17.1602 17.95 17.9416" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
