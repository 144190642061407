
import Axios from 'axios'
//host
import { host } from '../../assets/constant/host'
//help
import checkIsSuccessAction from '../../assets/helps/checkIsSuccessAction'
//type
import * as types from './types'

const apiFormRegister = `${host}/partner/create`

export const formRegister = ({ representativeName, businessName, phoneNumber, email }, callbackSuccess, callbackError) => async dispatch => {
    const condFilters = {
        representativeName,
        businessName,
        phoneNumber,
        email,        
    }
    const dataRequest = JSON.stringify(condFilters)
    try {
        const res = await Axios.post(apiFormRegister, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.FORM_REGISTER,
                payload: {
                    result: condFilters,
                    code: data.code
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.FORM_REGISTER_ERR,
                payload: {
                    ...data,
                    code: data.code
                }
            })
            callbackError && callbackError()
        }

    } catch (error) {
        // dispatch(mapErrors(error, types.FORM_REGISTER_ERR))
    }

}