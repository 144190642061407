import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

//constant
import { POLICY } from '../../../assets/constant/pathNames';


const InfomationSecurity = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${POLICY}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('infomation_security.title_page')}</p>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t('infomation_security.date_page')}</div>
                </div> */}
                <div className="content-page">
                    <p className="wrapper"><span className="title">1. </span>Chúng tôi cam kết và sẽ áp dụng các biện pháp hợp lý để bảo đảm tính an toàn, bảo mật của các thông tin cá nhân được thu thập, phong ngừa các rủi ro ăn cắp thông tin, tiếp cận, thay đổi hoặc phá hủy thông tin trái phép.</p>
                    <p className="wrapper"><span className="title">2. </span>Chúng tôi áp dụng các biện pháp an ninh khác nhau để đảm bảo tính bảo mật thông tin của bạn trên hệ thống. Thông tin của bạn sẽ được lưu trữ trong một cơ sở bảo mật và chỉ có thể được tiếp cận bởi một số ít các nhân sự có nhiệm vụ của chúng tôi.</p>
                    <p className="wrapper"><span className="title">3. </span>Trong phạm vi pháp luật cho phép, chúng tôi lưu trữ thông tin của bạn trong thời gian cần thiế để chúng tôi có thể cung cấp Dịch vụ vh@ fpa cho bạn. Chúng tôi sẽ tiêu hủy hoặc chuyển đổi thông tin sang chế độ vô danh ngay khi chúng tôi xác định rằng:</p>
                    <p className="note">a) Các mụch đích mà thông tin cần thu thập đã không còn phù hợp để lưu giữ dữ liệu;</p>
                    <p className="note">b) Việc lưu giữ dữ liệu đã không còn cần thiết cho mục đích pháp lý hoặc kinh doanh; hoặc</p>
                    <p className="note">c) Bạn không còn nhu cầu sử dụng Dịch vụ vh@ fpa và đã chọn đóng Tài khoản Facepay của bạn.</p>
                    <p className="wrapper"><span className="title">4. </span>Chúng tôi cũng khuyến khích bạn thực hiện các biện pháp cần thiết để bảo vệ Tài khoản Facepay của bạn bằng cách không chia sẽ, trao đổi Tài khoản Facepay, và không cung cấp mật khẩu đăng nhập hoặc OTP cho bất kỳ ai khác. Nếu bạn dùng chung máy tính, thiết bị di động với người dùng khác, bạn không nên chọn lưu các thông tin đăng nhập (như username, password) trên thiết bị dùng chung đó và bạn nên đăng xuất Tài khoản Facepay của bạn và tắt trình duyệt khi dùng xong. Wee Digital sẽ không chịu trách nhiệm về bất kỳ thiệt hại, hoặc rò rĩ thông tin nếu bạn vi phạm Chính sách này.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(InfomationSecurity)
