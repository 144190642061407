import { useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// styles
import { WrapApp } from './App.styles';

//constant
import * as pathNames from './assets/constant/pathNames'

// component
import Header from './components/Header';
import Home from './components/Home';
import Tutorial from './components/Tutorial';
import Support from './components/Support';
import Communication from './components/Communication';
import Partner from './components/Partner';
import Policy from "./components/Policy";
import TermsOfUse from "./components/TermsOfUser";
import AccountManagement from "./components/Support/AccountManagement";
import BasicInfomation from "./components/Support/BasicInfomation";
import PaymentAtCounter from "./components/Support/PaymentAtCounter";
import ResolveComplaint from "./components/Support/ResolveComplaint";
import SignUpAccount from "./components/Support/SignUpAccount";
import QAndADetails from "./components/QAndADetails";
import CommunicationPost from "./components/CommunicationPost";

import ScopeOfApplication from './components/Policy/ScopeOfApplication';
import InfomationColleted from './components/Policy/InfomationColleted';
import FacepayPurposes from './components/Policy/FacepayPurposes';
import ShareAndDisclose from './components/Policy/ShareAndDisclose';
import ChooseTheRightPrivate from './components/Policy/ChooseTheRightPrivate';
import PrivacyPolicyChanges from './components/Policy/PrivacyPolicyChanges';
import InfomationSecurity from './components/Policy/InfomationSecurity';
import OtherRegulation from './components/Policy/OtherRegulation';

import Recruitment from "./components/Recruitment";
import Founder from './components/Founder';
import WeeDigital from './components/WeeDigital';
import RecruitmentPost from './components/Recruitment/RecruitmentPost';
import FacePayMagicFAQ from './components/Support/FacePayMagicFAQ';
import FacePayMagic from './components/FacePayMagic';
import AccessAndCustomize from './components/Policy/AccessAndCustomize';
import FacePayByBank from './components/FacePayByBank';
import FacepayPaymentStores from './components/Support/FacepayPaymentStores';


const App = () => {
  const history = useHistory()
  useEffect(() => {
    history.listen(() => {
      window.scrollTo(0, 0)
    })
  }, []);

  return (
    <WrapApp>
      <Header />
      <Switch>
        <Route exact path={`/${pathNames.HOME}`}>
          <Home />
        </Route>
        {/* <Route path={`/${pathNames.FACEPAY_MAGIC}`}>
          <FacePayMagic />
        </Route> */}
        <Route path={`/${pathNames.FACEPAY_BY_BANK}/:subSupportType?`}>
          <FacePayByBank />
        </Route>
        <Route path={`/${pathNames.TUTORIAL}`}>
          <Tutorial />
        </Route>
        <Route exact path={`/${pathNames.SUPPORT}`}>
          <Support />
        </Route>
        <Route exact path={`/${pathNames.FACEPAY_PAYMENT_STORES}`}>
          <FacepayPaymentStores />
        </Route>
        <Route exact path={`/${pathNames.SUPPORT}/${pathNames.ACCOUNT_MANAGEMENT}/:subSupportType?`}>
          <AccountManagement />
        </Route>
        <Route exact path={`/${pathNames.SUPPORT}/${pathNames.BASIC_INFOMATION}`}>
          <BasicInfomation />
        </Route>
        <Route exact path={`/${pathNames.SUPPORT}/${pathNames.PAYMENT_AT_COUNTER}`}>
          <PaymentAtCounter />
        </Route>
        <Route exact path={`/${pathNames.SUPPORT}/${pathNames.RESOLVE_COMPLAINT}/:subSupportType?`}>
          <ResolveComplaint />
        </Route>
        <Route exact path={`/${pathNames.SUPPORT}/${pathNames.SIGN_UP_ACCOUNT}/:subSupportType?`}>
          <SignUpAccount />
        </Route>
        <Route exact path={`/${pathNames.SUPPORT}/${pathNames.FAQ_FACEPAY_MAGIC}/:subSupportType?`}>
          <FacePayMagicFAQ />
        </Route>
        <Route path={`/${pathNames.SUPPORT}/:supportType/:subSupportType?/:qAKey`}>
          <QAndADetails />
        </Route>
        <Route exact path={`/${pathNames.COMMUNICATION}`}>
          <Communication />
        </Route>
        <Route path={`/${pathNames.COMMUNICATION}/:postKey`}>
          <CommunicationPost />
        </Route>
        <Route path={`/${pathNames.PARTNER}`}>
          <Partner />
        </Route>
        <Route exact path={`/${pathNames.POLICY}`}>
          <Policy />
        </Route>
        <Route path={`/${pathNames.POLICY}/${pathNames.SCOPE_APPL}`}>
          <ScopeOfApplication />
        </Route>
        <Route path={`/${pathNames.POLICY}/${pathNames.INFOMATION_COLLETED}`}>
          <InfomationColleted />
        </Route>
        <Route path={`/${pathNames.POLICY}/${pathNames.FACEPAY_PURPOSES}`}>
          <FacepayPurposes />
        </Route>
        <Route path={`/${pathNames.POLICY}/${pathNames.SHARE_AND_DISCLOSE}`}>
          <ShareAndDisclose />
        </Route>
        <Route path={`/${pathNames.POLICY}/${pathNames.CHOOSE_THE_RIGHT_PRIVATE}`}>
          <ChooseTheRightPrivate />
        </Route>
        <Route path={`/${pathNames.POLICY}/${pathNames.ACCESS_AND_CUSTOMIZE}`}>
          <AccessAndCustomize />
        </Route>
        <Route path={`/${pathNames.POLICY}/${pathNames.PRIVACY_POLICY_CHANGES}`}>
          <PrivacyPolicyChanges />
        </Route>
        <Route path={`/${pathNames.POLICY}/${pathNames.INFOMATION_SECURITY}`}>
          <InfomationSecurity />
        </Route>
        <Route path={`/${pathNames.POLICY}/${pathNames.OTHER_REGULATIONS}`}>
          <OtherRegulation />
        </Route>
        <Route path={`/${pathNames.TERMS_OF_USE}`}>
          <TermsOfUse />
        </Route>
        <Route path={`/${pathNames.FOUNDER}`}>
          <Founder />
        </Route>
        <Route path={`/${pathNames.WEE_DIGITAL}`}>
          <WeeDigital />
        </Route>
        <Route exact path={`/${pathNames.RECRUITMENT}`}>
          <Recruitment />
        </Route>
        <Route path={`/${pathNames.RECRUITMENT}/:postKey`}>
          <RecruitmentPost />
        </Route>
        <Redirect to={`/${pathNames.HOME}`} />
      </Switch>
    </WrapApp>
  );
}

export default App;
