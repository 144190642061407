import styled from 'styled-components'

const WrapPage = styled.div`
    .page-one{        
        min-height: 778px;
        height: fit-content;
        padding: 147px 24px 37px 24px;
        font-family: "SVN-Gilroy";
        font-style: normal;
        .go-back{
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 130%;
            color: #1279DA;
            text-decoration: none;
        }
        .title-page{
            font-weight: bold;
            font-size: 36px;
            line-height: 39px;
            padding: 14px 0 24px 0;
            color: #304659;
        }
        .wrap-share-date {
            display: flex;
            align-items: center;
            padding-bottom: 20px;         
            button {
                height: 20px;
                border: none;
                outline: none;
                background: #395CA5;
                border-radius: 4px;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 130%;
                color: #F7FCFF;
                display: flex;
                align-items: center;
                padding: 3px 7px;
                box-sizing: border-box;
                margin-right: 7px;
                .ic-share {
                    height: 100%;
                    border-right: 1px solid #ffffff4d;
                    padding-right: 5px;
                    margin-right: 5px;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            div {
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                color: #939393;
            }
        }
        .content-page{
            /* padding-top: 30px; */
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            text-align: justify;
            color: #191919;
            .title-large{
                padding-top: 15px;
                font-weight: bold;
                font-size: 22px;
                text-align: start;
            }
            .wrapper{
                padding-top: 15px;
            }
            .title{      
                padding-top: 15px;          
                font-weight: bold;
                font-size: 18px;
            }
            .content{
                padding-top: 10px;
            }
            .note{
                padding-top: 10px;
                padding-left: 15px;
            }
        }
    }

    @media only screen and (min-width: 768px) {        
        .page-one{
            max-width: 1200px;
            margin: 0 auto;
            padding: 141px 0px 50px 0px;
            .go-back{
                font-size: 14px;
                line-height: 130%;
            }
            .title-page{
                font-size: 50px;
                line-height: 52px;
            }
            .wrap-share-date{
                div {
                    font-size: 18px;
                }
            }
            .date-page{
                font-size: 18px;
                line-height: 23px;
            }
            .content-page{
                /* padding-top: 40px; */
                font-size: 18px;
                line-height: 23px;
                .title-large{
                    padding-top: 20px;
                    font-weight: bold;
                    font-size: 24px;
                }
                .wrapper{
                    padding-top: 20px;
                }
                .title{
                    padding-top: 20px;
                    font-weight: bold;
                    font-size: 20px;
                }
                .content{
                    padding-top: 15px;
                }
                .note{
                    padding-top: 15px;
                    padding-left: 20px;
                }
            }
        }
    }
`
export { WrapPage }