import styled from 'styled-components'

const WrapStepByStep = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto) 400px 1fr;
    justify-items: center;
    width: 100%;
    height: 100%;
    .logo-bank{
        background-image: ${props => props.logoMobile ? `url(${props.logoMobile})`: 'unset'};
        background-size: cover;
        background-position: 0 0;
        background-repeat:no-repeat;
        background-position:center;
        width: 219px;
        height: 87px;
        box-sizing: border-box;
        margin-top: 17px;
    }
    .title-bank{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        width: calc(327px + (470 - 327) * ((100vw - 375px) / (768 - 375)));
        margin: 10px 0 72px 0;
        text-align: center;
    }
    .image-container{
        display: flex;
        height: 100%;
        position: relative;
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
        .wrap-slide-image{
            display: flex;
            align-items: center;
            grid-column: 1 / span 1;
            grid-row: 1 / span 3;
            .image-wrap{
                display: flex;
                align-items: center;
                position: relative;
                width: 234px;
                height: 100%;
                flex-grow: 1;
                overflow: hidden;
                .image{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    flex-shrink: 0;
                    transition: transform 0.3s ease-in-out;
                    display: flex;
                    justify-content: center;
                    &.previous {
                        transform: translateX(-100%);
                        z-index: 0;
                    }
                    &.active{
                        transform: translateX(0);
                        z-index: 1;
                    }
                    &.next {
                        transform: translateX(100%);
                        z-index: 0;
                    }

                    img {
                        height: 100%;
                    }
                }
            }
            .icon-previous-wrap, .icon-next-wrap{
                flex-shrink: 0;
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                .icon-previous, .icon-next{
                    cursor: pointer;
                    height: 40px;
                    width: 40px;
                }                
            }
        }
    }
    .content-container{
        padding: 42px 0 63px;
        .step-wrap{
            display: flex;
            justify-content: center;
            cursor: pointer;
            user-select: none;
            .cycle{
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background: #FFFFFF;
                position: relative;
                margin: 0 14px 0 0;                
                &:last-of-type{
                    margin: 0;
                }
                &.wrapper{
                    background: rgba(18, 121, 218, 0.2);                 
                }
                .step{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    color: #FFFFFF;
                    height: 24px;
                    width: 24px;
                    background: #EEEEEE;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 18px 0 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding-top: 2px;
                    box-sizing: border-box;
                    &.active{
                        background: #1279DA;                        
                        font-weight: bold;
                    }
                }
            }
        }
        .text-wrap{
            display: flex;
            overflow-x: auto;
            scroll-behavior: smooth;
            padding: 42px 0 0;
            justify-content: center;
            margin: 0 auto;
            .text{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: #232323;                
                overflow: hidden;
                flex-shrink: 0;
                display: none;
                &.active{
                    display: block;
                    width: 300px;
                    text-align: center;
                }

                a {
                    text-decoration: none;
                    color: #1279DA;
                }
            }
        }
    }
    

    @media only screen and (min-width: 768px){
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto) 400px 1fr;
        justify-items: center;
        .logo-bank{
            background-image: ${props => props.logoLaptop ? `url(${props.logoLaptop})`: 'unset'};
            width: 219px;
            margin: 17px 0 0 0;
        }
        .title-bank{
            font-size: calc(20px + (22 - 20) * ((100vw - 768px) / (1024 - 768)));
            line-height: calc(26px + (28 - 26) * ((100vw - 768px) / (1024 - 768)));
            width: calc(380px + (470 - 380) * ((100vw - 768px) / (1024 - 768)));
            color: #191919;
        }
        .image-container{
            .wrap-slide-image{ 
                position: absolute;
                top: 0;
                right: 50%;
                transform: translateX(50%);
                height: 400px;       
                .image-wrap{
                    width: 184px;
                }
                .icon-previous-wrap{
                    margin-right: 32px;
                }
                .icon-next-wrap{
                    margin-left: 32px;
                }
            }
        }
    }

    @media only screen and (min-width: 1024px){
        grid-template-columns: 1fr calc(250px + (394 - 250) * ((100vw - 1024px) / (1440 - 1024)));
        grid-template-rows: repeat(2, auto) 1fr;
        justify-items: unset;
        padding-left: calc(50px + (150 - 50) * ((100vw - 1024px) / (1440 - 1024)));
        .logo-bank{
            margin: 63px 0 0 -30px;
            width: 253px;
        }
        .title-bank{
            font-size: calc(20px + (22 - 20) * ((100vw - 1024px) / (1440 - 1024)));
            line-height: calc(26px + (28 - 26) * ((100vw - 1024px) / (1440 - 1024)));
            width: calc(380px + (579 - 380) * ((100vw - 1024px) / (1440 - 1024)));
            margin: 23px 0 36px;
            text-align: justify;
        }
        .content-container{
            width: calc(380px + (579 - 380) * ((100vw - 1024px) / (1440 - 1024)));
            padding: 0;
        }
        .image-container{
            grid-column: 2 / span 1;
            grid-row: 1 / span 3;
            .wrap-slide-image{
                top: 100px;
                right: calc(-130px + (-105 + 130) * ((100vw - 1024px) / (1440 - 1024)));
                transform: unset;
                height: 480px;
                .image-wrap{
                    width: 221px;
                }
                .icon-previous-wrap{
                    margin-right: 32px;
                }
                .icon-next-wrap{
                    margin-left: 32px;
                }
            }
        }
        .content-container{
            display: grid;
            grid-template-columns: 40px 1fr;
            grid-gap: 17px;
            .step-wrap{
                display: grid;
                grid-template-rows: repeat(${props => props.stepRow ? `${props.stepRow}`: 1}, 40px);
                grid-gap: 25px;
                .cycle{
                    height: 40px;
                    width: 40px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: unset;
                    .step{
                        font-size: 15px;
                        color: #898989;
                        height: 34px;
                        width: 34px;
                        &.active{
                            height: 28px;
                            width: 28px;
                            border-radius: 50%;
                            font-size: 18px;
                            color: #FFFFFF;
                        }
                    }
                }                
            }
            .text-wrap{
                display: grid;
                grid-template-rows: repeat(${props => props.stepRow ? `${props.stepRow}`: 1}, 40px);
                grid-gap: 25px;
                text-align: unset;
                justify-content: unset;
                padding: 0;
                margin: 0;
                width: fit-content;
                .text{                    
                    overflow: unset;
                    display: block;
                    align-self: center;
                    width: fit-content;
                    cursor: pointer;
                    &.active {
                        text-align: unset;
                        width: fit-content;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1440px){
        grid-template-columns: 1fr 394px;
        padding-left: 150px;
        .title-bank{
            font-size: 22px;
            line-height: 28px;
            width: 579px;
        }
        .image-container{
            .wrap-slide-image{
                right: -105px;
            }
        }
    }
`;

export {
    WrapStepByStep
}