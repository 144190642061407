import React, { useRef, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import { useParams } from 'react-router-dom'

// styles
import { WrapFacePageByBank } from './index.styles'

//image
import bg_facepay_by_bank from '../../assets/images/bg_facepay_by_bank2.png'

//component
import TpBank from './TpBank';
import OcbBank from './OcbBank';
import VietinBank from './VietinBank';
import SmartPay from './SmartPay';
import PageSix from '../Home/PageSix';
import EximBank from './EximBank';

//constant
import { OCB, TPBANK, VTB, SP, EXIM } from '../../assets/constant/pathNames';

const FacePayByBank = ({ t }) => {

    const refPageTPBank = useRef()
    const refPageOCBBank = useRef()
    const refPageVTBBank = useRef()
    const refPageSmartPay = useRef()
    const refPageEximBank = useRef()

    const { subSupportType } = useParams()

    useEffect(() => {
        // console.log("ping",subSupportType);
        if (subSupportType === TPBANK) {
            refPageTPBank.current.scrollIntoView({ behavior: "smooth", block: "end" })
        }
        if (subSupportType === OCB) {
            refPageOCBBank.current.scrollIntoView({ behavior: "smooth", block: "end" })
        }
        if (subSupportType === VTB) {
            refPageVTBBank.current.scrollIntoView({ behavior: "smooth", block: "end" })
        }
        if (subSupportType === SP) {
            refPageSmartPay.current.scrollIntoView({ behavior: "smooth", block: "end" })
        }
        if (subSupportType === EXIM) {
            refPageEximBank.current.scrollIntoView({ behavior: "smooth", block: "end" })
        }
    }, [subSupportType])

    return (
        <WrapFacePageByBank>
            <div className="wrap-home-page">
                <div className="home-page">
                    <div className="content-page">
                        <p className="title-page">{t('facePay_by_bank.page_one.title1')}</p>
                        <p className="title-page blue">{t('facePay_by_bank.page_one.title2')}</p>
                        <p className="text-page">{t('facePay_by_bank.page_one.text1')}</p>
                    </div>
                    <div className="bg_image" >
                        <img src={bg_facepay_by_bank} alt="bg_facepay_by_bank" />
                    </div>
                </div>
            </div>
            <TpBank refPage={refPageTPBank} />
            <OcbBank refPage={refPageOCBBank} />
            <VietinBank refPage={refPageVTBBank}/>
            <SmartPay refPage={refPageSmartPay} />
            <EximBank refPage={refPageEximBank} />
            <PageSix />
        </WrapFacePageByBank>
    )
}
export default withNamespaces()(FacePayByBank)
