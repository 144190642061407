import { ACCOUNT_MANAGEMENT, FACEPAY, FACEPAY_PAYMENT_STORES, PAYMENT_AT_COUNTER, SIGN_UP_ACCOUNT, SUPPORT } from '../constant/pathNames'
import bg_logo_bank from '../images/FAQ/BasicInfomation/bg_logo_bank.png'

export const BasicInfomationData = {
    q1: {
        key: "q1",
        date: "basic_infomation.q_01.date",
        title: "basic_infomation.q_01.title",
        desc: [
            "basic_infomation.q_01.desc.desc1",
        ],
    },
    // q2: {
    //     key: "q2",
    //     date: "basic_infomation.q_02.date",
    //     title: "basic_infomation.q_02.title",
    //     desc: [
    //         "basic_infomation.q_02.desc.desc1",
    //         "basic_infomation.q_02.desc.desc2",
    //     ],
    // },
    q3: {
        key: "q3",
        date: "basic_infomation.q_03.date",
        title: "basic_infomation.q_03.title",
        desc: [
            "basic_infomation.q_03.desc.desc1",
        ],
    },
    q4: {
        key: "q4",
        date: "basic_infomation.q_04.date",
        title: "basic_infomation.q_04.title",
        desc: [
            "basic_infomation.q_04.desc.desc1",
        ],
    },
    q6: {
        key: "q6",
        date: "basic_infomation.q_06.date",
        title: "basic_infomation.q_06.title",
        desc: [
            "basic_infomation.q_06.desc.desc1",
        ],
    },
    q7: {
        key: "q7",
        date: "basic_infomation.q_07.date",
        title: "basic_infomation.q_07.title",
        desc: [
            "basic_infomation.q_07.desc.desc1",
        ],
    },
    q8: {
        key: "q8",
        date: "basic_infomation.q_08.date",
        title: "basic_infomation.q_08.title",
        desc: [
            "basic_infomation.q_08.desc.desc1",
            // "basic_infomation.q_08.desc.desc2",
        ],
    },
    q18: {
        key: "q18",
        date: "basic_infomation.q_18.date",
        title: "basic_infomation.q_18.title",
        desc: [
            "basic_infomation.q_18.desc.desc1",
            "basic_infomation.q_18.desc.desc2",
            "basic_infomation.q_18.desc.desc3",
            "basic_infomation.q_18.desc.desc4",
        ],
        link_here: {
            href: `/${SUPPORT}/${SIGN_UP_ACCOUNT}/${FACEPAY}/q1`,
            target: "_blank"
        },
    },
    q9: {
        key: "q9",
        date: "basic_infomation.q_09.date",
        title: "basic_infomation.q_09.title",
        desc: [
            "basic_infomation.q_09.desc.desc1",
            "basic_infomation.q_09.desc.desc2",
        ],
    },
    q10: {
        key: "q10",
        date: "basic_infomation.q_10.date",
        title: "basic_infomation.q_10.title",
        desc: [
            "basic_infomation.q_10.desc.desc1",
            "basic_infomation.q_10.desc.desc2",
        ],
        image: {
            url: bg_logo_bank,
            alt: "logo_bank"
        }
    },
    // q11: {
    //     key: "q11",
    //     date: "basic_infomation.q_11.date",
    //     title: "basic_infomation.q_11.title",
    //     link_here: {
    //         href: `/${SUPPORT}/${ACCOUNT_MANAGEMENT}/q6`,
    //         target: "_blank"
    //     },
    //     desc: [
    //         "basic_infomation.q_11.desc.desc1",
    //         // "basic_infomation.q_11.desc.desc2",
    //         // "basic_infomation.q_11.desc.desc3",
    //     ]
    // },
    q12: {
        key: "q12",
        date: "basic_infomation.q_12.date",
        title: "basic_infomation.q_12.title",
        link_here: {
            href: `/${FACEPAY_PAYMENT_STORES}/`,
            target: "_blank"
        },
        desc: [
            "basic_infomation.q_12.desc.desc1",
            "basic_infomation.q_12.desc.desc2",
        ]
    },
    q13: {
        key: "q13",
        date: "basic_infomation.q_13.date",
        title: "basic_infomation.q_13.title",
        link_here_1: {
            href: `/${SUPPORT}/${PAYMENT_AT_COUNTER}/q10`,
            target: "_blank"
        },
        link_here_2: {
            href: `/${SUPPORT}/${PAYMENT_AT_COUNTER}/q11`,
            target: "_blank"
        },
        desc: [
            "basic_infomation.q_13.desc.desc1",
            "basic_infomation.q_13.desc.desc2",
            // "basic_infomation.q_13.desc.desc3",
        ]
    },
    // q14: {
    //     key: "q14",
    //     date: "basic_infomation.q_14.date",
    //     title: "basic_infomation.q_14.title",
    //     desc: [
    //         "basic_infomation.q_14.desc.desc1",
    //     ]
    // },
    // q15: {
    //     key: "q15",
    //     date: "basic_infomation.q_15.date",
    //     title: "basic_infomation.q_15.title",
    //     desc: [
    //         "basic_infomation.q_15.desc.desc1",
    //     ]
    // },
    q16: {
        key: "q16",
        date: "basic_infomation.q_16.date",
        title: "basic_infomation.q_16.title",
        desc: [
            "basic_infomation.q_16.desc.desc1",
        ]
    },
    q17: {
        key: "q17",
        date: "basic_infomation.q_17.date",
        title: "basic_infomation.q_17.title",
        link_email: {
            href: "mailto: cs@facepay.vn"
        },
        link_phone_number: {
            href: "tel: 02037101010"
        },
        desc: [
            "basic_infomation.q_17.desc.desc1",
            "basic_infomation.q_17.desc.desc2",
            "basic_infomation.q_17.desc.desc3",
        ]
    },
}