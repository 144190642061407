import styled from 'styled-components'

const WrapPage = styled.div`
    padding-top: 100px;
    font-family: 'SVN-Gilroy';
    background-image: -webkit-linear-gradient(top, #F7FCFF, #F7FCFF 35%, transparent 35%, transparent 100%);    
      
    .page-one {
        padding: 0 24px;
        height: fit-content;
        min-height: 788px;
        .title {
            margin-top: 23px;
            margin: 23px auto 0;
            color: #304659;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 39px;
            text-align: center;
            width: 250px;
            span {
                color: #1279DA;
            }
        }
        .desc {
            margin: 30px 0 0 0;
            font-style: normal;
            font-weight: normal;
            color: #304659;
            text-align: center;
            font-size: 15px;
            line-height: 21px;
            a{
                color: #1279DA;
                text-decoration: none;
            }
        }
        .list-policy-method {
            margin-top: 46px;
            .icon{
                height: 30px;
                width: 30px;
            }
            .policy-title {
                font-style: normal;
                font-weight: bold;
                color: #304659;
                font-size: 15px;
                line-height: 21px;
            }             
            .wrap-method {
                background: #FFFFFF;
                box-sizing: border-box;
                box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
                border-radius: 12px;
                padding: 24px 8px 24px 21px;
                /* width: 327px; */
                height: 83px;
                display: grid;
                grid-template-columns: 30px 1fr 18px;
                grid-gap: 26px;
                align-items: center;
                cursor: pointer;
                margin-top: 8px;  
                text-decoration: none;               
            }
        }
    }
    .page-two{
        margin-top: 52px;
    }

    @media only screen and (min-width: 768px) {      
        /* padding-top: 0; */
        .page-one {
            padding-bottom: unset;
            max-width: 1200px;
            margin: 0 auto;
            padding: 0;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title {
                margin-top: 64px auto 0;
                font-size: 50px;
                line-height: 52px;
                width: unset;
            }  
            .desc {
                margin: 30px auto 0;
                font-size: 16px;
                line-height: 22px;
                width: 1037px;
            }
            
            .list-policy-method {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(3, auto);
                grid-column-gap: 30px;
                grid-row-gap: 12px;
                margin-top: 76px;
                .icon{
                    width: 48px;
                    height: 48px;
                }
                .policy-title {
                    font-size: 16px;
                    line-height: 23px;
                }
                .ic-arrow-right {
                    display: none;
                }

                .wrap-method{
                    padding: 24px 25px 19px 25px;
                    /* width: 262px; */
                    height: 157px;
                    grid-template-columns: unset;
                    grid-template-rows: 48px 1fr;

                    align-items: unset;
                    margin-top: unset                    
                }
            }
        }
        .page-two{
            margin-top: unset;
        }         
    }
`;

export {
    WrapPage
}