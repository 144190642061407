import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

//constant
import { POLICY } from '../../../assets/constant/pathNames';


const OtherRegulation = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${POLICY}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('other_regulation.title_page')}</p>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t('other_regulation.date_page')}</div>
                </div> */}
                <div className="content-page">
                    <p className="wrapper"><span className="title">1. </span>Việc Wee Digital không thực hiện hoặc áp dụng bất kỳ quyền lợi hoặc biện pháp nào của Wee Digital theo Chính sách này không được xem là một sự từ bỏ hay hạn chế của Wee Digital, và Wee Digital có toàn quyền thực hiện cái quyền hoặc biện pháp này tại bất kỳ thời điểm nào mà Wee Digital đánh giá là thích hợp</p>
                    <p className="wrapper"><span className="title">2. </span>Nếu có bất kỳ điều nào của Chính sách này được xem là không hợp pháp hoặc không có hiệu lực theo quy định của pháp luật vh@ các cơ quan chức năng, Wee Digital sẽ điều chỉnh các điều khoản này, hoặc (tùy theo quyết định của Wee Digital) loại bỏ các điều khoản đó khỏi Chính sách này, đến phạm vi đủ để Chính sách này hợp pháp và có hiệu lực. Việc một điều khoản không có hiệu lực pháp lý sẽ không làm ảnh hướng đến các điều khoản khác của Chính sách này, các điều khoản khác vẫn có hiệu lực toàn vẹn.</p>
                    <p className="wrapper"><span className="title">3. </span>Bạn đồng ý rằng Wee Digital, theo quy định của pháp luật hoặc khi có yêu cầu từ cơ quan chức năng, có thể sẽ thực hiện các hành động trái với các điều khoản của Chính sách này. Theo đó, bạn đồng ý sẽ không buộc Wee Digital chịu trách nhiệm trong trường hợp này.</p>
                    <p className="wrapper"><span className="title">4. </span>Người dùng không được chuyển các quyền trong Chính sách này nếu như không có sự đồng ý bằng văn bản của chúng tôi. Wee Digital có thể chuyển quyền trong Chính sách này không cần có sự đồng ý bằng văn bản của bạn.</p>
                    <p className="wrapper"><span className="title">5. </span>Chính sách này sẽ ràng buộc đến người thừa kế, đại diện ủy quyền, đại diện pháp lý của bạn (nếu có)</p>
                    <p className="wrapper"><span className="title">6. </span>Bất kỳ tranh chấp hoặc sự bất đồng nào liên quan đến Chính sách này sẽ được giải quyết theo phương pháp hòa giải trước. Nếu một thỏa thuận không thể đạt được bằng phương pháp hòa già, các bên liên quan đồng ý sẽ giải quyết tranh chấp hoặc phất đồng tại tòa án ở Thành phố Hồ Chí Minh.</p>
                    <p className="wrapper"><span className="title">7. </span>Các tranh chấp giữa bạn và bên thứ ba: Trong trường hợp này, Wee Digital sẽ không có nghĩa vụ liên quan ngoại trừ việc hỗ trợ bạn trong việc cung cấp cho bạn và bên thứ ba các thông tin cần thiết trong khả năng của Wee Digital để giải quyết các tranh chấp giữa bạn và bên thứ ba. Bạn và bên thứ ba phải trực tiếp giải quyết tất cả vấn đề liên quan đến giao dịch giữa bạn và bên đó.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(OtherRegulation)
