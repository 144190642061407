import styled, { css } from 'styled-components'

const WrapDropdownList = styled.div`  
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;    
    height: 48px;
    margin-bottom: 36px;
    .wrap-title{
        width: 100%;
        height: 48px;
        background: #FFFFFF;
        border: 0.1px solid #939393;
        box-sizing: border-box;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
        border-radius: 6px;       
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        &.err{
            border: 0.5px solid #FF3131;
        }
        .value{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            color: #191919;
            position: relative;
            /* display: block; 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; */
            &.title{
                color: #939393;
            }
        }
        .note{
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #FF2028;
            position: absolute;
            top: 3px;
            right: -7px;
        }
    }
    .errro{
        position: absolute;
        top: 53px;
        left: 15px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: #FF3131;
    }
   
    .list-drop-down {
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        position: absolute;
        left: 0px;
        top: 60px;
        width: 100%;
        z-index: 1;

        .wrap-selection {
            max-height: calc(56px * 5);
            overflow: overlay;
            :first-of-type{
                border-radius:6px;
            }
            .item{
                background: #FFFFFF;
                box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
                height: 56px;
                padding: 0 10px 0 24px;
                outline: none;
                display: flex;
                align-items: center;
                .name{
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 23px;
                    color: #191919;
                }
                &.active{
                    background: #F7FCFF;
                }
            }
        }
    }
    &:hover { 
        cursor: pointer;
    }
`;

export {
    WrapDropdownList
}