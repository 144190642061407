import React from 'react'
import { withNamespaces } from 'react-i18next';

//data
import { ExperienceDownloadData } from '../../../assets/data/ExperienceDownloadData';

// component
import StepByStep from '../../Control/StepByStep';

const Downloads = ({ t }) => {

    return (
        <>
            {/* <p className="title-step">{t('tutorial.connect_page.title_download')}</p> */}
            <StepByStep
                defaultActiveStep={ExperienceDownloadData[0].step}
                lstStep={ExperienceDownloadData}
                isDisableChangePositionImage={true}
            />
        </>
    )
}

export default withNamespaces()(Downloads)
