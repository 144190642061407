import { createStore, applyMiddleware, compose } from 'redux';
import mainReducer from './mainReducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from "@redux-devtools/extension";


const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
});

const middleware = [thunk]


const configureStore = (initialState = {}) => {
    return createStore(
        mainReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware))
    );
};

export default configureStore

// export default function configureStore(initialState={}) {
//     const composeEnhancers = typeof window === 'object' &&
//         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//             trace: true,
//             traceLimit: 25 
//         }) : compose;

//     let store = createStore(
//         mainReducer,
//         initialState,
//         composeEnhancers(applyMiddleware(thunk))
//     )
//     return store
// }
