import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationVI from './vi/translation.json';
// console.log(translationVI)
// the translations
const resources = {
    vi: {
        translation: translationVI
    }
};

i18n
    .use(reactI18nextModule)
    .init({
        resources,
        lng: "vi",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;