import React from 'react'

// styles
import { WrapHome } from './index.styles'

// component
import PageOne from './PageOne'
import PageTwo from './PageTwo'
import PageThree from './PageThree'
import PageFour from './PageFour'
import PageFive from './PageFive'
import PageSix from './PageSix'
import PageSeven from './PageSeven'

const Home = () => {
    return (
        <WrapHome>
            <PageOne />
            <PageTwo />
            <PageThree />
            <PageFour />
            <PageSeven />
            {/* <PageFive /> */}
            <PageSix />
        </WrapHome>
    )
}

export default Home
