import styled from 'styled-components'

const WrapFacePageMagic = styled.div`
    .page-wrapper{
        height: fit-content;
        min-height: 788px;
        padding: 40px 32px 0 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.page_one{
            padding-top: 130px;
        }
        &.page_three{
            .lottie{
                svg{
                    /* margin-left: 40px; */
                }
            }
        }
        &.page_four{
            .lottie{
                svg{
                    /* margin-left: -40px; */
                }
            }
        }
        .content-text{
            .title-page{
                font-family: 'SVN-Bebas Neue';
                font-style: normal;
                font-weight: bold;
                font-size: 74px;
                line-height: 72px;
                color: #304659;
                letter-spacing: -1px;
                text-transform: uppercase;
                &.blue{
                    color: #1279DA;
                }
            }
            .content-page{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                color: #000000;
                padding-top: 34px;
                &.bold{
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                    padding-top: 42px;
                    }
                &.desc{
                    display: flex;
                    flex-direction: column;
                }
            }            
        }
        .lottie{
            padding-top: 20px;
            width: unset;
            height: fit-content;
            svg{
                height: 390px !important;
            }
            &.page-one{
                svg{
                    width: unset !important;
                    height: 412px !important;
                }
            }
        }
    }
    .wrapper-footer{
        .page-wrapper{
            padding: 0 24px 22px 24px;
            width: calc(100% - 64px);
            align-items: flex-start;
            min-height: 0;
        }
    }
    @media only screen and (min-width: 510px) and (max-width: 829px){
        .page-wrapper{
            .lottie{
                svg{
                    height: 600px !important;
                }
            }

        }
    }
    @media only screen and (min-width: 830px){
        .page-wrapper{
            display: flex;
            flex-direction: row;
            max-width: 1200px;
            padding: 0 ;
            margin: 0 auto;
            justify-content: space-between;
            &.page_one{
                padding-top: 0;
                height: 100vh;
            }
            &.page_two, &.page_four{
                flex-direction: row-reverse;
            }
            &.page_three, &.page_four{
                .lottie{
                    svg{
                        margin-left: 0px;
                    }
                }
            }           
            .content-text{
                /* padding: 0 0px 0 100px; */
                .title-page{
                    font-size: 102px;
                    line-height: 102px;
                }
                .content-page{
                    font-size: 18px;
                    line-height: 23px;
                    padding-top: 32px;
                    &.bold{
                        font-size: 22px;
                        line-height: 28px;
                        padding-top: 32px;
                    }
                }
            }
            &.page_one{
                .content-text{
                    padding: 0 0px 0 32px;
                    width: 400px;
                }
            }
            &.page_two{
                .content-text{
                    width: 540px;
                }
            }
            &.page_three{
                .content-text{
                    padding: 0 0px 0 32px;
                    width: 420px;
                }
            }
            &.page_four{
                .content-text{
                    width: 510px;
                }
            }
            .lottie{
                width: unset;
                height: fit-content;
                padding: 0; 
                svg{
                    height: 460px !important;
                }
                &.page-one{
                    svg{
                        height: 600px !important;
                    }
                }               
            }
        }
        .wrapper-footer{
            .page-wrapper{
                display: grid;
                grid-template-columns: repeat(5,1fr);
                padding: 46px 0 55px 0;
                max-width: 1200px;
                margin: 0 auto;
                width: 100%;
            }
        }
    }
`;

export {
    WrapFacePageMagic
}