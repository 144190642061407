import React from 'react'

export default function AppStoreBadgeBlack(props) {
    return (
        <svg {...props} width={169} height={56} viewBox="0 0 169 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5422_8843)">
                <path d="M154.666 4.46709e-05H13.8262C13.3128 4.46709e-05 12.8056 4.4671e-05 12.2936 0.00284467C11.865 0.00564467 11.4398 0.0137787 11.0071 0.0206247C10.067 0.0316872 9.12911 0.114391 8.2016 0.268019C7.27539 0.42499 6.3782 0.720927 5.54037 1.14582C4.70356 1.57431 3.93894 2.13108 3.27426 2.79594C2.60608 3.45894 2.04911 4.22527 1.62475 5.06547C1.19922 5.904 0.904158 6.80256 0.749751 7.73012C0.593876 8.6565 0.509999 9.59357 0.498871 10.5329C0.485879 10.9622 0.484507 11.3928 0.477661 11.8221V44.1821C0.484507 44.6168 0.485879 45.0379 0.498871 45.4727C0.510002 46.412 0.59388 47.3491 0.749751 48.2754C0.903731 49.2035 1.19881 50.1026 1.62475 50.9414C2.04892 51.7789 2.60597 52.5421 3.27426 53.2014C3.93642 53.8693 4.70153 54.4264 5.54037 54.8516C6.3782 55.2777 7.27529 55.5754 8.2016 55.7347C9.12927 55.8871 10.067 55.9699 11.0071 55.9823C11.4398 55.9918 11.865 55.9972 12.2936 55.9972C12.8056 56 13.3129 56 13.8262 56H154.666C155.17 56 155.681 56 156.184 55.9972C156.611 55.9972 157.048 55.9918 157.475 55.9823C158.413 55.9705 159.349 55.8878 160.275 55.7347C161.204 55.5742 162.104 55.2766 162.946 54.8516C163.784 54.4262 164.549 53.869 165.21 53.2014C165.877 52.5396 166.435 51.7769 166.864 50.9414C167.287 50.102 167.579 49.203 167.731 48.2754C167.887 47.3489 167.974 46.4121 167.991 45.4727C167.996 45.0379 167.996 44.6168 167.996 44.1821C168.007 43.6735 168.007 43.1676 168.007 42.6508V13.3506C168.007 12.8379 168.007 12.3293 167.996 11.8221C167.996 11.3928 167.996 10.9622 167.991 10.5329C167.974 9.59344 167.887 8.65658 167.731 7.73006C167.579 6.80304 167.287 5.90455 166.864 5.06541C166 3.38132 164.63 2.01048 162.946 1.14569C162.104 0.721839 161.204 0.425981 160.275 0.267893C159.349 0.113589 158.413 0.0308555 157.475 0.0204287C157.048 0.0135967 156.611 0.00539267 156.184 0.00266267C155.681 -0.000137329 155.17 -0.000137329 154.666 -0.000137329V4.46709e-05Z" fill="#A6A6A6" />
                <path d="M12.3004 54.775C11.8739 54.775 11.4576 54.7695 11.0344 54.76C10.1577 54.7486 9.28307 54.6723 8.41764 54.5317C7.61066 54.3927 6.82892 54.1342 6.0982 53.7646C5.37418 53.3981 4.71381 52.9176 4.1424 52.3414C3.56272 51.772 3.0801 51.1115 2.71369 50.3863C2.34323 49.6563 2.08685 48.8738 1.95349 48.0662C1.80946 47.1984 1.73154 46.3208 1.72039 45.4412C1.71151 45.1459 1.69989 44.1629 1.69989 44.1629V11.8221C1.69989 11.8221 1.71227 10.8541 1.72046 10.5697C1.73113 9.69147 1.8086 8.81531 1.9522 7.94881C2.08581 7.13894 2.34239 6.35424 2.71304 5.62187C3.07811 4.89711 3.55805 4.2362 4.13424 3.66474C4.70978 3.08786 5.37226 2.60484 6.09751 2.23328C6.82656 1.86492 7.60682 1.60822 8.41216 1.47177C9.28044 1.32976 10.1581 1.25299 11.0379 1.24208L12.3011 1.22499H156.177L157.455 1.24277C158.327 1.25313 159.197 1.32922 160.057 1.4704C160.871 1.60856 161.659 1.86705 162.396 2.23739C163.849 2.98617 165.032 4.17082 165.777 5.62529C166.142 6.3526 166.395 7.1309 166.527 7.93378C166.672 8.80738 166.753 9.69042 166.77 10.5759C166.774 10.9724 166.774 11.3982 166.774 11.8221C166.785 12.3471 166.785 12.8468 166.785 13.3506V42.6507C166.785 43.1593 166.785 43.6556 166.774 44.156C166.774 44.6113 166.774 45.0283 166.769 45.4576C166.752 46.3272 166.672 47.1945 166.529 48.0525C166.399 48.866 166.144 49.6545 165.773 50.3905C165.404 51.1079 164.925 51.7626 164.352 52.3304C163.78 52.9097 163.118 53.3931 162.392 53.762C161.657 54.1343 160.87 54.3938 160.057 54.5317C159.192 54.673 158.317 54.7493 157.44 54.76C157.03 54.7695 156.601 54.775 156.184 54.775L154.666 54.7778L12.3004 54.775Z" fill="black" />
                <path d="M35.1541 28.4206C35.1692 27.252 35.4795 26.1063 36.0564 25.09C36.6332 24.0736 37.4578 23.2197 38.4533 22.6077C37.8209 21.7044 36.9865 20.9611 36.0166 20.4368C35.0466 19.9124 33.9678 19.6214 32.8657 19.5869C30.5147 19.3402 28.2356 20.9937 27.0376 20.9937C25.8164 20.9937 23.9719 19.6114 21.9857 19.6523C20.701 19.6938 19.4489 20.0674 18.3514 20.7367C17.254 21.406 16.3487 22.3481 15.7236 23.4713C13.016 28.1591 15.0356 35.0486 17.6293 38.8378C18.927 40.6933 20.4435 42.7659 22.428 42.6924C24.3699 42.6119 25.0951 41.4542 27.4391 41.4542C29.7614 41.4542 30.4418 42.6924 32.4665 42.6457C34.5503 42.6119 35.8631 40.782 37.1153 38.9089C38.0476 37.5868 38.7651 36.1256 39.241 34.5794C38.0305 34.0674 36.9974 33.2103 36.2706 32.1151C35.5438 31.0199 35.1555 29.735 35.1541 28.4206Z" fill="white" />
                <path d="M31.3298 17.0957C32.466 15.7318 33.0257 13.9788 32.8902 12.2089C31.1544 12.3912 29.551 13.2208 28.3996 14.5323C27.8366 15.173 27.4054 15.9185 27.1306 16.7259C26.8559 17.5334 26.743 18.3871 26.7984 19.2383C27.6666 19.2472 28.5255 19.059 29.3104 18.6879C30.0953 18.3168 30.7858 17.7724 31.3298 17.0957Z" fill="white" />
                <path d="M59.7008 37.9955H53.0741L51.4827 42.6946H48.6758L54.9526 25.3094H57.8688L64.1456 42.6946H61.2909L59.7008 37.9955ZM53.7604 35.8272H59.0132L56.4238 28.201H56.3513L53.7604 35.8272Z" fill="white" />
                <path d="M77.7012 36.3573C77.7012 40.2962 75.593 42.8268 72.4116 42.8268C71.6057 42.869 70.8042 42.6833 70.0989 42.2912C69.3935 41.899 68.8129 41.3161 68.4235 40.6092H68.3633V46.8874H65.7615V30.019H68.2799V32.1272H68.3278C68.7351 31.4238 69.3255 30.8439 70.0362 30.4493C70.7469 30.0547 71.5513 29.8602 72.3637 29.8864C75.5807 29.8864 77.7012 32.4294 77.7012 36.3573ZM75.027 36.3573C75.027 33.7911 73.7008 32.104 71.6774 32.104C69.6895 32.104 68.3524 33.8266 68.3524 36.3573C68.3524 38.9112 69.6895 40.6216 71.6774 40.6216C73.7008 40.6216 75.027 38.9468 75.027 36.3573Z" fill="white" />
                <path d="M91.652 36.3573C91.652 40.2962 89.5438 42.8268 86.3624 42.8268C85.5565 42.869 84.755 42.6833 84.0497 42.2911C83.3443 41.899 82.7637 41.3161 82.3743 40.6092H82.3141V46.8874H79.7123V30.019H82.2307V32.1272H82.2785C82.6859 31.4238 83.2763 30.8439 83.987 30.4493C84.6977 30.0547 85.502 29.8602 86.3145 29.8864C89.5315 29.8864 91.652 32.4294 91.652 36.3573ZM88.9778 36.3573C88.9778 33.7911 87.6516 32.104 85.6282 32.104C83.6403 32.104 82.3032 33.8266 82.3032 36.3573C82.3032 38.9112 83.6403 40.6216 85.6282 40.6216C87.6516 40.6216 88.9778 38.9468 88.9778 36.3573H88.9778Z" fill="white" />
                <path d="M100.872 37.8506C101.065 39.5747 102.74 40.7066 105.029 40.7066C107.222 40.7066 108.799 39.5746 108.799 38.0201C108.799 36.6707 107.848 35.8627 105.595 35.309L103.341 34.7662C100.149 33.9951 98.667 32.5021 98.667 30.0795C98.667 27.0799 101.281 25.0195 104.993 25.0195C108.667 25.0195 111.185 27.0799 111.27 30.0795H108.643C108.486 28.3445 107.052 27.2973 104.956 27.2973C102.86 27.2973 101.426 28.3568 101.426 29.899C101.426 31.1281 102.342 31.8514 104.583 32.405L106.498 32.8753C110.065 33.7189 111.547 35.1517 111.547 37.6947C111.547 40.9472 108.956 42.9843 104.836 42.9843C100.98 42.9843 98.3772 40.9951 98.209 37.8505L100.872 37.8506Z" fill="white" />
                <path d="M117.162 27.0208V30.0204H119.573V32.0808H117.162V39.0685C117.162 40.154 117.645 40.6599 118.705 40.6599C118.991 40.6549 119.276 40.6348 119.56 40.5997V42.6477C119.084 42.7367 118.6 42.777 118.115 42.768C115.549 42.768 114.548 41.8042 114.548 39.346V32.0808H112.705V30.0204H114.548V27.0208H117.162Z" fill="white" />
                <path d="M120.969 36.3587C120.969 32.3706 123.318 29.8646 126.98 29.8646C130.655 29.8646 132.993 32.3706 132.993 36.3587C132.993 40.3577 130.668 42.8528 126.98 42.8528C123.294 42.8528 120.969 40.3577 120.969 36.3587ZM130.342 36.3587C130.342 33.623 129.088 32.0083 126.98 32.0083C124.872 32.0083 123.62 33.6353 123.62 36.3587C123.62 39.1054 124.872 40.7077 126.98 40.7077C129.088 40.7077 130.342 39.1054 130.342 36.3587H130.342Z" fill="white" />
                <path d="M135.138 30.0189H137.62V32.1763H137.68C137.848 31.5025 138.242 30.9071 138.798 30.49C139.353 30.073 140.035 29.8598 140.729 29.8863C141.028 29.8852 141.327 29.9178 141.62 29.9834V32.417C141.242 32.3013 140.847 32.2482 140.451 32.2597C140.073 32.2444 139.696 32.311 139.347 32.4551C138.997 32.5991 138.682 32.8171 138.425 33.0942C138.167 33.3712 137.973 33.7007 137.854 34.0601C137.736 34.4194 137.697 34.8001 137.74 35.1759V42.6941H135.138L135.138 30.0189Z" fill="white" />
                <path d="M153.616 38.9728C153.266 41.2737 151.025 42.8528 148.158 42.8528C144.471 42.8528 142.182 40.3823 142.182 36.4189C142.182 32.4431 144.483 29.8646 148.048 29.8646C151.555 29.8646 153.761 32.2735 153.761 36.1167V37.0081H144.808V37.1653C144.767 37.6318 144.825 38.1018 144.98 38.5439C145.134 38.9861 145.38 39.3904 145.703 39.73C146.025 40.0695 146.416 40.3366 146.85 40.5134C147.284 40.6903 147.75 40.7728 148.218 40.7556C148.833 40.8132 149.45 40.6708 149.977 40.3496C150.505 40.0284 150.914 39.5455 151.145 38.9728L153.616 38.9728ZM144.821 35.1898H151.157C151.181 34.7703 151.117 34.3506 150.971 33.9569C150.824 33.5632 150.598 33.204 150.306 32.902C150.014 32.5999 149.663 32.3614 149.274 32.2015C148.886 32.0416 148.468 31.9637 148.048 31.9728C147.625 31.9702 147.205 32.0516 146.813 32.2123C146.421 32.3729 146.064 32.6096 145.764 32.9087C145.464 33.2078 145.226 33.5633 145.064 33.9549C144.902 34.3464 144.819 34.7661 144.821 35.1898V35.1898Z" fill="white" />
                <path d="M53.4343 12.2238C53.9798 12.1847 54.5271 12.2671 55.0369 12.4651C55.5466 12.6631 56.0061 12.9718 56.3821 13.3688C56.7581 13.7659 57.0413 14.2415 57.2113 14.7613C57.3813 15.2811 57.4338 15.8321 57.365 16.3746C57.365 19.0433 55.9226 20.5774 53.4343 20.5774H50.4169V12.2238H53.4343ZM51.7144 19.396H53.2894C53.6792 19.4193 54.0692 19.3554 54.4312 19.209C54.7931 19.0625 55.1179 18.8372 55.3818 18.5494C55.6456 18.2616 55.842 17.9186 55.9566 17.5453C56.0712 17.172 56.1012 16.7779 56.0443 16.3916C56.0971 16.0069 56.0641 15.6152 55.9477 15.2446C55.8314 14.8741 55.6345 14.5339 55.3712 14.2484C55.1079 13.9629 54.7847 13.7392 54.4248 13.5932C54.0649 13.4473 53.6772 13.3828 53.2894 13.4043H51.7144V19.396Z" fill="white" />
                <path d="M58.8306 17.4236C58.791 17.0094 58.8384 16.5914 58.9697 16.1965C59.1011 15.8016 59.3136 15.4385 59.5935 15.1305C59.8734 14.8225 60.2146 14.5765 60.5952 14.4081C60.9758 14.2397 61.3874 14.1527 61.8035 14.1527C62.2197 14.1527 62.6313 14.2397 63.0119 14.4081C63.3925 14.5765 63.7337 14.8225 64.0136 15.1305C64.2935 15.4385 64.506 15.8016 64.6374 16.1965C64.7687 16.5914 64.8161 17.0094 64.7765 17.4236C64.8169 17.8384 64.77 18.2569 64.639 18.6525C64.508 19.048 64.2957 19.4117 64.0157 19.7203C63.7357 20.0289 63.3942 20.2755 63.0133 20.4442C62.6323 20.613 62.2202 20.7002 61.8035 20.7002C61.3869 20.7002 60.9748 20.613 60.5938 20.4442C60.2129 20.2755 59.8714 20.0289 59.5914 19.7203C59.3114 19.4117 59.0991 19.048 58.9681 18.6525C58.837 18.2569 58.7902 17.8384 58.8306 17.4236ZM63.4968 17.4236C63.4968 16.0571 62.8829 15.258 61.8056 15.258C60.7241 15.258 60.1158 16.0571 60.1158 17.4237C60.1158 18.8011 60.7242 19.5941 61.8056 19.5941C62.8829 19.594 63.4968 18.7956 63.4968 17.4236H63.4968Z" fill="white" />
                <path d="M72.6802 20.5774H71.3896L70.0867 15.9344H69.9882L68.6908 20.5774H67.4124L65.6747 14.2733H66.9367L68.066 19.0837H68.1589L69.455 14.2733H70.6486L71.9447 19.0837H72.0431L73.1669 14.2733H74.4111L72.6802 20.5774Z" fill="white" />
                <path d="M75.8726 14.2728H77.0703V15.2743H77.1632C77.321 14.9146 77.587 14.613 77.9242 14.4117C78.2614 14.2104 78.653 14.1193 79.0445 14.1511C79.3512 14.128 79.6592 14.1743 79.9457 14.2864C80.2321 14.3986 80.4896 14.5737 80.6992 14.7989C80.9088 15.0241 81.065 15.2935 81.1562 15.5873C81.2475 15.881 81.2715 16.1915 81.2265 16.4958V20.5768H79.9824V16.8082C79.9824 15.7952 79.5422 15.2913 78.622 15.2913C78.4138 15.2816 78.2059 15.3171 78.0126 15.3953C77.8193 15.4734 77.6452 15.5924 77.5023 15.7442C77.3593 15.8959 77.2508 16.0768 77.1843 16.2744C77.1177 16.472 77.0947 16.6816 77.1168 16.8889V20.5769H75.8726L75.8726 14.2728Z" fill="white" />
                <path d="M83.2089 11.8123H84.4531V20.5774H83.2089V11.8123Z" fill="white" />
                <path d="M86.1825 17.4216C86.1429 17.0072 86.1904 16.5892 86.3218 16.1943C86.4532 15.7994 86.6657 15.4363 86.9456 15.1283C87.2256 14.8203 87.5668 14.5743 87.9474 14.4059C88.328 14.2375 88.7396 14.1505 89.1558 14.1505C89.572 14.1505 89.9836 14.2375 90.3643 14.4059C90.7449 14.5743 91.0861 14.8203 91.366 15.1283C91.646 15.4363 91.8585 15.7994 91.9899 16.1943C92.1213 16.5892 92.1687 17.0072 92.1291 17.4216C92.1694 17.8363 92.1226 18.2549 91.9915 18.6504C91.8604 19.0459 91.6481 19.4097 91.3681 19.7182C91.088 20.0268 90.7466 20.2734 90.3656 20.4421C89.9846 20.6109 89.5725 20.698 89.1558 20.698C88.7392 20.698 88.3271 20.6109 87.9461 20.4421C87.5651 20.2734 87.2236 20.0268 86.9436 19.7182C86.6636 19.4097 86.4512 19.0459 86.3202 18.6504C86.1891 18.2549 86.1422 17.8363 86.1825 17.4216ZM90.8487 17.4216C90.8487 16.0551 90.2349 15.2559 89.1575 15.2559C88.0761 15.2559 87.4677 16.0551 87.4677 17.4216C87.4677 18.799 88.0761 19.592 89.1575 19.592C90.2349 19.592 90.8487 18.7935 90.8487 17.4216H90.8487Z" fill="white" />
                <path d="M93.4389 18.7957C93.4389 17.6609 94.2838 17.0067 95.7836 16.9137L97.4913 16.8153V16.2712C97.4913 15.6053 97.051 15.2294 96.2006 15.2294C95.5061 15.2294 95.0249 15.4843 94.8868 15.9301H93.6823C93.8094 14.8472 94.828 14.1527 96.258 14.1527C97.8385 14.1527 98.7299 14.9395 98.7299 16.2712V20.5785H97.5323V19.6926H97.4338C97.234 20.0103 96.9535 20.2694 96.6208 20.4433C96.2881 20.6172 95.9153 20.6997 95.5403 20.6824C95.2756 20.7099 95.0081 20.6816 94.7551 20.5995C94.502 20.5173 94.269 20.383 94.071 20.2052C93.873 20.0275 93.7145 19.8102 93.6056 19.5674C93.4967 19.3246 93.4399 19.0617 93.4389 18.7957ZM97.4913 18.257V17.7299L95.9518 17.8284C95.0836 17.8865 94.6899 18.1818 94.6899 18.7376C94.6899 19.3049 95.1821 19.6351 95.8589 19.6351C96.0572 19.6552 96.2575 19.6352 96.4479 19.5762C96.6383 19.5173 96.8149 19.4207 96.9672 19.2921C97.1195 19.1635 97.2444 19.0056 97.3344 18.8278C97.4244 18.65 97.4778 18.4559 97.4913 18.257Z" fill="white" />
                <path d="M100.365 17.4226C100.365 15.4306 101.389 14.1687 102.982 14.1687C103.376 14.1506 103.767 14.2449 104.109 14.4408C104.452 14.6366 104.731 14.9259 104.915 15.2747H105.008V11.8123H106.252V20.5774H105.06V19.5814H104.962C104.763 19.9279 104.474 20.2135 104.125 20.4074C103.776 20.6013 103.381 20.696 102.982 20.6813C101.378 20.6813 100.365 19.4194 100.365 17.4226ZM101.65 17.4226C101.65 18.7597 102.281 19.5643 103.335 19.5643C104.383 19.5643 105.031 18.7481 105.031 17.4281C105.031 16.1142 104.376 15.2864 103.335 15.2864C102.287 15.2864 101.65 16.0964 101.65 17.4226H101.65Z" fill="white" />
                <path d="M111.4 17.4236C111.36 17.0094 111.407 16.5914 111.539 16.1965C111.67 15.8016 111.883 15.4385 112.163 15.1305C112.442 14.8225 112.784 14.5765 113.164 14.4081C113.545 14.2397 113.956 14.1527 114.373 14.1527C114.789 14.1527 115.2 14.2397 115.581 14.4081C115.962 14.5765 116.303 14.8225 116.583 15.1305C116.863 15.4385 117.075 15.8016 117.206 16.1965C117.338 16.5914 117.385 17.0094 117.346 17.4236C117.386 17.8384 117.339 18.2569 117.208 18.6525C117.077 19.048 116.865 19.4117 116.585 19.7203C116.305 20.0289 115.963 20.2755 115.582 20.4442C115.201 20.613 114.789 20.7002 114.373 20.7002C113.956 20.7002 113.544 20.613 113.163 20.4442C112.782 20.2755 112.44 20.0289 112.161 19.7203C111.881 19.4117 111.668 19.048 111.537 18.6525C111.406 18.2569 111.359 17.8384 111.4 17.4236ZM116.066 17.4236C116.066 16.0571 115.452 15.258 114.375 15.258C113.293 15.258 112.685 16.0571 112.685 17.4237C112.685 18.8011 113.293 19.5941 114.375 19.5941C115.452 19.594 116.066 18.7956 116.066 17.4236Z" fill="white" />
                <path d="M119.015 14.2728H120.213V15.2743H120.306C120.463 14.9146 120.729 14.613 121.066 14.4117C121.404 14.2104 121.795 14.1193 122.187 14.1511C122.494 14.128 122.802 14.1743 123.088 14.2864C123.374 14.3986 123.632 14.5737 123.841 14.7989C124.051 15.0241 124.207 15.2935 124.299 15.5873C124.39 15.881 124.414 16.1915 124.369 16.4958V20.5768H123.125V16.8082C123.125 15.7952 122.684 15.2913 121.764 15.2913C121.556 15.2816 121.348 15.3171 121.155 15.3953C120.962 15.4734 120.787 15.5924 120.645 15.7442C120.502 15.8959 120.393 16.0768 120.327 16.2744C120.26 16.472 120.237 16.6816 120.259 16.8889V20.5769H119.015V14.2728Z" fill="white" />
                <path d="M131.399 12.7038V14.302H132.765V15.3499H131.399V18.5915C131.399 19.2519 131.671 19.5411 132.29 19.5411C132.449 19.5405 132.607 19.531 132.765 19.5123V20.5487C132.541 20.5886 132.315 20.6099 132.088 20.6122C130.704 20.6122 130.153 20.1255 130.153 18.9101V15.3499H129.153V14.3019H130.153V12.7038H131.399Z" fill="white" />
                <path d="M134.464 11.8123H135.697V15.2864H135.796C135.961 14.9233 136.234 14.6201 136.578 14.418C136.922 14.2159 137.32 14.1248 137.718 14.1571C138.023 14.1405 138.328 14.1914 138.611 14.3063C138.895 14.4211 139.149 14.5971 139.356 14.8216C139.564 15.046 139.719 15.3135 139.811 15.6049C139.903 15.8963 139.93 16.2044 139.889 16.5073V20.5774H138.644V16.8142C138.644 15.8073 138.175 15.2973 137.296 15.2973C137.082 15.2798 136.867 15.3092 136.665 15.3834C136.464 15.4577 136.281 15.5751 136.13 15.7273C135.979 15.8795 135.863 16.063 135.79 16.2648C135.717 16.4665 135.689 16.6818 135.708 16.8955V20.5774H134.464L134.464 11.8123Z" fill="white" />
                <path d="M147.143 18.8742C146.974 19.4504 146.608 19.9488 146.109 20.2822C145.609 20.6156 145.009 20.7628 144.412 20.6981C143.996 20.709 143.584 20.6295 143.202 20.4649C142.821 20.3004 142.48 20.0548 142.202 19.7452C141.925 19.4356 141.719 19.0694 141.598 18.6721C141.476 18.2747 141.443 17.8557 141.5 17.4442C141.444 17.0313 141.478 16.6114 141.599 16.2129C141.721 15.8143 141.926 15.4465 142.201 15.1342C142.477 14.8219 142.817 14.5724 143.197 14.4027C143.577 14.233 143.99 14.1471 144.406 14.1506C146.16 14.1506 147.219 15.349 147.219 17.3286V17.7627H142.767V17.8324C142.748 18.0638 142.777 18.2967 142.852 18.5161C142.928 18.7356 143.049 18.9367 143.207 19.1068C143.365 19.2768 143.557 19.412 143.77 19.5035C143.984 19.5951 144.214 19.641 144.446 19.6384C144.744 19.6742 145.045 19.6206 145.312 19.4845C145.579 19.3484 145.8 19.136 145.946 18.8742L147.143 18.8742ZM142.767 16.8426H145.951C145.967 16.631 145.938 16.4185 145.867 16.2187C145.795 16.0189 145.683 15.8362 145.537 15.6824C145.391 15.5286 145.214 15.4071 145.018 15.3256C144.822 15.2442 144.612 15.2046 144.399 15.2095C144.184 15.2068 143.971 15.2472 143.771 15.3283C143.572 15.4094 143.391 15.5296 143.239 15.6818C143.087 15.834 142.967 16.0151 142.886 16.2145C142.805 16.4139 142.764 16.6274 142.767 16.8426H142.767Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_5422_8843">
                    <rect width="167.53" height={56} fill="white" transform="translate(0.477661)" />
                </clipPath>
            </defs>
        </svg>
    )
}
