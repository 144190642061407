import { ACCOUNT_MANAGEMENT, BASIC_INFOMATION, FACEPAY_PAYMENT_STORES, PAYMENT_AT_COUNTER, SUPPORT } from '../constant/pathNames'
import q2_step1 from '../images/FAQ/PaymentAtCounter/q2_step1.png'
import q2_step2 from '../images/FAQ/PaymentAtCounter/q2_step2.png'
import q2_step3 from '../images/FAQ/PaymentAtCounter/q2_step3.png'
import q2_step4 from '../images/FAQ/PaymentAtCounter/q2_step4.png'
import q2_step5 from '../images/FAQ/PaymentAtCounter/q2_step5.png'

import q9_step1 from '../images/FAQ/PaymentAtCounter/q9_step1.png'
import q9_step2 from '../images/FAQ/PaymentAtCounter/q9_step2.png'
import q9_step3 from '../images/FAQ/PaymentAtCounter/q9_step3.png'
import q9_step4 from '../images/FAQ/PaymentAtCounter/q9_step4.png'
import q9_step5 from '../images/FAQ/PaymentAtCounter/q9_step5.png'

import q2_2_step1 from '../images/FAQ/PaymentAtCounter/q2_2_step1.png'
import q2_2_step2 from '../images/FAQ/PaymentAtCounter/q2_2_step2.png'
import q2_2_step3 from '../images/FAQ/PaymentAtCounter/q2_2_step3.png'
import q2_2_step4 from '../images/FAQ/PaymentAtCounter/q2_2_step4.png'
import q2_2_step5 from '../images/FAQ/PaymentAtCounter/q2_2_step5.png'
import q2_2_step6 from '../images/FAQ/PaymentAtCounter/q2_2_step6.png'
import q2_2_step7 from '../images/FAQ/PaymentAtCounter/q2_2_step7.png'

import q10_step1 from '../images/FAQ/PaymentAtCounter/q10_step1.png'
import q10_step2 from '../images/FAQ/PaymentAtCounter/q10_step2.png'
import q10_step3 from '../images/FAQ/PaymentAtCounter/q10_step3.png'
import q10_step4 from '../images/FAQ/PaymentAtCounter/q10_step4.png'

import q11_step1 from '../images/FAQ/PaymentAtCounter/q11_step1.png'
import q11_step2 from '../images/FAQ/PaymentAtCounter/q11_step2.png'
import q11_step3 from '../images/FAQ/PaymentAtCounter/q11_step3.png'
import q11_step4 from '../images/FAQ/PaymentAtCounter/q11_step4.png'


export const PaymentAtCounterData = { 
    q1: {
        key: "q1",
        title: "payment_at_counter.q_01.title",
        date: "payment_at_counter.q_01.date",
        link_here: {
            href: `/${FACEPAY_PAYMENT_STORES}`,
            target: "_blank"
        },
        desc: [
            "payment_at_counter.q_01.desc.desc1",
        ]    
    },
    q10: {
        key: "q10",
        date: "payment_at_counter.q_10.date",
        title: "payment_at_counter.q_10.title",
        desc: [
            "payment_at_counter.q_10.desc.desc1",
        ],       
        step: [
            {
                step: 1,
                image: q10_step1,
                text: "payment_at_counter.q_10.step.text1",
            },
            {
                step: 2,
                image: q10_step2,
                text: "payment_at_counter.q_10.step.text2",
            },
            {
                step: 3,
                image: q10_step3,
                text: "payment_at_counter.q_10.step.text3",
            },
            {
                step: 4,
                image: q10_step4,
                text: "payment_at_counter.q_10.step.text4",
                link_OTP: "",
            }
        ]
    },
    q13: {
        key: "q13",
        title: "payment_at_counter.q_13.title",
        date: "payment_at_counter.q_13.date",
        desc: [
            "payment_at_counter.q_13.desc.desc1",
            "payment_at_counter.q_13.desc.desc2"
        ]    
    },   
    // q11: {
    //     key: "q11",
    //     date: "payment_at_counter.q_11.date",
    //     title: "payment_at_counter.q_11.title",
    //     desc: [
    //         "payment_at_counter.q_11.desc.desc1",
    //     ],       
    //     step: [
    //         {
    //             step: 1,
    //             image: q11_step1,
    //             text: "payment_at_counter.q_11.step.text1",
    //         },
    //         {
    //             step: 2,
    //             image: q11_step2,
    //             text: "payment_at_counter.q_11.step.text2",
    //         },
    //         {
    //             step: 3,
    //             image: q11_step3,
    //             text: "payment_at_counter.q_11.step.text3",
    //         },
    //         {
    //             step: 4,
    //             image: q11_step4,
    //             text: "payment_at_counter.q_11.step.text4",
    //             link_OTP: "",
    //         }
    //     ]
    // },
    // q2: {
    //     key: "q2",
    //     date: "payment_at_counter.q_02.date",
    //     title: "payment_at_counter.q_02.title",
    //     desc: [
    //         "payment_at_counter.q_02.desc.desc1",
    //     ],       
    //     step: [
    //         {
    //             step: 1,
    //             image: q2_step1,
    //             text: "payment_at_counter.q_02.step.text1",
    //         },
    //         {
    //             step: 2,
    //             image: q2_step2,
    //             text: "payment_at_counter.q_02.step.text2",
    //         },
    //         {
    //             step: 3,
    //             image: q2_step3,
    //             text: "payment_at_counter.q_02.step.text3",
    //         },
    //         {
    //             step: 4,
    //             image: q2_step4,
    //             text: "payment_at_counter.q_02.step.text4",
    //             link_OTP: "",
    //         },
    //         {
    //             step: 5,
    //             image: q2_step5,
    //             text: "payment_at_counter.q_02.step.text5",
    //             link_OTP: "",
    //         }
    //     ]
    // },
    // q3: {
    //     key: "q3",
    //     title: "payment_at_counter.q_03.title",
    //     date: "payment_at_counter.q_03.date",
    //     desc: [
    //         "payment_at_counter.q_03.desc.desc1",
    //         "payment_at_counter.q_03.desc.desc2",
    //         "payment_at_counter.q_03.desc.desc3"
    //     ],
    // },
    // q4: {
    //     key: "q4",
    //     title: "payment_at_counter.q_04.title",
    //     date: "payment_at_counter.q_04.date",
    //     desc: ["payment_at_counter.q_04.desc.desc1"],
    //     link_here: {
    //         href: `/${SUPPORT}/${ACCOUNT_MANAGEMENT}/q6`,
    //         target: "_blank"
    //     }
    // },
    // q9: {
    //     key: "q9",
    //     date: "payment_at_counter.q_09.date",
    //     title: "payment_at_counter.q_09.title",
    //     desc: [
    //         "payment_at_counter.q_09.desc.desc1",
    //     ],       
    //     step: [
    //         {
    //             step: 1,
    //             image: q9_step1,
    //             text: "payment_at_counter.q_09.step.text1",
    //         },
    //         {
    //             step: 2,
    //             image: q9_step2,
    //             text: "payment_at_counter.q_09.step.text2",
    //         },
    //         {
    //             step: 3,
    //             image: q9_step3,
    //             text: "payment_at_counter.q_09.step.text3",
    //         },
    //         {
    //             step: 4,
    //             image: q9_step4,
    //             text: "payment_at_counter.q_09.step.text4",
    //             link_OTP: "",
    //         },
    //         {
    //             step: 5,
    //             image: q9_step5,
    //             text: "payment_at_counter.q_09.step.text5",
    //             link_OTP: "",
    //         }
    //     ],
    // },
    q5: {
        key: "q5",
        title: "payment_at_counter.q_05.title",
        date: "payment_at_counter.q_05.date",
        desc: [
            "payment_at_counter.q_05.desc.desc1",
            "payment_at_counter.q_05.desc.desc2"
        ],
    },
    q6: {
        key: "q6",
        title: "payment_at_counter.q_06.title",
        date: "payment_at_counter.q_06.date",
        link_customer_care: {
            href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
            target: "_blank"
        },
        desc: [
            "payment_at_counter.q_06.desc.desc1",
        ],        
    },
    // q7: {
    //     key: "q7",
    //     title: "payment_at_counter.q_07.title",
    //     date: "payment_at_counter.q_07.date",
    //     link_customer_care: {
    //         href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
    //         target: "_blank"
    //     },
    //     desc: [
    //         "payment_at_counter.q_07.desc.desc1",
    //         "payment_at_counter.q_07.desc.desc2",
    //     ],        
    // },
    // q8: {
    //     key: "q8",
    //     title: "payment_at_counter.q_08.title",
    //     date: "payment_at_counter.q_08.date",        
    //     desc: [
    //         "payment_at_counter.q_08.desc.desc1",
    //         "payment_at_counter.q_08.desc.desc2",
    //         "payment_at_counter.q_08.desc.desc3",
    //         "payment_at_counter.q_08.desc.desc4",
    //     ],
    //     link_accuracy: {
    //         href: `/${SUPPORT}/${ACCOUNT_MANAGEMENT}/q16`,
    //         target: "_blank"
    //     },
    //     link_payment: {
    //         href: `/${SUPPORT}/${PAYMENT_AT_COUNTER}/q2`,
    //         target: "_blank"
    //     },  
    // },
    q12: {
        key: "q12",
        title: "payment_at_counter.q_12.title",
        date: "payment_at_counter.q_12.date",
        desc: [
            "payment_at_counter.q_12.desc.desc1",
            "payment_at_counter.q_12.desc.desc2",
            "payment_at_counter.q_12.desc.desc3"
        ],
    },
    
}