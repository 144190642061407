import React from 'react'
import { withNamespaces } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';

//constant
import * as pathNames from '../../../assets/constant/pathNames'

//data
import { ResolveComplaintData } from '../../../assets/data/ResolveComplaintData';

//component
import QAndAList from '../../Control/QAndAList';

const ResolveComplaint = ({ t }) => {
    const { subSupportType } = useParams()
    const { url } = useRouteMatch()

    return (
        <QAndAList
            urlSupportPage={url.replace(`\/${subSupportType}`, "")}
            defaultActiveTab={subSupportType}
            title={t('resolve_complaint.title')}
            tab={t('resolve_complaint.tab', { returnObjects: true })}
            objDatas={ResolveComplaintData}
            path={pathNames.RESOLVE_COMPLAINT}
        />
    )
}

export default withNamespaces()(ResolveComplaint)
