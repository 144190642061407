import styled from 'styled-components'

const WrapCommunicationPost = styled.div`
    font-family: 'SVN-Gilroy';
    color: #191919;
    .page {
        padding: 0 24px 49px;
        box-sizing: border-box;
        margin: 0 auto;

        &.page-first {
            margin: 142px auto 0;
        }

        &.page-other-post {
            padding-top: 33px;
            padding-bottom: 54px;
            background: #F7FCFF;

            .title-other-post {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #191919;
            }

            .wrap-post {
                margin-top: 28px;

                .mini-post {
                    display: flex;
                    align-items: center;
                    

                    &:not(:first-child) {
                        margin-top: 18px;
                    }

                    img {
                        width: 30%;
                        flex-shrink: 0;
                        object-fit: cover;
                        min-height: 186px;
                    }

                    

                    & > div {
                        margin-left: 12px;

                        .title-post {
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 21px;
                            color: #191919;
                        }

                        .more {
                            margin-top: 12px;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 130%;
                            color: #1279DA;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .go-back {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 130%;
            color: #1279DA;
            text-decoration: none;
        }

        .title {
            margin-top: 14px;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 39px;
            color: #191919;
        }

        .wrap-share-date {
            margin-top: 26px;
            display: flex;
            align-items: center;
            
            button {
                height: 20px;
                border: none;
                outline: none;
                background: #395CA5;
                border-radius: 4px;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 130%;
                color: #F7FCFF;
                display: flex;
                align-items: center;
                padding: 3px 7px;
                box-sizing: border-box;
                margin-right: 7px;

                .ic-share {
                    height: 100%;
                    border-right: 1px solid #ffffff4d;
                    padding-right: 5px;
                    margin-right: 5px;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            div {
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 130%;
                color: #939393;
            }
        }

        .desc {
            margin-top: 31px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #191919;
        }

        .wrap-content {
            margin-top: 50px;

            .content {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                color: #191919;

                &:not(:first-child) {
                    margin-top: 28px;
                }
            }

            figure {
                margin: 0;
                text-align: center;
                img {
                    width: 100%;
                    display: inline-block;
                    margin-top: 28px;
                    object-fit: contain;
                }

                figcaption {
                    margin-top: 8px;
                    font-size: 16px;
                    line-height: 21px;
                    font-style: italic;
                    font-weight: 500;
                }
            }

        }
    }
    @media only screen and (min-width: 768px){
        .wrap-page {
            width: 100%;
            background: #F7FCFF;
        }
        .page {
            max-width: 1200px;
            padding: 0 0 82px 0;

            &.page-other-post {
                padding-bottom: 39px;
                .title-other-post {
                    font-size: 21px;
                    line-height: 24px;
                }

                .wrap-post {
                    margin-top: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .mini-post {
                        flex-basis: calc((100% - 30px)/2);
                        &:not(:first-child) {
                            margin-top: 0;
                        }

                        & > div {
                            margin-left: 19px;

                            .title-post {
                                font-size: 18px;
                                line-height: 23px;
                            }

                            .more {
                                font-size: 14px;
                                line-height: 130%;
                            }
                        }
                    }
                }
            }

            .go-back {
                font-size: 14px;
            }

            .title {
                margin-top: 15px;
                font-size: 50px;
                line-height: 52px;
            }

            .wrap-share-date {
                margin-top: 23px;
                .react-share__ShareButton{
                    margin: 0 7px 0 0;
                }

                button {
                    height: 24px;
                    font-size: 14px;
                }
                div {
                    font-size: 18px;
                    line-height: unset;
                }
            }

            .desc {
                margin-top: 51px;
                font-size: 21px;
                line-height: 24px;
            }

            .wrap-content {
                margin-top: 40px;
                padding-top: 42px;
                border-top: 1px solid #939393;

                .content {
                    font-size: 18px;
                    line-height: 23px;

                    &:not(:first-child) {
                        margin-top: 28px;
                    }
                }

                figure {
                    img {
                        margin-top: 43px;
                        /* width: auto; */
                    }

                    figcaption {
                        margin-top: 10px;
                        font-size: 18px;
                        line-height: 23px;
                    }
                }
            }
        }
    }
`;

export {
    WrapCommunicationPost
}