import React, { useState, useCallback, useEffect } from 'react'
//style
import * as style from './index.style'
const InputTextControl = ({  
    isError, 
    placeholder,
    value,
    onChange,
    type
}) => {

    // const [value, setValue] = useState("")

    // useEffect(() => {
    //     if(value === valueChange) return;
    //     setValue(valueChange)
    // }, [valueChange])

    // const _handleOnChange = useCallback(
    //     (e) => {
    //         const { value } = e.target
    //         // const val = value.trimStart().replace(/\s+/g, " ")
    //         const val = value
    //         setValue(val)
    //         onChange(val)
    //     },
    //     [value, onChange, setValue],
    // )

    return (
        <style.Bound>
            <input
                name={type?type:""}
                autoComplete={type?type:""}
                className= {isError === false ? "value-input" : "value-input err"}
                type={type?type:"text"}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        </style.Bound>
    )
}

export default InputTextControl
