import React from 'react'

const IcUserPlus = (props) => {
    return (
        <svg {...props} width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 42V38C32 35.8783 31.1571 33.8434 29.6569 32.3431C28.1566 30.8429 26.1217 30 24 30H10C7.87827 30 5.84344 30.8429 4.34315 32.3431C2.84285 33.8434 2 35.8783 2 38V42" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40 16V28" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M46 22H34" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 22C21.4183 22 25 18.4183 25 14C25 9.58172 21.4183 6 17 6C12.5817 6 9 9.58172 9 14C9 18.4183 12.5817 22 17 22Z" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcUserPlus
