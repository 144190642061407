import React, { useState, useLayoutEffect, Fragment } from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom'

// component
import IcCycleNext from '../../../assets/images/IcCycleNext'
import IcCyclePrevious from '../../../assets/images/IcCyclePrevious'

// styles
import { WrapStepByStep } from './index.styles'

const StepByStepReverse = ({ t, defaultActiveStep, lstStep, headLogo, headSubTitle}) => {

    const [activeStep, setActiveStep] = useState(defaultActiveStep)

    useLayoutEffect(() => {
        
    }, [])

    useLayoutEffect(() => {
        const isInvalidField = lstStep.some(({ step, image, text }, index) => {
            if (!step || !image || !text)
                throw Error(`step, image, text are obligate value but some missing in item have index ${index}`)
            if (typeof (step) !== 'number')
                throw Error(`Invalid step value: step expect number but ${typeof (step)} in item have index ${index}`)
            return false
        })
    }, [])

    const _handleChooseStep = (item) => {
        setActiveStep(item.step)
    }

    const _handleClickPrevious = () => {
        const newStep = activeStep - 1
        setActiveStep(newStep)
    }

    const _handleClickNext = () => {
        const newStep = activeStep + 1
        setActiveStep(newStep)
    }

    const imgItem = ({ step, image }, index, classImg) => (
        <div id={`image${step}`} key={index} className={`image ${classImg}`}>
            <img src={image} alt="image" />
        </div>
    )

    return (
        <WrapStepByStep 
            logoLaptop={headLogo?.laptop}
            logoMobile={headLogo?.mobile}
            stepRow={lstStep?.length}>
            <div className="logo-bank"></div>
            <p className="title-bank">{headSubTitle}</p>
            <div className="content-container">
                <div className="step-wrap">
                    {
                        lstStep.map((item, index) => (
                            <div
                                className={activeStep === item.step ? "cycle wrapper" : "cycle"}
                                key={index}
                                onMouseEnter={() => _handleChooseStep(item)}
                            >
                                <div className={activeStep === item.step ? "step active" : "step"}>{item.step}</div>
                            </div>
                        ))
                    }
                </div>
                <div className="text-wrap">
                    {
                        lstStep.map((item, index) => {
                            const text = t(item.text, { returnObjects: true })
                            if (Array.isArray(text))
                                return (
                                    <div
                                        className={activeStep === item.step ? "text active" : "text"}
                                        key={index}
                                        onMouseEnter={() => _handleChooseStep(item)}
                                    >
                                        {text.map((itemText, itemTextIndex) => {
                                            if (typeof (itemText) === 'string')
                                                return <Fragment key={itemTextIndex}>
                                                    {itemText}
                                                </Fragment>
                                            return <Fragment key={itemTextIndex}> <a {...item[itemText.link]}>{itemText.text}</a> </Fragment>
                                        })}
                                    </div>
                                )
                            return (
                                <div
                                    className={activeStep === item.step ? "text active" : "text"}
                                    key={index}
                                    onMouseEnter={() => _handleChooseStep(item)}
                                >
                                    {text}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="image-container" >
                <div className='wrap-slide-image'>
                    <div className="icon-previous-wrap">
                        {
                            activeStep > lstStep[0].step &&
                            <IcCyclePrevious className="icon-previous" onClick={_handleClickPrevious} />
                        }
                    </div>
                    <div className="image-wrap">
                        {
                            lstStep.map((item, index) => {
                                if (item.step === activeStep)
                                    return imgItem(item, index, 'active')
                                if (activeStep - 1 > 0 && item.step === activeStep - 1)
                                    return imgItem(item, index, 'previous')
                                return imgItem(item, index, 'next')
                            })
                        }
                    </div>
                    <div className="icon-next-wrap">
                        {
                            activeStep < lstStep[lstStep.length - 1].step &&
                            <IcCycleNext className="icon-next" onClick={_handleClickNext} />
                        }
                    </div>
                </div>
            </div>
        </WrapStepByStep>
    )
}

export default withNamespaces()(StepByStepReverse)
