import React from 'react'
import { withNamespaces } from 'react-i18next';

//constant
import * as pathNames from '../../../assets/constant/pathNames'

//data
import { BasicInfomationData } from '../../../assets/data/BasicInfomationData';

//component
import QAndAList from '../../Control/QAndAList';

const BasicInfomation = ({ t }) => {
    return (
        <QAndAList
            title={t('basic_infomation.title')}
            objDatas={BasicInfomationData}
            path={pathNames.BASIC_INFOMATION}
        />  
    )
}

export default withNamespaces()(BasicInfomation)
