import React from 'react'
import { withNamespaces } from 'react-i18next';

//style
import { WrapPage } from './index.styles';

//image
import img_payment from '../../../assets/images/img_payment.png'

const Payments = ({ t }) => {
    return (
        <WrapPage>
            <img className="image-page" src={img_payment} alt="image" />
            <div className="content-page">
                {t('tutorial.connect_page.payment.tuyet_voi')}
            </div>
        </WrapPage>
    )
}

export default withNamespaces()(Payments)
