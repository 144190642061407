import React, { useState, useEffect, useRef } from 'react'
import { withNamespaces } from 'react-i18next';
//style
import { WrapPage } from './index.styles'

//image
import ic_faceprint from '../../assets/images/WeeDigital/ic_faceprint.svg'
import ic_user from '../../assets/images/WeeDigital/ic_user.svg'
import ic_award from '../../assets/images/WeeDigital/ic_award.svg'
import ic_shield from '../../assets/images/WeeDigital/ic_shield.svg'
import IcCyclePrevious from '../../assets/images/IcCyclePrevious';
import IcCycleNext from '../../assets/images/IcCycleNext';

//logo Partner 
import { LogoPartnerData } from '../../assets/data/LogoPartnerData';
import PageSix from '../Home/PageSix';

let interval = null
const WeeDigital = ({ t }) => {

    const lstStep = LogoPartnerData
    const keyRef = useRef(lstStep[0].step)
    const [activeReview, setActiveReview] = useState(lstStep[0].step);


    useEffect(() => {
        interval = setInterval(() => {
            setActiveReviewKey(1)
        }, 2000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    const onClickRedirect = (delta) => {
        if (interval) {
            clearInterval(interval)
            interval = null;
            interval = setInterval(() => {
                setActiveReviewKey(1)
            }, 4000);
        }
        setActiveReviewKey(delta)
    }

    const setActiveReviewKey = (delta) => {
        let newKey = keyRef.current + delta
        if (newKey < 1) newKey = 18
        if (newKey > 18) newKey = 1
        keyRef.current = newKey
        setActiveReview(newKey)
    }

    const imgItem = ({ step, image }, index, classImg) => (
        <div id={`image${step}`} key={index} className={`image ${classImg}`}>
            <img src={image} alt="image" />
        </div>
    )

    return (
        <WrapPage>
            <div className="wrap-page-home">
                <div className="page-home">
                    <p className="title">
                        <span className="title blue">{t('wee_digital.title_page_blue')}</span> {t('wee_digital.title_page')}
                    </p>
                    <p className="desc">{t('wee_digital.desc_page')}</p>
                </div>
            </div>
            <div className="page-one">
                <div className="content-left">
                    <p className="title blue">{t('wee_digital.page_one.title_blue_one')}</p>
                    <div className="title blue wrap">
                        {t('wee_digital.page_one.title_blue_two')} 
                        <p className="title">{t('wee_digital.page_one.title')}</p>
                    </div>
                </div>
                <div className="content-right">
                    <p className="desc">{t('wee_digital.page_one.desc_1')}</p>
                    <p className="desc">{t('wee_digital.page_one.desc_2')}</p>
                    <p className="desc">{t('wee_digital.page_one.desc_3')}</p>
                    <p className="desc-bold">
                        <span className="desc-bold blue" >{t('wee_digital.page_one.desc_bold_blue')}</span> {t('wee_digital.page_one.desc_bold')}
                    </p>
                </div>
            </div>
            <div className="wrap-page-two">
                <div className="page-two">
                    <div className="content">
                        <img src={ic_faceprint} aly="icon" />
                        <p className="title special">{t('wee_digital.page_two.content_1.title')}</p>
                        <p className="desc">{t('wee_digital.page_two.content_1.desc')}</p>
                    </div>
                    <div className="content">
                        <img src={ic_user} aly="icon" />
                        <p className="title two">{t('wee_digital.page_two.content_2.title')}</p>
                        <p className="desc">{t('wee_digital.page_two.content_2.desc')}</p>
                    </div>
                    <div className="content">
                        <img src={ic_award} aly="icon" />
                        <p className="title">{t('wee_digital.page_two.content_3.title')}</p>
                        <p className="desc">{t('wee_digital.page_two.content_3.desc')}</p>
                    </div>
                    <div className="content">
                        <img src={ic_shield} aly="icon" />
                        <p className="title">{t('wee_digital.page_two.content_4.title')}</p>
                        <p className="desc">{t('wee_digital.page_two.content_4.desc')}</p>
                    </div>
                </div>
            </div>
            <div className="page-three">
                <div className="content-left">
                    <p className="title blue">{t('wee_digital.page_three.title_blue')}</p>
                    <p className="title">{t('wee_digital.page_three.title')}</p>
                    <p className="desc">{t('wee_digital.page_three.desc')}</p>
                </div>
                <div className="content-right">
                    <div className="image-container" >
                        <div className="icon-previous-wrap">
                            <IcCyclePrevious className="icon-previous" onClick={() => onClickRedirect(-1)} />
                        </div>
                        <div className="image-wrap">
                            {
                                lstStep.map((item, index) => {
                                    if (item.step === activeReview)
                                        return imgItem(item, index, 'active')
                                    if (activeReview - 1 > 0 && item.step === activeReview - 1)
                                        return imgItem(item, index, 'previous')
                                    return imgItem(item, index, 'next')
                                })
                            }
                        </div>
                        <div className="icon-next-wrap">
                            <IcCycleNext className="icon-next" onClick={() => onClickRedirect(1)} />
                        </div>
                    </div>
                    <div className="step-container">
                        {
                            lstStep.map((item, index) => (
                                <div
                                    className={activeReview === item.step ? "cycle wrapper" : "cycle"}
                                    key={index}
                                >
                                    <div className={activeReview === item.step ? "step active" : "step"}></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(WeeDigital)
