import React from 'react'

const IcMail = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33341 3.33334H16.6667C17.5834 3.33334 18.3334 4.08334 18.3334 5.00001V15C18.3334 15.9167 17.5834 16.6667 16.6667 16.6667H3.33341C2.41675 16.6667 1.66675 15.9167 1.66675 15V5.00001C1.66675 4.08334 2.41675 3.33334 3.33341 3.33334Z" stroke="#1279DA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.3334 5L10.0001 10.8333L1.66675 5" stroke="#1279DA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcMail
