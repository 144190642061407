import React, { useState, useEffect } from 'react'

import { WrapBurgerBtn } from './index.styles'

const BurgerBtn = ({ handleToggle, checkExpand, color, ...props }) => {

    const [isExpand, setIsExpand] = useState(checkExpand || false);

    useEffect(() => {
        setIsExpand(checkExpand)
    }, [checkExpand]);

    const _handleToggle = () => {
        const newIsExpand = !isExpand
        // setIsExpand(newIsExpand)
        handleToggle(newIsExpand)
    }

    return (
        <WrapBurgerBtn {...props} onClick={_handleToggle} color={color}>
            <div className={`inner-burger-btn${isExpand ? ' expand' : ''}`}>
                <div className="top-bun"></div>
                <div className="patty"></div>
                <div className="bottom-bun"></div>
            </div>
        </WrapBurgerBtn>
    )
}

export default BurgerBtn
