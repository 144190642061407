import q3_step1 from '../images/FAQ/AccManagement/q3_step1.png'
import q3_step2 from '../images/FAQ/AccManagement/q3_step2.png'
import q3_step3 from '../images/FAQ/AccManagement/q3_step3.png'
import q3_step4 from '../images/FAQ/AccManagement/q3_step4.png'
import q3_step5 from '../images/FAQ/AccManagement/q3_step5.png'

import q5_step1 from '../images/FAQ/AccManagement/q5_step1.png'
import q5_step2 from '../images/FAQ/AccManagement/q5_step2.png'
import q5_step3 from '../images/FAQ/AccManagement/q5_step3.png'

import q6_step1 from '../images/FAQ/AccManagement/q6_step1.png'
import q6_step2 from '../images/FAQ/AccManagement/q6_step2.png'
import q6_step3 from '../images/FAQ/AccManagement/q6_step3.png'

import q7_step1 from '../images/FAQ/AccManagement/q7_step1.png'
import q7_step2 from '../images/FAQ/AccManagement/q7_step2.png'
import q7_step3 from '../images/FAQ/AccManagement/q7_step3.png'
import q7_step4 from '../images/FAQ/AccManagement/q7_step4.png'

import q8_step1 from '../images/FAQ/AccManagement/q8_step1.png'
import q8_step2 from '../images/FAQ/AccManagement/q8_step2.png'
import q8_step3 from '../images/FAQ/AccManagement/q8_step3.png'
import q8_step4 from '../images/FAQ/AccManagement/q8_step4.png'

import q12_step1 from '../images/FAQ/AccManagement/q12_step1.png'
import q12_step2 from '../images/FAQ/AccManagement/q12_step2.png'
import q12_step3 from '../images/FAQ/AccManagement/q12_step3.png'
import q12_step4 from '../images/FAQ/AccManagement/q12_step4.png'
import q12_step5 from '../images/FAQ/AccManagement/q12_step5.png'

import q17_step1 from '../images/FAQ/AccManagement/q17_step1.png'
import q17_step2 from '../images/FAQ/AccManagement/q17_step2.png'
import q17_step3 from '../images/FAQ/AccManagement/q17_step3.png'

import q19_step1 from '../images/FAQ/AccManagement/q19_step1.png'
import q19_step2 from '../images/FAQ/AccManagement/q19_step2.png'

import q24_step1 from '../images/FAQ/AccManagement/q24_step1.png'
import q24_step2 from '../images/FAQ/AccManagement/q24_step2.png'
import q24_step3 from '../images/FAQ/AccManagement/q24_step3.png'
import q24_step4 from '../images/FAQ/AccManagement/q24_step4.png'
import q24_step5 from '../images/FAQ/AccManagement/q24_step5.png'

import q25_step1 from '../images/FAQ/AccManagement/q25_step1.png'
import q25_step2 from '../images/FAQ/AccManagement/q25_step2.png'
import q25_step3 from '../images/FAQ/AccManagement/q25_step3.png'

import q26_step1 from '../images/FAQ/AccManagement/q26_step1.png'
import q26_step2 from '../images/FAQ/AccManagement/q26_step2.png'
import q26_step3 from '../images/FAQ/AccManagement/q26_step3.png'
import q26_step4 from '../images/FAQ/AccManagement/q26_step4.png'
import q26_step5 from '../images/FAQ/AccManagement/q26_step5.png'
import q26_step6 from '../images/FAQ/AccManagement/q26_step6.png'

import q27_step1 from '../images/FAQ/AccManagement/q27_step1.png'
import q27_step2 from '../images/FAQ/AccManagement/q27_step2.png'
import q27_step3 from '../images/FAQ/AccManagement/q27_step3.png'
import q27_step4 from '../images/FAQ/AccManagement/q27_step4.png'
import q27_step5 from '../images/FAQ/AccManagement/q27_step5.png'
import q27_step6 from '../images/FAQ/AccManagement/q27_step6.png'
import q27_step7 from '../images/FAQ/AccManagement/q27_step7.png'
import q27_step8 from '../images/FAQ/AccManagement/q27_step8.png'

import q28_step1 from '../images/FAQ/AccManagement/q28_step1.png'
import q28_step2 from '../images/FAQ/AccManagement/q28_step2.png'
import q28_step3 from '../images/FAQ/AccManagement/q28_step3.png'
import q28_step4 from '../images/FAQ/AccManagement/q28_step4.png'
import q28_step5 from '../images/FAQ/AccManagement/q28_step5.png'
import q28_step6 from '../images/FAQ/AccManagement/q28_step6.png'

//constant
import { ACCOUNT_MANAGEMENT, BASIC_INFOMATION, FACEPAY, SIGN_UP_ACCOUNT, SUPPORT } from '../constant/pathNames'

export const AccountManagementData = {
    facepay: {
        q24: {
            key: "q24",
            title: "account_management.facepay.q_24.title",
            date: "account_management.facepay.q_24.date",
            desc: [
                "account_management.facepay.q_24.desc.desc1",
            ],
            step: [
                {
                    step: 1,
                    image: q24_step1,
                    text: "account_management.facepay.q_24.step.text1",
                },
                {
                    step: 2,
                    image: q24_step2,
                    text: "account_management.facepay.q_24.step.text2",
                },
                {
                    step: 3,
                    image: q24_step3,
                    text: "account_management.facepay.q_24.step.text3",
                },
                {
                    step: 4,
                    image: q24_step4,
                    text: "account_management.facepay.q_24.step.text4",
                },
                {
                    step: 5,
                    image: q24_step5,
                    text: "account_management.facepay.q_24.step.text5"
                },
            ],
        },
        q21: {
            key: "q21",
            title: "account_management.facepay.q_21.title",
            date: "account_management.facepay.q_21.date",
            desc: [
                "account_management.facepay.q_21.desc.desc1",
                "account_management.facepay.q_21.desc.desc2"
            ]
        },
        q1: {
            key: "q1",
            title: "account_management.facepay.q_01.title",
            date: "account_management.facepay.q_01.date",
            desc: ["account_management.facepay.q_01.desc.desc1"]
        },
        q2: {
            key: "q2",
            date: "account_management.facepay.q_02.date",
            title: "account_management.facepay.q_02.title",
            desc: ["account_management.facepay.q_02.desc.desc1"]
        },
        q3: {
            key: "q3",
            date: "account_management.facepay.q_03.date",
            title: "account_management.facepay.q_03.title",
            desc: ["account_management.facepay.q_03.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q3_step1,
                    text: "account_management.facepay.q_03.step.text1",
                },
                {
                    step: 2,
                    image: q3_step2,
                    text: "account_management.facepay.q_03.step.text2",
                },
                {
                    step: 3,
                    image: q3_step3,
                    text: "account_management.facepay.q_03.step.text3",
                },
                {
                    step: 4,
                    image: q3_step4,
                    text: "account_management.facepay.q_03.step.text4",
                },
                {
                    step: 5,
                    image: q3_step5,
                    text: "account_management.facepay.q_03.step.text5"
                },                
            ],
            // note: ["account_management.facepay.q_03.note"]
        },
        q22: {
            key: "q22",
            title: "account_management.facepay.q_22.title",
            date: "account_management.facepay.q_22.date",
            desc: [
                "account_management.facepay.q_22.desc.desc1",
                "account_management.facepay.q_22.desc.desc2",
                "account_management.facepay.q_22.desc.desc3",
            ]
        },
        q4: {
            key: "q4",
            title: "account_management.facepay.q_04.title",
            date: "account_management.facepay.q_04.date",
            desc: [
                "account_management.facepay.q_04.desc.desc1",
                "account_management.facepay.q_04.desc.desc2",
                "account_management.facepay.q_04.desc.desc3",
                "account_management.facepay.q_04.desc.desc4",
                "account_management.facepay.q_04.desc.desc5",
            ]
        },
        q23: {
            key: "q23",
            title: "account_management.facepay.q_23.title",
            date: "account_management.facepay.q_23.date",
            desc: [
                "account_management.facepay.q_23.desc.desc1",
                "account_management.facepay.q_23.desc.desc2",
            ]
        },
        q26: {
            key: "q26",
            date: "account_management.facepay.q_26.date",
            title: "account_management.facepay.q_26.title",
            desc: ["account_management.facepay.q_26.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q26_step1,
                    text: "account_management.facepay.q_26.step.text1",
                },
                {
                    step: 2,
                    image: q26_step2,
                    text: "account_management.facepay.q_26.step.text2",
                },
                {
                    step: 3,
                    image: q26_step3,
                    text: "account_management.facepay.q_26.step.text3",
                },
                {
                    step: 4,
                    image: q26_step4,
                    text: "account_management.facepay.q_26.step.text4",
                },
                {
                    step: 5,
                    image: q26_step5,
                    text: "account_management.facepay.q_26.step.text5"
                },
                {
                    step: 6,
                    image: q26_step6,
                    text: "account_management.facepay.q_26.step.text6"
                },               
            ]
        },
        q27: {
            key: "q27",
            date: "account_management.facepay.q_27.date",
            title: "account_management.facepay.q_27.title",
            desc: ["account_management.facepay.q_27.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q27_step1,
                    text: "account_management.facepay.q_27.step.text1",
                },
                {
                    step: 2,
                    image: q27_step2,
                    text: "account_management.facepay.q_27.step.text2",
                },
                {
                    step: 3,
                    image: q27_step3,
                    text: "account_management.facepay.q_27.step.text3",
                },
                {
                    step: 4,
                    image: q27_step4,
                    text: "account_management.facepay.q_27.step.text4",
                },
                {
                    step: 5,
                    image: q27_step5,
                    text: "account_management.facepay.q_27.step.text5"
                },
                {
                    step: 6,
                    image: q27_step6,
                    text: "account_management.facepay.q_27.step.text6"
                }, 
                {
                    step: 7,
                    image: q27_step7,
                    text: "account_management.facepay.q_27.step.text7"
                }, 
                {
                    step: 8,
                    image: q27_step8,
                    text: "account_management.facepay.q_27.step.text8"
                },               
            ]
        },
        q28: {
            key: "q28",
            date: "account_management.facepay.q_28.date",
            title: "account_management.facepay.q_28.title",
            desc: ["account_management.facepay.q_28.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q28_step1,
                    text: "account_management.facepay.q_28.step.text1",
                },
                {
                    step: 2,
                    image: q28_step2,
                    text: "account_management.facepay.q_28.step.text2",
                },
                {
                    step: 3,
                    image: q28_step3,
                    text: "account_management.facepay.q_28.step.text3",
                },
                {
                    step: 4,
                    image: q28_step4,
                    text: "account_management.facepay.q_28.step.text4",
                },
                {
                    step: 5,
                    image: q28_step5,
                    text: "account_management.facepay.q_28.step.text5"
                },
                {
                    step: 6,
                    image: q28_step6,
                    text: "account_management.facepay.q_28.step.text6"
                },               
            ]
        },
        q29: {
            key: "q29",
            title: "account_management.facepay.q_29.title",
            date: "account_management.facepay.q_29.date",
            desc: [
                "account_management.facepay.q_29.desc.desc1",
            ]
        },
        // q5: {
        //     key: "q5",
        //     title: "account_management.facepay.q_05.title",
        //     date: "account_management.facepay.q_05.date",
        //     desc: ["account_management.facepay.q_05.desc.desc1"],
        //     link_here: {
        //         href: `/${SUPPORT}/${ACCOUNT_MANAGEMENT}/${FACEPAY}/q3`,
        //         target: "_blank"
        //     },
        //     step: [
        //         {
        //             step: 1,
        //             image: q5_step1,
        //             text: "account_management.facepay.q_05.step.text1",
        //         },
        //         {
        //             step: 2,
        //             image: q5_step2,
        //             text: "account_management.facepay.q_05.step.text2",
        //         },
        //         {
        //             step: 3,
        //             image: q5_step3,
        //             text: "account_management.facepay.q_05.step.text3",
        //         },
        //     ],
        //     note: ["account_management.facepay.q_05.note"]
        // },
        // q6: {
        //     key: "q6",
        //     title: "account_management.facepay.q_06.title",
        //     date: "account_management.facepay.q_06.date",
        //     desc: ["account_management.facepay.q_06.desc.desc1"],
        //     step: [
        //         {
        //             step: 1,
        //             image: q6_step1,
        //             text: "account_management.facepay.q_06.step.text1",
        //         },
        //         {
        //             step: 2,
        //             image: q6_step2,
        //             text: "account_management.facepay.q_06.step.text2",
        //         },
        //         {
        //             step: 3,
        //             image: q6_step3,
        //             text: "account_management.facepay.q_06.step.text3",
        //         },
        //     ]
        // },
        // q7: {
        //     key: "q7",
        //     title: "account_management.facepay.q_07.title",
        //     date: "account_management.facepay.q_07.date",
        //     desc: ["account_management.facepay.q_07.desc.desc1"],
        //     step: [
        //         {
        //             step: 1,
        //             image: q7_step1,
        //             text: "account_management.facepay.q_07.step.text1",
        //         },
        //         {
        //             step: 2,
        //             image: q7_step2,
        //             text: "account_management.facepay.q_07.step.text2",
        //         },
        //         {
        //             step: 3,
        //             image: q7_step3,
        //             text: "account_management.facepay.q_07.step.text3",
        //         },
        //         {
        //             step: 4,
        //             image: q7_step4,
        //             text: "account_management.facepay.q_07.step.text4",
        //         },
        //     ]
        // },
        // q8: {
        //     key: "q8",
        //     title: "account_management.facepay.q_08.title",
        //     date: "account_management.facepay.q_08.date",
        //     desc: ["account_management.facepay.q_08.desc.desc1"],
        //     step: [
        //         {
        //             step: 1,
        //             image: q8_step1,
        //             text: "account_management.facepay.q_08.step.text1",
        //         },
        //         {
        //             step: 2,
        //             image: q8_step2,
        //             text: "account_management.facepay.q_08.step.text2",
        //         },
        //         {
        //             step: 3,
        //             image: q8_step3,
        //             text: "account_management.facepay.q_08.step.text3",
        //         },
        //         {
        //             step: 4,
        //             image: q8_step4,
        //             text: "account_management.facepay.q_08.step.text4",
        //         },
        //     ]
        // },
        q9: {
            key: "q9",
            title: "account_management.facepay.q_09.title",
            date: "account_management.facepay.q_09.date",
            link_customer_care: {
                href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
                target: "_blank"
            },
            desc: ["account_management.facepay.q_09.desc.desc1"]
        },
        q10: {
            key: "q10",
            title: "account_management.facepay.q_10.title",
            date: "account_management.facepay.q_10.date",
            link_customer_care: {
                href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
                target: "_blank"
            },
            desc: ["account_management.facepay.q_10.desc.desc1"]
        },
        q11: {
            key: "q11",
            title: "account_management.facepay.q_11.title",
            date: "account_management.facepay.q_11.date",
            link_login: {
                href: `/${SUPPORT}/${SIGN_UP_ACCOUNT}/${FACEPAY}/q2`,
                target: "_blank"
            },
            desc: [
                "account_management.facepay.q_11.desc.desc1",
                "account_management.facepay.q_11.desc.desc2"
            ]
        },
        q12: {
            key: "q12",
            title: "account_management.facepay.q_12.title",
            date: "account_management.facepay.q_12.date",
            desc: ["account_management.facepay.q_12.desc.desc1"],
            link_login: {
                href: `/${SUPPORT}/${SIGN_UP_ACCOUNT}/${FACEPAY}/q2`,
                target: "_blank"
            },
            step: [
                {
                    step: 1,
                    image: q12_step1,
                    text: "account_management.facepay.q_12.step.text1",
                },
                {
                    step: 2,
                    image: q12_step2,
                    text: "account_management.facepay.q_12.step.text2",
                },
                {
                    step: 3,
                    image: q12_step3,
                    text: "account_management.facepay.q_12.step.text3",
                },
                {
                    step: 4,
                    image: q12_step4,
                    text: "account_management.facepay.q_12.step.text4",
                },
                {
                    step: 5,
                    image: q12_step5,
                    text: "account_management.facepay.q_12.step.text5",
                },
            ],
            note: ["account_management.facepay.q_12.note"]
        },
        q25: {
            key: "q25",
            title: "account_management.facepay.q_25.title",
            date: "account_management.facepay.q_25.date",
            desc: ["account_management.facepay.q_25.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q25_step1,
                    text: "account_management.facepay.q_25.step.text1",
                },
                {
                    step: 2,
                    image: q25_step2,
                    text: "account_management.facepay.q_25.step.text2",
                },
                {
                    step: 3,
                    image: q25_step3,
                    text: "account_management.facepay.q_25.step.text3",
                }
            ],
        },
        q13: {
            key: "q13",
            title: "account_management.facepay.q_13.title",
            date: "account_management.facepay.q_13.date",
            link_customer_care: {
                href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
                target: "_blank"
            },
            desc: ["account_management.facepay.q_13.desc.desc1"]
        },
        q14: {
            key: "q14",
            title: "account_management.facepay.q_14.title",
            date: "account_management.facepay.q_14.date",
            link_customer_care: {
                href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
                target: "_blank"
            },
            desc: ["account_management.facepay.q_14.desc.desc1"]
        },
        // q15: {
        //     key: "q15",
        //     title: "account_management.facepay.q_15.title",
        //     date: "account_management.facepay.q_15.date",
        //     link_payment_lock: {
        //         href: `/${SUPPORT}/${ACCOUNT_MANAGEMENT}/q7`,
        //         target: "_blank"
        //     },
        //     link_account_deactivation: {
        //         href: `/${SUPPORT}/${ACCOUNT_MANAGEMENT}/q12`,
        //         target: "_blank"
        //     },
        //     link_customer_care: {
        //         href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
        //         target: "_blank"
        //     },
        //     desc: [
        //         "account_management.facepay.q_15.desc.desc1",
        //         "account_management.facepay.q_15.desc.desc2",
        //         "account_management.facepay.q_15.desc.desc3"
        //     ]
        // },
        // q16: {
        //     key: "q16",
        //     title: "account_management.facepay.q_16.title",
        //     date: "account_management.facepay.q_16.date",
        //     desc: [
        //         "account_management.facepay.q_16.desc.desc1",
        //         "account_management.facepay.q_16.desc.desc2",
        //         "account_management.facepay.q_16.desc.desc3",
        //         "account_management.facepay.q_16.desc.desc4",
        //         "account_management.facepay.q_16.desc.desc5",
        //         "account_management.facepay.q_16.desc.desc6"
        //     ]
        // },
        // q17: {
        //     key: "q17",
        //     title: "account_management.facepay.q_17.title",
        //     date: "account_management.facepay.q_17.date",
        //     desc: ["account_management.facepay.q_17.desc.desc1"],
        //     link_confirm: {
        //         href: `/${SUPPORT}/${ACCOUNT_MANAGEMENT}/q16`,
        //         target: "_blank"
        //     },
        //     step: [
        //         {
        //             step: 1,
        //             image: q17_step1,
        //             text: "account_management.facepay.q_17.step.text1",
        //         },
        //         {
        //             step: 2,
        //             image: q17_step2,
        //             text: "account_management.facepay.q_17.step.text2",
        //         },
        //         {
        //             step: 3,
        //             image: q17_step3,
        //             text: "account_management.facepay.q_17.step.text3",
        //         },
        //     ]
        // },
        // q18: {
        //     key: "q18",
        //     title: "account_management.facepay.q_18.title",
        //     date: "account_management.facepay.q_18.date",
        //     desc: [
        //         "account_management.facepay.q_18.desc.desc1",
        //         "account_management.facepay.q_18.desc.desc2",
        //     ]
        // },
        q19: {
            key: "q19",
            title: "account_management.facepay.q_19.title",
            date: "account_management.facepay.q_19.date",
            desc: [
                "account_management.facepay.q_19.desc.desc1",
            ]
        },
        // q20: {
        //     key: "q20",
        //     title: "account_management.facepay.q_20.title",
        //     date: "account_management.facepay.q_20.date",
        //     desc: [
        //         "account_management.facepay.q_20.desc.desc1",
        //     ]
        // },
    }
}