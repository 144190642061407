import React, { useRef, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import lottie from 'lottie-web'
import { Link } from 'react-router-dom';

//style
import { WrapPage } from './index.styles'

//image
import ellip_heart from '../../assets/images/Recruitment/ellip_heart.svg'
import ellip_message from '../../assets/images/Recruitment/ellip_message.svg'
import ellip_search from '../../assets/images/Recruitment/ellip_search.svg'
import ellip_target from '../../assets/images/Recruitment/ellip_target.svg'
import PageSix from '../Home/PageSix';
import { RecruitmentData } from '../../assets/data/RecruitmentData';


const Recruitment = ({ t }) => {

    const wrapAnimation = useRef()

    useEffect(() => {
        lottie.loadAnimation({
            container: wrapAnimation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation/recruitment.json'
        })
    }, []);

    return (
        <WrapPage>
            <div className="wrap-page">
                <div className="page page-one">
                    <div className="wrap-text">
                        <div className="title">
                            {t('recruitment.page_one.title')} <span className="title blue">{t('recruitment.page_one.title_blue')}</span>
                        </div>
                        <p className="desc">{t('recruitment.page_one.desc')}</p>
                    </div>
                    <div className="lottie" ref={wrapAnimation}></div>
                </div>
            </div>
            <div className="page-two">
                <div className="wrap-title">
                    <div className="title"><span className="symbol">“</span>{t('recruitment.page_two.title_one')}</div>
                    <div className="title two">{t('recruitment.page_two.title_two')}</div>
                    <div className="title blue">{t('recruitment.page_two.title_three_blue')}<span className="symbol">“</span></div>
                </div>
                <div className="wrap-desc">
                    <p className="desc">{t('recruitment.page_two.desc_one')}</p>
                    <p className="desc">{t('recruitment.page_two.desc_two')}</p>
                    <p className="desc">{t('recruitment.page_two.desc_three')}</p>
                </div>

            </div>
            <div className="wrap-page">
                <div className="page page-three">
                    <div className="content">
                        <img src={ellip_heart} alt="hear" />
                        <p className="desc bold">{t('recruitment.page_three.content_one.title')}</p>
                        <p className="desc">{t('recruitment.page_three.content_one.desc')}</p>
                    </div>
                    <div className="content">
                        <img src={ellip_message} alt="hear" />
                        <p className="desc bold">{t('recruitment.page_three.content_two.title')}</p>
                        <p className="desc">{t('recruitment.page_three.content_two.desc')}</p>
                    </div>
                    <div className="content">
                        <img src={ellip_search} alt="hear" />
                        <p className="desc bold">{t('recruitment.page_three.content_three.title')}</p>
                        <p className="desc">{t('recruitment.page_three.content_three.desc')}</p>
                    </div>
                    <div className="content">
                        <img src={ellip_target} alt="hear" />
                        <p className="desc bold">{t('recruitment.page_three.content_four.title')}</p>
                        <p className="desc">{t('recruitment.page_three.content_four.desc')}</p>
                    </div>
                </div>
            </div>
            <div className="page-four">
                <div className="title-page">
                    <span className="title-page blue">{t('recruitment.page_four.title_blue')}</span> {t('recruitment.page_four.title')}
                </div>
                <div className="wrap-content">
                    {
                        Object.values(RecruitmentData).map((item, i) => {
                            return (
                                <Link key={i} className="content" to={item.link}>
                                    <p className="title">{t(item.title)}</p>
                                    <p className="date">
                                        <span>{t(item.place)}</span> {t(item.date)}
                                    </p>
                                    <p className="desc">{t(item.desc)}</p>
                                    <p className="view">{t("common.xem_them")}</p>
                                </Link>
                            )
                        })
                    }

                </div>
            </div>
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(Recruitment)
