import React from 'react'

const IcLifeBouy = (props) => {
    return (
        <svg {...props} width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 32C28.4183 32 32 28.4183 32 24C32 19.5817 28.4183 16 24 16C19.5817 16 16 19.5817 16 24C16 28.4183 19.5817 32 24 32Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.6599 29.66L38.1399 38.14" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.86011 38.14L18.3401 29.66" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.6599 18.34L38.1399 9.86" stroke="#191919" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.6599 18.34L36.7199 11.28" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.86011 9.86L18.3401 18.34" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcLifeBouy
