import React from 'react'
import { withNamespaces } from 'react-i18next';

//style
import * as styles from './index.styles'

const PageSeven = ({ t }) => {
    return (
        <styles.Bound>
            <p className="text">{t('home.page_seven.text')}</p>
        </styles.Bound >
    )
}

export default withNamespaces()(PageSeven)
