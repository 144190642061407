import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix'
import ContactPage from '../../Support/ContactPage'

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft'
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'

//constant
import { POLICY } from '../../../assets/constant/pathNames';


const InfomationColleted = ({ t }) => {
    return (
        <WrapPage>
            <div className="page-one">
                <Link to={`/${POLICY}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <p className="title-page">{t('infomation_collected.title_page')}</p>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t('infomation_collected.date_page')}</div>
                </div> */}
                <div className="content-page">
                    <p className="content">Các thông tin được thu thập bao gồm:</p>
                    <p className="note">a) Các thông tin được bạn cung cấp cho chúng tôi khi đăng ký và mở Tài khoản Facepay trên Ứng dụng Facepay, bao gồm nhưng không giới hạn các thông tin Hồ sơ Mở tài khoản;</p>
                    <p className="note">b) Các thông tin có được từ việc bạn sử dụng Dịch vụ và Facepay, bao gồm nhưng không giới hạn các thông tin liên quan đến hành vị, vị trí, thiết bị, giao dịch được thực hiện, vv...</p>
                    <p className="note">c) Các thông tin từ các nguồn khác, như các bên thứ ba của Wee Digital và các bên thứ basử dụng giao thức API của Wee Digital; và</p>
                    <p className="note">d) Các thông tin khác cần thiết để Wee Digital thực hiện tuân thủ pháp luật theo các quy định hiện hành.</p>
                    <p className="content">Để tránh nhầm lẫn, chúng tôi không thu thập  hay không xử lý các thông tin đối với trẻ em dưới 16 tuổi. Nếu bạn nhận thấy chúng tôi có thu thập thông tin cá nhân của trẻ em dưới 16 tuổi, vui lòng liên hệ bộ phận Chăm sóc khách hàng hoặc/ và [.]</p>
                    <p className="title-large">1. Các thông tin mà bạn cung cấp cho Wee Digital</p>
                    <p className="content">Chúng tôi thu thập và sử dụng các thông tin sau:</p>
                    <p className="note">a) Hồ sơ Mở Tài khoản Facepay: khi bạn đăng ký mở một Tài Khoản Facepay, bạn sẽ được yêu cầu cung cấp các thông tin bao gồm nhưng không giới hạn (tùy thuộc vào loạiDịch vụ): họ tên đầy đủ, ngày tháng năm sinh, quốc tịch, giới tính, số điện thoại, email, địa chỉ, username, số CMND/ CCCD, đặc điểm sinh trắc học, vv... Để tránh nhầm lẫn, tại từng thời điểm, bạn có thể lựa chọn hoặc được yêu cầu cập nhật, chỉnh sửa, thay đổicác thông tin này (ví dụ khi bạn có thay đổi so với thông tin đã cung cấp). Chúng tôi cũng thu thập và xử lý các thông tin này như được cập nhật, chỉnh sửa, thay đổi bởi bạn;</p>
                    <p className="note">b) Các thông tin bạn cung cấp khi liên hệ với chúng tôi: Khi bạn liên hệ với Wee Digital thông qua bộ phận CSKH, tổng đài, ứng dụng, website, tài khoản mạng xã hội và/hoặc khi bạn cần phải cung cấp cho chúng tôi các thông tin để chúng tôi có thể phản hồi yêu cầu của bạn và, hỗ trợ bạn;</p>
                    <p className="note">c) Các thông tin bạn cung cấp khi truy cập: Khi bạn sử dụng một phần tính năng của Facepay, bạn có thể được yêu cầu cho phép Facepay quyền truy cập vào danh sách liên hệ, hình ảnh, camera và vị trí của thiết bi; và</p>
                    <p className="note">d) Các thông tin được cung cấp qua khảo sát: vào từng thời điểm, chúng tôi có thể mời bạn tham gia các cuộc khảo sát nhằm nâng cao chất lượng và trải nghiệm dịch vụ của Facepay và/ hoặc các Dịch vụ chúng tôi cung cấp cho bạn. Nếu bạn chọn thực hiện khảo sát, chúng tôi sẽ thu thập các thông tin về bạn và ý kía của bạn cho mục đích đề racủa cuộc khảo sát.</p>
                    <p className="title-large">2. Thông tin liên quan đến việc sử dụng Facepay</p>
                    <p className="note">a) Chúng tôi thu thập và xử lý các thông tin liên quan đến việc sử dụng Facepay của bạn, bao gồm các cài đặt bạn tùy chỉnh (ví dụ ngôn ngữ), hành vi sử dụng Facepay và Dịch vụ, các loại Hàng hóa bạn giao dịch, thêm vào giỏ hàng hoặc mua thông qua Giao dịch. Trong một số trường hợp, chúng tôi thu thập thông tin của bạn sử dụng cookies, pixcels và/ hoặc các công nghệ tương tự cho phép tạo ra và giữ lại các đoạn mã xác nhận riêngbiệt. Các công nghệ giúp chúng tôi tự động thu thập thông tin trên các website/ ứng dụng bạn truy cập và các bạn sử dụng Facepay, giúp nâng cao trải nghiệm sử dụng Facepay, tối ưu Ứng dụng Facepay và/ hoặc Dịch vụ, và, khi phù hợp, sẽ đưa ra các gọiý quảng cáo/ Hàng hóa ướng đến bạn;</p>
                    <p className="note">b) Nếu Người dùng cho phép Ứng dụng Facepay thu thập các thông tin về vị trí thiết bị, chúng tôi sẽ thu thập vị trí chinh xác và tương đối thông qua dữ liệu GPS, địa chỉ IP và Wifi. Khi bạn sử dụng chức năng “Tìm kiếm các địa điểm chấp nhận Facepay” trên Ứng dụng Facepay, Facepay sẽ tự động thể hiện vị trí của bạn trên bản đồ và vị trí các địa điểm chấp nhận thanh toán Facepay gần nhất. Chúng tôi có thể thu thập dữ liệu vị trí khiỨng dụng Facepay đang hoạt động ở chế độ hiển thị (khi ứng dụng được mở và hiển thịtrên màn hình) hoặc ở chế độ ẩn (khi ứng dụng được mở nhưng không được hiển thị trên màn hình);</p>
                    <p className="note">c) Các thông tin về Giao dịch: chúng tôi cũng thu thập các thông tin liên quan đến các giao dịch bạn đã thực hiện sử dụng Facepay và/ hoặc Dịch vụ, bao gồm nhưng không giời hạn các loại DỊch vụ, ngày và tháng sử dụng, số tiền và các thông tin liên quan khác;</p>
                    <p className="note">d) Thông tin về kỹ thuật: Chúng tôi có thể thu thập các thông tin về thiết bị bạn sử dụng để truy cập Facepay và/ hoặc dịch vụ, bao gồm nhưng khôn giới hạn mẫu phần cứng, hệ điều hành, phiên bản, phần mềm, tên file và phiên bản của nó, ngôn ngữ, mã định danh thiết bị, quảng cáo, số serial, và/ hoặc thông tin về nhà mạng vv...; và</p>
                    <p className="note">e) Thông tin truy cập: Khi bạn tương tác với Ứng dụng Facepay, chúng tôi sẽ thu thập nhậtký server, bao gồm thông tin về địa chỉ IP của thiết bị, ngày truy cập, tính năng hoặc trang đã xem, lỗi ứng dụng và/ hoặc các hoạt động khác của hệ thống, loại trình duyệt và website của bên thứ ba hoặc dịch vụ mà bạn đang sử dụng trước khi tương tác trên Ứng dụng Facepay.</p>
                    <p className="title-large">3. Thông tin từ các nguồn khác</p>
                    <p className="content">Chúng tôi có thể tự động thu tập một số thông tin nhất định về bạn từ các bên thứ ba mà bạn đã chọn để chia sẽ thông tin cho chúng tôi hoặc từ việc sử dụng Ứng dụng Facepay. Cụ thể:</p>
                    <p className="note">a) Nếu bạn cho đăng ký @VH truy cập vào ỨNG DỤNG FACEPAY sử dụng tài khoản mạng xã hội từ bên thứ ba của bạn, có nghĩa bạn đã cung cấp cho chúng tôi các thông tin được công khai trên tài khoản mạng xã hội của bạn. Ngoài ra, tùy thuộc vào sự đồng ý của bạn với các bên phát triển mạng xã hội này, bạn có thể cho phép các bên phát triển mạng xã hội này cung cấp/ chia sẽ thông tin của bạn cho chúng tôi. Chúng tôi cũngthu thập và xử lý các thông tin của bạn thông qua các websites có sử dụng API của Facepay và/ hoặc chia sẽ API của họ với chúng tôi;</p>
                    <p className="note">b) Chúng tôi cũng thu thập các thông tin của bạn từ nguồn của các bên thứ ba, ví dụ các cửa hàng, và hoặc các đối tác của Wee Digital, và</p>
                    <p className="note">c) Chúng tôi cũng thu thập thông tin của bạn từ các nguồn khác mà tại đó, thông tin của bạn đã được công bố.</p>
                    <p className="content">Bạn hiểu rằng, chúng tôi có thể kết hợp thông tin từ các nguồn khác nhau với các thông tin mà chúng tôi đã có.</p>
                    <p className="title-large">4. Các thông tin được yêu cầu cho việc tuân thủ các quy định của pháp luật</p>
                    <p className="content">Tại từng thời điểm, chúng tôi sẽ yêu cầu bạn cung cấp cho chúng tôi các thông tin của bạn liên quan đến việc sử dụng ỨNG DỤNG FACEPAY cho các mục đích sau:</p>
                    <p className="note">a) Giúp chúng tôi tuân thủ các quy định của pháp luật;</p>
                    <p className="note">b) Báo cáo với các cơ quan chức năng về sự tuân thủ với các quy định của pháp luật; và</p>
                    <p className="note">c) Đánh giá bạn có đã, đang và sẽ có khả năng tiếp tuận tuân thủ các nghĩa vụ của bạn theo quy định của pháp luật và Điều khoản chung.</p>
                </div>
            </div>
            {/* page two */}
            <ContactPage />
            {/* page three */}
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(InfomationColleted)
