import q1_facepay_step1 from '../images/FAQ/SignUpAccount/Facepay/step_01.png'
import q1_facepay_step2 from '../images/FAQ/SignUpAccount/Facepay/step_02.png'
import q1_facepay_step3 from '../images/FAQ/SignUpAccount/Facepay/step_03.png'
import q1_facepay_step4 from '../images/FAQ/SignUpAccount/Facepay/step_04.png'
import q1_facepay_step5 from '../images/FAQ/SignUpAccount/Facepay/step_05.png'
import q1_facepay_step6 from '../images/FAQ/SignUpAccount/Facepay/step_06.png'
import q1_facepay_step7 from '../images/FAQ/SignUpAccount/Facepay/step_07.png'
import q1_facepay_step8 from '../images/FAQ/SignUpAccount/Facepay/step_08.png'

import q2_facepay_step1 from '../images/FAQ/SignUpAccount/Facepay/q2_01.png'
import q2_facepay_step2 from '../images/FAQ/SignUpAccount/Facepay/q2_02.png'
import q2_facepay_step3 from '../images/FAQ/SignUpAccount/Facepay/q2_03.png'
import q2_facepay_step4 from '../images/FAQ/SignUpAccount/Facepay/q2_04.png'
import q2_facepay_step5 from '../images/FAQ/SignUpAccount/Facepay/q2_05.png'
import q2_facepay_step6 from '../images/FAQ/SignUpAccount/Facepay/q2_06.png'
import q2_facepay_step7 from '../images/FAQ/SignUpAccount/Facepay/q2_07.png'

import q18_facepay_step1 from '../images/FAQ/SignUpAccount/Facepay/q18_01.png'
import q18_facepay_step2 from '../images/FAQ/SignUpAccount/Facepay/q18_02.png'
import q18_facepay_step3 from '../images/FAQ/SignUpAccount/Facepay/q18_03.png'
import q18_facepay_step4 from '../images/FAQ/SignUpAccount/Facepay/q18_04.png'
import q18_facepay_step5 from '../images/FAQ/SignUpAccount/Facepay/q18_05.png'

import q19_facepay_step1 from '../images/FAQ/SignUpAccount/Facepay/q19_01.png'
import q19_facepay_step2 from '../images/FAQ/SignUpAccount/Facepay/q19_02.png'
import q19_facepay_step3 from '../images/FAQ/SignUpAccount/Facepay/q19_03.png'
import q19_facepay_step4 from '../images/FAQ/SignUpAccount/Facepay/q19_04.png'
import q19_facepay_step5 from '../images/FAQ/SignUpAccount/Facepay/q19_05.png'

import q20_tpbank_step1 from '../images/FAQ/SignUpAccount/TPBank/q20_01.png'
import q20_tpbank_step2 from '../images/FAQ/SignUpAccount/TPBank/q20_02.png'

import q21_ocb_step1 from '../images/FAQ/SignUpAccount/OCB/q21_01.png'
import q21_ocb_step2 from '../images/FAQ/SignUpAccount/OCB/q21_02.png'

import q23_vietinbank_step1 from '../images/FAQ/SignUpAccount/Vietinbank/q23_01.png'
import q23_vietinbank_step2 from '../images/FAQ/SignUpAccount/Vietinbank/q23_02.png'
import q23_vietinbank_step3 from '../images/FAQ/SignUpAccount/Vietinbank/q23_03.png'


import q25_smartpay_step1 from '../images/FAQ/SignUpAccount/SmartPay/q25_01.png'
import q25_smartpay_step2 from '../images/FAQ/SignUpAccount/SmartPay/q25_02.png'
import q25_smartpay_step3 from '../images/FAQ/SignUpAccount/SmartPay/q25_03.png'


//constant
import { BASIC_INFOMATION, SUPPORT } from '../constant/pathNames'



export const SignUpAccountData = {
    facepay: {
        q1: {
            key: "q1",
            date: "sign_up_account.facepay.q_01.date",
            title: "sign_up_account.facepay.q_01.title",
            desc: ["sign_up_account.facepay.q_01.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q1_facepay_step1,
                    text: "sign_up_account.facepay.q_01.step.text1",
                },
                {
                    step: 2,
                    image: q1_facepay_step2,
                    text: "sign_up_account.facepay.q_01.step.text2",
                },
                {
                    step: 3,
                    image: q1_facepay_step3,
                    text: "sign_up_account.facepay.q_01.step.text3",
                },
                {
                    step: 4,
                    image: q1_facepay_step4,
                    text: "sign_up_account.facepay.q_01.step.text4",
                    link_OTP: "",
                },
                {
                    step: 5,
                    image: q1_facepay_step5,
                    text: "sign_up_account.facepay.q_01.step.text5"
                },
                {
                    step: 6,
                    image: q1_facepay_step6,
                    text: "sign_up_account.facepay.q_01.step.text6"
                },
                {
                    step: 7,
                    image: q1_facepay_step7,
                    text: "sign_up_account.facepay.q_01.step.text7"
                },
                {
                    step: 8,
                    image: q1_facepay_step8,
                    text: "sign_up_account.facepay.q_01.step.text8"
                }
            ],
        },
        q24: {
            key: "q24",
            date: "sign_up_account.facepay.q_24.date",
            title: "sign_up_account.facepay.q_24.title",
            desc: [
                "sign_up_account.facepay.q_24.desc.desc1",
                "sign_up_account.facepay.q_24.desc.desc2",
                "sign_up_account.facepay.q_24.desc.desc3",
                "sign_up_account.facepay.q_24.desc.desc4",
                "sign_up_account.facepay.q_24.desc.desc5",
                "sign_up_account.facepay.q_24.desc.desc6",
                "sign_up_account.facepay.q_24.desc.desc7",
                "sign_up_account.facepay.q_24.desc.desc8",
                "sign_up_account.facepay.q_24.desc.desc9",
            ],
        },
        q2: {
            key: "q2",
            date: "sign_up_account.facepay.q_02.date",
            title: "sign_up_account.facepay.q_02.title",
            desc: ["sign_up_account.facepay.q_02.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q2_facepay_step1,
                    text: "sign_up_account.facepay.q_02.step.text1",
                },
                {
                    step: 2,
                    image: q2_facepay_step2,
                    text: "sign_up_account.facepay.q_02.step.text2",
                },
                {
                    step: 3,
                    image: q2_facepay_step3,
                    text: "sign_up_account.facepay.q_02.step.text3",
                },
                {
                    step: 4,
                    image: q2_facepay_step4,
                    text: "sign_up_account.facepay.q_02.step.text4",
                    link_OTP: "",
                },
                {
                    step: 5,
                    image: q2_facepay_step5,
                    text: "sign_up_account.facepay.q_02.step.text5"
                },
                {
                    step: 6,
                    image: q2_facepay_step6,
                    text: "sign_up_account.facepay.q_02.step.text6"
                },
                {
                    step: 7,
                    image: q2_facepay_step7,
                    text: "sign_up_account.facepay.q_02.step.text7"
                }
            ],
        },
        q3: {
            key: "q3",
            date: "sign_up_account.facepay.q_3.date",
            link_customer_care: {
                href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
                target: "_blank"
            },
            title: "sign_up_account.facepay.q_3.title",
            desc: [
                "sign_up_account.facepay.q_3.desc.desc1",
                "sign_up_account.facepay.q_3.desc.desc2",
                "sign_up_account.facepay.q_3.desc.desc3",
                "sign_up_account.facepay.q_3.desc.desc4",
            ],
        },
        q4: {
            key: "q4",
            date: "sign_up_account.facepay.q_4.date",
            title: "sign_up_account.facepay.q_4.title",
            desc: [
                "sign_up_account.facepay.q_4.desc.desc1",
            ],
        },
        q5: {
            key: "q5",
            date: "sign_up_account.facepay.q_5.date",
            title: "sign_up_account.facepay.q_5.title",
            desc: [
                "sign_up_account.facepay.q_5.desc.desc1",
            ],
        },
        q6: {
            key: "q6",
            date: "sign_up_account.facepay.q_6.date",
            title: "sign_up_account.facepay.q_6.title",
            desc: [
                "sign_up_account.facepay.q_6.desc.desc1",
                "sign_up_account.facepay.q_6.desc.desc2",
                "sign_up_account.facepay.q_6.desc.desc3",
            ],
        },
        q7: {
            key: "q7",
            date: "sign_up_account.facepay.q_7.date",
            title: "sign_up_account.facepay.q_7.title",
            desc: [
                "sign_up_account.facepay.q_7.desc.desc1",
                "sign_up_account.facepay.q_7.desc.desc2",
            ],
        },
        q8: {
            key: "q8",
            date: "sign_up_account.facepay.q_8.date",
            title: "sign_up_account.facepay.q_8.title",
            desc: [
                "sign_up_account.facepay.q_8.desc.desc1",
                "sign_up_account.facepay.q_8.desc.desc2",
            ],
        },
        q10: {
            key: "q10",
            date: "sign_up_account.facepay.q_10.date",
            title: "sign_up_account.facepay.q_10.title",
            desc: [
                "sign_up_account.facepay.q_10.desc.desc1",
            ],
        },
        q11: {
            key: "q11",
            date: "sign_up_account.facepay.q_11.date",
            title: "sign_up_account.facepay.q_11.title",
            desc: [
                "sign_up_account.facepay.q_11.desc.desc1",
            ],
        },
        q12: {
            key: "q12",
            date: "sign_up_account.facepay.q_12.date",
            title: "sign_up_account.facepay.q_12.title",
            desc: [
                "sign_up_account.facepay.q_12.desc.desc1",
                "sign_up_account.facepay.q_12.desc.desc2",
                "sign_up_account.facepay.q_12.desc.desc3",
                "sign_up_account.facepay.q_12.desc.desc4",
            ],
        },
        q13: {
            key: "q13",
            date: "sign_up_account.facepay.q_13.date",
            link_customer_care: {
                href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
                target: "_blank"
            },
            title: "sign_up_account.facepay.q_13.title",
            desc: [
                "sign_up_account.facepay.q_13.desc.desc1",
                "sign_up_account.facepay.q_13.desc.desc2",
                "sign_up_account.facepay.q_13.desc.desc3",
                "sign_up_account.facepay.q_13.desc.desc4",
                "sign_up_account.facepay.q_13.desc.desc5",
            ],
        },
        q14: {
            key: "q14",
            date: "sign_up_account.facepay.q_14.date",
            title: "sign_up_account.facepay.q_14.title",
            desc: [
                "sign_up_account.facepay.q_14.desc.desc1",
            ],
        },
        q18: {
            key: "q18",
            date: "sign_up_account.facepay.q_18.date",
            title: "sign_up_account.facepay.q_18.title",
            desc: ["sign_up_account.facepay.q_18.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q18_facepay_step1,
                    text: "sign_up_account.facepay.q_18.step.text1",
                },
                {
                    step: 2,
                    image: q18_facepay_step2,
                    text: "sign_up_account.facepay.q_18.step.text2",
                },
                {
                    step: 3,
                    image: q18_facepay_step3,
                    text: "sign_up_account.facepay.q_18.step.text3",
                },
                {
                    step: 4,
                    image: q18_facepay_step4,
                    text: "sign_up_account.facepay.q_18.step.text4",
                    link_OTP: "",
                },
                {
                    step: 5,
                    image: q18_facepay_step5,
                    text: "sign_up_account.facepay.q_18.step.text5"
                },
            ],
        },
        q19: {
            key: "q19",
            date: "sign_up_account.facepay.q_19.date",
            title: "sign_up_account.facepay.q_19.title",
            desc: ["sign_up_account.facepay.q_19.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q19_facepay_step1,
                    text: "sign_up_account.facepay.q_19.step.text1",
                },
                {
                    step: 2,
                    image: q19_facepay_step2,
                    text: "sign_up_account.facepay.q_19.step.text2",
                },
                {
                    step: 3,
                    image: q19_facepay_step3,
                    text: "sign_up_account.facepay.q_19.step.text3",
                },
                {
                    step: 4,
                    image: q19_facepay_step4,
                    text: "sign_up_account.facepay.q_19.step.text4",
                    link_OTP: "",
                },
                {
                    step: 5,
                    image: q19_facepay_step5,
                    text: "sign_up_account.facepay.q_19.step.text5"
                },
            ],
        },
    },
    tp_bank: {
        q20: {
            key: "q20",
            date: "sign_up_account.tp_bank.q_20.date",
            title: "sign_up_account.tp_bank.q_20.title",
            desc: ["sign_up_account.tp_bank.q_20.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q20_tpbank_step1,
                    text: "sign_up_account.tp_bank.q_20.step.text1",
                },
                {
                    step: 2,
                    image: q20_tpbank_step2,
                    text: "sign_up_account.tp_bank.q_20.step.text2",
                }
            ],
        },
        q22: {
            key: "q22",
            date: "sign_up_account.tp_bank.q_22.date",
            title: "sign_up_account.tp_bank.q_22.title",
            desc: ["sign_up_account.tp_bank.q_22.desc.desc1"],
        }
    },
    ocb: {
        q21: {
            key: "q21",
            date: "sign_up_account.ocb.q_21.date",
            title: "sign_up_account.ocb.q_21.title",
            desc: ["sign_up_account.ocb.q_21.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q21_ocb_step1,
                    text: "sign_up_account.ocb.q_21.step.text1",
                },
                {
                    step: 2,
                    image: q21_ocb_step2,
                    text: "sign_up_account.ocb.q_21.step.text2",
                }
            ],
        }
    },
    vietinbank: {
        q23: {
            key: "q23",
            date: "sign_up_account.vietinbank.q_23.date",
            title: "sign_up_account.vietinbank.q_23.title",
            desc: ["sign_up_account.vietinbank.q_23.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q23_vietinbank_step1,
                    text: "sign_up_account.vietinbank.q_23.step.text1",
                },
                {
                    step: 2,
                    image: q23_vietinbank_step2,
                    text: "sign_up_account.vietinbank.q_23.step.text2",
                },
                {
                    step: 3,
                    image: q23_vietinbank_step3,
                    text: "sign_up_account.vietinbank.q_23.step.text3",
                }
            ],
        }
    },    
    smartpay: {
        q25: {
            key: "q25",
            date: "sign_up_account.smartpay.q_25.date",
            title: "sign_up_account.smartpay.q_25.title",
            desc: ["sign_up_account.smartpay.q_25.desc.desc1"],
            step: [
                {
                    step: 1,
                    image: q25_smartpay_step1,
                    text: "sign_up_account.smartpay.q_25.step.text1",
                },
                {
                    step: 2,
                    image: q25_smartpay_step2,
                    text: "sign_up_account.smartpay.q_25.step.text2",
                },
                {
                    step: 3,
                    image: q25_smartpay_step3,
                    text: "sign_up_account.smartpay.q_25.step.text3",
                }
            ],
        },
        q26: {
            key: "q26",
            date: "sign_up_account.smartpay.q_26.date",
            title: "sign_up_account.smartpay.q_26.title",
            desc: [
                "sign_up_account.smartpay.q_26.desc.desc1",
            ],
        },
    }
}