import { BASIC_INFOMATION, SUPPORT } from '../constant/pathNames'
import q9_step1_1 from '../images/FacePayMagic/GeneralInfomation/q9_step1_1.png'
import q9_step1_2 from '../images/FacePayMagic/GeneralInfomation/q9_step1_2.png'
import q9_step1_3 from '../images/FacePayMagic/GeneralInfomation/q9_step1_3.png'
import q9_step1_4 from '../images/FacePayMagic/GeneralInfomation/q9_step1_4.png'
import q9_step2_1 from '../images/FacePayMagic/GeneralInfomation/q9_step2_1.png'
import q9_step2_2 from '../images/FacePayMagic/GeneralInfomation/q9_step2_2.png'
import q9_step2_3 from '../images/FacePayMagic/GeneralInfomation/q9_step2_3.png'
import q9_step2_4 from '../images/FacePayMagic/GeneralInfomation/q9_step2_4.png'
import q9_step2_5 from '../images/FacePayMagic/GeneralInfomation/q9_step2_5.png'
import q9_step2_6 from '../images/FacePayMagic/GeneralInfomation/q9_step2_6.png'
import q9_step2_7 from '../images/FacePayMagic/GeneralInfomation/q9_step2_7.png'

import q1_step1_1 from '../images/FacePayMagic/RefundPolicy/q1_step1_1.png'
import q1_step1_2 from '../images/FacePayMagic/RefundPolicy/q1_step1_2.png'
import q1_step1_3 from '../images/FacePayMagic/RefundPolicy/q1_step1_3.png'
import q1_step1_4 from '../images/FacePayMagic/RefundPolicy/q1_step1_4.png'
import q1_step1_5 from '../images/FacePayMagic/RefundPolicy/q1_step1_5.png'
import q1_step1_6 from '../images/FacePayMagic/RefundPolicy/q1_step1_6.png'
import q1_step2_1 from '../images/FacePayMagic/RefundPolicy/q1_step2_1.png'
import q1_step2_2 from '../images/FacePayMagic/RefundPolicy/q1_step2_2.png'
import q1_step2_3 from '../images/FacePayMagic/RefundPolicy/q1_step2_3.png'
import q1_step2_4 from '../images/FacePayMagic/RefundPolicy/q1_step2_4.png'
import q1_step2_5 from '../images/FacePayMagic/RefundPolicy/q1_step2_5.png'
import q1_step2_6 from '../images/FacePayMagic/RefundPolicy/q1_step2_6.png'

export const FacePayMagicFAQData = { 
    general_information: {
        q1: {
            key: "q1",
            title: "facepay_magic.general_information.q_01.title",
            date: "facepay_magic.general_information.q_01.date",
            desc: [
                "facepay_magic.general_information.q_01.desc.desc1",
            ],        
        },
        q2: {
            key: "q2",
            title: "facepay_magic.general_information.q_02.title",
            date: "facepay_magic.general_information.q_02.date",
            desc: [
                "facepay_magic.general_information.q_02.desc.desc1",
            ],        
        },
        q3: {
            key: "q3",
            title: "facepay_magic.general_information.q_03.title",
            date: "facepay_magic.general_information.q_03.date",
            desc: [
                "facepay_magic.general_information.q_03.desc.desc1",
            ],        
        },
        q4: {
            key: "q4",
            title: "facepay_magic.general_information.q_04.title",
            date: "facepay_magic.general_information.q_04.date",
            desc: [
                "facepay_magic.general_information.q_04.desc.desc1",
            ],        
        },
        q5: {
            key: "q5",
            title: "facepay_magic.general_information.q_05.title",
            date: "facepay_magic.general_information.q_05.date",
            desc: [
                "facepay_magic.general_information.q_05.desc.desc1",
            ],        
        },
        q6: {
            key: "q6",
            title: "facepay_magic.general_information.q_06.title",
            date: "facepay_magic.general_information.q_06.date",
            desc: [
                "facepay_magic.general_information.q_06.desc.desc1",
            ],        
        },
        q7: {
            key: "q7",
            title: "facepay_magic.general_information.q_07.title",
            date: "facepay_magic.general_information.q_07.date",
            desc: [
                "facepay_magic.general_information.q_07.desc.desc1",
            ],        
        },
        q8: {
            key: "q8",
            title: "facepay_magic.general_information.q_08.title",
            date: "facepay_magic.general_information.q_08.date",
            desc: [
                "facepay_magic.general_information.q_08.desc.desc1",
            ],        
        },
        q9: {
            key: "q9",
            title: "facepay_magic.general_information.q_09.title",
            date: "facepay_magic.general_information.q_09.date",
            desc: [
                "facepay_magic.general_information.q_09.desc.desc1",
                "facepay_magic.general_information.q_09.desc.desc2",
            ],
            step: [
                {
                    step: 1,
                    image: q9_step1_1,
                    text: "facepay_magic.general_information.q_09.step.text1",
                },
                {
                    step: 2,
                    image: q9_step1_2,
                    text: "facepay_magic.general_information.q_09.step.text2",
                },
                {
                    step: 3,
                    image: q9_step1_3,
                    text: "facepay_magic.general_information.q_09.step.text3",
                },
                {
                    step: 4,
                    image: q9_step1_4,
                    text: "facepay_magic.general_information.q_09.step.text4",
                },
            ],
            desc_2: [
                "facepay_magic.general_information.q_09.desc_2.desc1",
            ],
            step_2: [
                {
                    step: 1,
                    image: q9_step2_1,
                    text: "facepay_magic.general_information.q_09.step_2.text1",
                },
                {
                    step: 2,
                    image: q9_step2_2,
                    text: "facepay_magic.general_information.q_09.step_2.text2",
                },
                {
                    step: 3,
                    image: q9_step2_3,
                    text: "facepay_magic.general_information.q_09.step_2.text3",
                },
                {
                    step: 4,
                    image: q9_step2_4,
                    text: "facepay_magic.general_information.q_09.step_2.text4",
                },
                {
                    step: 5,
                    image: q9_step2_5,
                    text: "facepay_magic.general_information.q_09.step_2.text5",
                },
                {
                    step: 6,
                    image: q9_step2_6,
                    text: "facepay_magic.general_information.q_09.step_2.text6",
                },
                {
                    step: 7,
                    image: q9_step2_7,
                    text: "facepay_magic.general_information.q_09.step_2.text7",
                },
            ]      
        },
    },
    refund_policy: {
        q1: {
            key: "q1",
            title: "facepay_magic.refund_policy.q_01.title",
            date: "facepay_magic.refund_policy.q_01.date",
            desc: [
                "facepay_magic.refund_policy.q_01.desc.desc1",
            ],
            step: [
                {
                    step: 1,
                    image: q1_step1_1,
                    text: "facepay_magic.refund_policy.q_01.step.text1",
                },
                {
                    step: 2,
                    image: q1_step1_2,
                    text: "facepay_magic.refund_policy.q_01.step.text2",
                },
                {
                    step: 3,
                    image: q1_step1_3,
                    text: "facepay_magic.refund_policy.q_01.step.text3",
                },
                {
                    step: 4,
                    image: q1_step1_4,
                    text: "facepay_magic.refund_policy.q_01.step.text4",
                },
                {
                    step: 5,
                    image: q1_step1_5,
                    text: "facepay_magic.refund_policy.q_01.step.text5",
                },
                {
                    step: 6,
                    image: q1_step1_6,
                    text: "facepay_magic.refund_policy.q_01.step.text6",
                }
            ],
            desc_3: [
                "facepay_magic.refund_policy.q_01.desc_3.desc1",
            ],
            desc_2: [
                "facepay_magic.refund_policy.q_01.desc_2.desc1",
            ],
            step_2: [
                {
                    step: 1,
                    image: q1_step2_1,
                    text: "facepay_magic.refund_policy.q_01.step_2.text1",
                },
                {
                    step: 2,
                    image: q1_step2_2,
                    text: "facepay_magic.refund_policy.q_01.step_2.text2",
                },
                {
                    step: 3,
                    image: q1_step2_3,
                    text: "facepay_magic.refund_policy.q_01.step_2.text3",
                },
                {
                    step: 4,
                    image: q1_step2_4,
                    text: "facepay_magic.refund_policy.q_01.step_2.text4",
                },
                {
                    step: 5,
                    image: q1_step2_5,
                    text: "facepay_magic.refund_policy.q_01.step_2.text5",
                },
                {
                    step: 6,
                    image: q1_step2_6,
                    text: "facepay_magic.refund_policy.q_01.step_2.text6",
                }
            ],
            desc_4: [
                "facepay_magic.refund_policy.q_01.desc_4.desc1",
            ],      
        },
        q2: {
            key: "q2",
            title: "facepay_magic.refund_policy.q_02.title",
            date: "facepay_magic.refund_policy.q_02.date",
            desc: [
                "facepay_magic.refund_policy.q_02.desc.desc1",
            ],
        },
        q3: {
            key: "q3",
            title: "facepay_magic.refund_policy.q_03.title",
            date: "facepay_magic.refund_policy.q_03.date",
            desc: [
                "facepay_magic.refund_policy.q_03.desc.desc1",
                "facepay_magic.refund_policy.q_03.desc.desc2",
                "facepay_magic.refund_policy.q_03.desc.desc3",
            ],
        },
        q4: {
            key: "q4",
            title: "facepay_magic.refund_policy.q_04.title",
            date: "facepay_magic.refund_policy.q_04.date",
            desc: [
                "facepay_magic.refund_policy.q_04.desc.desc1",
            ],
        }
    },
    resolve_complaints: {
        q1: {
            key: "q1",
            title: "facepay_magic.resolve_complaints.q_01.title",
            date: "facepay_magic.resolve_complaints.q_01.date",
            desc: [
                "facepay_magic.resolve_complaints.q_01.desc.desc1",
                "facepay_magic.resolve_complaints.q_01.desc.desc2",
                "facepay_magic.resolve_complaints.q_01.desc.desc3",
                "facepay_magic.resolve_complaints.q_01.desc.desc4",
                "facepay_magic.resolve_complaints.q_01.desc.desc5",
            ],
            link_customer_care: {
                href: `/${SUPPORT}/${BASIC_INFOMATION}/q17`,
                target: "_blank"
            },
        },
        q2: {
            key: "q2",
            title: "facepay_magic.resolve_complaints.q_02.title",
            date: "facepay_magic.resolve_complaints.q_02.date",
            desc: [
                "facepay_magic.resolve_complaints.q_02.desc.desc1",
                "facepay_magic.resolve_complaints.q_02.desc.desc2",
                "facepay_magic.resolve_complaints.q_02.desc.desc3",
            ],
            link_email: {
                href: "mailto: cs@facepay.vn"
            },
            link_phone_number: {
                href: "tel: 02037101010"
            },
        },
        q3: {
            key: "q3",
            title: "facepay_magic.resolve_complaints.q_03.title",
            date: "facepay_magic.resolve_complaints.q_03.date",
            desc: [
                "facepay_magic.resolve_complaints.q_03.desc.desc1",
            ],
        },
        q4: {
            key: "q4",
            title: "facepay_magic.resolve_complaints.q_04.title",
            date: "facepay_magic.resolve_complaints.q_04.date",
            desc: [
                "facepay_magic.resolve_complaints.q_04.desc.desc1",
                "facepay_magic.resolve_complaints.q_04.desc.desc2",
            ],
        },
    }
}