import img_01 from '../images/SmartPay/B01.png'
import img_02 from '../images/SmartPay/B02.png'
import img_03 from '../images/SmartPay/B03.png'


export const SmartPayData = [
    {
        step: 1,
        image: img_01,
        text: "facePay_by_bank.smart_pay.dowload.0"
    },
    {
        step: 2,
        image: img_02,
        text: "facePay_by_bank.smart_pay.dowload.1"
    },
    {
        step: 3,
        image: img_03,
        text: "facePay_by_bank.smart_pay.dowload.2"
    },
]