import styled from "styled-components";

const WrapPopup = styled.div`
     position: relative;
     .ic_close{
          position: absolute;
          top: -45px;
          right: -45px;
          cursor: pointer;
     }
     .image-popup{
          width: 400px;
          height: 400px;
     }
     @media only screen and (max-width: 475px){
          .ic_close{
               height: 36px;
               width: 36px;
               top: -30px;
               right: -30px;
          }
          .image-popup{
               width: 300px;
               height: 300px;
          }

     }
`
export { WrapPopup }