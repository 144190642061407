import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

// image
import IcArrowRightBlue from '../../../assets/images/IcArrowRightBlue/IcArrowRightBlue'

// styles
import { WrapQAndAItem } from './index.styles'

const QAndAItem = ({ uniqueKey, ques, ans, link, isActive, setActive, t }) => {

    const _handleSelected = () => {
        if (isActive) {
            setActive(null)
            return
        }
        setActive(uniqueKey)
    }

    return (
        <WrapQAndAItem>
            <div className="wrap-quest" onClick={_handleSelected}>
                <div className="question">{ques}</div>
                <IcArrowRightBlue className={isActive ? "ic-expand expand" : "ic-expand"} />
            </div>
            {
                isActive &&
                <div className="answer">
                    {ans}
                    <Link className="view" to={link}>{t("common.xem_them")}</Link>
                </div>
            }
        </WrapQAndAItem>
    )
}

export default withNamespaces()(QAndAItem)
