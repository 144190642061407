import React from 'react'

const IcTiktok = (props) => {
    return (
        <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 32C7.15789 32 0 24.8421 0 16C0 7.15789 7.15789 0 16 0C24.8421 0 32 7.15789 32 16C32.0842 24.8421 24.8421 32 16 32Z" fill="url(#ic_tiktok)" />
            <path d="M25.6602 13.3454C25.4795 13.3454 25.2987 13.3454 25.2083 13.3454C23.4004 13.3454 21.6828 12.4414 20.7789 10.9047V19.1307C20.7789 22.4753 18.067 25.1872 14.7224 25.1872C11.3778 25.1872 8.66589 22.4753 8.66589 19.1307C8.66589 15.7861 11.3778 13.0742 14.7224 13.0742C14.8128 13.0742 14.9936 13.0742 15.084 13.0742V16.0572C14.9936 16.0572 14.8128 16.0572 14.7224 16.0572C13.0049 16.0572 11.6489 17.4132 11.6489 19.1307C11.6489 20.8482 13.0049 22.2041 14.7224 22.2041C16.4399 22.2041 17.9766 20.8482 17.9766 19.1307V5.11938H20.8693C21.1405 7.65046 23.2196 9.72956 25.7506 9.91035L25.6602 13.3454Z" fill="white" />
            <defs>
                <linearGradient id="ic_tiktok" x1="32.0007" y1="2.85557e-07" x2="31.3878" y2="38.3185" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFFF" />
                    <stop offset="0.651042" stopColor="#005BEC" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default IcTiktok
