import { combineReducers } from 'redux';
import formRegister from './formRegister'

const appReducer = combineReducers({
    formRegister
})

const mainReducer = (state, action) => {
    return appReducer(state, action)
}

export default mainReducer