import styled from 'styled-components'

const WrapPage = styled.div`
    min-height: 788px;
    background: #F7FCFF;
    .wrap-page-home{
        display: flex;
        flex-direction: column;        
        min-height: 812px;
        padding: 115px 24px 43px 24px;
        box-sizing: border-box;
        .page-home{
            .title{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 37px;
                text-align: center;
                color: #304659;
            }
            .desc{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 21px;
                text-align: center;
                color: #304659;
                padding: 45px 0 42px 0;
            }
        }
        .page-search{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 470px;
            .wrap-drop-down{

            }            
        }
    }
    .wrap-list-search{
        background: #FFFFFF;
        padding: 0 24px;
        .result{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 130%;
            color: #191919;
            padding: 28px 0 17px 0;
        }
        .list-search{
            display: flex;
            flex-direction: column;
            .item{
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                .name-store{                   
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 22px;
                    color: #1279DA;
                }
                .add-store{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;
                    color: #191919;
                    padding: 8px 0;
                }
                .phone-store{
                    font-weight: normal;
                    font-size: 11.5px;
                    line-height: 16px;
                    letter-spacing: -0.02em;
                    color: #939393;
                }
            }
        }
    }



@media only screen and (min-width: 768px){
    min-height: unset;
    .wrap-page-home{
        padding: 164px 0 0 0;
        max-width: 1200px;
        margin: 0 auto;
        min-height: 371px;        
        .page-home{
            .title{
                font-size: 50px;
                line-height: 52px;
            }
            .desc{
                font-size: 16px;
                line-height: 23px;
                padding: 34px 0 72px 0;
            }
        }
        .page-search{
            flex-direction: row;
            justify-content: unset;
            height: unset;
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            position: absolute;
            top: 345px;
            .wrap-drop-down{
                display: grid;
                grid-template-columns: repeat(3, 300px);
                grid-gap: 30px;
            } 
            .btn-search{
                height: 39px;
                margin: 5px 0 0 15px;
                padding: 0 12px;
                svg{
                    margin-right: 6px;
                }
            }
        }
    }
    .wrap-list-search{
        padding: 0 0 20px 0;
        min-height: 297px;
        .result{
            max-width: 1200px;
            margin: 0 auto;
            padding: 70px 0 16px 0;
        }
        .list-search{
            display: grid;
            grid-template-columns: repeat(4, 277px);
            /* grid-column-gap: 30px;
            grid-row-gap: 45px; */
            grid-gap: 30px;
            max-width: 1200px;
            margin: 0 auto;
            .item{
                margin-bottom: 0;
                .name-store{ 
                    font-size: 16px;
                    line-height: 23px;
                }
                .add-store{
                    font-size: 13px;
                    line-height: 130%;
                    padding: 12px 0;
                }
                .phone-store{
                    font-size: 13px;
                    line-height: 130%;
                }
            }
        }
    }
}

`
export { WrapPage }