import img_01 from '../images/VietinBank/B01.png'
import img_02 from '../images/VietinBank/B02.png'
import img_03 from '../images/VietinBank/B03.png'


export const VietinBankData = [
    {
        step: 1,
        image: img_01,
        text: "facePay_by_bank.vtb_bank.dowload.0"
    },
    {
        step: 2,
        image: img_02,
        text: "facePay_by_bank.vtb_bank.dowload.1"
    },
    {
        step: 3,
        image: img_03,
        text: "facePay_by_bank.vtb_bank.dowload.2"
    },     
]