import React, { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';

// styles
import { WrapPage } from './index.styles'

//image
import img_text from '../../assets/images/img_text.svg'

//component
import PageSix from '../Home/PageSix';
import Registration from './Registration';

const Partner = ({ t }) => {
    return (
        <WrapPage>
            <div className="background-container">
                <div className="background-wrap">
                    <div className="background-left">
                        <div className="title-page">
                            <img src={img_text} alt="image-text" />
                        </div>
                    </div>
                    <div className="background-right">
                        <Registration />
                    </div>
                </div>
            </div>
            <PageSix />
        </WrapPage>
    )
}

export default withNamespaces()(Partner)
