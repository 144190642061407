import styled from "styled-components";

const ButtonWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;    
    position: relative;
    height: 46px;
    background: #1279DA;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
    border-radius: 38px;
    cursor: pointer;
    border: none;
    outline: none;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;

    svg{
        margin-right: 7px;
    }
`

export {ButtonWrapper};