import React from 'react'
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

//style
import * as styles from './index.styles'

//image
import GooglePlayBadgeBlack from '../../../assets/images/GooglePlayBadgeBlack';
import AppStoreBadgeBlack from '../../../assets/images/AppStoreBadgeBlack';
import IcFacebook from '../../../assets/images/IcFacebook/IcFacebook';
import IcYoutube from '../../../assets/images/IcYoutube/IcYoutube';
import IcLindin from '../../../assets/images/IcLindin/IcLindin';
import IcInstagram from '../../../assets/images/IcInstagram';
import IcTiktok from '../../../assets/images/IcTiktok/IcTiktok';
import IcZalo from '../../../assets/images/IcZalo/IcZalo';

// constant
import { POLICY, SUPPORT, TERMS_OF_USE, PARTNER, TUTORIAL, RECRUITMENT, COMMUNICATION, FOUNDER, WEE_DIGITAL, FACEPAY_PAYMENT_STORES } from '../../../assets/constant/pathNames';

const PageSix = ({ t }) => {
    return (
        <styles.Bound>
            <div className="page-wrapper">
                <div className="introduce">
                    <div className="text-bold">{t(`home.page_six.about_us.title`)}</div>
                    <Link className="text" to={`/${FOUNDER}`}>{t(`home.page_six.about_us.founder`)}</Link>
                    <Link className="text" to={`/${WEE_DIGITAL}`}>{t(`home.page_six.about_us.wee_digital`)}</Link>
                    {/* <Link className="text" to={`/${RECRUITMENT}`}>{t(`home.page_six.about_us.recruitment`)}</Link> */}
                </div>
                <div className="introduce">
                    <div className="text-bold">{t(`home.page_six.support.title`)}</div>
                    <Link className="text" to={`/${SUPPORT}`}>{t(`home.page_six.support.lien_he`)}</Link>
                    <Link className="text" to={`/${SUPPORT}`}>{t(`home.page_six.support.cau_hoi`)}</Link>
                    <Link className="text" to={`/${TUTORIAL}`}>{t(`home.page_six.support.huong_dan`)}</Link>
                    <Link className="text" to={`/${FACEPAY_PAYMENT_STORES}`}>{t(`home.page_six.support.dia_diem`)}</Link>
                </div>
                <div className="introduce">
                    <div className="text-bold">{t(`home.page_six.juridical.title`)}</div>
                    <Link className="text" to={`/${TERMS_OF_USE}`}>{t(`home.page_six.juridical.dieu_khoan`)}</Link>
                    <Link className="text" to={`/${POLICY}`}>{t(`home.page_six.juridical.chinh_sach`)}</Link>
                </div>
                <div className="introduce">
                    <div className="text-bold">{t(`home.page_six.media.title`)}</div>
                    <Link to={`/${COMMUNICATION}`} className="text">{t(`home.page_six.media.tin_tuc`)}</Link>
                </div>
                <div className="introduce social">
                    <div className="text-bold">{t(`home.page_six.connect`)}</div>
                    <div className="icon-wrap">
                        <a className="icon" href="https://www.facebook.com/facepayvietnam" target="_blank"><IcFacebook /></a>
                        <a className="icon" href="https://www.youtube.com/channel/UCctGm-4I-iyuA092uzG_Cmg" target="_blank"><IcYoutube /></a>
                        <a className="icon" href="https://www.linkedin.com/company/facepay-việt-nam" target="_blank"><IcLindin /></a>
                        <a className="icon" href="https://www.instagram.com/facepay.vn/" target="_blank"><IcInstagram /></a>
                        <a className="icon" href="https://www.tiktok.com/@facepayvietnam" target="_blank"><IcTiktok /></a>
                        <a className="icon" href="https://zalo.me/3836098346553639514" target="_blank"><IcZalo /></a>
                    </div>
                </div>
            </div>
            <div className="footer-wrap">
                <div className="footer">
                    <div className="contact">
                        <div className="contact-wrap frist">
                            <p>{t(`home.page_six.hotline`)}</p>
                            <a href="tel:02037101010">0203 710 1010</a>
                        </div>
                        <div className="contact-wrap">
                            <p>{t(`home.page_six.email`)}</p>
                            <a href="mailto: hello@facepay.vn">hello@facepay.vn</a>
                        </div>
                    </div>
                    <div className="license">Bản quyền ©2020 thuộc về Công ty TNHH Wee Digital.</div>
                </div>
            </div>
        </styles.Bound>
    )
}

export default withNamespaces()(PageSix)
