import styled from 'styled-components'

const initialHeight = window.innerHeight

const WrapPage = styled.div`
    .wrap-page{
        background: #000000;
        .page-home{
            min-height: 788px;
            height: 100vh;
            padding: 119px 24px 0 24px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            .content{
                font-family: "SVN-Gilroy";
                font-style: normal;
                .title{                    
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 22px;
                    color: #FFFFFF;
                }
                .name{
                    font-family: "SVN-Bebas Neue";
                    font-style: normal;
                    font-weight: bold;
                    font-size: 74px;
                    line-height: 72px;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    &.blue{
                        color: #1279DA;
                    }
                }
                .desc{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    color: #F7FCFF;
                    margin-top: 19px;
                }
            }
            
            .video-container{
                flex-grow: 1;
                background-color: black;
                overflow: hidden;
                position: relative;
                video{
                    position: absolute;
                    top: 0;
                    left: 50%;                    
                    transform: translate(-50%, 0);
                    height: 100%;
                    background-color: black;
                }
                .icon-mute{
                    position: absolute;
                    bottom: 3%;
                    left: 0;
                    /* transform: translate(-50%, 0); */
                    z-index: 99;
                    width: 4vh;
                    height: 4vh;
                    cursor: pointer;
                }
            }
        }
    }
    


    @media only screen and (min-width: 768px){
        .wrap-page{
            .page-home{
                max-width: 1200px;
                margin: 0 auto;
                padding: 40px 0 0 0;
                flex-direction: row;
                align-items: center;
                .content{
                    width: 350px;
                    .title{
                        font-size: 21px;
                        line-height: 24px;
                    }
                    .name{
                        font-size: 102px;
                        line-height: 102px;
                    }
                    .desc{
                        font-size: 18px;
                        line-height: 23px;
                        margin-top: 36px;
                    }
                }
                .video-container{
                    height: 100%;
                }
            }
        }
    }
`
export { WrapPage }