import React, { useRef, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import lottie from 'lottie-web'
import { Link } from 'react-router-dom';

//style
import * as styles from './index.styles'

//component
import IcArrowRightBlue from '../../../assets/images/IcArrowRightBlue/IcArrowRightBlue';

//constant
import { FACEPAY_PAYMENT_STORES } from '../../../assets/constant/pathNames'

const PageThree = ({ t }) => {
    const wrapAnimation = useRef()

    useEffect(() => {
        lottie.loadAnimation({
            container: wrapAnimation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation/payment.json'
        })
    }, []);

    return (
        <styles.Bound>
            <div className="content-text">
                <div className="title-page">
                    <p>{t('home.page_three.text_1')}</p>
                    <p className="title-page blue">{t('home.page_three.text_2')}</p>
                </div>
                <p className="des-page">{t('home.page_three.text_3')}</p>
                <div className="content-page">
                    {t('home.page_three.text_4')}
                </div>
                <Link className="tutorial" to={`/${FACEPAY_PAYMENT_STORES}`}>
                    <p>{t('home.page_three.text_5')}</p>
                    <IcArrowRightBlue />
                </Link>
            </div>
            <div className="lottie-wrap">
                <div className="lottie" ref={wrapAnimation}></div>
            </div>
        </styles.Bound >
    )
}

export default withNamespaces()(PageThree)
