import React, { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { FacebookShareButton } from "react-share";


//style 
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix';

//data
import { RecruitmentData } from '../../../assets/data/RecruitmentData';

//constant
import { RECRUITMENT } from '../../../assets/constant/pathNames';

//image
import IcArrowLeft from '../../../assets/images/IcArrowLeft';
import IcClock from '../../../assets/images/IcClock';
import ic_facebook from '../../../assets/images/IcFacebookTransparent/ic_fb_share.svg'


const RecruitmentPost = ({ t }) => {

    const { postKey } = useParams()

    const [postDetails, setPostDetails] = useState(null);

    useEffect(() => {
        const postDetails = RecruitmentData[postKey]
        if (!postDetails) return;
        setPostDetails(postDetails)
    }, [postKey]);

    if (!postDetails)
        return null

    const { title, date, place, time, footer, contents, mail } = postDetails

    return (
        <WrapPage>
            <div className="page page-first">
                <Link to={`/${RECRUITMENT}`} className="go-back"><IcArrowLeft />{t('common.quay_lai')}</Link>
                <div className="title">{t(title)}</div>
                {/* <div className="wrap-share-date">                    
                    <div className="wrap-left">
                        <FacebookShareButton
                            url={`https://facepay.vn${window.location.pathname}`}
                            className="ic-share">
                            <img src={ic_facebook} alt="icon-share" />
                        </FacebookShareButton>
                        <div className="place">{t(place)}</div>
                        <div className="date">{t(date)}</div>
                    </div>
                    <div className="wrap-right">
                        <IcClock className="icon-clock" />
                        <p>{t(time)}</p>
                    </div>
                </div> */}
                <div className="line"></div>
                <div className="wrap-content">
                    {contents.map((item, index) => {
                        const content = t(item, { returnObjects: true })
                        return (
                            <div key={index} className="content" dangerouslySetInnerHTML={{ __html: content }} />
                        )
                    })}
                    <div className="line-dashed"></div>
                    {footer.map((item, j) => {
                        const footer = t(item, { returnObjects: true })
                        if (typeof (footer) === 'string') {
                            return <div key={j} className="content">{footer}</div>
                        }
                        return (
                            <div className="wrap-mail" key={j}>
                                {footer.map((item, k) => {
                                    if (typeof (item) === 'string') {
                                        return (
                                            <span key={k} className="content">{item}</span>
                                        )
                                    }
                                    return <span key={k}><a className="mail" {...postDetails[item.link]}>{item.text}</a></span>
                                })
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
            <PageSix />
        </WrapPage>
    )
}

export default withNamespaces()(RecruitmentPost)
