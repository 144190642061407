import React, { useState, useReducer, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';

//style
import { WrapPage } from './index.styles'

//component
import PageSix from '../../Home/PageSix';
import DropdownList from '../../Control/DropdownList';
import Button from '../../Control/Button';
import IcSearchWhite from '../../../assets/images/IcSearchWhite';

//StoreData
import { listProvices, listStoreGS25 } from '../../../assets/data/StoreGS25';

const errProvincesEmpty = "Bạn vui lòng chọn thành phố để tìm kiếm"
const errDistricsEmpty = "Bạn vui lòng chọn quận, huyện để tìm kiếm"

const FacepayPaymentStores = ({ t }) => {

    const [listSearch, setListSearch] = useState([])

    const [arrData, setArrData] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            arrProvinces: listProvices,
            arrDistricts: [],
            arrWards: []
        }
    )

    const [data, setData] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            provinces: "",
            districts: "",
            wards: ""
        }
    )

    const [error, setError] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            errProvinces: "",
            errDistricts: "",
        }
    )

    useEffect(() => {
        setData({
            districts: "",
            wards: ""
        })
    }, [JSON.stringify(data.provinces)])

    useEffect(() => {
        setData({
            wards: ""
        })
    }, [JSON.stringify(data.districts)])

    const handleSetValueProvinces = (item) => {
        setError({
            errProvinces: "",
            errDistricts: ""
        })
        setData({ provinces: item })
        setArrData({ arrDistricts: item.districs })
    }

    const handleSetValueDistrics = (item) => {
        setError({ errDistricts: "" })
        setData({ districts: item })
        setArrData({ arrWards: item.wards })
    }

    const handleSetValueWards = (item) => {
        setError({ errDistricts: "" })
        setData({ wards: item })
    }

    const arrListProvinces = listStoreGS25.filter(item => item.provinces === data.provinces.key)

    const arrListDistricts = arrListProvinces.filter(item => item.districts === data.districts.key)

    const arrListWards = arrListDistricts.filter(item => item.wards === data.wards.key)

    const _handleSearch = () => {
        if (data.provinces.length === 0) {
            setError({ errProvinces: errProvincesEmpty })
        }
        if (data.districts.length === 0) {
            setError({ errDistricts: errDistricsEmpty })
        }

        const listDataSearchNoWard = arrListDistricts.map((item) => item.store)
        const listDataSearchWithWard = arrListWards.map((item) => item.store)
        if (data.wards) {
            setListSearch(listDataSearchWithWard)
        } else {
            setListSearch(listDataSearchNoWard)
        }
    }

    return (
        <WrapPage>
            <div className="wrap-page-home">
                <div className="page-home">
                    <p className="title">{t('facepay_payment_stores.title')}</p>
                    <p className="desc">{t('facepay_payment_stores.desc')}</p>
                </div>
                <div className="page-search">
                    <div className="wrap-drop-down">
                        <DropdownList
                            listItem={arrData.arrProvinces}
                            title="Chọn thành phố"
                            value={data.provinces}
                            handleSetValue={handleSetValueProvinces}
                            isRequire={true}
                            errStr={error.errProvinces}
                        />
                        <DropdownList
                            listItem={arrData.arrDistricts}
                            title="Chọn quận, huyện"
                            value={data.districts}
                            handleSetValue={handleSetValueDistrics}
                            isRequire={true}
                            errStr={error.errDistricts}
                        />
                        <DropdownList
                            listItem={arrData.arrWards}
                            title="Chọn phường, xã"
                            value={data.wards}
                            handleSetValue={handleSetValueWards}
                        />
                    </div>
                    <Button
                        className="btn-search"
                        onClick={_handleSearch}
                        iconBtn={<IcSearchWhite />}
                        text="Tìm kiếm" />
                </div>
            </div>
            <div className="wrap-list-search">
                {listSearch.length !== 0 &&
                    <>
                        <p className="result">Kết quả tìm kiếm</p>
                        <div className="list-search">
                            {
                                listSearch.map((item, i) => {
                                    return (
                                        <div className="item" key={i}>
                                            <p className="name-store">{item.nameStore}</p>
                                            <p className="add-store">{item.addStore}</p>
                                            <p className="phone-store">{item.phoneStore}</p>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </>
                }
            </div>
            <PageSix />

        </WrapPage>
    )
}

export default withNamespaces()(FacepayPaymentStores)

