import React, { useRef, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

//style
import * as styles from './index.styles'

//component
import IcArrowRightBlue from '../../../assets/images/IcArrowRightBlue/IcArrowRightBlue';
import page_two_gif from '../../../assets/images/page_two.gif'

//constant
import { TUTORIAL } from '../../../assets/constant/pathNames'

const PageTwo = ({ t }) => {    

    return (
        <styles.Bound>
            <div className="content-text">
                <div className="title-page">
                    <p>{t('home.page_two.text_1')}</p>
                    <p className="title-page blue">{t('home.page_two.text_2')}</p>
                </div>
                <p className="des-page">{t('home.page_two.text_3')}</p>
                <div className="content-page">
                    <p className="text">
                        <span className="bold">{t('home.page_two.text_4')}</span>
                        {t('home.page_two.text_5')}
                    </p>
                    <p className="text">
                        <span className="bold">{t('home.page_two.text_6')}</span>
                        {t('home.page_two.text_7')}
                    </p>
                </div>
                <Link className="tutorial" to={`/${TUTORIAL}`}>
                    <p>{t('home.page_two.text_8')}</p>
                    <IcArrowRightBlue />
                </Link>
            </div>
            <img className="img-terminal" src={page_two_gif} alt="gif" />
        </styles.Bound>
    )
}
export default withNamespaces()(PageTwo)
