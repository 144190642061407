import React from 'react'

const IcMailWhite = (props) => {
    return (
        <svg {...props} width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33341 3.83337H16.6667C17.5834 3.83337 18.3334 4.58337 18.3334 5.50004V15.5C18.3334 16.4167 17.5834 17.1667 16.6667 17.1667H3.33341C2.41675 17.1667 1.66675 16.4167 1.66675 15.5V5.50004C1.66675 4.58337 2.41675 3.83337 3.33341 3.83337Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.3334 5.5L10.0001 11.3333L1.66675 5.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcMailWhite
