import React, { useRef, useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom'
import lottie from 'lottie-web'

// component
import PageSix from '../Home/PageSix'

// styles
import { WrapPage } from './index.styles'

//data
import { CommunicationData } from '../../assets/data/CommunicationData'

const Communication = ({ t }) => {

    const pageTwo = useRef(null)
    const arrCommunicationData = useRef(Object.values(CommunicationData))
    const [heightPostItem, setHeightPostItem] = useState('');

    const wrapAnimation = useRef()

    useEffect(() => {
        lottie.loadAnimation({
            container: wrapAnimation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation/page_communication.json'
        })
    }, []);

    const _executeSetPostHeight = () => {
        const postHeight = pageTwo.current.offsetWidth / 2;
        setHeightPostItem(postHeight)
    }

    useEffect(() => {
        _executeSetPostHeight()
    }, [_executeSetPostHeight]);

    useEffect(() => {
        const _handleResize = () => {
            _executeSetPostHeight()
        }
        window.addEventListener('resize', _handleResize)
        return () => {
            window.removeEventListener('resize', _handleResize)
        }
    }, [_executeSetPostHeight]);

    return (
        <WrapPage heightPostItem={heightPostItem}>
            <div className="wrap-page-one">
                <div className="page-one">
                    {/* <div className="content-page">
                        <p className="title-page">
                            {t('communication.title_one')}
                        </p>
                        <p className="title-page blue">
                            {t('communication.title_two')}
                        </p>
                        <p className="text-page">{t('communication.text_1')}</p>
                        <p className="text-page bold">"{t('communication.text_2')}"</p>
                    </div>
                    <div className="lottie" ref={wrapAnimation}></div> */}

                    <p className="title-page">
                        {t('communication.title_one')}
                    </p>
                    <a className="btn" href="https://we.facepay.vn/" target="_blank">Nhận quà ngay</a>
                </div>
            </div>
            <div className="page-two" ref={pageTwo}>
                {
                    arrCommunicationData.current.slice(0).reverse().map((item, i) => item
                        ? (
                            <div key={i} className="post">
                                <img className="image" src={item.image} alt="image" />
                                <Link className="content" to={item.link}>
                                    <p className="title" title={t(`${item.title}`)}>{t(`${item.title}`)}</p>
                                    <p className="date">{t(`${item.date}`)}</p>
                                    <p className="desc" title={t(`${item.desc}`)}>{t(`${item.desc}`)}</p>
                                    <p className="view">{t("common.xem_them")}</p>
                                </Link>
                            </div>
                        )
                        : <div key={i} className="none-effect" />
                    )
                }
            </div>
            <PageSix />
        </WrapPage>
    )
}

export default withNamespaces()(Communication)
