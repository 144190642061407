import * as pathNames from '../constant/pathNames'

export const menuData = [
    {
        path: `/${pathNames.HOME}`,
        label: "menu.trang_chu",
        class: 'home'
    },
    // {
    //     path: `/${pathNames.FACEPAY_MAGIC}`,
    //     label: "menu.facepay_magic",
    // },
    {
        path: `/${pathNames.FACEPAY_BY_BANK}`,
        label: "menu.facepay_univese",
        class: 'facepay-bank'
    },
    {
        path: `/${pathNames.TUTORIAL}`,
        label: "menu.huong_dan",
    },
    {
        path: `/${pathNames.SUPPORT}`,
        label: "menu.ho_tro",
    },
    {
        path: `/${pathNames.COMMUNICATION}`,
        label: "menu.truyen_thong",
    },
    {
        path: `/${pathNames.PARTNER}`,
        label: "menu.doi_tac",
    },
    {
        path: `/${pathNames.RECRUITMENT}`,
        label: "menu.tuyen_dung",
    },
]