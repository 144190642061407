import styled from 'styled-components'

const WrapPage = styled.div`
    display: flex;
    flex-direction: column;
    .image-page{
        /* padding: 50px 0 0 0; */
        height: 500px;
        object-fit: cover
    }
    .content-page{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        padding: 50px 24px;
        text-align: center;
    }
    @media only screen and (min-width: 768px){
        flex-direction: row;
        max-width: 1200px;
        margin: 0 auto;
        .image-page{
            padding: 0;
        }
        .content-page{
            font-size: 18px;
            line-height: 24px;
            padding: 195px 0 0 0;;
            text-align: start;

        }
    }

`
export { WrapPage }