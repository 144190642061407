export const TP_HCM = "TP_HCM"
export const TP_TD = "TP_TD"

export const Q_1 = "Q_1"
export const Q_2 = "Q_2"
export const Q_3 = "Q_3"
export const Q_4 = "Q_4"
export const Q_5 = "Q_5"
export const Q_6 = "Q_6"
export const Q_7 = "Q_7"
export const Q_8 = "Q_8"
export const Q_9 = "Q_9"
export const Q_10 = "Q_10"
export const Q_11 = "Q_11"
export const Q_12 = "Q_12"
export const Q_BTan = "Q_BTan"
export const Q_BThanh = "Q_BThanh"
export const Q_GVap = "Q_GVap"
export const Q_PNhuan = "Q_PNhuan"
export const Q_TBinh = "Q_TBinh"
export const Q_TPhu = "Q_TPhu"
export const Q_BChanh = "Q_BChanh"
export const Q_NBe = "Q_NBe"
export const Q_TDuc = "Q_TDuc"

//Q2
export const P_TDien = "P_TDien"
export const P_BKhanh = "P_BKhanh"
export const P_TThiem = "P_TThiem"
export const P_BTTay = "P_BTTay"
//Q9
export const P_PLongA = "P_PLongA"
export const P_PLongB = "P_PLongB"
export const P_PBinh = "P_PBinh"
export const P_HPhu = "P_HPhu"
// Q.Thủ Đức
export const P_LTMy = "P_LTMy"
export const P_LChieu = "P_LChieu"
export const P_BTho = "P_BTho"
export const P_TNPhuB = "P_TNPhuB"
//Q1
export const P_DKao = "P_DKao"
export const P_BNghe = "P_BNghe"
export const P_PNLao = "P_PNLao"
export const P_NTBinh = "P_NTBinh"
export const P_NCTrinh = "P_NCTrinh"
//Q3
export const P_6 = "P_6"
//Q4
export const P_12 = "P_12"
export const P_13 = "P_13"
//Q5
export const P_9 = "P_9"
export const P_11 = "P_11"
//Q6
export const P_3 = "P_3"
export const P_10 = "P_10"
//Q7
export const P_TPhu = "P_TPhu"
export const P_TPhong = "P_TPhong"
export const P_THung = "P_THung"
export const P_PMy = "P_PMy"
export const P_TQuy = "P_TQuy"
export const P_PThuan = "P_PThuan"
//Q8
export const P_4 = "P_4"
export const P_8 = "P_8"
//Q10
export const P_14 = "P_14"
//Q12
export const P_TTNhat = "P_TTNhat"
export const P_DHThuan = "P_DHThuan"
export const P_HThanh = "P_HThanh"
export const P_TAn = "P_TAn"
//Q.Bình Tân
export const P_BTDong = "P_BTDong"
export const P_TTao = "P_TTao"
export const P_ALac = "P_ALac"
export const P_BHHoaB = "P_BHHoaB"
export const P_ALacA = "P_ALacA"
//Q.Bình Thạnh
export const P_15 = "P_15"
export const P_17 = "P_17"
export const P_22 = "P_22"
export const P_25 = "P_25"
//Q.Gò vấp
export const P_5 = "P_5"
//Q.Tân Bình
export const P_2 = "P_2"
//Q Tân Phú
export const P_PTHoa = "P_PTHoa"
export const P_HoaThanh = "P_HoaThanh"
export const P_PTrung = "P_PTrung"
export const P_TTHoa = "P_TTHoa"
export const P_TanQuy = "P_TanQuy"
export const P_TSNhi = "P_TSNhi"
//Q Bình Chánh
export const X_BHung = "X_BHung"
//Q Nhà Bè
export const X_PKien = "X_PKien"


const listProvices = [
    {
        key: TP_HCM,
        name: "TP. Hồ Chí Minh",
        districs: [
            {
                key: Q_1,
                name: "Quận 1",
                wards: [
                    {
                        key: P_DKao,
                        name: "Phường Đa Kao",
                    },
                    {
                        key: P_BNghe,
                        name: "Phường Bến Nghé",
                    },
                    {
                        key: P_PNLao,
                        name: "Phường Phạm Ngũ Lão",
                    },
                    {
                        key: P_NTBinh,
                        name: "Phường Nguyễn Thái Bình",
                    },
                    {
                        key: P_NCTrinh,
                        name: "Phường Nguyễn Cư Trinh",
                    },
                ]
            },
            {
                key: Q_3,
                name: "Quận 3",
                wards: [
                    {
                        key: P_6,
                        name: "Phường 6",
                    },
                ]
            },
            {
                key: Q_4,
                name: "Quận 4",
                wards: [
                    {
                        key: P_6,
                        name: "Phường 6",
                    },
                    {
                        key: P_12,
                        name: "Phường 12",
                    },
                    {
                        key: P_13,
                        name: "Phường 13",
                    },
                ]
            },
            {
                key: Q_5,
                name: "Quận 5",
                wards: [
                    {
                        key: P_9,
                        name: "Phường 9",
                    },
                    {
                        key: P_11,
                        name: "Phường 11",
                    },
                ]
            },
            {
                key: Q_6,
                name: "Quận 6",
                wards: [
                    {
                        key: P_3,
                        name: "Phường 3",
                    },
                    {
                        key: P_10,
                        name: "Phường 10",
                    },
                    {
                        key: P_11,
                        name: "Phường 11",
                    },
                ]
            },
            {
                key: Q_7,
                name: "Quận 7",
                wards: [
                    {
                        key: P_TPhu,
                        name: "Phường Tân Phú",
                    },
                    {
                        key: P_TPhong,
                        name: "Phường Tân Phong",
                    },
                    {
                        key: P_THung,
                        name: "Phường Tân Hưng",
                    },
                    {
                        key: P_PMy,
                        name: "Phường Phú Mỹ",
                    },
                    {
                        key: P_TQuy,
                        name: "Phường Tân Quy",
                    },
                    {
                        key: P_PThuan,
                        name: "Phường Phú Thuận",
                    },
                ]
            },
            {
                key: Q_8,
                name: "Quận 8",
                wards: [
                    {
                        key: P_4,
                        name: "Phường 4",
                    },
                    {
                        key: P_8,
                        name: "Phường 8",
                    },
                ]
            },
            {
                key: Q_10,
                name: "Quận 10",
                wards: [
                    {
                        key: P_9,
                        name: "Phường 9",
                    },
                    {
                        key: P_12,
                        name: "Phường 12",
                    },
                    {
                        key: P_14,
                        name: "Phường 14",
                    }
                ]
            },
            {
                key: Q_12,
                name: "Quận 12",
                wards: [
                    {
                        key: P_TTNhat,
                        name: "Phường Tân Thới Nhất",
                    },
                    {
                        key: P_DHThuan,
                        name: "Phường Đông Hưng Thuận",
                    },
                    {
                        key: P_HThanh,
                        name: "Phường Hiệp Thành",
                    },
                    {
                        key: P_TAn,
                        name: "Phường Thới An",
                    }
                ]
            },
            {
                key: Q_BTan,
                name: "Quận Bình Tân",
                wards: [
                    {
                        key: P_BTDong,
                        name: "Phường Bình Trị Đông",
                    },
                    {
                        key: P_TTao,
                        name: "Phường Tân Tạo",
                    },
                    {
                        key: P_ALac,
                        name: "Phường An Lạc",
                    },
                    {
                        key: P_BHHoaB,
                        name: "Phường Bình Hưng Hòa B",
                    },
                    {
                        key: P_ALacA,
                        name: "Phường An Lạc A",
                    }
                ]
            },
            {
                key: Q_BThanh,
                name: "Quận Bình Thạnh",
                wards: [
                    {
                        key: P_3,
                        name: "Phường 3",
                    },
                    {
                        key: P_13,
                        name: "Phường 13",
                    },
                    {
                        key: P_14,
                        name: "Phường 14",
                    },
                    {
                        key: P_15,
                        name: "Phường 15",
                    },
                    {
                        key: P_17,
                        name: "Phường 17",
                    },
                    {
                        key: P_22,
                        name: "Phường 22",
                    },
                    {
                        key: P_25,
                        name: "Phường 25",
                    }
                ]
            },
            {
                key: Q_GVap,
                name: "Quận Gò Vấp",
                wards: [
                    {
                        key: P_5,
                        name: "Phường 5",
                    },
                    {
                        key: P_8,
                        name: "Phường 8",
                    },
                    {
                        key: P_12,
                        name: "Phường 12",
                    },
                    {
                        key: P_14,
                        name: "Phường 14",
                    }
                ]
            },
            {
                key: Q_PNhuan,
                name: "Quận Phú Nhuận",
                wards: [
                    {
                        key: P_8,
                        name: "Phường 8",
                    },
                    {
                        key: P_10,
                        name: "Phường 10",
                    },
                    {
                        key: P_14,
                        name: "Phường 14",
                    },
                    {
                        key: P_15,
                        name: "Phường 15",
                    }
                ]
            },
            {
                key: Q_TBinh,
                name: "Quận Tân Bình",
                wards: [
                    {
                        key: P_2,
                        name: "Phường 2",
                    },
                    {
                        key: P_9,
                        name: "Phường 9",
                    },
                    {
                        key: P_12,
                        name: "Phường 12",
                    },
                    {
                        key: P_13,
                        name: "Phường 13",
                    },
                    {
                        key: P_14,
                        name: "Phường 14",
                    }
                ]
            },
            {
                key: Q_TPhu,
                name: "Quận Tân Phú",
                wards: [
                    {
                        key: P_PTHoa,
                        name: "Phường Phú Thọ Hòa",
                    },
                    {
                        key: P_HoaThanh,
                        name: "Phường Hòa Thạnh",
                    },
                    {
                        key: P_PTrung,
                        name: "Phường Phú Trung",
                    },
                    {
                        key: P_TTHoa,
                        name: "Phường Tân Thới Hòa",
                    },
                    {
                        key: P_TanQuy,
                        name: "Phường Tân Quý",
                    },
                    {
                        key: P_TSNhi,
                        name: "Phường Tân Sơn Nhì",
                    }
                ]
            },
            {
                key: Q_BChanh,
                name: "Quận Bình Chánh",
                wards: [
                    {
                        key: X_BHung,
                        name: "Xã Bình Hưng",
                    },
                ]
            },
            {
                key: Q_NBe,
                name: "Quận Nhà Bè",
                wards: [
                    {
                        key: X_PKien,
                        name: "Xã Phước Kiển",
                    },
                ]
            },
        ]
    },
    {
        key: TP_TD,
        name: "TP. Thủ Đức",
        districs: [
            {
                key: Q_2,
                name: "Quận 2",
                wards: [
                    {
                        key: P_TDien,
                        name: "Phường Thảo Điền",
                    },
                    {
                        key: P_BKhanh,
                        name: "Phường Bình Khánh",
                    },
                    {
                        key: P_TThiem,
                        name: "Phường Thủ Thiêm",
                    },
                    {
                        key: P_BTTay,
                        name: "Phường Bình Trưng Tây",
                    },
                ]
            },
            {
                key: Q_9,
                name: "Quận 9",
                wards: [
                    {
                        key: P_PLongA,
                        name: "Phường Phước Long A",
                    },
                    {
                        key: P_PLongB,
                        name: "Phường Phước Long B",
                    },
                    {
                        key: P_PBinh,
                        name: "Phường Phước Bình",
                    },
                    {
                        key: P_HPhu,
                        name: "Phường Hiệp Phú"
                    },                    
                    {
                        key: P_LTMy,
                        name: "Phường Long Thạnh Mỹ",
                    },
                ]
            },
            {
                key: Q_TDuc,
                name: "Quận Thủ Đức",
                wards: [
                    {
                        key: P_LTMy,
                        name: "Phường Long Thạnh Mỹ",
                    },                    
                    {
                        key: P_LChieu,
                        name: "Phường Linh Chiểu"
                    },
                    {
                        key: P_BTho,
                        name: "Phường Bình Thọ"
                    },
                    {
                        key: P_TNPhuB,
                        name: "Phường Tăng Nhơn Phú B",
                    },
                    {
                        key: P_PLongA,
                        name: "Phường Phước Long A",
                    },
                    {
                        key: P_TDien,
                        name: "Phường Thảo Điền",
                    },
                ]
            },
        ]
    }
]


const listStoreGS25 = [
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_DKao,
        store: {
            nameStore: "GS25 Empress",
            addStore: "138-142 Hai Bà Trưng, ​​P. Đa Kao, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025001",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_DKao,
        store: {
            nameStore: "GS25 Mạc Đỉnh Chi",
            addStore: "56 Mạc Đĩnh Chi, P. Đa Kao, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025011",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_DKao,
        store: {
            nameStore: "GS25 Mạc Đỉnh Chi 2",
            addStore: "Số 28 Ter B, Mạc Đĩnh Chi, P. Đa Kao, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025064",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_DKao,
        store: {
            nameStore: "GS25 Tổng công ty xây dựng Sài Gòn",
            addStore: "21B / 4 Nguyễn Đình Chiểu, P. Đa Kao, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025077",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_BNghe,
        store: {
            nameStore: "GS25 Mplaza",
            addStore: "Tòa nhà Mplaza Sài Gòn, 39 Lê Duẩn, P. Bến Nghé, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025002",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_BNghe,
        store: {
            nameStore: "GS25 Aqua 1",
            addStore: "A1SH04, Số 2 Tôn Đức Thắng, P. Bến Nghé, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025028",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_BNghe,
        store: {
            nameStore: "GS25 Phạm Ngọc Thạch",
            addStore: "Số 1Bis, Đường Phạm Ngọc Thạch, Phường Bến Nghé, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025041",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_BNghe,
        store: {
            nameStore: "GS25 Lê Thánh Tôn",
            addStore: "Số 2 Lê Thánh Tôn, P. Bến Nghé, Quận 1",
            phoneStore: "(028) 73025061",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_BNghe,
        store: {
            nameStore: "GS25 Hồ Tùng Mậu",
            addStore: "Số 50 đường Hồ Tùng Mậu, Phường Bến Nghé, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025063",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_BNghe,
        store: {
            nameStore: "GS25 Tôn Đức Thắng",
            addStore: "2A-4A Tôn Đức Thắng, P. Bến Nghé, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025071",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_BNghe,
        store: {
            nameStore: "GS25 Amena",
            addStore: "17/2, Lê Thánh Tôn, P. Bến Nghé, Quận1, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025076",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_BNghe,
        store: {
            nameStore: "GS25 Deutsches Haus",
            addStore: "Lầu 1 và lửng, 12-20 Lê Văn Hưu, Phường Bến Nghé, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025085",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_PNLao,
        store: {
            nameStore: "GS25 Bùi Thị Xuân",
            addStore: "122D Bùi Thị Xuân, P. Phạm Ngũ Lão, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025025",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_NTBinh,
        store: {
            nameStore: "GS25 Phó Đức Chính",
            addStore: "Số 2 Phó Đức Chính, Phường Nguyễn Thái Bình, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025066",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_NTBinh,
        store: {
            nameStore: "GS25 Nguyễn Công Trứ",
            addStore: "Số 79 Nguyễn Công Trứ, Phường Nguyễn Thái Bình, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025068",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_1,
        wards: P_NCTrinh,
        store: {
            nameStore: "GS25 Nowzone",
            addStore: "Căn 150, Lầu 01, TTTM Nowzone, 235 Nguyễn Văn Cừ, P. Nguyễn Cư Trinh, Quận 1, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025083",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_2,
        wards: P_TDien,
        store: {
            nameStore: "GS25 Gateway",
            addStore: "A01.05 Tòa nhà Aspen, dự án getway, 177 Xa lộ Hà Nội, Phường Thảo Điền, Quận 2, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025020",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_2,
        wards: P_TDien,
        store: {
            nameStore: "GS25 The Ascent",
            addStore: "62-62A Đường Quốc Hương, Phường Thảo Điền, Quận 2, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025033",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_2,
        wards: P_TDien,
        store: {
            nameStore: "GS25 Thảo Điền",
            addStore: "Số 16 Thảo Điền, P. Thảo Điền, Quận 2, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025062",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_2,
        wards: P_BKhanh,
        store: {
            nameStore: "GS25 New City",
            addStore: "Shophouse số: BA-S01C, Tầng trệt Khu Thương mại Tòa nhà Bali, Khu dân cư Thành phố Mới tại địa chỉ số 17, Đường Mai Chí Thọ, Phường Bình Khánh, Quận 2, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025031",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_2,
        wards: P_TThiem,
        store: {
            nameStore: "GS25 Sadora",
            addStore: "Số A-00.04, Khu dân cư đa chức năng tại Lô 6-9, đường số 2, đường 13, Phường Thủ Thiêm, Quận 2, TP. Hồ Chí Minh (Tòa nhà Sadora)",
            phoneStore: "(028) 73025034",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_2,
        wards: P_BTTay,
        store: {
            nameStore: "GS25 La Astoria",
            addStore: "Tầng trệt T1.05-1, T1.05-2, T1.05-3, block 4 (LA3) Tòa nhà La Astoria 3, số 383, đường Nguyễn Duy Trinh, P. Bình Trưng Tây, Quận 2, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025549",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_2,
        wards: P_BTTay,
        store: {
            nameStore: "GS25 Nguyễn Thị Định",
            addStore: "Số 210 Nguyễn Thị Định, Khu phố 3, Phường Bình Trưng Tây, Quận 2, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025101",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_3,
        wards: P_6,
        store: {
            nameStore: "GS25 Trương Định",
            addStore: "24C Trương Định, Phường 6, Quận 3, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025003",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_3,
        wards: P_6,
        store: {
            nameStore: "GS25 Nguyễn Đình Chiểu",
            addStore: "130 Nguyễn Đình Chiểu, Phường 6, Quận 3, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025550",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_3,
        wards: P_6,
        store: {
            nameStore: "GS25 Nam Kỳ Khởi Nghĩa",
            addStore: "Số 155A, Đường Nam Kỳ Khởi Nghĩa, Phường 6, Quận 3, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025056",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_4,
        wards: P_6,
        store: {
            nameStore: "GS25 Khánh Hội",
            addStore: "Số 262 đường Khánh Hội, Phường 6, Quận 4, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025067",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_4,
        wards: P_12,
        store: {
            nameStore: "GS25 Ree Tower",
            addStore: "9 Đoàn Văn Bơ, Phường 12, Quận 4, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025024",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_4,
        wards: P_12,
        store: {
            nameStore: "GS25 SG Royal",
            addStore: "34-35 Bến Vân Đồn, Phường 12, Quận 4, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025042",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_4,
        wards: P_13,
        store: {
            nameStore: "GS25 Nguyễn Tất Thành",
            addStore: "296 (Lầu 1) - 296/1 - 296/2 Nguyễn Tất Thành, Phường 13, Quận 4, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025094",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_5,
        wards: P_9,
        store: {
            nameStore: "GS25 Nguyễn Chí Thanh",
            addStore: "133 Nguyễn Chí Thanh, Phường 9, Quận 5, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025012",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_5,
        wards: P_9,
        store: {
            nameStore: "GS25 Chung cư 155",
            addStore: "Lầu 1, khu thương mại dịch vụ 0,01, lầu 1, lô A, chung cư 155 Nguyễn Chí Thanh, Phường 9, Quận 5, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025093",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_5,
        wards: P_11,
        store: {
            nameStore: "GS25 Nguyễn Trãi",
            addStore: "Số 706 đường Nguyễn Trãi, Phường 11, Quận 5 , TP. Hồ Chí Minh",
            phoneStore: "(028) 71025172",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_6,
        wards: P_8,
        store: {
            nameStore: "GS25 Viva Riverside",
            addStore: "Lô đất thương mại số 1.25 của chung cư Viva Riverside tại số 1472 Võ Văn Kiệt và số 445-449 Gia Phú, Phường 3, Quận 6, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025145",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_6,
        wards: P_10,
        store: {
            nameStore: "GS25 Bình Phú",
            addStore: "1E - 3E Khu dân cư Phú Lâm D, Bình Phú, Phường 10, Quận 6, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025086",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_6,
        wards: P_10,
        store: {
            nameStore: "GS25 Trần Văn Kiểu",
            addStore: "45-47 Đường số 11, Phường 10, Quận 6, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025152",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_6,
        wards: P_11,
        store: {
            nameStore: "GS25 Hậu Giang",
            addStore: "Số 489B / 18-18A, Đường Hậu Giang, Phường 11, Quận 6, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025099",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_7,
        wards: P_TPhu,
        store: {
            nameStore: "GS25 Happy Res.",
            addStore: "104 - GF, Tòa nhà Đại Minh, Số 77 Hoàng Văn Thái, Phường Tân Phú, Quận 7, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025007",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_7,
        wards: P_TPhu,
        store: {
            nameStore: "GS25 Đại Minh Tower",
            addStore: "39 Đường 19, Phường Tân Phú, Quận 7, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025074",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_7,
        wards: P_TPhong,
        store: {
            nameStore: "GS25 Sky Gargen",
            addStore: "1016/12 Nguyễn Văn Linh, Sky Garden 1 - R1 - 1, Phường Tân Phong, Quận 7, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025008",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_7,
        wards: P_TPhong,
        store: {
            nameStore: "GS25 Korean Town",
            addStore: "Số 96 Lê Văn Thiêm, Phường Tân Phong, Quận 7, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025079",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_7,
        wards: P_THung,
        store: {
            nameStore: "GS25 Sunrise City View",
            addStore: "Lô đất thương mại SC.A-01.08 Sunrise City View, thuộc khu chung cư Nhật Hoa, số 33 Nguyễn Hữu Thọ, P.Tân Hưng, Quận 7, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025037",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_7,
        wards: P_PMy,
        store: {
            nameStore: "GS25 Era Town",
            addStore: "Căn hộ EA1-01-01C VÀ EA1-01-01B Đường số 15B, Phường Phú Mỹ, Quận 7, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025054",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_7,
        wards: P_TQuy,
        store: {
            nameStore: "GS25 Tân Quy",
            addStore: "Số 72A- 74/2 Đường 79, Khu phố 1, Phường Tân Quy, Quận 7, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025127",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_7,
        wards: P_PThuan,
        store: {
            nameStore: "GS25 Skyline",
            addStore: "Căn hộ thương mại 1,05 Tầng 1 CC Cao tầng 2 (An Gia Skyline). Đường Hoàng Quốc Việt, Khu dân cư La Casa, Phường Phú Thuận, Quận 7, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025131",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_8,
        wards: P_4,
        store: {
            nameStore: "GS25 Cao Lỗ",
            addStore: "Số 194 - 196 Cao Lỗ, Phường 4, Quận 8, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025009",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_8,
        wards: P_8,
        store: {
            nameStore: "GS25 Diamond Lotus",
            addStore: "B2 Khối đế thương mại dự án Diamond Lotus Riverside, số 49C, đường Lê Quang Kim, Phường 8, Quận 8, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025038",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_9,
        wards: P_PLongB,
        store: {
            nameStore: "GS25 The Art",
            addStore: "Lầu 1, Block D Chung cư Gia Hòa, số 523A Đỗ Xuân Hợp, Khu phố 6, Phường Phước Long B, Quận 9, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025088",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_9,
        wards: P_PBinh,
        store: {
            nameStore: "GS25 Cao đẳng kinh tế đối ngoại",
            addStore: "Số 143-145 Đại lộ III, Phường Phước Bình, Quận 9, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025091",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_9,
        wards: P_HPhu,
        store: {
            nameStore: "GS25 Man Thiện",
            addStore: "123 Man Thiện, Phường Hiệp Phú , TP. Thủ Đức, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025162",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_9,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 10",
            addStore: "Căn hộ 1.01 tại tầng 1 Tòa chung cư S1.05 thuộc khu A - Dự án khu dân cư và công viên Phước Thiện số 512 đường Nguyễn Xiển, khu phố Long Hòa, phường Long Thạnh Mỹ, thành phố Thủ Đức, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025146",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_9,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 12",
            addStore: "Căn hộ 1.01 - tầng 1 chung cư S1.06 khu A - Dự án khu dân cư và công viên Phước Thiện số 512 Nguyễn Xiển, khu phố Long Hòa, phường Long Thạnh Mỹ, TP. Thủ Đức, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025141",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_9,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 14",
            addStore: "Căn hộ 1.01 - Lầu 1, chung cư S2.03 khu A - Dự án khu dân cư và công viên Phước Thiện số 512 Nguyễn Xiển, khu phố Long Hòa, phường Long Thạnh Mỹ, TP. Thủ Đức, TP.",
            phoneStore: "(028) 71025144",
        }
    },    
    {
        provinces: TP_TD,
        districts: Q_9,
        wards: P_PLongA,
        store: {
            nameStore: "GS25 DH GTVT",
            addStore: "Số 449 Lê Văn Việt, P. Phước Long A, TP. Thủ Đức, Tp. Hồ Chí Minh",
            phoneStore: "(028) 71025115",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_10,
        wards: P_9,
        store: {
            nameStore: "GS25 Bà Hạt",
            addStore: "172 Bà Hạt, Phường 9, Quận 10, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025021",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_10,
        wards: P_12,
        store: {
            nameStore: "GS25 Viettel",
            addStore: "285 Cách Mạng Tháng 8, Phường 12, Quận 10, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025004",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_10,
        wards: P_12,
        store: {
            nameStore: "GS25 Hoàng Dư Khương",
            addStore: "Số 01, Đường Hoàng Dư Khương, Phường 12, Quận 10, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025065",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_10,
        wards: P_14,
        store: {
            nameStore: "GS25 Thành Thái",
            addStore: "Căn hộ 0.2 thuộc Chung cư Thiên Nam, tọa lạc tại số 7A / 162 Thành Thái, Phường 14, Quận 10, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025053",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_10,
        wards: P_14,
        store: {
            nameStore: "GS25 Tô Hiến Thành",
            addStore: "447 Tô Hiến Thành, Phường 14, Quận 10, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025158",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_12,
        wards: P_TTNhat,
        store: {
            nameStore: "GS25 Prosper Plaza",
            addStore: "Căn hộ Shophouse thương mại CS6-CS7 Tầng 1, Block C, Prosper Plaza, Số 22/14 Phan Văn Hớn, P. Tân Thới Nhất, Q.12",
            phoneStore: "(028) 71025084",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_12,
        wards: P_DHThuan,
        store: {
            nameStore: "GS25 Nguyễn Văn Qúa",
            addStore: "445 Nguyễn Văn Quá, Khu phố 4, Phường Đông Hưng Thuận, Quận 12, Thành phố Hồ Chí Minh, Việt Nam",
            phoneStore: "(028) 71025140",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_12,
        wards: P_HThanh,
        store: {
            nameStore: "GS25 KDC Hiệp Thành",
            addStore: "156 Nguyễn Thị Búp, Khu dân cư Hiệp Thành, Phường Hiệp Thành, Quận 12, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025154",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_12,
        wards: P_TAn,
        store: {
            nameStore: "GS25 Lê Thị Riêng",
            addStore: "363 Lê Thị Riêng, Phường Thới An, Quận 12. TP. Hồ Chí Minh",
            phoneStore: "(028) 71025157",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BTan,
        wards: P_BTDong,
        store: {
            nameStore: "GS25 Trương Phước Phan",
            addStore: "52 Trương Phước Phan, P. Bình Trị Đông, Q. Bình Tân, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025095",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BTan,
        wards: P_TTao,
        store: {
            nameStore: "GS25 Hồ Văn Long",
            addStore: "Số 79 Hồ Văn Long, Phường Tân Tạo, Quận Bình Tân, TP.Hồ Chí Mình",
            phoneStore: "(028) 71025106",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BTan,
        wards: P_ALac,
        store: {
            nameStore: "GS25 THPT Phú Lâm",
            addStore: "Số 02, đường 2D nối dài, khu phố 4, phường An Lạc, quận Bình Tân, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025111",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BTan,
        wards: P_BHHoaB,
        store: {
            nameStore: "GS25 Vĩnh Lộc",
            addStore: "Số 01 Đường số 03-KDC Vĩnh Lộc, Đường Nguyễn Thị Tú, Phường Bình Hưng Hòa B, Quận Bình Tân, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025121",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BTan,
        wards: P_ALacA,
        store: {
            nameStore: "GS25 An Lạc",
            addStore: "Số 8-10 Đường số 7, Phường An Lạc A, Quận Bình Tân, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025163",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_3,
        store: {
            nameStore: "GS25 Công viên văn hóa Phú Nhuận",
            addStore: "49L, Phan Đăng Lưu, Phường 3, Quận Bình Thạnh, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025075",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_13,
        store: {
            nameStore: "GS25 Đặng Thùy Trâm",
            addStore: "Số 98A Đặng Thùy Trâm, Phường 13, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025170",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_14,
        store: {
            nameStore: "GS25 Huỳnh Đình Hai",
            addStore: "38A Huỳnh Đình Hai, Phường 14, Quận Bình Thạnh, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025048",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_15,
        store: {
            nameStore: "GS25 UEF",
            addStore: "Số 141, Đường Điện Biên Phủ, Phường 15, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 73025060",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_17,
        store: {
            nameStore: "GS25 Hutech D",
            addStore: "Số 276 Điện Biên Phủ, Phường 17, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 73025052",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_22,
        store: {
            nameStore: "GS25 Nguyễn Hữu Cảnh",
            addStore: "135/57 và 135/59 Nguyễn Hữu Cảnh, Phường 22, Quận Bình Thạnh, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025005",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_22,
        store: {
            nameStore: "GS25 Vinhome",
            addStore: "Nhà Dịch Vụ LP-SH.03 (Shophouse), LandMark Plus Vinhomes Central Park, Số 720, Đường Điện Biên Phủ, Phường 22, Quận Bình Thạnh, Thành phố Hồ Chí Minh, Việt Nam.",
            phoneStore: "(028) 73025017",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_22,
        store: {
            nameStore: "GS25 The Park 1",
            addStore: "Nhà dịch vụ số P1-SH.01 Tòa Park 1 Vinhomes Central Park, số 720A Điện Biên Phủ, P. 22, Q. Bình Thạnh, TP.",
            phoneStore: "(028) 73025043",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_22,
        store: {
            nameStore: "GS25 Opal Tower",
            addStore: "SH01, Căn hộ Opal Tower, Saigon Pearl, Số 92 Nguyễn Hữu Cảnh, Phường 22, Quận Bình Thạnh",
            phoneStore: "(028) 71025071",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_25,
        store: {
            nameStore: "GS25 Wilton",
            addStore: "Căn hộ kết hợp kinh doanh (Shophouse / SH) số WT2-1.SH01, Lầu 1, Tháp 2, Số 71/3, Đường Nguyễn Văn Thương, Phường 25, Quận Bình Thạnh, Tp.HCM, Việt Nam.",
            phoneStore: "(028) 73025019",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_25,
        store: {
            nameStore: "GS25 Ung Văn Khiêm",
            addStore: "SH01, Căn hộ Opal Tower, Saigon Pearl, Số 92 Nguyễn Hữu Cảnh, Phường 22, Quận Bình Thạnh",
            phoneStore: "(028) 71025071",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_25,
        store: {
            nameStore: "GS25 Nguyễn Văn Thương",
            addStore: "Số 150 Nguyễn Văn Thương (D1), Phường 25, Quận Bình Thạnh",
            phoneStore: "(028) 71025069",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BThanh,
        wards: P_25,
        store: {
            nameStore: "GS25 Hutech B",
            addStore: "226 Ung Văn Khiêm, Phường 25, Quận Bình Thạnh, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025059",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_GVap,
        wards: P_5,
        store: {
            nameStore: "GS25 Đại học Văn Lang 3",
            addStore: "Tòa nhà A, trường Văn Lang cơ sở 3, số 80/68 Dương Quảng Hàm, Phường 5, Q.Gò Vấp, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025103",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_GVap,
        wards: P_8,
        store: {
            nameStore: "GS25 Phạm Văn Chiêu",
            addStore: "6C-6D Phạm Văn Chiêu, Phường 8, Q uận Gò Vấp, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025090",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_GVap,
        wards: P_8,
        store: {
            nameStore: "GS25 Nguyễn Văn Khôi",
            addStore: "Số 443-445 Nguyễn Văn Khôi, Phường 8, Q.Gò Vấp, TP.HCM",
            phoneStore: "(028) 71025161",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_GVap,
        wards: P_12,
        store: {
            nameStore: "GS25 Phan Huy Ích",
            addStore: "160 Phan Huy Ích, Phường 12, Q.Gò Vấp, TP. Hồ Chí Minh, Việt Nam.",
            phoneStore: "(028) 71025142",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_GVap,
        wards: P_14,
        store: {
            nameStore: "GS25 Dream Home",
            addStore: "148/60 Đường 59, Phường 14, Quận Gò Vấp, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025125",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_PNhuan,
        wards: P_8,
        store: {
            nameStore: "GS25 Centre Point",
            addStore: "106 Nguyễn Văn Trỗi, Phường 8, Phú Nhuận, Hồ Chí Minh",
            phoneStore: "(028) 73025027",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_PNhuan,
        wards: P_10,
        store: {
            nameStore: "GS25 Đặng Văn Ngữ",
            addStore: "Số 70 Đặng Văn Ngữ, Phường 10, Q.Phú Nhuận, Hồ Chí Minh",
            phoneStore: "(028) 71025096",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_PNhuan,
        wards: P_14,
        store: {
            nameStore: "GS25 Huỳnh Văn Bánh",
            addStore: "Số 511 Huỳnh Văn Bánh, Phường 14, Q.Phú Nhuận, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025015",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_PNhuan,
        wards: P_15,
        store: {
            nameStore: "GS25 Kingston",
            addStore: "223 - 223B Hoàng Văn Thụ, P.15, Q.Phú Nhuận, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025022",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TBinh,
        wards: P_2,
        store: {
            nameStore: "GS25 Phổ Quang",
            addStore: "8A Phổ Quang, P. 02, Q. Tân Bình, TP. Thành phố Hồ Chí Minh",
            phoneStore: "(028) 73025039",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TBinh,
        wards: P_2,
        store: {
            nameStore: "GS25 Hồng Hà",
            addStore: "12 Hồng Hà, P.2, Q. Tân Bình",
            phoneStore: "(028) 71025072",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TBinh,
        wards: P_2,
        store: {
            nameStore: "GS25 The Botanica",
            addStore: "Shop house, TB-01.01, Tầng trệt, Tòa nhà Botanica, 104 Phổ Quang, P.2, Q.Tân Bình, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025143",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TBinh,
        wards: P_9,
        store: {
            nameStore: "GS25 Lý Thường Kiệt",
            addStore: "373 / 3-3A Lý Thường Kiệt, P.9, Q.Tân Bình, TP.HCM",
            phoneStore: "(028) 73025030",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TBinh,
        wards: P_12,
        store: {
            nameStore: "GS25 Cộng Hòa Garden",
            addStore: "- Trục A1 &amp; # 39; - C / 1-2 thuộc tầng 1 khối đế D-E, dự án khu phức hợp Cộng Hòa Garden. Địa chỉ tại 20 Cộng Hòa, P.12, Q.Tân Bình, TP.HCM",
            phoneStore: "(028) 73025036",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TBinh,
        wards: P_12,
        store: {
            nameStore: "GS25 Nguyễn Thái Bình",
            addStore: "260A Nguyễn Thái Bình, Q.Tân Bình, TP.HCM",
            phoneStore: "(028) 71025166",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TBinh,
        wards: P_13,
        store: {
            nameStore: "GS25 Hoàng Hoa Thám",
            addStore: "63 Hoàng Hoa Thám, P.13, Q.Tân Bình, TP.HCM",
            phoneStore: "(028) 71025149",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TBinh,
        wards: P_14,
        store: {
            nameStore: "GS25 Trương Công Định",
            addStore: "35 Trương Công Định, P.14, Q.Tân Bình, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025014",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TPhu,
        wards: P_PTHoa,
        store: {
            nameStore: "GS25 Lê Vĩnh Hòa",
            addStore: "130-130A Lê Vĩnh Hòa, P. Phú Thọ Hòa, Q. Tân Phú",
            phoneStore: "(028) 71025070",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TPhu,
        wards: P_HoaThanh,
        store: {
            nameStore: "GS25 Resgreen",
            addStore: "34A-36 Thoại Ngọc Hầu, P. Hòa Thạnh, Q. Tân Phú",
            phoneStore: "(028) 71025087",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TPhu,
        wards: P_PTrung,
        store: {
            nameStore: "GS25 Âu Cơ",
            addStore: "Số 605 Âu Cơ, Phường Phú Trung, Quận Tân Phú",
            phoneStore: "(028) 71025105",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TPhu,
        wards: P_TTHoa,
        store: {
            nameStore: "GS25 Carillon 7",
            addStore: "Căn hộ CR7-TM-A01-001-12B-S12, Lầu 1, Tòa nhà Carillon 7, 33 Lương Minh Nguyệt, P. Tân Thới Hòa, Q. Tân Phú",
            phoneStore: "(028) 71025147",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TPhu,
        wards: P_TanQuy,
        store: {
            nameStore: "GS25 Tân Hương",
            addStore: "290-292-294 Đường Tân Hương, Phường Tân Quý, Quân Tân Phú, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025165",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_TPhu,
        wards: P_TSNhi,
        store: {
            nameStore: "GS25 Nguyễn Thế Truyện",
            addStore: "Số 30 Đường Nguyễn Thế Truyện, Phường Tân Sơn Nhì, Quận Tân Phú, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025167",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_BChanh,
        wards: X_BHung,
        store: {
            nameStore: "GS25 Trung Sơn",
            addStore: "Số 53, Đường 9A, Khu dân cư Trung Sơn, Xã Bình Hưng, Huyện Bình Chánh, Thành phố Hồ Chí Minh, Việt Nam.",
            phoneStore: "(028) 73025006",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_NBe,
        wards: X_PKien,
        store: {
            nameStore: "GS25 The Park Residence",
            addStore: "44,46,48 Tầng trệt, Block B4, Chung cư The Park Residence - 12 Nguyễn Hữu Thọ, Phường Phước Kiển, Huyện Nhà Bè, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025032",
        }
    },
    {
        provinces: TP_HCM,
        districts: Q_NBe,
        wards: X_PKien,
        store: {
            nameStore: "GS25 Sunrise Riverside",
            addStore: "Lô K.1.09 (Tháp K) Căn hộ Sunrise Riverside, Xã Phước Kiển, Huyện Nhà Bè, TP. Hồ Chí Minh",
            phoneStore: "(028) 73025551",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 VinCity 1",
            addStore: "S1.02-khu A, dự án khu dân cư và công viên Phước Thiện, số 512 đường Nguyễn Xiển, khu phố Long Hòa, phường Long Thạnh Mỹ, Thành phố Thủ Đức, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025097",
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 2",
            addStore: "1,01 Tầng 1, Chung cư số S2.02 Khu A - Khu dân cư và công viên Phước Thiện số 512 Nguyễn Xiển, Khu phố Long Hòa, Phường Long Thạnh Mỹ, Thành phố Thủ Đức, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025104"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 3",
            addStore: "Căn 1.20, Tầng 1, Chung cư S2.05, Khu A - KDC &amp; Công viên Phước Thiện - Số 512 Nguyễn Xiển, KP. Long Hòa, P. Long Thạnh Mỹ, TP. Thủ Đức, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025116"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 4",
            addStore: "Căn hộ thương mại 1.20 - Tầng 1, Tòa nhà căn hộ số S1.07, Khu A - Dự án Khu dân cư và công viên Phước Thiện số 512 Nguyễn Xiển, Khu phố Long Hòa, Phường Long Thạnh Mỹ, Thành phố Thủ Đức, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025130"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 5",
            addStore: "Số 1S.01 tại tầng: 1, Căn hộ số: S3.05 thuộc Khu A - Dự án Khu dân cư và Công viên Phước Thiện tại số 512 Nguyễn Xiển, Khu phố Long Hòa, Phường Long Thạnh Mỹ, Thành phố Thủ Đức, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025119"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 6",
            addStore: "Tầng 1,07: 1. Căn hộ số S5.02 thuộc Khu A - Dự án Khu dân cư và Công viên Phước Thiện số 512 Nguyễn Xiển, Khu phố Long Hòa, Phường Long Thạnh Mỹ, Thành phố Thủ Đức, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025120"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 8",
            addStore: "Căn hộ thương mại 1.20 - Tầng 1, chung cư S5.03, Khu A - Dự án Khu dân cư và công viên Phước Thiện tại 512 Nguyễn Xiển, Khu phố Long Hòa, Phường Long Thạnh Mỹ, Thành phố Thủ Đức, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025132"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 Vincity 9",
            addStore: "Căn hộ thương mại 1.01 - Tầng 1, tòa căn hộ số S3.02, Khu A - Dự án khu dân cư và công viên Phước Thiện số 512 Nguyễn Xiển, Khu phố Long Hòa, Phường Long Thạnh Mỹ, Thành phố Thủ Đức, Thành phố Hồ Chí Minh",
            phoneStore: "(028) 71025120"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LChieu,
        store: {
            nameStore: "GS25 SPKT",
            addStore: "Số 1-3 Võ Văn Ngân, P. Linh Chiểu, Q. Thủ Đức, TP. Thành phố Hồ Chí Minh",
            phoneStore: "(028) 73025040"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_BTho,
        store: {
            nameStore: "GS25 Đặng Văn Bi",
            addStore: "162 Đặng Văn Bi, Khu phố 1, Phường Bình Thọ, Quận Thủ Đức",
            phoneStore: "(028) 71025082"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_TNPhuB,
        store: {
            nameStore: "GS25 CĐ Giao Thông Vận Tải",
            addStore: "Số 252A - 254 Đường Đình Hội, Khu phố 3, Phường Tăng Nhơn Phú B, Thành phố Thủ Đức, TP. Hồ Chí Minh",
            phoneStore: "(028) 71025112"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_PLongA,
        store: {
            nameStore: "GS25 Hồ Bá Phấn",
            addStore: "Số 57 Đường Hồ Bá Phấn, Khu phố 4, Phường Phước Long A, Thủ Đức, Tp. Hồ Chí Minh",
            phoneStore: "(028) 71025113"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_TDien,
        store: {
            nameStore: "GS25 Masteri An Phú",
            addStore: "Căn hộ dịch vụ thương mại (Tầng 1-2-3) số 28-TMDV Chung cư phường Thảo Điền, TP. Thủ Đức tại 179 Xa Lộ Hà Nội, P.Thảo Điền, Q.Thủ Đức, TP.HCM",
            phoneStore: "(028) 71025117"
        }
    },
    {
        provinces: TP_TD,
        districts: Q_TDuc,
        wards: P_LTMy,
        store: {
            nameStore: "GS25 THPT Nguyễn Huệ",
            addStore: "Số 6 Đường Nguyễn Văn Tăng, Phường Long Thạnh Mỹ, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam",
            phoneStore: "(028) 71025128"
        }
    },
]

export { listStoreGS25, listProvices }