import vietinbank from '../images/LogoBank/vietinbank.png'
import hdbank from '../images/LogoBank/hdbank.png'
import NCB from '../images/LogoBank/NCB.png'
import MBB from '../images/LogoBank/MBB.png'
import msb from '../images/LogoBank/msb.png'
import vietabank from '../images/LogoBank/vietabank.png'
import vietcombank from '../images/LogoBank/vietcombank.png'
import vib from '../images/LogoBank/vib.png'
import gpbank from '../images/LogoBank/gpbank.png'
import OCEAN from '../images/LogoBank/OCEAN.png'
import bacabank from '../images/LogoBank/bacabank.png'
import abbank from '../images/LogoBank/abbank.png'
import bidv from '../images/LogoBank/bidv.png'
import shb from '../images/LogoBank/shb.png'
import baovietbank from '../images/LogoBank/baovietbank.png'
import tpbank from '../images/LogoBank/tpbank.png'
import AGRB from '../images/LogoBank/AGRB.png'
import scbbank from '../images/LogoBank/scbbank.png'
import kienlongbank from '../images/LogoBank/kienlongbank.png'
import seabank from '../images/LogoBank/seabank.png'
import vietbank from '../images/LogoBank/vietbank.png'
import publicbank from '../images/LogoBank/publicbank.png'
import NAMA from '../images/LogoBank/NAMA.png'
import pvcombank from '../images/LogoBank/pvcombank.png'
import saigonbank from '../images/LogoBank/saigonbank.png'
import techcombank from '../images/LogoBank/techcombank.png'
import acb from '../images/LogoBank/acb.png'
import sacombank from '../images/LogoBank/sacombank.png'
import vpbank from '../images/LogoBank/vpbank.png'
import OCB from '../images/LogoBank/OCB.png'
import pgbank from '../images/LogoBank/pgbank.png'
import UOB from '../images/LogoBank/UOB.png'
import idovinabank from '../images/LogoBank/idovinabank.png'
import WIB from '../images/LogoBank/WIB.png'
import exmimbank from '../images/LogoBank/exmimbank.png'
import shinhan from '../images/LogoBank/shinhan.png'
import vietcapital from '../images/LogoBank/vietcapital.png'
import vrb from '../images/LogoBank/vrb.png'

export const LogoBankData = [
    {
        type: 'logo',
        step: 1,
        image: hdbank,
    },
    {
        type: 'logo',
        step: 2,
        image: NCB,
    },
    {
        type: 'logo',
        step: 3,
        image: msb,
    },
    {
        type: 'logo',
        step: 4  ,
        image: vietabank,
    },
    {
        type: 'logo',
        step: 5,
        image: vietcombank,
    },
    {
        type: 'logo',
        step: 6,
        image: vib,
    },
    {
        type: 'logo',
        step: 7,
        image: MBB,
    },
    {
        type: 'logo',
        step: 8,
        image: vietinbank,
    },
    {
        type: 'logo',
        step: 9,
        image: gpbank,
    },
    {
        type: 'logo',
        step: 10,
        image: OCEAN,
    },   
    {
        type: 'logo',
        step: 11,
        image: bacabank,
    },
    {
        type: 'logo',
        step: 12,
        image: abbank,
    },
    {
        type: 'logo',
        step: 13,
        image: bidv,
    },
    {
        type: 'logo',
        step: 14,
        image: shb,
    },
    {
        type: 'logo',
        step: 15,
        image: baovietbank,
    },
    {
        type: 'logo',
        step: 16,
        image: tpbank,
    },
    {
        type: 'logo',
        step: 17,
        image: AGRB,
    },
    {
        type: 'logo',
        step: 18,
        image: scbbank,
    },
    {
        type: 'logo',
        step: 19,
        image: kienlongbank,
    },
    {
        type: 'logo',
        step: 20,
        image: seabank,
    },
    {
        type: 'logo',
        step: 21,
        image: vietbank,
    },
    {
        type: 'logo',
        step: 22,
        image: publicbank,
    },
    {
        type: 'logo',
        step: 23,
        image: NAMA,
    },
    {
        type: 'logo',
        step: 24,
        image: pvcombank,
    },
    {
        type: 'logo',
        step: 25,
        image: vpbank,
    },
    {
        type: 'logo',
        step: 26,
        image: OCB,
    },
    {
        type: 'logo',
        step: 27,
        image: pgbank,
    },
    {
        type: 'logo',
        step: 28,
        image: UOB,
    },
    {
        type: 'logo',
        step: 29,
        image: saigonbank,
    },
    {
        type: 'logo',
        step: 30,
        image: techcombank,
    },
    {
        type: 'logo',
        step: 31,
        image: acb,
    },
    {
        type: 'logo',
        step: 32,
        image: sacombank,
    },
    {
        type: 'logo',
        step: 33,
        image: vietcapital,
    },
    {
        type: 'logo',
        step: 34,
        image: vrb,
    },
    {
        type: 'logo',
        step: 35,
        image: idovinabank,
    },
    {
        type: 'logo',
        step: 36,
        image: WIB,
    },
    {
        type: 'logo',
        step: 37,
        image: exmimbank,
    },
    {
        type: 'logo',
        step: 38,
        image: shinhan,
    },
]