import React, { useState, useRef, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import { useHistory, NavLink, useLocation, Link } from "react-router-dom";

// styles
import { WrapHeader } from './index.styles'

// image
import logo_facepay from '../../assets/images/LogoFacepay/logo_facepay.svg'
import logo_facepay_mobile from '../../assets/images/LogoFacepay/logo_facepay_mobile.svg'
import ic_tpbank from '../../assets/images/IconMiniTpBank/ic_mini_tpbank.svg'
import ic_ocbbank from '../../assets/images/IconMiniOcbBank/ic_mini_ocbbank.svg'
import ic_vietinbank from '../../assets/images/IconMiniVietinbank/ic_vtb.svg'
import ic_smartpay from '../../assets/images/IconMiniSmartPay/ic_smartpay.svg'
import ic_mini_eximbank from '../../assets/images/IconMiniEximbank/ic_mini_eximbank.svg'

// component
import BurgerBtn from './BurgerBtn';

//data
import { menuData } from '../../assets/data/MenuData'

//constant
import { HOME, FOUNDER, WEE_DIGITAL, FACEPAY_BY_BANK, TPBANK, OCB, VTB, SP, EXIM } from '../../assets/constant/pathNames';

const BLACK_COLOR = '#304659'
const WHITE_COLOR = '#F7FCFF'
const matchChangeBgColor = [`/${FOUNDER}`, `/${WEE_DIGITAL}`]

const popupData = [
    {
        icon: ic_tpbank,
        text: "TPBank",
        path: `/${FACEPAY_BY_BANK}/${TPBANK}`,
    },
    {
        icon: ic_ocbbank,
        text: "OCB",
        path: `/${FACEPAY_BY_BANK}/${OCB}`,
    },
    {
        icon: ic_vietinbank,
        text: "Vietinbank",
        path: `/${FACEPAY_BY_BANK}/${VTB}`,
    },
    {
        icon: ic_smartpay,
        text: "Smartpay",
        path: `/${FACEPAY_BY_BANK}/${SP}`,
    },
    {
        icon: ic_mini_eximbank,
        text: "EximBank",
        path: `/${FACEPAY_BY_BANK}/${EXIM}`,
    }
]

let isMouseEnterPopup = false

const Header = ({ t }) => {
    const history = useHistory();
    const location = useLocation();

    const pathname = location.pathname;
    const lastScrollTop = useRef(0);

    const [isShowMenuMobile, setIsShowMenuMobile] = useState(false);
    const [isShowHeader, setIsShowHeader] = useState(true);
    const [changeBgColor, setChangeBgColor] = useState(false);
    const [buggerColor, setBuggerColor] = useState(undefined);

    const _handleScrollDown = (st) => {
        // setIsShowHeader(false)
        st > 10 && setChangeBgColor(true)
    }

    const _handleScrollUp = (st) => {
        // setIsShowHeader(true)
        // st !== 0 && setChangeBgColor(true)
        st <= 10 && setChangeBgColor(false)
    }

    const _handleScroll = () => {
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop.current) {
            _handleScrollDown(st)
        } else {
            _handleScrollUp(st)
        }
        lastScrollTop.current = st <= 0 ? 0 : st;
    }

    const checkPathNameChangeBuggerColor = (pathname) => {
        return matchChangeBgColor.includes(pathname)
    }

    useEffect(() => {
        document.addEventListener('scroll', _handleScroll)

        return () => {
            document.removeEventListener('scroll', _handleScroll)
        }
    }, []);

    useEffect(() => {
        if (!checkPathNameChangeBuggerColor(pathname))
            return;
        setBuggerColor(WHITE_COLOR)
    }, []);

    useEffect(() => {
        history.listen((location) => {
            if (!checkPathNameChangeBuggerColor(location.pathname)) {
                setBuggerColor(BLACK_COLOR)
                return;
            }
            setBuggerColor(WHITE_COLOR)
        })
        return () => {
            history.listen(() => null)
        }
    }, [history]);

    useEffect(() => {
        if (!checkPathNameChangeBuggerColor(pathname))
            return;
        if (changeBgColor) {
            setBuggerColor(BLACK_COLOR)
            return;
        }
        setBuggerColor(WHITE_COLOR)
    }, [changeBgColor]);

    useEffect(() => {
        if (!checkPathNameChangeBuggerColor(pathname))
            return;
        if (isShowMenuMobile) {
            setBuggerColor(BLACK_COLOR)
            return;
        }
        if (!changeBgColor) {
            setBuggerColor(WHITE_COLOR)
            return;
        }
    }, [isShowMenuMobile]);
    
    const _handleChangeMenu = (item) => {
        if (isMouseEnterPopup) return
        history.push(item.path)
    }

    const _handleChooseBank = (item) => {
        history.push(item.path)
    }

    return (
        <WrapHeader isShowHeader={isShowHeader} changeBgColor={changeBgColor}>
            <div className="header-desktop">
                <div className="inner-header-desktop">
                    <Link to={`/${HOME}`}>
                        <img src={logo_facepay} alt='facepay' />
                    </Link>
                    <div className="wrap-menu">
                        {
                            menuData.map((item, i) => (
                                <div
                                    // to={item.path}
                                    key={i}
                                    className={
                                        pathname === item.path || pathname.includes(item.path)
                                            ? "navigation-routes-link active"
                                            : "navigation-routes-link"
                                    }
                                    onClick={() => _handleChangeMenu(item)}
                                >
                                    <div className={`menu-item ${item.class ? item.class : ""}`}>
                                        {t(item.label)}
                                        <div className={
                                            pathname === item.path || pathname.includes(item.path)
                                                ? "line active"
                                                : "line"
                                        } />
                                        <div className={`wrap-popup ${item.class ? item.class : ""}`}
                                            onMouseEnter={() => { isMouseEnterPopup = true }}
                                            onMouseLeave={() => { isMouseEnterPopup = false }}>
                                            {
                                                popupData.map((item, i) =>
                                                    <div className="item" key={i} onClick={() => _handleChooseBank(item)}>
                                                        <img className="icon" src={item.icon} alt="icon-bank" />
                                                        <p>{item.text}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className={`header-mobile${isShowMenuMobile ? ' active' : ''}`}>
                <div className="wrap-logo">
                    <Link to={`/${HOME}`}>
                        <img src={logo_facepay} alt='facepay' />
                    </Link>
                    <BurgerBtn
                        className="burger-btn"
                        color={buggerColor}
                        checkExpand={isShowMenuMobile}
                        handleToggle={(toggle) => setIsShowMenuMobile(toggle)}
                    />
                </div>
                {
                    isShowHeader &&
                    <div className="wrap-menu">
                        <div className="inner-menu">
                            {
                                menuData.map(({ path, label }) => (
                                    <Link to={path} key={path} onClick={() => setIsShowMenuMobile(pre => !pre)}>{t(label)}</Link>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
        </WrapHeader>
    )
}

export default withNamespaces()(Header)
