import styled from 'styled-components'

const WrapQAndAItem = styled.div`

    font-family: 'SVN-Gilroy';
    /* border: 0.2px solid rgba(147, 147, 147, 0.5); */
    box-sizing: border-box;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07); */
    box-shadow: inset 0px -1px 0px #C8C8C8;
    /* border-radius: 6px; */
    color: #000000;

    .wrap-quest {
        display: flex;
        align-items: center;
        padding: 18px 12px;
        /* min-height: 69px; */

        & > * {
            flex-shrink: 0;
        }

        .question {
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 21px;
            flex-grow: 1;
            flex-basis: 60%;
        }

        .ic-expand {
            margin-left: 38px;

            &.expand {
                transform: rotate(90deg);
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .answer {
        padding: 0 12px 12px 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        display: flex;
        flex-direction: column;
        .view{
            font-weight: normal;
            font-size: 13px;
            line-height: 130%;
            color: #1279DA;
            cursor: pointer;
            text-decoration: none;
            padding-top: 10px;
        }
    }

    @media only screen and (min-width: 768px) {
        .wrap-quest {
            padding: 30px 12px 30px 12px;

            .question {
                font-size: 22px;
                line-height: 26px;
            }
        }

        .answer {
            padding: 0 12px 24px 12px;
            font-size: 16px;
            line-height: 23px;
        }
    }
`;

export {
    WrapQAndAItem
}