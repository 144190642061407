import React, { useState, useRef, useEffect } from 'react'

//help
import { useOnClickOutside } from '../../../assets/helps/useClickOutside'
import { useTargetActiveElement } from '../../../assets/helps/useTargetActiveElement'

//component
import IcArrowDown from '../../../assets/images/IcArrowDown'

// styles
import { WrapDropdownList } from './index.styles'

const DropdownList = ({
    title,
    listItem,
    value,
    handleSetValue,
    isRequire,
    errStr,
    className, }) => {

    //scroll to top 
    let key = null
    let elementActiveRef = useRef(null)
    const listSelectedRef = useRef(null)
    const dropdownRef = useRef(null)

    const [isShowPopup, setIsShowPopup] = useState(false)
    const [err, setErr] = useState(errStr)

    useEffect(() => {
        if (!isShowPopup) return
        if (!value) return
        key = value.key
        elementActiveRef.current = listSelectedRef.current.querySelector(`.tag-${key}`)
    }, [isShowPopup])

    useTargetActiveElement(listSelectedRef, elementActiveRef, isShowPopup)

    useEffect(() => {
        setErr(errStr)
    }, [errStr])

    const _toggleOnClick = () => {
        if (!listItem || listItem.length === 0) return
        setIsShowPopup(!isShowPopup)
    }

    const _handleSetValue = (item) => {
        handleSetValue(item)
        setIsShowPopup(false)
    }

    useOnClickOutside(dropdownRef, () => {
        setIsShowPopup(false)
    })

    return (
        <WrapDropdownList className={className} ref={dropdownRef}>
            <div className={err ? "wrap-title err" : "wrap-title"} onClick={_toggleOnClick} >
                {
                    value ?
                        <p className="value" title={value.name}>{value.name}</p>
                        :
                        <p className="value title" >
                            {title} {isRequire && <span className="note"></span>}
                        </p>
                }
                <IcArrowDown className="icon-arrow" />
            </div>
            <p className="errro">{err}</p>
            {
                isShowPopup && listItem.length !== 0 &&
                <div className="list-drop-down"  >
                    <div className="wrap-selection" ref={listSelectedRef} >
                        {
                            listItem.map((item, i) => {
                                return (
                                    <div
                                        className={value?.name === item.name ? `item active tag-${item.key}` : `item tag-${item.key}`}
                                        key={i}
                                        onClick={() => _handleSetValue(item)}
                                    >
                                        <p className="name" title={item.name}>{item.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </WrapDropdownList>
    )
}

export default DropdownList
