import React from 'react'

const IcSearch = (props) => {
    return (
        <svg {...props} width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 38C30.8366 38 38 30.8366 38 22C38 13.1634 30.8366 6 22 6C13.1634 6 6 13.1634 6 22C6 30.8366 13.1634 38 22 38Z" stroke="#1279DA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42 41.9998L33.3 33.2998" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcSearch
