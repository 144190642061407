import styled from 'styled-components'
const Bound = styled.div`
    height: fit-content;
    min-height: 788px;
    padding: 41px 32px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .content-text{    
        .title-page{
            font-family: 'SVN-Bebas Neue';
            font-style: normal;
            font-weight: bold;
            font-size: 72px;
            line-height: 72px;
            color: #304659;
            letter-spacing: -1px;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
        }       
        .content-page{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
            color: #191919;
            padding: 24px 0 43px 0;
            width: 300px;
        }   
        .badge-wrap{
            display: flex;
            .ic-badge{
                padding-right: 10px;
                cursor: pointer;                    
                &.google-play{
                    width: 161px;
                    height: 48px;
                }
                &.app-store{
                    width: 141px;
                    height: 48px;
                }
            }
        }   
    }
    .wrap-image{
        .image-phone{
            height: 416px;
            z-index: 1;
            position: absolute;
            top: 450px;
            right: 0;
        }
        .mask-group{
            height: 320px;
            position: absolute;
            top: 400px;
            right: 15px;
        }
    }

    @media only screen and (min-width: 830px){
        display: flex;
        flex-direction: row;
        max-width: 1200px;
        padding: 0 ;
        margin: 0 auto;
        .content-text{
            .title-page{
                font-size: 102px;
                line-height: 99px;
            }            
            .content-page{               
                font-size: 16px;
                line-height: 23px;
                width: 380px;
                padding: 28px 0 60px 0;

            }
            .badge-wrap{
                .ic-badge{
                    padding-right: 20px;                
                    &.google-play{
                        width: 188px;
                        height: 55px;
                    }
                    &.app-store{
                        width: 167px;
                        height: 55px;
                    }
                }
            }
        }
        .wrap-image{
            .image-phone{
                height: 592px;
                top: 91px;
                right: 0;
            }
            .mask-group{
                height: 400px;
                position: absolute;
                top: 179px;
                right: 250px;
            }
        }
    }
`
export { Bound }