import * as pathNames from '../constant/pathNames'

export const ConnectData = [
    {
        path: `/${pathNames.DOWNLOAD}`,
        step: "01",
        label: "Tải và đăng ký ",
    },
    {
        path: `/${pathNames.LINK}`,
        step: "02",
        label: "Liên kết ngân hàng",
    },
    // {
    //     path: `/${pathNames.PAYMENT}`,
    //     step: "03",
    //     label: "Thanh toán",
    // },
]