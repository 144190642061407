import React from 'react'
import { withNamespaces } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';

//constant
import * as pathNames from '../../../assets/constant/pathNames'

//data
import { SignUpAccountData } from '../../../assets/data/SignUpAccountData';

//component
import QAndAList from '../../Control/QAndAList';

const SignUpAccount = ({ t }) => {
    const { subSupportType } = useParams()
    const { url } = useRouteMatch()

    return (
        <QAndAList
            urlSupportPage={url.replace(`\/${subSupportType}`, "")}
            defaultActiveTab={subSupportType}
            title={t('sign_up_account.title')}
            tab={t('sign_up_account.tab', { returnObjects: true })}
            objDatas={SignUpAccountData}
            path={pathNames.SIGN_UP_ACCOUNT}
        />  
    )
}

export default withNamespaces()(SignUpAccount)
