import styled from 'styled-components'

const WrapQAndADetails = styled.div`
    font-family: 'SVN-Gilroy';
    min-height: 800px;
    /* height: 100vh; */
    padding-top: 129px;
    .page {
        padding: 0 32px 49px;
        box-sizing: border-box;

        &.page-first {
            margin: 0 auto;
        }
        &:not(:first-child) {
            margin: 20px auto 0;
        }

        .go-back {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 130%;
            color: #1279DA;
            text-decoration: none;
            cursor: pointer;
        }

        .title {
            margin-top: 14px;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 39px;
            color: #191919;
        }

        .wrap-share-date {
            margin-top: 26px;
            display: flex;
            align-items: center;
            
            button {
                height: 20px;
                border: none;
                outline: none;
                background: #395CA5;
                border-radius: 4px;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 130%;
                color: #F7FCFF;
                display: flex;
                align-items: center;
                padding: 3px 7px;
                box-sizing: border-box;
                margin-right: 7px;
                a{
                    text-decoration: none;
                    color: #F7FCFF;
                    display: flex;
                    align-items: center;
                }
                .ic-share {
                    height: 100%;
                    border-right: 1px solid #ffffff4d;
                    padding-right: 5px;
                    margin-right: 5px;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            div {
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 130%;
                color: #939393;
            }
        }

        .wrap-desc {
            margin-top: 20px;
            &:not(:first-child) {
                margin-top: 31px;
            }
            .desc {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                color: #191919;

                &:not(:first-child) {
                    margin-top: 22px;
                }

                &.bold {
                    font-weight: bold;
                    padding: 0 5px;
                }

                a {
                    text-decoration: none;
                    color: #1279DA;
                    padding: 0 5px;
                }
            }
        }

        .img-details {
            width: 100%;
            margin-top: 90px;
            object-fit: contain;
        }
        
        .wrap-note {
            margin-top: 38px;

            .note {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                color: #191919;

                &:not(:first-child) {
                    margin-top: 22px;
                }

                a {
                    text-decoration: none;
                    color: #1279DA;
                }
            }
        }
    }
    .step-bound{
        /* width: 100%; */
        max-width: 1200px;
        min-height: 450px;
        margin: 0 auto;
    }

    @media only screen and (min-width: 768px){
        padding-top: 142px;
        .page {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 0 82px 0;

            &.page-first {
                margin: 0 auto;
            }
            &:not(:first-child) {
                margin: 50px auto 0;
            }
            .go-back {
                font-size: 14px;
            }

            .title {
                margin-top: 15px;
                font-size: 50px;
                line-height: 52px;
            }

            .wrap-share-date {
                margin-top: 23px;

                button {
                    height: 24px;
                    font-size: 14px;
                }
                div {
                    font-size: 18px;
                    line-height: 23px;
                }
            }

            .wrap-desc {
                margin-top: 20px;
                font-size: 18px;
                line-height: 23px;

                &:not(:first-child) {
                    margin-top: 31px;
                }
            }

            .wrap-note {
                margin-top: 62px;
                font-size: 18px;
                line-height: 23px;

                &:not(:first-child) {
                    margin-top: 31px;
                }
            }
        }
    }
`;

export {
    WrapQAndADetails
}