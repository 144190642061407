import React, { useState, useEffect, Fragment } from 'react'
import { withNamespaces } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FacebookShareButton } from "react-share";

// constant
import { ACCOUNT_MANAGEMENT, BASIC_INFOMATION, FAQ_FACEPAY_MAGIC, PAYMENT_AT_COUNTER, RESOLVE_COMPLAINT, SIGN_UP_ACCOUNT, SUPPORT } from '../../assets/constant/pathNames'

// data
import { AccountManagementData } from '../../assets/data/AccountManagementData'
import { BasicInfomationData } from '../../assets/data/BasicInfomationData'
import { SignUpAccountData } from '../../assets/data/SignUpAccountData'
import { ResolveComplaintData } from '../../assets/data/ResolveComplaintData'
import { PaymentAtCounterData } from '../../assets/data/PaymentAtCounterData'
import { FacePayMagicFAQData } from '../../assets/data/FacePayMagicFAQData'

// component
import IcArrowLeft from '../../assets/images/IcArrowLeft'
import StepByStep from '../Control/StepByStep'
import ContactPage from '../Support/ContactPage'
import PageSix from '../Home/PageSix'

// styles
import { WrapQAndADetails } from './index.styles'

//image
import ic_facebook from '../../assets/images/IcFacebookTransparent/ic_fb_share.svg'



const QAndADetails = ({ t }) => {

    const history = useHistory()
    const { supportType, subSupportType, qAKey } = useParams()

    const [qAndA, setQAndA] = useState(null);

    const _handleComeBack = () => {
        if (history.length > 2) {
            history.goBack()
            return
        }
    }

    useEffect(() => {
        const executeQAndABySupportType = (objDatas) => {
            const qAndA = objDatas[qAKey]
            if (!qAndA) {
                history.push(`/${SUPPORT}/${supportType}`)
                return
            }
            setQAndA(qAndA)
        }
        switch (supportType) {
            case ACCOUNT_MANAGEMENT: {
                const objData = AccountManagementData[subSupportType]
                if (!objData) {
                    history.push(`/${SUPPORT}/${supportType}`)
                    break;
                }
                executeQAndABySupportType(objData)
                break;
            }
            case BASIC_INFOMATION: {
                executeQAndABySupportType(BasicInfomationData)
                break;
            }
            case PAYMENT_AT_COUNTER: {
                executeQAndABySupportType(PaymentAtCounterData)
                break;
            }
            case RESOLVE_COMPLAINT: {
                const objData = ResolveComplaintData[subSupportType]
                if (!objData) {
                    history.push(`/${SUPPORT}/${supportType}`)
                    break;
                }
                executeQAndABySupportType(objData)
                break;
            }
            case SIGN_UP_ACCOUNT: {
                const objData = SignUpAccountData[subSupportType]
                if (!objData) {
                    history.push(`/${SUPPORT}/${supportType}`)
                    break;
                }
                executeQAndABySupportType(objData)
                break;
            }
            case FAQ_FACEPAY_MAGIC: {
                const objData = FacePayMagicFAQData[subSupportType]
                if (!objData) {
                    history.push(`/${SUPPORT}/${supportType}`)
                    break;
                }
                executeQAndABySupportType(objData)
                break;
            }
            default:
                break;
        }
    }, [supportType, subSupportType, qAKey]);

    if (!qAndA)
        return null

    const { title, date, desc, step, desc_2, step_2, note, image, desc_3, desc_4 } = qAndA

    return (
        <WrapQAndADetails>
            <div className="page page-first">
                <div className="go-back" onClick={_handleComeBack}>
                    <IcArrowLeft />{t('common.quay_lai')}
                </div>
                <div className="title">{t(title)}</div>
                {/* <div className="wrap-share-date">
                    <FacebookShareButton
                        url={`https://facepay.vn${window.location.pathname}`}
                        className="ic-share">
                        <img src={ic_facebook} alt="icon-share" />
                    </FacebookShareButton>
                    <div>{t(date)}</div>
                </div> */}
                <div className="wrap-desc">
                    {desc.map((rowT, index) => {
                        const row = t(rowT, { returnObjects: true })
                        return (
                            <div className="desc" key={index}>
                                {row.map((rowItem, rowItemIndex) => {
                                    if (typeof (rowItem) === "string")
                                        return (
                                            <Fragment key={rowItemIndex}>{rowItem}</Fragment>
                                        )
                                    else if (typeof (rowItem) === "object")
                                        if (rowItem.bold) {
                                            return (
                                                <Fragment key={rowItemIndex} >
                                                    <span className="desc bold">{rowItem.bold}</span>
                                                </Fragment>
                                            )
                                        }
                                    return (
                                        <Fragment key={rowItemIndex}>
                                            <a {...qAndA[rowItem.link]}>{rowItem.text}</a>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                {
                    !!image &&
                    <img className="img-details" src={image.url} alt={image.alt} />
                }
            </div>
            {
                !!step &&
                <div className='step-bound'>
                    <StepByStep
                        defaultActiveStep={step[0].step}
                        lstStep={step}
                        isDisableChangePositionImage={true}
                    />
                </div>
            }

            {
                !!desc_3 &&
                <div className="page page-first" >
                    <div className="wrap-desc">
                        {desc_3.map((rowT, index) => {
                            const row = t(rowT, { returnObjects: true })
                            return (
                                <div className="desc" key={index}>
                                    {row.map((rowItem, rowItemIndex) => {
                                        if (typeof (rowItem) === "string")
                                            return (
                                                <Fragment key={rowItemIndex}>{rowItem}</Fragment>
                                            )
                                        return (
                                            <Fragment key={rowItemIndex}> <a {...qAndA[rowItem.link]}>{rowItem.text}</a> </Fragment>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            {
                !!desc_2 &&
                <div className="page page-first" >
                    <div className="wrap-desc">
                        {desc_2.map((rowT, index) => {
                            const row = t(rowT, { returnObjects: true })
                            return (
                                <div className="desc" key={index}>
                                    {row.map((rowItem, rowItemIndex) => {
                                        if (typeof (rowItem) === "string")
                                            return (
                                                <Fragment key={rowItemIndex}>{rowItem}</Fragment>
                                            )
                                        return (
                                            <Fragment key={rowItemIndex}> <a {...qAndA[rowItem.link]}>{rowItem.text}</a> </Fragment>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            {
                !!step_2 &&
                <div className='step-bound'>
                    <StepByStep
                        defaultActiveStep={step_2[0].step}
                        lstStep={step_2}
                        isDisableChangePositionImage = {true}
                    />
                </div>
            }
            {
                !!desc_4 &&
                <div className="page page-first" >
                    <div className="wrap-desc">
                        {desc_4.map((rowT, index) => {
                            const row = t(rowT, { returnObjects: true })
                            return (
                                <div className="desc" key={index}>
                                    {row.map((rowItem, rowItemIndex) => {
                                        if (typeof (rowItem) === "string")
                                            return (
                                                <Fragment key={rowItemIndex}>{rowItem}</Fragment>
                                            )
                                        return (
                                            <Fragment key={rowItemIndex}> <a {...qAndA[rowItem.link]}>{rowItem.text}</a> </Fragment>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            {
                !!note &&
                <div className="page">
                    <div className="wrap-note">
                        {note.map((noteItemT, nodeIndex) => {
                            const noteItem = t(noteItemT, { returnObjects: true })

                            return <div className="note" key={nodeIndex}>
                                {
                                    noteItem.map((rowNoteItem, rowNoteItemIndex) => {
                                        if (typeof (rowNoteItem) === 'string')
                                            return <Fragment key={rowNoteItemIndex}>
                                                {rowNoteItem}
                                            </Fragment>
                                        return <Fragment key={rowNoteItemIndex}> <a {...qAndA[rowNoteItem.link]}>{rowNoteItem.text}</a> </Fragment>
                                    })
                                }
                            </div>
                        })}
                    </div>
                </div>
            }
            <ContactPage />
            <PageSix />
        </WrapQAndADetails>
    )
}

export default withNamespaces()(QAndADetails)
