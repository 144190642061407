import React, { useState, useEffect, useRef } from 'react'
import { withNamespaces } from 'react-i18next';

//component
import AppStoreBadgeBlack from '../../../assets/images/AppStoreBadgeBlack';
import GooglePlayBadgeBlack from '../../../assets/images/GooglePlayBadgeBlack';
import MaskGroup from '../../../assets/images/MaskGroup'
import hand_with_phone from '../../../assets/images/hand_with_phone.png'

//style
import * as styles from './index.styles'

const PageFive = ({ t }) => {

    return (
        <styles.Bound>
            <div className="content-text">
                <div className="title-page">
                    <p>{t('home.page_five.text_1')}</p>
                    <p>{t('home.page_five.text_2')}</p>
                </div>
                <div className="content-page">
                    {t('home.page_five.text_3')}
                </div>
                <div className="badge-wrap">
                    <GooglePlayBadgeBlack className="ic-badge google-play" />
                    <AppStoreBadgeBlack className="ic-badge app-store" />
                </div>
            </div>
            <div className="wrap-image">
                <img className="image-phone" src={hand_with_phone} alt="img" />
                <MaskGroup className='mask-group' />
            </div>


        </styles.Bound>
    )
}

export default withNamespaces()(PageFive)
