
// constant
import { COMMUNICATION } from '../constant/pathNames'
import img_post1 from '../images/Communication/img_post4.png'
import img_post1_1 from '../images/Communication/img_post4_1.png'

import img_post2 from '../images/Communication/img_post2.png'
import img_post2_1 from '../images/Communication/img_post2_1.png'
import img_post2_2 from '../images/Communication/img_post2_2.png'
import img_post2_3 from '../images/Communication/img_post2_3.png'
import img_post2_4 from '../images/Communication/img_post2_4.png'
import img_post2_5 from '../images/Communication/img_post2_5.png'
import img_post3 from '../images/Communication/img_post3.png'

import img_post4 from '../images/Communication/img_post1.png'
import img_post4_1 from '../images/Communication/img_post1_1.png'

import img_post5 from '../images/Communication/img_post7.png'
import img_post5_1 from '../images/Communication/img_post7_1.png'

import img_post6 from '../images/Communication/img_post5.png'
import img_post6_1 from '../images/Communication/img_post5_1.png'

import img_post7 from '../images/Communication/img_post6.png'
import img_post7_1 from '../images/Communication/img_post6_1.png'
import img_post7_2 from '../images/Communication/img_post6_2.png'
import img_post7_3 from '../images/Communication/img_post6_3.png'
import img_post7_4 from '../images/Communication/img_post6_4.png'
import img_post7_5 from '../images/Communication/img_post6_5.png'
import img_post7_6 from '../images/Communication/img_post6_6.png'
import img_post7_7 from '../images/Communication/img_post6_7.png'

export const CommunicationData = {
    post1: {
        key: "post1",
        title: "communication.post1.title",
        date: "communication.post1.date",
        desc: "communication.post1.desc",
        link: `/${COMMUNICATION}/post1`,
        image: img_post1,
        link_content_1: {
            type: 'image',
            src: img_post1,
            alt: 'communication.post1.contents.content1.alt',
            title_img: 'communication.post1.contents.content1.title_img'
        },
        link_content_15: {
            type: 'image',
            src: img_post1_1,
            alt: 'communication.post1.contents.content15.alt',
            title_img: 'communication.post1.contents.content15.title_img'
        },
        contents: [
            "communication.post1.contents.content1",
            "communication.post1.contents.content2",
            "communication.post1.contents.content3",
            "communication.post1.contents.content4",
            "communication.post1.contents.content5",
            "communication.post1.contents.content6",
            "communication.post1.contents.content7",
            "communication.post1.contents.content8",
            "communication.post1.contents.content9",
            "communication.post1.contents.content10",
            "communication.post1.contents.content11",
            "communication.post1.contents.content12",
            "communication.post1.contents.content13",
            "communication.post1.contents.content14",
            "communication.post1.contents.content15",
            "communication.post1.contents.content16",
            "communication.post1.contents.content17",
            "communication.post1.contents.content18",
            "communication.post1.contents.content19",
            "communication.post1.contents.content20",
            "communication.post1.contents.content21",
            "communication.post1.contents.content22",
            "communication.post1.contents.content23",
            "communication.post1.contents.content24",
            "communication.post1.contents.content25",
            "communication.post1.contents.content26",
            "communication.post1.contents.content27",
            "communication.post1.contents.content28",
            "communication.post1.contents.content29",
        ],
        otherPosts: [
            'post2',
            'post3'
        ]
    },
    post2: {
        key: "post2",
        title: "communication.post2.title",
        date: "communication.post2.date",
        desc: "communication.post2.desc",
        link: `/${COMMUNICATION}/post2`,
        image: img_post2,
        link_content_1: {
            type: 'image',
            src: img_post2,
            alt: 'communication.post2.contents.content1.alt'
        },
        link_content_6: {
            type: 'image',
            src: img_post2_1,
            alt: 'communication.post2.contents.content6.alt',
            title_img: "communication.post2.contents.content6.title_img"
        },
        link_content_13: {
            type: 'image',
            src: img_post2_2,
            alt: 'communication.post2.contents.content13.alt',
            title_img: "communication.post2.contents.content13.title_img"
        },
        link_content_22: {
            type: 'image',
            src: img_post2_3,
            alt: 'communication.post2.contents.content22.alt',
            title_img: "communication.post2.contents.content22.title_img"
        },
        link_content_26: {
            type: 'image',
            src: img_post2_4,
            alt: 'communication.post2.contents.content26.alt',
            title_img: "communication.post2.contents.content26.title_img"
        },
        link_content_30: {
            type: 'image',
            src: img_post2_5,
            alt: 'communication.post2.contents.content30.alt',
            title_img: "communication.post2.contents.content30.title_img"
        },
        contents: [
            'communication.post2.contents.content1',
            'communication.post2.contents.content2',
            'communication.post2.contents.content3',
            'communication.post2.contents.content4',
            'communication.post2.contents.content5',
            'communication.post2.contents.content6',
            "communication.post2.contents.content7",
            "communication.post2.contents.content8",
            "communication.post2.contents.content9",
            "communication.post2.contents.content10",
            "communication.post2.contents.content11",
            "communication.post2.contents.content12",
            "communication.post2.contents.content13",
            "communication.post2.contents.content14",
            "communication.post2.contents.content15",
            "communication.post2.contents.content16",
            "communication.post2.contents.content17",
            "communication.post2.contents.content18",
            "communication.post2.contents.content19",
            "communication.post2.contents.content20",
            "communication.post2.contents.content21",
            "communication.post2.contents.content22",
            "communication.post2.contents.content23",
            "communication.post2.contents.content24",
            "communication.post2.contents.content25",
            "communication.post2.contents.content26",
            "communication.post2.contents.content27",
            "communication.post2.contents.content28",
            "communication.post2.contents.content29",
            "communication.post2.contents.content30",
            "communication.post2.contents.content31",
            "communication.post2.contents.content32",
            "communication.post2.contents.content33",
            "communication.post2.contents.content34"
        ],
        otherPosts: [
            'post3',
            'post4'
        ]
    },
    post3: {
        key: "post3",
        title: "communication.post3.title",
        date: "communication.post3.date",
        desc: "communication.post3.desc",
        link: `/${COMMUNICATION}/post3`,
        image: img_post3,
        link_content_2: {
            type: 'image',
            src: img_post3,
            alt: 'communication.post1.contents.content2.alt',
        },
        contents: [
            "communication.post3.contents.content1",
            "communication.post3.contents.content2",
            "communication.post3.contents.content3",
            "communication.post3.contents.content4",
            "communication.post3.contents.content5",
            "communication.post3.contents.content6",
            "communication.post3.contents.content7",
            "communication.post3.contents.content8",
            "communication.post3.contents.content9",
            "communication.post3.contents.content10",
            "communication.post3.contents.content11",
            "communication.post3.contents.content12",
            "communication.post3.contents.content13",
            "communication.post3.contents.content14",
            "communication.post3.contents.content15",
            "communication.post3.contents.content16",
            "communication.post3.contents.content17",
            "communication.post3.contents.content18",
            "communication.post3.contents.content19",
            "communication.post3.contents.content20",
            "communication.post3.contents.content21",
            "communication.post3.contents.content22",
            "communication.post3.contents.content23",
            "communication.post3.contents.content24",
            "communication.post3.contents.content25",
        ],
        otherPosts: [
            'post4',
            'post5'
        ]
    },
    post4: {
        key: "post4",
        title: "communication.post4.title",
        date: "communication.post4.date",
        desc: "communication.post4.desc",
        link: `/${COMMUNICATION}/post4`,
        image: img_post4,
        link_content_1: {
            type: 'image',
            src: img_post4,
            alt: 'communication.post4.contents.content1.alt'
        },
        link_content_9: {
            type: 'image',
            src: img_post4_1,
            alt: 'communication.post4.contents.content9.alt',
            title_img: 'communication.post4.contents.content9.title_img'
        },
        contents: [
            "communication.post4.contents.content1",
            "communication.post4.contents.content2",
            "communication.post4.contents.content3",
            "communication.post4.contents.content4",
            "communication.post4.contents.content5",
            "communication.post4.contents.content6",
            "communication.post4.contents.content7",
            "communication.post4.contents.content8",
            "communication.post4.contents.content9",
            "communication.post4.contents.content10",
            "communication.post4.contents.content11",
            "communication.post4.contents.content12",
            "communication.post4.contents.content13",
            "communication.post4.contents.content14",
            "communication.post4.contents.content15",
            "communication.post4.contents.content16",
            "communication.post4.contents.content17",
            "communication.post4.contents.content18",
            "communication.post4.contents.content19",
            "communication.post4.contents.content20",
            "communication.post4.contents.content21",
            "communication.post4.contents.content22",
            "communication.post4.contents.content23",
            "communication.post4.contents.content24",
            "communication.post4.contents.content25",
            "communication.post4.contents.content26",
            "communication.post4.contents.content27",
            "communication.post4.contents.content28",
            "communication.post4.contents.content29",
            "communication.post4.contents.content30",
            "communication.post4.contents.content31",
            "communication.post4.contents.content32",
            "communication.post4.contents.content33",
            "communication.post4.contents.content34",
            "communication.post4.contents.content35",
            "communication.post4.contents.content36",
            "communication.post4.contents.content37",
            "communication.post4.contents.content38",
            "communication.post4.contents.content39",
            "communication.post4.contents.content40",
            "communication.post4.contents.content41",
            "communication.post4.contents.content42",
            "communication.post4.contents.content43",
            "communication.post4.contents.content44",
            "communication.post4.contents.content45",
        ],
        otherPosts: [
            'post5',
            'post6'
        ]
    },
    post5: {
        key: "post5",
        title: "communication.post5.title",
        date: "communication.post5.date",
        desc: "communication.post5.desc",
        link: `/${COMMUNICATION}/post5`,
        image: img_post5,
        link_content_12: {
            type: 'image',
            src: img_post5,
            alt: 'communication.post5.contents.content12.alt',
            title_img: 'communication.post5.contents.content12.title_img'
        },
        link_content_32: {
            type: 'image',
            src: img_post5_1,
            alt: 'communication.post5.contents.content32.alt',
            title_img: 'communication.post5.contents.content32.title_img'
        },
        contents: [
            "communication.post5.contents.content1",
            "communication.post5.contents.content2",
            "communication.post5.contents.content3",
            "communication.post5.contents.content4",
            "communication.post5.contents.content5",
            "communication.post5.contents.content6",
            "communication.post5.contents.content7",
            "communication.post5.contents.content8",
            "communication.post5.contents.content9",
            "communication.post5.contents.content10",
            "communication.post5.contents.content11",
            "communication.post5.contents.content12",
            "communication.post5.contents.content13",
            "communication.post5.contents.content14",
            "communication.post5.contents.content15",
            "communication.post5.contents.content16",
            "communication.post5.contents.content17",
            "communication.post5.contents.content18",
            "communication.post5.contents.content19",
            "communication.post5.contents.content20",
            "communication.post5.contents.content21",
            "communication.post5.contents.content22",
            "communication.post5.contents.content23",
            "communication.post5.contents.content24",
            "communication.post5.contents.content25",
            "communication.post5.contents.content26",
            "communication.post5.contents.content27",
            "communication.post5.contents.content28",
            "communication.post5.contents.content29",
            "communication.post5.contents.content30",
            "communication.post5.contents.content31",
            "communication.post5.contents.content32",
            "communication.post5.contents.content33",
            "communication.post5.contents.content34",
            "communication.post5.contents.content35",
            "communication.post5.contents.content36",
            "communication.post5.contents.content37",
            "communication.post5.contents.content38",
            "communication.post5.contents.content39",
        ],
        otherPosts: [
            'post6', 'post7'
        ]
    },
    post6: {
        key: "post6",
        title: "communication.post6.title",
        date: "communication.post6.date",
        desc: "communication.post6.desc",
        link: `/${COMMUNICATION}/post6`,
        image: img_post6_1,
        link_content_1: {
            type: 'image',
            src: img_post6,
            alt: 'communication.post6.contents.content1.alt',
            title_img: 'communication.post6.contents.content1.title_img'
        },
        link_content_9: {
            type: 'image',
            src: img_post6_1,
            alt: 'communication.post6.contents.content9.alt',
            title_img: 'communication.post6.contents.content9.title_img'
        },
        contents: [
            "communication.post6.contents.content1",
            "communication.post6.contents.content2",
            "communication.post6.contents.content3",
            "communication.post6.contents.content4",
            "communication.post6.contents.content5",
            "communication.post6.contents.content6",
            "communication.post6.contents.content7",
            "communication.post6.contents.content8",
            "communication.post6.contents.content9",
            "communication.post6.contents.content10",
            "communication.post6.contents.content11",
            "communication.post6.contents.content12",
            "communication.post6.contents.content13",
            "communication.post6.contents.content14",
            "communication.post6.contents.content15",
            "communication.post6.contents.content16",
            "communication.post6.contents.content17",
            "communication.post6.contents.content18",
            "communication.post6.contents.content19",
            "communication.post6.contents.content20",
        ],
        otherPosts: [
            'post7'
        ]
    },
    post7: {
        key: "post7",
        title: "communication.post7.title",
        date: "communication.post7.date",
        desc: "communication.post7.desc",
        link: `/${COMMUNICATION}/post7`,
        image: img_post7_4,
        link_content_1: {
            type: 'image',
            src: img_post7,
            alt: 'communication.post7.contents.content1.alt',
        },
        link_content_2: {
            type: 'image',
            src: img_post7_1,
            alt: 'communication.post7.contents.content2.alt',
        },
        link_content_10: {
            type: 'image',
            src: img_post7_2,
            alt: 'communication.post7.contents.content10.alt',
        },
        link_content_19: {
            type: 'image',
            src: img_post7_3,
            alt: 'communication.post7.contents.content19.alt',
        },
        link_content_25: {
            type: 'image',
            src: img_post7_4,
            alt: 'communication.post7.contents.content25.alt',
        },
        link_content_38: {
            type: 'image',
            src: img_post7_5,
            alt: 'communication.post7.contents.content38.alt',
        },
        link_content_41: {
            type: 'image',
            src: img_post7_6,
            alt: 'communication.post7.contents.content41.alt',
        },
        link_content_50: {
            type: 'image',
            src: img_post7_7,
            alt: 'communication.post7.contents.content50.alt',
        },
        contents: [
            "communication.post7.contents.content1",
            "communication.post7.contents.content2",
            "communication.post7.contents.content3",
            "communication.post7.contents.content4",
            "communication.post7.contents.content5",
            "communication.post7.contents.content6",
            "communication.post7.contents.content7",
            "communication.post7.contents.content8",
            "communication.post7.contents.content9",
            "communication.post7.contents.content10",
            "communication.post7.contents.content11",
            "communication.post7.contents.content12",
            "communication.post7.contents.content13",
            "communication.post7.contents.content14",
            "communication.post7.contents.content15",
            "communication.post7.contents.content16",
            "communication.post7.contents.content17",
            "communication.post7.contents.content18",
            "communication.post7.contents.content19",
            "communication.post7.contents.content20",
            "communication.post7.contents.content21",
            "communication.post7.contents.content22",
            "communication.post7.contents.content23",
            "communication.post7.contents.content24",
            "communication.post7.contents.content25",
            "communication.post7.contents.content26",
            "communication.post7.contents.content27",
            "communication.post7.contents.content28",
            "communication.post7.contents.content29",
            "communication.post7.contents.content30",
            "communication.post7.contents.content31",
            "communication.post7.contents.content32",
            "communication.post7.contents.content33",
            "communication.post7.contents.content34",
            "communication.post7.contents.content35",
            "communication.post7.contents.content36",
            "communication.post7.contents.content37",
            "communication.post7.contents.content38",
            "communication.post7.contents.content39",
            "communication.post7.contents.content40",
            "communication.post7.contents.content41",
            "communication.post7.contents.content42",
            "communication.post7.contents.content43",
            "communication.post7.contents.content44",
            "communication.post7.contents.content45",
            "communication.post7.contents.content46",
            "communication.post7.contents.content47",
            "communication.post7.contents.content48",
            "communication.post7.contents.content49",
            "communication.post7.contents.content50",
            "communication.post7.contents.content51",
            "communication.post7.contents.content52",
        ],
        otherPosts: [
            // 'post8',
        ]
    },

}