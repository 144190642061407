import img_01 from '../images/DownLoad/01.png'
import img_02 from '../images/DownLoad/02.png'
import img_03 from '../images/DownLoad/03.png'
import img_04 from '../images/DownLoad/04.png'
import img_05 from '../images/DownLoad/05.png'
import img_06 from '../images/DownLoad/06.png'
import img_07 from '../images/DownLoad/07.png'
import img_08 from '../images/DownLoad/08.png'
import img_09 from '../images/DownLoad/09.png'

export const ExperienceDownloadData = [
    {
        step: 1,
        image: img_01,
        text: "tutorial.connect_page.dowload.0"
    },
    {
        step: 2,
        image: img_02,
        text: "tutorial.connect_page.dowload.1"
    },
    {
        step: 3,
        image: img_03,
        text: "tutorial.connect_page.dowload.2"
    },
    {
        step: 4,
        image: img_04,
        text: "tutorial.connect_page.dowload.3"
    },
    {
        step: 5,
        image: img_05,
        text: "tutorial.connect_page.dowload.4"
    },
    {
        step: 6,
        image: img_06,
        text: "tutorial.connect_page.dowload.5"
    },
    {
        step: 7,
        image: img_07,
        text: "tutorial.connect_page.dowload.6"
    },
    {
        step: 8,
        image: img_08,
        text: "tutorial.connect_page.dowload.7"
    },
    {
        step: 9,
        image: img_09,
        text: "tutorial.connect_page.dowload.8"
    },
]