import React, { useRef, useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import lottie from 'lottie-web'

// styles
import { WrapFacePageMagic } from './index.styles'

// component
import PageSix from '../Home/PageSix';

const FacePayMagic = ({ t }) => {

    const wrapAnimationOne = useRef()
    const wrapAnimationTwo = useRef()
    const wrapAnimationThree = useRef()
    const wrapAnimationFour = useRef()

    useEffect(() => {
        lottie.loadAnimation({
            container: wrapAnimationOne.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation/FacePayMagic/page_one.json'
        })
        //
        lottie.loadAnimation({
            container: wrapAnimationTwo.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation/FacePayMagic/page_two.json'
        })
        //
        lottie.loadAnimation({
            container: wrapAnimationThree.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation/FacePayMagic/page_three.json'
        })
         //
        lottie.loadAnimation({
            container: wrapAnimationFour.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation/FacePayMagic/page_four.json'
        })
    }, []);

    return (
        <WrapFacePageMagic>
            <div className="page-wrapper page_one">
                <div className="content-text">
                    <div className="title-page">
                        <p>{t('facePay_magic.page_one.face_page')}</p>
                        <p className="title-page blue">{t('facePay_magic.page_one.magic')}</p>
                    </div>
                    <p className="content-page bold">{t('facePay_magic.page_one.la_dac_quyen')}</p>
                    <div className="content-page">
                        {t('facePay_magic.page_one.nhanh_chong_thanh')}
                    </div>
                </div>
                <div className="lottie page-one" ref={wrapAnimationOne}></div>
            </div>
            <div className="page-wrapper page_two">
                <div className="content-text">
                    <div className="title-page">
                        <p>{t('facePay_magic.page_two.tinh_nang')}</p>
                        <p className="title-page blue">{t('facePay_magic.page_two.doc_dao')}</p>
                    </div>
                    <p className="content-page bold">{t('facePay_magic.page_two.mang_den_cho')}</p>
                    <div className="content-page desc">
                        <span>{t('facePay_magic.page_two.thong_tin_1')}</span>
                        <span>{t('facePay_magic.page_two.thong_tin_2')}</span>
                        <span>{t('facePay_magic.page_two.thong_tin_3')}</span>
                    </div>
                </div>
                <div className="lottie" ref={wrapAnimationTwo}></div>
            </div>
            <div className="page-wrapper page_three">
                <div className="content-text">
                    <div className="title-page">
                        <p>{t('facePay_magic.page_three.lam_chu')}</p>
                        <p className="title-page blue">{t('facePay_magic.page_three.cuoc_song')}</p>
                    </div>
                    <p className="content-page bold">{t('facePay_magic.page_three.thoai_mai_mua')}</p>
                    <div className="content-page desc">
                        <span>{t('facePay_magic.page_three.thong_tin_1')}</span>
                        <span>{t('facePay_magic.page_three.thong_tin_2')}</span>
                        <span>{t('facePay_magic.page_three.thong_tin_3')}</span>
                        <span>{t('facePay_magic.page_three.thong_tin_4')}</span>
                    </div>
                </div>
                <div className="lottie" ref={wrapAnimationThree}></div>
            </div>
            <div className="page-wrapper page_four">
                <div className="content-text">
                    <div className="title-page">
                        <p>{t('facePay_magic.page_four.de_dang')}</p>
                        <p className="title-page blue">{t('facePay_magic.page_four.tim_kiem')}</p>
                    </div>
                    <p className="content-page bold">{t('facePay_magic.page_four.hoan_toan_chu')}</p>
                    <div className="content-page">
                        {t('facePay_magic.page_four.ban_co_the')}
                    </div>
                </div>
                <div className="lottie" ref={wrapAnimationFour}></div>
            </div>
            <div className="wrapper-footer">
                <PageSix /> 
            </div>
        </WrapFacePageMagic>
    )
}

export default withNamespaces()(FacePayMagic)
