import styled from 'styled-components'

const WrapComponent = styled.div`
    --height-header: 85px;
    display: flex;
    height: calc(100% - var(--height-header));
    min-height: calc(100vh - var(--height-header));
    .bank-page-smartpay{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        position: relative;
        .overlay{
            background: #16B3A9;
            width: 100%;
            height: 65%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
        }
        .block-left{
            display: flex;
            width: 100%;
            z-index: 1;
        }  
        .block-right{
            display: none;
            width: 338px;
            height: 100%;
            background: #16B3A9;
            position: relative;
        }      
    }
    @media only screen and (min-width: 768px){
        .bank-page-smartpay{
            grid-template-columns: 1fr auto;
            .overlay{
                display: none;
            }
            .block-left{
                background: #FFFFFF;
                height: 100%;
            }   
            .block-right{
                display: block;
                .icon{
                    position: absolute;
                    top: 66px;
                    right: 38px;
                }
            }     
        }
    }
    @media only screen and (min-width: 1024px){
        .bank-page-smartpay{
            .block-right{
                display: flex;
            }
        }
    }
`

export { WrapComponent }