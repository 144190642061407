import React from 'react'
import { withNamespaces } from 'react-i18next';

//data
import { ExperienceLinkData } from '../../../assets/data/ExperienceLinkData';

//image
import StepByStep from '../../Control/StepByStep';

const Links = ({ t }) => {

    return (       
        <>
            {/* <p className="title-step link-page">{t('tutorial.connect_page.title_link')}</p> */}
            <StepByStep
                defaultActiveStep={ExperienceLinkData[0].step}
                lstStep={ExperienceLinkData}
                isDisableChangePositionImage={true}
            />
        </>
    )
}

export default withNamespaces()(Links)
