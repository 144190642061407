import styled from 'styled-components'

//image
import bg_page_seven from '../../../assets/images/bg_page_seven.png'

const Bound = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${bg_page_seven});
    position: relative;
    min-height: 788px;
    /* margin: 40px 0; */
    display: flex;
    justify-content: center;
    align-items: center;   
    .text{
        font-family: 'SVN-Bebas Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 72px;
        line-height: 72px;
        color: #FFFFFF;
        text-transform: uppercase;        
        text-align: center;
        max-width: 190px;
        margin: 0 auto;       
    }

    @media only screen and (min-width: 830px){        
        .text{
            font-size: 102px;
            line-height: 99px;
            max-width: unset;
        }
    }
`
export { Bound }